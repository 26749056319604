import React from "react";
import styles from "../../css/shared/ToggleSwitch.module.scss";
import { PropTypes } from "prop-types";

const SIZES = {
	XSM: {
		transform: "scale(.4)",
	},
	SM: {
		transform: "scale(.7)",
	},
	MD: {
		transform: "scale(1)",
	},
	LG: {
		transform: "scale(1.3)",
	},
	XLG: {
		transform: "scale(1.6)",
	},
};

const getCheckedColor = (val, customColor) => {
	if (!val) return {};
	if (!customColor) return {};
	return { backgroundColor: customColor };
};

const ToggleSwitch = ({
	name,
	id,
	val,
	handleToggle,
	size = "MD",
	isDisabled = false,
	readOnly = false,
	customColor = null,
}) => {
	const style = {
		...SIZES[size],
		opacity: isDisabled ? ".4" : "1",
		cursor: isDisabled ? "not-allowed" : "pointer",
	};

	return (
		<div className={styles.ToggleSwitch} style={style}>
			<input
				type="checkbox"
				name={name}
				id={id}
				checked={val}
				className={styles.ToggleSwitch_input}
				onChange={handleToggle}
				style={style}
				disabled={isDisabled}
				readOnly={readOnly}
			/>
			<label
				htmlFor={id}
				className={styles.ToggleSwitch_label}
				style={getCheckedColor(val, customColor)}
			></label>
		</div>
	);
};

export default ToggleSwitch;

ToggleSwitch.defaultProps = {
	size: "MD",
	isDisabled: false,
	readOnly: false,
};

ToggleSwitch.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	val: PropTypes.bool,
	defaultCheck: PropTypes.bool,
	isDisabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	handleToggle: PropTypes.func,
};
