import { currentEnv } from "./utils_env";
import { lockouts, security } from "./utils_endpoints";
import { isEmptyArray, isEmptyVal } from "./utils_types";

/**
 * Fetches a facility's user-lockout summary.
 * @param {String} token - Security token.
 * @param {String} facilityId - The target facility ID.
 * @returns {Object} - Return includes 'Facility' object & 'UserLockOuts' list(array)
 */
const getUserLockoutSummary = async (token, facilityId) => {
	let url = currentEnv.base + lockouts.summary.getSummary;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetch failed login attempts for a user based off a specific time range (in minutes).
 * @param {String} idOrName - A string username/email, or userID.
 * @param {Number} pastMinutes - Number of minutes from now-backwards to check for failed logins.
 * @returns {Array} - Returns an array of records, if applicable.
 */
const getFailedLogins = async (token, idOrName, pastMinutes = 4320) => {
	let url = currentEnv.base + lockouts.summary.getLoginAttempts;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });
	url += "&" + new URLSearchParams({ pastMinutes });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Tests whether a user's password is a OTP/temp or not.
 * @param {String} token - Security token
 * @param {String} idOrName - A userID or username
 * @param {String} password - A unencrypted/decoded password to test.
 * @returns {Object} - Returns object w/ data about provided 'password'
 */
const checkIfOtp = async (token, idOrName, password) => {
	let url = currentEnv.base + lockouts.otp.isOtp;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });
	url += "&" + new URLSearchParams({ password });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Requests & generates a single OTP.
 * @param {String} token - Security token
 * @param {String} userLoginId - A userloginID, a unique guid.
 */
const generateOTP = async (token, userLoginId) => {
	let url = currentEnv.base + lockouts.otp.generate;
	url += "?" + new URLSearchParams({ userLoginId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		const otp = processOTP(response?.Data);
		return otp;
	} catch (err) {
		return err.message;
	}
};

/**
 * Requests & generates a single OTP and send it to the user's email address.
 * @param {String} token - Security token
 * @param {String} userLoginId - A userloginID, a unique guid.
 * @param {String} emailToAddress - Target user's email address. Recipient email.
 * @param {String} subject - Optional email subject line
 * @param {String|HTML} body - Optional email body
 * @param {Boolean} isBodyHtml - Optional boolean to specify the 'body' type (eg string, html etc)
 */
const generateAndSendOTP = async (
	token,
	userLoginId,
	emailToAddress,
	emailContent = { ...htmlEmail }
) => {
	let url = currentEnv.base + lockouts.otp.generateAndSend;
	url += "?" + new URLSearchParams({ userLoginId });
	url += "&" + new URLSearchParams({ emailToAddress });

	// if (checkForEmailContent(emailContent)) {
	// 	url += "&" + new URLSearchParams({ subject: emailContent?.subject });
	// 	url += "&" + new URLSearchParams({ body: emailContent?.body });
	// 	url += "&" + new URLSearchParams({ isBodyHtml: emailContent?.isBodyHtml });
	// }

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Requests & sends an confirmation email to the provided email address so the user can verify/confirm.
 * @param {String} token - Auth token.
 * @param {String} userIdToVerify - A user ID for the user whose email address to confirm.
 * @param {String} emailToConfirm - An email address to verify/confirm via email.
 */
const sendConfirmationEmail = async (token, userIdToVerify, emailToConfirm) => {
	let url = currentEnv.base + lockouts.confirmation.sendConfirmationEmail;
	url += "?" + new URLSearchParams({ userId: userIdToVerify });
	url += "&" + new URLSearchParams({ emailAddress: emailToConfirm });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Confirms that a given email address has been verified as operational for resets.
 * @param {String} token - Auth token.
 * @param {String} userIdToVerify - A user ID for the user whose email address to confirm.
 * @param {String} emailToConfirm - An email address to verify/confirm via email.
 */
const confirmEmail = async (token, userIdToVerify, emailToConfirm) => {
	let url = currentEnv.base + lockouts.confirmation.registerConfirmationEmail;
	url += "?" + new URLSearchParams({ userId: userIdToVerify });
	url += "&" + new URLSearchParams({ emailAddress: emailToConfirm });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const htmlEmail = {
	subject: `Reset Password`,
	body: `<b>Here's your one-time-password. Use this to login and reset your password.</b>`,
	isBodyHtml: true,
};

/**
 * Locks a user's 'Login' record.
 * @param {String} token - Security token
 * @param {String} idOrName - Username/email or userID.
 */
const lockUserLogin = async (token, idOrName) => {
	let url = currentEnv.base + lockouts.login.lock;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Unlocks a user's 'Login' record.
 * @param {String} token - Security token
 * @param {String} idOrName - Username/email or userID.
 */
const unlockUserLogin = async (token, idOrName) => {
	let url = currentEnv.base + lockouts.login.unlock;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Suspends a user's 'Login' record.
 * @param {String} token - Security token
 * @param {String} idOrName - Username/email or userID.
 */
const suspendUserLogin = async (token, idOrName) => {
	let url = currentEnv.base + lockouts.login.suspend;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Un-suspends/re-enables a user's 'Login' record.
 * @param {String} token - Security token
 * @param {String} idOrName - Username/email or userID.
 */
const unsuspendUserLogin = async (token, idOrName) => {
	let url = currentEnv.base + lockouts.login.unsuspend;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Retrieves account info for user such as: password reset methods, account status etc.
 * @param {String} token - Security token.
 * @param {String} idOrName - User's login, username, email etc.
 * @returns {Object} - Returns object of account info
 */
const getPasswordResetTypes = async (token, idOrName) => {
	let url = currentEnv.base + security.resetType.get;
	url += "?" + new URLSearchParams({ IdOrName: idOrName });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// unlock several user accounts at once
const unlockUserLoginMany = async (token, userIDs = []) => {
	const requests = userIDs.map((userID) => {
		const wasUnlocked = unlockUserLogin(token, userID);
		return wasUnlocked;
	});

	const allResponses = await Promise.all([...requests]);

	return allResponses;
};

// LOCKOUT, SUSPEND & DEACTIVATE UTILS //

/**
 * Determine which request to fire off for handling suspensions/un-suspensions.
 * @param {String} token - Auth token.
 * @param {String} idOrName - A user's guid or username.
 * @param {Boolean} isSuspended - State of a user's suspension status.
 * @returns {Boolean} - Returns whether changes were saved!
 */
const updateSuspendStatus = async (token, idOrName, isSuspended = false) => {
	if (isSuspended) {
		const wasSaved = await suspendUserLogin(token, idOrName);

		return wasSaved;
	} else {
		const wasSaved = await unsuspendUserLogin(token, idOrName);

		return wasSaved;
	}
};

const isOtp = async (token, idOrName, password) => {
	const data = await checkIfOtp(token, idOrName, password);
	const otpData = processOtpCheck(data);
	return otpData;
};

// processes 'checkIfOTP()' response data
const processOtpCheck = (data) => {
	const { IsOtp, IsExpired, OTPExpireDate } = data;

	return {
		isOtp: IsOtp,
		isExpired: IsExpired,
		otpExpiry: OTPExpireDate,
	};
};

/**
 * Process 'generateOTP' response payload
 * @returns {Object} - returns a formatted object from the payload
 */
const processOTP = (data) => {
	const { UserId, UserOTP, OTPExpireDate } = data;
	return {
		userID: UserId,
		otp: UserOTP,
		otpExpiry: OTPExpireDate,
	};
};

/**
 * Checks if HTML or String content is passed to be included in the password reset email.
 * @param {Object} content - An object w/ HTML|String email content for sending OTP(s)
 */
const checkForEmailContent = (content = {}) => {
	const hasContent =
		!isEmptyVal(content?.body) && !isEmptyVal(content?.subject);

	return hasContent;
};

const processLockoutsSummary = (summary = {}) => {
	const { Facility, UserLockOuts } = summary;
};

const createLockoutObj = (record = {}) => {
	const {
		UserId,
		UserFirstName,
		UserLastName,
		UserTitle,
		UserLoginName,
		UserLoginNameByEmail,
		LockoutTimestamp,
		TimeElapsedSinceLockout,
	} = record;

	return {
		userID: UserId,
		firstName: UserFirstName,
		lastName: UserLastName,
		title: UserTitle,
		username: UserLoginName,
		email: UserLoginNameByEmail,
		lockoutTimestamp: LockoutTimestamp,
		timeElapsedSinceLockout: TimeElapsedSinceLockout,
	};
};

const processLockoutsList = (list = []) => {
	if (isEmptyArray(list)) return [];

	return list.reduce((lockouts, entry) => {
		const lockoutObj = createLockoutObj(entry);
		lockouts = [...lockouts, lockoutObj];

		return lockouts;
	}, []);
};

const checkForUserLockout = (userID, allLockouts = []) => {
	const allIDs = allLockouts.map(({ userID }) => userID?.toLowerCase());
	return allIDs.includes(userID?.toLowerCase());
};
/**
 * Sorts user lockouts by longest time locked out.
 * @param {Array} lockouts - An array of client-formatted lockouts.
 */
const sortLockoutsByTime = (lockouts = []) => {
	if (isEmptyArray(lockouts)) return [];

	return lockouts.sort((a, b) => {
		return b?.timeElapsedSinceLockout - a?.timeElapsedSinceLockout;
	});
};

/**
 * Map of 'Login Failure Reasons' (ie. 'ERROR CODES')
 * @property {String} name - Human-readble name.
 * @property {String} desc - Description of the status/reason.
 * @property {String} systemDesc - Generic description provided by the ALAServices system.
 */
const LOGIN_FAILURES = {
	AccountException: {
		id: 0,
		errorCode: `E000`,
		name: "Account Exception",
		desc: "There was an exception with this account.",
		systemDesc: "",
	},
	AccountExpired: {
		id: 1,
		errorCode: `E100`,
		name: "Account Expired",
		desc: "This account was only enabled for a specified amount of time. That time has expired.",
		systemDesc: "",
	},
	AccountLocked: {
		id: 2,
		errorCode: `E200`,
		name: "Account Locked",
		desc: "This account is locked. This is due to multiple failed logins. Please reset your password.",
		systemDesc:
			"Login account access expired for the login information provided.",
	},
	AccountSuspended: {
		id: 3,
		errorCode: `E300`,
		name: "Account Suspended",
		desc: "This account is temporarily suspended. Contact your admin to release the account.",
		systemDesc: "",
	},
	InvalidCredential: {
		id: 4,
		errorCode: `E400`,
		name: "Invalid Credentials",
		desc: "The login info entered is incorrect.",
		systemDesc: "Credential failure due to the login information provided.",
	},
	PermissionDenied: {
		id: 5,
		errorCode: `E500`,
		name: "Permission Denied",
		desc: "You are not authorized for this application. Contact your administrator",
		systemDesc:
			"No permission granted to appplication for login information provided.",
	},
	UnknownError: {
		id: 6,
		errorCode: `E600`,
		name: "Unknown Error",
		desc: "There was an unknown error. Please try again.",
		systemDesc: "",
	},
	UserDoesNotExists: {
		id: 7,
		errorCode: `E700`,
		name: "User Does Not Exist",
		desc: "This is not a valid user. Or this user does not exist.",
		systemDesc: "",
	},
};
/**
 * Map of 'Login Failure Reasons' (ie. 'ERROR CODES')
 * @property {String} name - Human-readble name.
 * @property {String} desc - Description of the status/reason.
 * @property {String} systemDesc - Generic description provided by the ALAServices system.
 */
const ERROR_CODES = {
	E000: {
		id: 0,
		errorCode: `E000`,
		name: "Account Exception",
		desc: "There was an exception with this account.",
		systemDesc: "",
	},
	E100: {
		id: 1,
		errorCode: `E100`,
		name: "Account Expired",
		desc: "This account was only enabled for a specified amount of time. That time has expired.",
		systemDesc: "",
	},
	E200: {
		id: 2,
		errorCode: `E200`,
		name: "Account Locked",
		desc: "This account is locked. This is due to multiple failed logins. Please reset your password.",
		systemDesc:
			"Login account access expired for the login information provided.",
	},
	E300: {
		id: 3,
		errorCode: `E300`,
		name: "Account Suspended",
		desc: "This account is temporarily suspended. Contact your admin to release the account.",
		systemDesc: "",
	},
	E400: {
		id: 4,
		errorCode: `E400`,
		name: "Invalid Credentials",
		desc: "The login info entered is incorrect.",
		systemDesc: "Credential failure due to the login information provided.",
	},
	E500: {
		id: 5,
		errorCode: `E500`,
		name: "Permission Denied",
		desc: "You are not authorized for this application. Contact your administrator",
		systemDesc:
			"No permission granted to appplication for login information provided.",
	},
	E600: {
		id: 6,
		errorCode: `E600`,
		name: "Unknown Error",
		desc: "There was an unknown error. Please try again.",
		systemDesc: "",
	},
	E700: {
		id: 7,
		errorCode: `E700`,
		name: "User Does Not Exist",
		desc: "This is not a valid user. Or this user does not exist.",
		systemDesc: "",
	},
};

export {
	getUserLockoutSummary,
	getFailedLogins,
	isOtp,
	checkIfOtp,
	processOtpCheck,
	generateOTP,
	generateAndSendOTP,
	getPasswordResetTypes,
	// lockouts 'UserLogin'
	lockUserLogin,
	unlockUserLogin,
	// unlock list of userIDs (UNTESTED!!!)
	unlockUserLoginMany,
	// suspend/unsuspend 'UserLogin'
	suspendUserLogin,
	unsuspendUserLogin,
	updateSuspendStatus,
	// email confirmation
	sendConfirmationEmail,
	confirmEmail,
};

export { checkForEmailContent };

export { processLockoutsList, checkForUserLockout, sortLockoutsByTime };

export { LOGIN_FAILURES, ERROR_CODES };
