import React from "react";
import styles from "../../css/user/EditAvatarForm.module.scss";
import { PropTypes } from "prop-types";
import FileDropZone from "../shared/FileDropZone";

const EditAvatarForm = ({
	hasFile = false,
	fileVal,
	handleDragOver,
	handleFile,
	handleFileDrop,
}) => {
	return (
		<div className={styles.EditAvatarForm}>
			<div className={styles.EditAvatarForm_upload}>
				<div className={styles.EditAvatarForm_upload_label}>
					Change Avatar Image
				</div>
				<FileDropZone
					name="avatarUpload"
					id="avatarUpload"
					hasFile={hasFile}
					handleFile={handleFile}
					handleFileDrop={handleFileDrop}
					handleDragOver={handleDragOver}
					accept={["image/png", "image/jpg", "image/jpeg"]}
				/>
			</div>
			<div className={styles.EditAvatarForm_file}>
				File(s) Uploaded: <b>{fileVal?.name}</b>
			</div>
			<div className={styles.EditAvatarForm_actions}>
				<button className={styles.EditAvatarForm_actions_cancelBtn}>
					Cancel Upload
				</button>
				<button className={styles.EditAvatarForm_actions_saveBtn}>
					Save as Avatar
				</button>
			</div>
		</div>
	);
};

export default EditAvatarForm;

EditAvatarForm.defaultProps = {
	hasFile: false,
};

EditAvatarForm.propTypes = {
	hasFile: PropTypes.bool,
	fileVal: PropTypes.object,
	handleDragOver: PropTypes.func.isRequired,
	handleFile: PropTypes.func.isRequired,
	handleFileDrop: PropTypes.func.isRequired,
};
