import React, { useState } from "react";
import styles from "../../css/residents/TransferResident.module.scss";
import { PropTypes } from "prop-types";
import NumberInput from "../shared/NumberInput";
import { red } from "../../helpers/utils_styles";
import ResidentTarget from "./ResidentTarget";
import { sortAlphaAscByKey } from "../../helpers/utils_processing";

const customCSS = {
	resident: {
		width: "35rem",
		backgroundColor: "#ffffff",
	},
	facility: {
		width: "35rem",
		backgroundColor: "#ffffff",
		// marginBottom: "2rem",
	},
	load: {
		width: "max-content",
		minWidth: "max-content",
		padding: ".9rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: "1rem",
	},
	move: {
		width: "max-content",
		minWidth: "max-content",
		padding: ".9rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: "1rem",
		backgroundColor: red[600],
	},
	target: {
		width: "35rem",
		// marginTop: "2rem",
	},
};

const TransferResident = ({
	vals = {},
	currentUser = {},
	currentResident = {},
	handleChange,
	handleKeyDown,
	handleSelection,
	handleFacilitySelect,
	moveResidentToFacility,
}) => {
	const { facilities } = currentUser;
	return (
		<div className={styles.TransferResident}>
			<ResidentTarget
				vals={vals}
				handleSelection={handleSelection}
				handleFacilitySelect={handleFacilitySelect}
				facilities={[...sortAlphaAscByKey("communityName", facilities)]}
				currentResident={currentResident}
				moveResidentToFacility={moveResidentToFacility}
			/>
		</div>
	);
};

export default TransferResident;

TransferResident.defaultProps = {};

TransferResident.propTypes = {};
