import React from "react";
import styles from "../../css/app/ViewContainer.module.scss";
import { PropTypes } from "prop-types";

const ViewContainer = ({ title, desc, children }) => {
	return (
		<div className={styles.ViewContainer}>
			<header className={styles.ViewContainer_header}>
				<h2 className={styles.ViewContainer_header_title}>{title}</h2>
				<p className={styles.ViewContainer_header_desc}>{desc}</p>
			</header>
			<section className={styles.ViewContainer_view}>{children}</section>
		</div>
	);
};

export default ViewContainer;

ViewContainer.defaultProps = {};

ViewContainer.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	children: PropTypes.any,
};
