import React from "react";
import packageJSON from "../../package.json";

/**
 * Accepts a set of date values and formats into a formatted date YYYY-MM-DD or YYYY-MM.
 */
const getVersionRelease = (json) => {
	const {
		release_details: { release_version, release_date, release_prefix },
	} = json;

	const releaseDate = `${release_date}`;

	return `${release_prefix} ${release_version} ${releaseDate}`;
};

const versionRelease = getVersionRelease(packageJSON);

// version release logger util
const logVersion = (json) => {
	const version = getVersionRelease(json);
	if (process.env.NODE_ENV === "development") {
		console.log(
			`%cRelease - ${version}`,
			"font-size: 16px;font-weight:600;padding: 12px;color: #5c75ea;"
		);
		console.log("React Version:", React.version);
	}
};

export { versionRelease };

export { logVersion };
