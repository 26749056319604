import React from "react";
import styles from "../css/pages/DashboardPage.module.scss";
import { Switch } from "react-router-dom";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../helpers/utils_types";
import {
	routesConfig,
	// final 'routes' handler
	routesHandler,
} from "../helpers/utils_routes";

/**
 * Contains '/portal/:routes' for nested pages.
 */

const DashboardPage = ({ globalState, history, isCollapsed, winSize = {} }) => {
	const { currentUser } = globalState;

	return (
		<div className={styles.DashboardPage}>
			{/* AVAILABLE ROUTES */}
			<Switch>
				{!isEmptyArray(routesConfig) && [
					...routesHandler(routesConfig, currentUser, {
						history: history,
						globalState: globalState,
						isCollapsed: isCollapsed,
						winSize: winSize,
					}),
				]}
			</Switch>
		</div>
	);
};

export default DashboardPage;

DashboardPage.defaultProps = {};

DashboardPage.propTypes = {
	children: PropTypes.any,
};
