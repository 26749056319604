import React, { useRef, useEffect } from "react";
import styles from "../../css/loc/LOCModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";

const LOCModal = ({ title, children, closeModal }) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside || wasEscaped) {
			closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [closeModal, isOutside, wasEscaped]);

	return (
		<div className={styles.LOCModal} ref={modalRef}>
			<div className={styles.LOCModal_top}>
				<div className={styles.LOCModal_top_title}>{title}</div>
				<svg className={styles.LOCModal_top_close} onClick={closeModal}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</div>
			<div className={styles.LOCModal_main}>{children}</div>
		</div>
	);
};

export default LOCModal;

LOCModal.defaultProps = {};

LOCModal.propTypes = {};
