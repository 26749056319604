import React, { useState } from "react";
import styles from "../../css/facility/EnabledBadge.module.scss";
import sprite from "../../assets/icons/placeholders.svg";

const EnabledBadge = () => {
	const [showStatus, setShowStatus] = useState(false);

	return (
		<div
			className={styles.EnabledBadge}
			onMouseOver={() => setShowStatus(true)}
			onMouseLeave={() => setShowStatus(false)}
		>
			{!showStatus && (
				<svg className={styles.EnabledBadge_icon}>
					<use xlinkHref={`${sprite}#icon-done`}></use>
				</svg>
			)}
			{showStatus && <div className={styles.EnabledBadge_text}>Enabled</div>}
		</div>
	);
};

export default EnabledBadge;

EnabledBadge.defaultProps = {};
EnabledBadge.propTypes = {};
