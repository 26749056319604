import React from "react";
import styles from "../../css/loctemplate/LOCTemplateActions.module.scss";
import { PropTypes } from "prop-types";
import { blue, green } from "../../helpers/utils_styles";
// component
import ButtonSM from "../shared/ButtonSM";
import LOCTemplateSelector from "./LOCTemplateSelector";
import { isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	new: {
		padding: ".9rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		color: "#ffffff",
		backgroundColor: green[600],
		// marginRight: "auto",
	},
	load: {
		padding: ".9rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		color: "#ffffff",
		backgroundColor: blue[600],
		// marginRight: "auto",
		marginLeft: "1rem",
	},
	selector: {
		width: "35rem",
		marginLeft: "auto",
	},
	selectorWrapper: {
		marginLeft: "auto",
	},
};

const disableNewBtn = (templateType) => {
	return templateType === "NEW";
};

const LOCTemplateActions = ({
	templateType,
	selectTemplate,
	selectedTemplate = "",
	currentTemplate = {},
	facilityTemplates = {},
	initNewTemplate,
	loadExistingTemplate,
	currentUser,
	currentFacility,
}) => {
	return (
		<div className={styles.LOCTemplateActions}>
			<div className={styles.LOCTemplateActions_top}>
				<div className={styles.LOCTemplateActions_top_heading}>
					{templateType === "NEW"
						? `Editing new template...`
						: `Editing "${selectedTemplate}"...`}
				</div>
			</div>
			<div className={styles.LOCTemplateActions_main}>
				<ButtonSM
					isDisabled={disableNewBtn(templateType)}
					handleClick={initNewTemplate}
					customStyles={customCSS.new}
				>
					New Template
				</ButtonSM>

				<LOCTemplateSelector
					key={`TEMPLATE-SELECTOR-${facilityTemplates?.names?.length}`}
					currentFacility={currentFacility}
					selectedTemplate={selectedTemplate}
					handleTemplate={selectTemplate}
					templateNames={facilityTemplates.names}
					customStyles={customCSS.selector}
					custom={customCSS.selectorWrapper}
				/>
				<ButtonSM
					isDisabled={isEmptyVal(selectedTemplate)}
					handleClick={loadExistingTemplate}
					customStyles={customCSS.load}
				>
					Load Template
				</ButtonSM>
			</div>
		</div>
	);
};

export default LOCTemplateActions;

LOCTemplateActions.defaultProps = {};

LOCTemplateActions.propTypes = {
	facilityTemplates: PropTypes.shape({
		names: PropTypes.arrayOf(PropTypes.string),
		templates: PropTypes.arrayOf(PropTypes.object),
	}),
};
