import React from "react";
import styles from "../../css/userTypes/UserTypesList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import UserType from "./UserType";

const UserTypesList = ({
	userTypesList = [],
	removeUserType,
	editUserType,
	enableUserType,
	disableUserType,
}) => {
	return (
		<div className={styles.UserTypesList}>
			<ul className={styles.UserTypesList_list}>
				{!isEmptyArray(userTypesList) &&
					userTypesList.map((userType, idx) => (
						<UserType
							key={`USER-TYPE:${userType.name}-${idx}`}
							userType={userType}
							removeUserType={() => removeUserType(userType)}
							editUserType={() => editUserType(userType)}
							disableUserType={() => disableUserType(userType)}
							enableUserType={() => enableUserType(userType)}
						/>
					))}
			</ul>
		</div>
	);
};

export default UserTypesList;

UserTypesList.defaultProps = {};

UserTypesList.propTypes = {
	userTypesList: PropTypes.arrayOf(PropTypes.object),
	removeUserType: PropTypes.func,
	editUserType: PropTypes.func,
};
