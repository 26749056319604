import React, { useState, useEffect } from "react";
import styles from "../../css/user/UserSearchDetails.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import {
	getUserFacilityList,
	getUserName,
	isEmailDeleted,
} from "../../helpers/utils_user";
import { blue, blueGrey, orange, red } from "../../helpers/utils_styles";
import {
	featureFlags,
	featuresWhiteList,
	isAccessEnabled,
} from "../../helpers/utils_permissions";
import { useForm } from "../../utils/useForm";
import {
	appIDs,
	getAppAccessByUser,
	getAppAccessByUserID,
	matchAppAccessByAppID,
	saveUserAccessByApp,
} from "../../helpers/utils_apps";
import {
	formatAndSortUserFacilities,
	processFacilityList,
} from "../../helpers/utils_facility";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { addUserToEmar, disableUserToEmar } from "../../helpers/utils_emar";
import { getPasswordResetTypes } from "../../helpers/utils_lockouts";
import {
	processAccountSecurity,
	processUserApps,
} from "../../helpers/utils_security";
// components
import CopyText from "../forms/CopyText";
import AboutUser from "./AboutUser";
import Divider from "../forms/Divider";
import FormSection from "../forms/FormSection";
import ProtectedFeature from "../permissions/ProtectedFeature";
import LockoutStatus from "../summary/LockoutStatus";
import UserResetMethods from "./UserResetMethods";
import ResetMethodWarnings from "./ResetMethodWarnings";
import FormActions from "../forms/FormActions";
import EditFacilityAccess from "./EditFacilityAccess";
import UserAccessSwitch from "./UserAccessSwitch";
import EmarUserAccess from "../emar/EmarUserAccess";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	deleteBtn: {
		backgroundColor: red[600],
		color: "#ffffff",
		padding: ".7rem 1.3rem",
		borderRadius: "5rem",
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	confirmDeleteBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: ".5rem",
		backgroundColor: red[600],
		color: red[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelDeleteBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: ".5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${red[400]}`,
		marginRight: "1rem",
	},
	grantAccessBtn: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelGrantBtn: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${blueGrey[400]}`,
		marginLeft: "1rem",
	},
};

const checkForUserLockout = (userID, allLockouts = []) => {
	const allIDs = allLockouts.map(({ userID }) => userID?.toLowerCase());
	return allIDs.includes(userID?.toLowerCase());
};

// locks app access switch: enabled for supers, admins & whitelisted
const lockAppAccessSwitch = (currentUser) => {
	const hasOverride = featuresWhiteList.includes(currentUser.userID);
	const isAdminType =
		currentUser?.isAdmin ||
		currentUser?.isFacilityAdmin ||
		currentUser?.isALAAdmin;

	if (hasOverride || isAdminType) return false;
	return true;
};

const lockTrackerAccessSwitch = (appAccess = {}, currentUser) => {
	const enabledUser = !lockAppAccessSwitch(currentUser);
	const hasAccess = currentUser?.hasTrackerAccess ?? false;
	const enabledApp = appAccess?.AdvantageTracker ?? false;
	// double-check this logic?????
	const enableSwitch = enabledApp || enabledUser || hasAccess;
	const shouldLock = !enableSwitch;

	return shouldLock;
};

const DeletedIndicator = ({ selectedUser = {} }) => {
	const email = selectedUser?.email;

	if (!isEmailDeleted(email)) {
		return null;
	}
	return (
		<div className={styles.DeletedIndicator}>
			<div className={styles.DeletedIndicator_bubble}>
				<div className={styles.DeletedIndicator_bubble_text}>DELETED</div>
			</div>
		</div>
	);
};

const UserSearchDetails = ({
	vals = {},
	selectedUser = {},
	currentUser = {},
	currentFacility = {},
	allFacilities = [],
	hasLegacyAccess = false,
	hasTrackerAccess = false,
	hasPortalAccess = false,
	hasEmarAccess = false,
	dispatchAlert,
	dispatchToState, // PROBABLY NOT NEEDED???
	syncFacilityAccessChanges, // PROBABLY NOT NEEDED???
	closeModal,
}) => {
	const { formState, setFormState, handleCheckbox, handleChange } = useForm({
		isSuperUser: selectedUser.isSuperUser ?? false,
		isRegionalAdmin: selectedUser.isRegionalAdmin ?? false,
		isALAAdmin: selectedUser.isALAAdmin ?? false,
		isFacilityAdmin: selectedUser.isFacilityAdmin ?? false,
		isAdmin: selectedUser.isAdmin ?? false,
		isMedTechRestricted: selectedUser.isMedTechRestricted ?? false,
		// user status vals
		suspendUser: selectedUser.isSuspended ?? false,
		disableUser: !selectedUser.isActive ?? false,
		// lockouts
		isLockedOut:
			checkForUserLockout(selectedUser.userID, currentFacility?.lockouts) ||
			selectedUser.isLockedOut,
		// reset methods
		isPwdResetByEmail: selectedUser?.isPwdResetByEmail ?? false,
		isPwdResetByQuestions: selectedUser?.isPwdResetByQuestions ?? false,
		isPwdResetByAdmin: selectedUser?.isPwdResetByAdmin ?? true,
	});

	const { values, touched } = formState;
	const [userData, setUserData] = useState(selectedUser);
	// facility access granted to 'user' NOT 'currentUser'
	const [facilityAccessList, setFacilityAccessList] = useState([]);
	const [appAccess, setAppAccess] = useState({
		SeniorCareVB: hasLegacyAccess ?? false,
		SeniorCareEHR: hasLegacyAccess ?? false,
		AdvantageTracker: hasTrackerAccess ?? false,
		AdminPortal: hasPortalAccess ?? false,
		ChartMeds: hasEmarAccess ?? false,
	});

	const handleAccess = (e) => {
		const { name, checked } = e.target;
		setAppAccess({
			...appAccess,
			[name]: checked,
		});

		// fire off 'saveAppAccessChange(appName, accessVal)'
		saveAppAccessChange(name, checked);
	};

	const handleEmarAccess = (e) => {
		const { name, checked } = e.target;
		setAppAccess({
			...appAccess,
			[name]: checked,
		});

		return saveEmarAccess(checked);
	};

	// deletes a single user
	const handleDeleteUser = () => {
		// setShowDeleteUserConfirmation(true);
	};

	const saveResetMethods = (e) => {};
	const cancelResetMethods = (e) => {};

	// saves application access record/change
	const saveAppAccessChange = async (appName, accessVal) => {
		const { token } = currentUser;
		const { userID } = selectedUser;
		// fetch user access model here - ONLY tracker is available
		const appID = appIDs[appName];
		const accessModels = await getAppAccessByUser(token, userID);
		const accessModel = matchAppAccessByAppID(appID, accessModels);

		// const response = false;
		const response = await saveUserAccessByApp(token, {
			ApplicationByUserID: accessModel?.ApplicationByUserID ?? 0,
			ApplicationID: accessModel?.ApplicationID ?? appID,
			UserID: userID,
			IsAccessible: accessVal,
		});

		if (!isEmptyVal(response)) {
			// updateAccessList(appName, {
			// 	isEnabled: accessVal,
			// 	userID: userID,
			// });
			return dispatchAlert("INFO", {
				heading: "Success!",
				subheading: "User's access has been updated!",
			});
		} else {
			// updateAccessList(appName, {
			// 	isEnabled: accessVal,
			// 	userID: userID,
			// });
			return dispatchAlert("ERROR", {
				heading: "Ooops!",
				subheading: "User changes could NOT be saved!",
			});
		}
	};

	// save emar access for user - ChartMeds specific (currently!!!)
	const saveEmarAccess = async (accessVal) => {
		const { token } = currentUser;
		const { userID } = selectedUser;
		const { facilityID } = currentFacility;

		switch (true) {
			// DISABLE-ACCESS: already has access, now disabling
			case !accessVal && hasEmarAccess: {
				const wasDisabled = await disableUserToEmar(token, facilityID, userID);

				if (wasDisabled) {
					closeModal();
					return dispatchAlert("SUCCESS", {
						heading: `EMAR Access was disabled!`,
					});
				} else {
					return dispatchAlert("ERROR", {
						heading: `There was an issue!`,
						subheading: `Please try again.`,
					});
				}
			}
			// ENABLE-ACCESS: enabling access via AddUser API
			case accessVal && !hasEmarAccess: {
				const wasAdded = await addUserToEmar(token, facilityID, userID);

				if (wasAdded) {
					closeModal();
					return dispatchAlert("SUCCESS", {
						heading: `EMAR Access was enabled!`,
					});
				} else {
					return dispatchAlert("ERROR", {
						heading: `There was an issue!`,
						subheading: `Please try again.`,
					});
				}
			}
			default:
				return;
		}
	};

	const fetchInitialResources = async () => {
		const { token } = currentUser;
		const { email, userID } = selectedUser;

		const [facilityAccess, resetTypes, userAppAccess] = await Promise.all([
			getUserFacilityList(token, email),
			getPasswordResetTypes(token, email),
			getAppAccessByUser(token, userID),
		]);

		if (!isEmptyArray(facilityAccess)) {
			// processed data
			const listOfFacilities = processFacilityList(facilityAccess);
			const resetRules = processAccountSecurity(resetTypes);
			const userAccess = processUserApps(userAppAccess);
			const { legacyAccess, trackerAccess, portalAccess, emarAccess } =
				userAccess;

			setUserData({
				...userData,
				...resetRules,
			});

			setFormState({
				...formState,
				values: {
					...values,
					isPwdResetByAdmin: resetRules?.isPwdResetByAdmin,
					isPwdResetByEmail: resetRules?.isPwdResetByEmail,
					isPwdResetByQuestions: resetRules?.isPwdResetByQuestions,
				},
			});
			setAppAccess({
				SeniorCareVB: legacyAccess?.isAccessible,
				SeniorCareEHR: legacyAccess?.isAccessible,
				AdvantageTracker: trackerAccess?.isAccessible,
				AdminPortal: portalAccess?.isAccessible,
				ChartMeds: emarAccess?.isAccessible,
			});
			return setFacilityAccessList([...listOfFacilities]);
		} else {
			setUserData({});
			setFormState({});
			setAppAccess({
				SeniorCareVB: false,
				SeniorCareEHR: false,
				CareTracker: false,
				AdminPortal: false,
				ChartMeds: false,
			});
			return setFacilityAccessList([]);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchInitialResources();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.UserSearchDetails}>
			<header className={styles.UserSearchDetails_header}>
				<h2 className={styles.UserSearchDetails_header_title}>
					User: <b>{getUserName(selectedUser)}</b>
				</h2>
				<div className={styles.UserSearchDetails_header_id}>
					<span>User ID:</span>
					<CopyText text={selectedUser?.userID} />
				</div>
				{isEmailDeleted(selectedUser?.email) && (
					<div className={styles.UserSearchDetails_isDeleted}>
						<DeletedIndicator selectedUser={selectedUser} />
					</div>
				)}
			</header>
			<main className={styles.UserSearchDetails_main}>
				{/* ABOUT USER */}
				<FormSection
					title="About"
					icon="news"
					hideInfoIcon={true}
					hoverText="Information about this user."
				>
					<AboutUser
						user={selectedUser}
						allFacilities={currentUser?.facilities}
					/>
				</FormSection>
				<Divider />
				{/* USER-LOCKOUT */}
				<ProtectedFeature
					isEnabled={isAccessEnabled(
						"unlockUser",
						featureFlags?.user,
						currentUser
					)}
				>
					<FormSection
						title={
							vals?.isLockedOut
								? `Account Status: LOCKED`
								: `Account Status: UNLOCKED`
						}
						hoverText="Lock or unlock a user's account."
						icon={vals?.isLockedOut ? "lock" : "lockOpen"}
						titleColor={vals?.isLockedOut ? red[600] : ""}
						iconColor={vals?.isLockedOut ? red[600] : ""}
					>
						<div className={styles.UserSearchDetails_desc}>
							Update this user's account status. Locking an account will disable
							their ability to login to all ALA applications. Unlocking will
							enable them to reset their password, once unlocked.
						</div>
						<LockoutStatus isLockedOut={vals?.isLockedOut} />
					</FormSection>
					<Divider />
				</ProtectedFeature>
				{/* PASSWORD RESET METHODS */}
				<ProtectedFeature
					isEnabled={isAccessEnabled(
						"changeResetMethods",
						featureFlags?.user,
						currentUser
					)}
				>
					<FormSection
						title="Password Reset Methods"
						icon="login"
						hoverText="Update how a user can reset their password."
					>
						<UserResetMethods vals={values} handleCheckbox={handleCheckbox} />

						<ResetMethodWarnings formState={formState} />
						<FormActions
							saveTxt="Save Changes"
							cancelTxt="Cancel"
							isDisabled={
								!values?.isPwdResetByAdmin &&
								!values?.isPwdResetByEmail &&
								!values?.isPwdResetByQuestions
							}
							saveHandler={saveResetMethods}
							cancelHandler={cancelResetMethods}
						/>
					</FormSection>
					<Divider />
				</ProtectedFeature>

				{/* GRANT/DENY FACILITY ACCESS */}
				<FormSection
					title="Edit Facility Access"
					icon="building"
					hoverText="Edit access to one or more facilities."
					isDisabled={false}
				>
					<EditFacilityAccess
						key={`FACILITY-LIST:${facilityAccessList?.length}`}
						targetUser={selectedUser}
						currentUser={currentUser}
						facilityAccessList={[
							...formatAndSortUserFacilities(facilityAccessList),
						]}
						allFacilities={[...allFacilities]}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						syncFacilityAccessChanges={syncFacilityAccessChanges}
						closeModal={closeModal}
					/>
				</FormSection>
				<Divider />

				{/* APPLICATION ACCESS */}
				<ProtectedFeature
					isEnabled={isAccessEnabled(
						"changeAppAccess",
						featureFlags?.user,
						currentUser
					)}
				>
					<FormSection
						icon="lock"
						title="Application Access"
						hoverText="Manage a users application access."
						isDisabled={false}
					>
						<p className={styles.UserSearchDetails_desc}>
							Disabling app access will prevent a user from accessing a given
							application. This will not delete any user-related data. Users can
							be re-enabled at any time.
						</p>
						<UserAccessSwitch
							// appName="SeniorCareVB"
							// isEnabled={appAccess?.SeniorCareVB}
							appName="SeniorCareEHR"
							isEnabled={appAccess?.SeniorCareEHR}
							isLocked={lockAppAccessSwitch(currentUser)}
							handleAccess={handleAccess}
						/>
						<br style={{ margin: "2rem 0" }} />
						<UserAccessSwitch
							appName="AdvantageTracker"
							isEnabled={appAccess?.AdvantageTracker}
							// isLocked={lockAppAccessSwitch(currentUser)} // CURRENT LOCKER - 3/16/2023 at 11:16 AM
							isLocked={lockTrackerAccessSwitch(appAccess, currentUser)}
							handleAccess={handleAccess}
						/>
						<UserAccessSwitch
							appName="AdminPortal"
							isEnabled={appAccess?.AdminPortal}
							isLocked={false}
							handleAccess={handleAccess}
						/>
					</FormSection>
					<Divider />
				</ProtectedFeature>

				{/* EMAR ACCESS (CHART-MEDS) - EXECUTIVE ADMINS ONLY!!! */}
				{/* CONDITIONS: 
							- Check logged-in user's user type (MUST BE EXECUTIVE-ADMIN TYPE FOR FEATURE TOGGLE TO SHOW) 
							- Then, check facility's access
							- Then, check selected user's access and set state accordingly
					*/}
				<ProtectedFeature isEnabled={true}>
					<FormSection
						icon="emar"
						title="EMAR Access"
						hoverText="Manage a user's EMAR access."
						isDisabled={false}
						titleColor={blue[600]}
						iconColor={orange[500]}
					>
						<p className={styles.UserSearchDetails_desc}>
							Manage this user's access to your community's assigned EMAR
							system.
						</p>

						<EmarUserAccess
							appName="Chart-Meds (EMAR)"
							isEnabled={appAccess?.ChartMeds}
							isLocked={false}
							handleAccess={handleEmarAccess}
						/>
					</FormSection>
					<Divider />
				</ProtectedFeature>

				{/* DELETE USER - ONLY ENABLED FOR WHITELISTED USERS */}
				<ProtectedFeature
					isEnabled={isAccessEnabled(
						"deleteUser",
						featureFlags?.user,
						currentUser
					)}
				>
					<FormSection
						title="Delete  User"
						icon="error"
						hoverText="Deleting a user is permanent and is not reversible."
						iconColor={red[600]}
						titleColor={red[600]}
					>
						<p className={styles.UserSearchDetails_desc}>
							Deleting a user will permanently remove all user-related data from
							our system. This is not reversible.
						</p>
						<ButtonSM
							handleClick={handleDeleteUser}
							customStyles={customCSS.deleteBtn}
						>
							<svg className={styles.UserSearchDetails_deleteIcon}>
								<use xlinkHref={`${sprite}#icon-delete`}></use>
							</svg>
							<span>Delete User</span>
						</ButtonSM>
					</FormSection>
				</ProtectedFeature>
			</main>
		</div>
	);
};

export default UserSearchDetails;

UserSearchDetails.defaultProps = {};

UserSearchDetails.propTypes = {};
