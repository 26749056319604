import React from "react";
import styles from "../../css/app/ALAStepCounter.module.scss";
import { PropTypes } from "prop-types";
import { main } from "../../helpers/utils_styles";

const activeCircle = {
	backgroundColor: main.main,
};

const getActiveCircle = (current, step) => {
	if (current === step) {
		return activeCircle;
	}
	return {};
};

const ALAStepCounter = ({
	isAdmin = false,
	currentStep,
	goToNext,
	goToPrevious,
	disableResident = false,
}) => {
	if (!isAdmin || disableResident) {
		return (
			<section className={styles.ALAStepCounter}>
				<div
					className={styles.ALAStepCounter_stepCircle}
					style={activeCircle}
				></div>
			</section>
		);
	}
	return (
		<section className={styles.ALAStepCounter}>
			<div
				className={styles.ALAStepCounter_stepCircle}
				style={getActiveCircle(currentStep, 1)}
				onClick={goToPrevious}
			></div>
			<div
				className={styles.ALAStepCounter_stepCircle}
				style={getActiveCircle(currentStep, 2)}
				onClick={goToNext}
			></div>
		</section>
	);
};

export default ALAStepCounter;

ALAStepCounter.defaultProps = {
	isAdmin: false,
};

ALAStepCounter.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	currentStep: PropTypes.number.isRequired,
	goToNext: PropTypes.func.isRequired,
	goToPrevious: PropTypes.func.isRequired,
};
