import React from "react";
import styles from "../../css/facility/FacilityBadge.module.scss";
import sprite from "../../assets/icons/us-states.svg";
import { PropTypes } from "prop-types";

const FacilityBadge = ({ state = "NA" }) => {
	return (
		<div className={styles.FacilityBadge}>
			<svg className={styles.FacilityBadge_icon}>
				<use xlinkHref={`${sprite}#icon-state-${state}`}></use>
			</svg>
		</div>
	);
};

export default FacilityBadge;

FacilityBadge.defaultProps = {
	state: "NA",
};
FacilityBadge.propTypes = {
	state: PropTypes.string.isRequired,
};
