import React from "react";
import styles from "../../css/migration/MigrationStatus.module.scss";
import sprite2 from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import {
	blueGrey,
	purple,
	red,
	yellow,
	orange,
} from "../../helpers/utils_styles";

const MAPS = {
	ICONS: {
		COMPLETED: "check_circle1",
		FAILED: "error",
		PROCESSING: "access_alarmalarm1",
		NOT_MIGRATED: "info-with-circle",
		SCHEDULED: "alarm_on",
		// NEW
		Completed: "check_circle1",
		// 'Not-Migrated': "error",
		Failed: "access_alarmalarm1",
		"Not-Migrated": "info-with-circle",
		Schedule: "alarm_on",
	},
	FILLS: {
		COMPLETED: {
			fill: purple[600],
		},
		Completed: {
			fill: purple[600],
		},
		FAILED: {
			fill: red[600],
		},
		Failed: {
			fill: red[600],
		},
		PROCESSING: {
			fill: yellow[500],
		},
		PartiallyCompleted: {
			fill: yellow[500],
		},
		Processing: {
			fill: yellow[500],
		},
		NOT_MIGRATED: {
			fill: blueGrey[700],
		},
		"Not-Migrated": {
			fill: blueGrey[700],
		},
		SCHEDULED: {
			fill: orange[600],
		},
		Schedule: {
			fill: orange[600],
		},
	},
	COLORS: {
		COMPLETED: {
			color: purple[600],
			backgroundColor: purple[100],
		},
		Completed: {
			color: purple[600],
			backgroundColor: purple[100],
		},

		FAILED: {
			color: red[600],
			backgroundColor: red[100],
		},
		Failed: {
			color: red[600],
			backgroundColor: red[100],
		},
		PROCESSING: {
			color: yellow[500],
			backgroundColor: yellow[100],
		},
		PartiallyCompleted: {
			color: yellow[500],
			backgroundColor: yellow[100],
		},
		Processing: {
			color: yellow[500],
			backgroundColor: yellow[100],
		},
		NOT_MIGRATED: {
			color: blueGrey[700],
			backgroundColor: blueGrey[100],
		},
		"Not-Migrated": {
			color: blueGrey[700],
			backgroundColor: blueGrey[100],
		},
		SCHEDULED: {
			color: orange[600],
			backgroundColor: orange[100],
		},
		Schedule: {
			color: orange[600],
			backgroundColor: orange[100],
		},
	},
	BORDERS: {
		COMPLETED: {
			backgroundColor: purple[100],
			border: `2px solid ${purple[600]}`,
		},
		Completed: {
			backgroundColor: purple[100],
			border: `2px solid ${purple[600]}`,
		},
		FAILED: {
			backgroundColor: red[100],
			border: `2px solid ${red[600]}`,
		},
		"Not-Migrated": {
			backgroundColor: blueGrey[100],
			border: `2px solid ${blueGrey[600]}`,
		},
		PROCESSING: {
			backgroundColor: yellow[100],
			border: `2px solid ${yellow[500]}`,
		},
		PartiallyCompleted: {
			backgroundColor: yellow[100],
			border: `2px solid ${yellow[500]}`,
		},
		NOT_MIGRATED: {
			backgroundColor: blueGrey[100],
			border: `2px solid ${blueGrey[700]}`,
		},
		SCHEDULED: {
			backgroundColor: orange[100],
			border: `2px solid ${orange[600]}`,
		},
		Schedule: {
			backgroundColor: orange[100],
			border: `2px solid ${orange[600]}`,
		},
	},
};

const STATES = {
	COMPLETED: "COMPLETED",
	FAILED: "FAILED",
	PROCESSING: "PROCESSING",
	NOT_MIGRATED: "NOT-MIGRATED",
	SCHEDULED: "SCHEDULED",
	Completed: "COMPLETED",
	PartiallyCompleted: "PROCESSING",
	"Not-Migrated": "NOT-MIGRATED",
	"NOT-MIGRATED": "NOT-MIGRATED",
};

const StatusIcon = ({ status = "NOT-MIGRATED" }) => {
	return (
		<div className={styles.StatusIcon} style={MAPS.BORDERS[status]}>
			<svg className={styles.StatusIcon_icon} style={MAPS.FILLS[status]}>
				<use xlinkHref={`${sprite2}#icon-${MAPS.ICONS[status]}`}></use>
			</svg>
			<span className={styles.StatusIcon_desc} style={MAPS.COLORS[status]}>
				{STATES?.[status]}
			</span>
		</div>
	);
};

const MigrationStatus = ({ status = "NOT-MIGRATED" }) => {
	return (
		<div className={styles.MigrationStatus}>
			<StatusIcon status={status} />
		</div>
	);
};

export default MigrationStatus;

MigrationStatus.defaultProps = {};

MigrationStatus.propTypes = {
	status: PropTypes.string.isRequired,
};
