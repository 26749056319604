import React, { useState, useEffect } from "react";
import styles from "../../css/app/AppLogin.module.scss";
import trackerLogo from "../../assets/brand/LOGO-AdvantageTracker.svg";
import legacyLogo from "../../assets/brand/LOGO-SeniorCareVB.svg";
import { PropTypes } from "prop-types";
import { isEmptyObj } from "../../helpers/utils_types";
import { getAppAccessDeniedReason } from "../../helpers/utils_apps";

// whitelist access to apps by user
const appsWhitelist = [`dandemo@aladvantage.com`, `sgore99@aladvantage.com`];

// ##TODOS:
// - Replace svg icons w/ app logos

const appNames = {
	AdvantageTracker: `Care Tracker`,
	// SeniorCareVB: ` EHR: Care Manager`,
	SeniorCareVB: ` Senior Care EHR`,
	AdminPortal: `ALA Portal`,
};

const assets = {
	AdvantageTracker: trackerLogo,
	SeniorCareVB: legacyLogo,
};

const getAccessStyle = (isAccessible) => {
	if (isAccessible) {
		return { opacity: "1", cursor: "pointer" };
	} else {
		return { opacity: ".4", cursor: "not-allowed" };
	}
};

const disableAppAccess = (appName, isAccessible) => {
	if (appName === "SeniorCareVB") return false;
	return !isAccessible;
};

const getAppTitle = (appName, isEnabled) => {
	return `${appName}: ${isEnabled ? "Enabled" : "Disabled"}`;
};

// shows reason for application being disabled
const AccessDisabledReason = ({ reason, callToAction }) => {
	return (
		<div className={styles.AccessDisabledReason}>
			<div className={styles.AccessDisabledReason_text}>{reason}</div>
			<div className={styles.AccessDisabledReason_cta}>{callToAction}</div>
		</div>
	);
};

const AppLogin = ({ currentUser, app = {}, goToApp }) => {
	const {
		ApplicationId: appID,
		ApplicationName: appName,
		Alias: appAlias,
		IsAccessible: isAccessible,
	} = app;
	const [showDisabledReason, setShowDisabledReason] = useState(!isAccessible);
	const [deniedReason, setDeniedReason] = useState({
		reason: null,
		cta: null,
	});

	const getDeniedReason = async () => {
		if (isAccessible || isEmptyObj(app)) return;
		const { token, userID, facilityID } = currentUser;
		const cause = await getAppAccessDeniedReason(token, {
			userID: userID,
			facilityID: facilityID,
			appID: appID,
		});

		setDeniedReason({ ...cause });
	};

	// fetch disabled reason(s) & cta
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getDeniedReason();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isEmptyObj(app)) {
		return null;
	}
	return (
		<>
			<button
				className={styles.AppLogin}
				style={getAccessStyle(isAccessible)}
				onClick={goToApp}
				type="button"
				disabled={disableAppAccess(appName, isAccessible)}
				title={getAppTitle(appName, isAccessible)}
			>
				<img
					src={assets[appName]}
					alt={`${appName} Logo`}
					className={styles.AppLogin_logo}
				/>

				<span className={styles.AppLogin_name}>{appNames[appName]}</span>
				{showDisabledReason && (
					<AccessDisabledReason
						reason={deniedReason?.reason}
						callToAction={deniedReason.cta}
					/>
				)}
			</button>
		</>
	);
};

export default AppLogin;

AppLogin.defaultProps = {};

AppLogin.propTypes = {
	app: PropTypes.object,
	goToApp: PropTypes.func,
};
