import React from "react";
import styles from "../../css/loc/LOCValidatorResults.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	getGapRange,
	getOverlapRange,
	sortMessages,
} from "../../helpers/utils_loc";
import { green, red } from "../../helpers/utils_styles";
// components
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	cancel: {
		padding: ".7rem 1.5rem",
		fontSize: "1.6rem",
		color: red[600],
		backgroundColor: "transparent",
		marginRight: "1rem",
	},
	save: {
		padding: ".7rem 1.5rem",
		fontSize: "1.6rem",
		color: "#ffffff",
		backgroundColor: red[600],
	},
};

const errCSS = {
	fill: red[600],
};
const noErrCSS = {
	fill: green[500],
};

const baseState = {
	isBaseValid: false,
	isLastValid: false,
	errors: [],
};

const hasError = (errorDetails) => {
	const { isBaseValid, isLastValid, hasGap, hasOverlap } = errorDetails;

	const hasError = !isBaseValid || !isLastValid || hasGap || hasOverlap;

	return hasError;
};

const disableConfirmBtn = (errorDetails = {}) => {
	const { isBaseValid, isLastValid, hasGap, hasOverlap } = errorDetails;

	const hasError = !isBaseValid || !isLastValid || hasGap || hasOverlap;

	return hasError;
};

const LOCGaps = ({ gapErrors = [] }) => {
	return (
		<div className={styles.LOCGaps}>
			<div className={styles.LOCGaps_list}>
				{!isEmptyArray(gapErrors) &&
					gapErrors.map((error, idx) => {
						const { start, end } = getGapRange(error);
						return (
							<LOCGapMsg key={`GAP-${idx}`} gapStart={start} gapEnd={end} />
						);
					})}
			</div>
		</div>
	);
};
const LOCOverlaps = ({ overlapErrors = [] }) => {
	return (
		<div className={styles.LOCOverlaps}>
			<div className={styles.LOCOverlaps_list}>
				{!isEmptyArray(overlapErrors) &&
					overlapErrors.map((error, idx) => {
						const { start, end } = getOverlapRange(error);
						return (
							<LOCOverlapMsg
								key={`OVERLAP-${idx}`}
								overlapStart={start}
								overlapEnd={end}
							/>
						);
					})}
			</div>
		</div>
	);
};

const LOCGapMsg = ({ gapStart, gapEnd }) => {
	return (
		<div className={styles.LOCGapMsg}>
			<span className={styles.LOCGapMsg}>
				There's a gap between <b>{gapStart}</b> and <b>{gapEnd}</b>
			</span>
		</div>
	);
};
const LOCOverlapMsg = ({ overlapStart, overlapEnd }) => {
	return (
		<div className={styles.LOCOverlapMsg}>
			<span className={styles.LOCOverlapMsg}>
				There's an overlap between <b>{overlapStart}</b> and <b>{overlapEnd}</b>
			</span>
		</div>
	);
};

// 'hasError' is either: gap or overlap or base/last level validation
const LOCErrorItem = ({ hasError, children }) => {
	return (
		<div className={styles.LOCErrorItem}>
			<svg
				className={styles.LOCErrorItem_icon}
				style={hasError ? errCSS : noErrCSS}
			>
				<use
					xlinkHref={`${sprite}#icon-${
						hasError ? "clearclose" : "check_circle"
					}`}
				></use>
			</svg>
			{children}
		</div>
	);
};

const LOCErrorList = ({ errorData = {} }) => {
	const { isBaseValid, isLastValid, hasGap, hasOverlap, errors } = errorData;
	const { gaps, overlaps } = sortMessages(errors);

	return (
		<div className={styles.LOCErrorList}>
			{/* BASE LEVEL: OK */}
			<LOCErrorItem hasError={!isBaseValid}>
				<span className={styles.LOCErrorList_msg}>
					{isBaseValid ? ` Base Level is OK!` : `Base Level MUST start with 0!`}
				</span>
			</LOCErrorItem>
			{/* LAST LEVEL: OK */}
			<LOCErrorItem hasError={!isLastValid}>
				<span className={styles.LOCErrorList_msg}>
					{isLastValid
						? ` Last Level is OK!`
						: `Last Level MUST end with 99999!`}
				</span>
			</LOCErrorItem>
			{/* GAPS: NONE */}
			<LOCErrorItem hasError={hasGap}>
				{hasGap
					? `${gaps?.length} Gap(s) found in Levels of Care:`
					: `No Gaps in care levels found!`}
			</LOCErrorItem>
			<div className={styles.LOCErrorList_entry}>
				<LOCGaps gapErrors={gaps} />
			</div>
			{/* OVERLAPS: NONE */}
			<LOCErrorItem hasError={hasOverlap}>
				{hasOverlap
					? `${overlaps?.length} Overlap(s) found in Levels of Care:`
					: `No Overlaps in care levels found!`}
			</LOCErrorItem>
			<div className={styles.LOCErrorList_entry}>
				<LOCOverlaps overlapErrors={overlaps} />
			</div>
		</div>
	);
};

const LOCValidatorResults = ({ results = {} }) => {
	return (
		<div className={styles.LOCValidatorResults}>
			<div className={styles.LOCValidatorResults_title}>
				Care Levels Validation:
			</div>
			<div className={styles.LOCValidatorResults_details}>
				<LOCErrorList errorData={results} />
			</div>
			{hasError(results) && (
				<div className={styles.LOCValidatorResults_details}>
					<span className={styles.LOCValidatorResults_details_msg}>
						Please fix issues before saving!
					</span>
				</div>
			)}
		</div>
	);
};

export default LOCValidatorResults;

LOCValidatorResults.defaultProps = {};

LOCValidatorResults.propTypes = {};
