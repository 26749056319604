import React, { useState, useEffect } from "react";
import styles from "../../css/user/CreateUserStep3.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import TextInput from "../shared/TextInput";
import CustomCheckbox from "../shared/CustomCheckbox";
import CustomDropdown from "../shared/CustomDropdown";
import { featureFlags } from "../../helpers/utils_permissions";
import { formatAndSortUserTitles } from "../../helpers/utils_user";
import PhoneInput from "../shared/PhoneInput";
import JobTitleHandler from "./JobTitleHandler";
// import ConfirmedInput from "../forms/ConfirmedInput";

// REQUIREMENTS:
// - First Name
// - Last Name
// - Job Title
// - Unit Type (if applicable)

const customCSS = {
	firstName: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	lastName: {
		width: "100%",
		minWidth: "20rem",
		display: "flex",
		justifyContent: "center",
	},
	jobTitle: {
		width: "100%",
	},
	phoneNumber: {
		width: "100%",
	},
	checkbox: {
		marginBottom: ".5rem",
	},
	notApplicable: {
		marginTop: "1rem",
	},
	rowItem2: {
		marginBottom: "2rem",
	},
};

const CreateUserStep3 = ({
	vals = {},
	currentUser,
	handlePhone,
	handleChange,
	handleCheckbox,
	handleSettings,
	handleCustomJobTitle,
	userTitles = [],
}) => {
	const [disableTypes, setDisableTypes] = useState(false);

	// disables unit types when 'notApplicable' is selected
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setDisableTypes(vals.notApplicable);

		return () => {
			isMounted = false;
		};
	}, [vals.notApplicable]);

	return (
		<div className={styles.CreateUserStep3}>
			<section className={styles.CreateUserStep3_row}>
				<div className={styles.CreateUserStep3_row_item}>
					<TextInput
						name="firstName"
						id="firstName"
						label="First Name"
						val={vals.firstName}
						handleChange={handleChange}
						placeholder="Enter your first name..."
						customStyles={customCSS.firstName}
					/>
				</div>
				<div className={styles.CreateUserStep3_row_item}>
					<TextInput
						name="lastName"
						id="lastName"
						label="Last Name"
						val={vals.lastName}
						handleChange={handleChange}
						placeholder="Enter your last name..."
						customStyles={customCSS.lastName}
					/>
				</div>
			</section>
			<section className={styles.CreateUserStep3_section}>
				<JobTitleHandler
					currentUser={currentUser}
					vals={vals}
					userTitles={userTitles}
					handleJobTitle={handleSettings}
					handleChange={handleChange}
					handleCustomJobTitle={handleCustomJobTitle}
				/>
				{/* <CustomDropdown
					name="jobTitle"
					id="jobTitle"
					label="Job Title"
					selection={vals.jobTitle}
					setSelection={handleSettings}
					placeholder="Select job title..."
					customStyles={customCSS.jobTitle}
					options={[...formatAndSortUserTitles(userTitles)]}
				/> */}

				{/* DISABLED VIA FEATURE FLAG CURRENTLY */}

				{featureFlags.user.addCustomTitle && (
					<TextInput
						name="jobTitle"
						id="jobTitle"
						label="Job Title"
						val={vals.jobTitle}
						handleChange={handleChange}
						placeholder="Enter your job title..."
						customStyles={customCSS.jobTitle}
					/>
				)}
			</section>
			<section className={styles.CreateUserStep3_section}>
				<PhoneInput
					name="phoneNumber"
					id="phoneNumber"
					label="Phone Number (optional)"
					val={vals.phoneNumber}
					handlePhone={handlePhone}
					placeholder="Enter a phone number..."
					customStyles={customCSS.phoneNumber}
					autoComplete="new-phone-number"
					inputMode="tel"
				/>
			</section>
		</div>
	);
};

export default CreateUserStep3;

CreateUserStep3.defaultProps = {
	vals: {},
};

CreateUserStep3.propTypes = {
	vals: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheckbox: PropTypes.func.isRequired,
};
