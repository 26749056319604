import React from "react";
import styles from "../../css/loctemplate/LOCTemplateNameInput.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
// components
import TextInput from "../shared/TextInput";
import LimitedTextInput from "../shared/LimitedTextInput";

const customCSS = {
	input: {
		width: "70rem",
		backgroundColor: "#ffffff",
	},
};

const LOCTemplateNameInput = ({ vals, handleChange }) => {
	return (
		<div className={styles.LOCTemplateNameInput}>
			<LimitedTextInput
				name="templateName"
				id="templateName"
				val={vals.templateName}
				label={`Template Name (required)`}
				handleChange={handleChange}
				placeholder="Enter template name..."
				customStyles={customCSS.input}
				required={true}
				addRequiredFlag={true}
				enableMaxChars={true}
				maxChars={100}
			/>
		</div>
	);
};

export default LOCTemplateNameInput;

LOCTemplateNameInput.defaultProps = {};

LOCTemplateNameInput.propTypes = {};
