import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/views/LevelsOfCareView.module.scss";
import { PropTypes } from "prop-types";
import { useQueryParams } from "../../utils/useQueryParams";
import { setParam, setParamsMany } from "../../helpers/utils_params";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	getFacilityInfoAndSummary,
	getFacilityType,
	matchUserFacilityByName,
	processFacilityInfo,
} from "../../helpers/utils_facility";
import { processUserList } from "../../helpers/utils_user";
import { processLockoutsList } from "../../helpers/utils_lockouts";
import { removeDups } from "../../helpers/utils_processing";
import { blue, red } from "../../helpers/utils_styles";
// components
import ViewContainer from "../../components/app/ViewContainer";
import ALASelector from "../../components/app/ALASelector";
import LoadingMsg from "../../components/ui/LoadingMsg";
// tabs components
import FullPageTabsController from "../../components/tabs/FullPageTabsController";
import FullPageTabButtons from "../../components/tabs/FullPageTabButtons";
import FullPageTabButton from "../../components/tabs/FullPageTabButton";
import FullPageTabPanels from "../../components/tabs/FullPageTabPanels";
import FullPageTabPanel from "../../components/tabs/FullPageTabPanel";
// loc components
import LOCController from "../../components/loc/LOCController";
import LOCTemplateController from "../../components/loctemplate/LOCTemplateController";
import Dialog from "../../components/shared/Dialog";
import ButtonSM from "../../components/shared/ButtonSM";

// REQUIREMENTS:
// - Tabs:
// 		- Tab1: "Set LOC"
// 				- Available to ALL facilities (child, parent, independent).
// 		- Tab2: "Create/Edit LOC Templates"
// 				- ONLY available to parent facilities.

const viewDetails = {
	title: `Levels of Care`,
	desc: `Edit the Levels of Care for one or more communities at once.`,
};

const customCSS = {
	cancel: {
		padding: "1rem 1.4rem",
		backgroundColor: blue[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	confirm: {
		padding: "1rem 1.4rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

// returns array of hidden tabs (eg. numbers)
const getUserHiddenTabs = (currentUser) => {
	const { isAdmin, isFacilityAdmin, isSuperUser } = currentUser;
	const hasSuper = isSuperUser || isAdmin;

	switch (true) {
		case isFacilityAdmin && !hasSuper: {
			return [1];
		}
		case hasSuper: {
			return [];
		}

		default:
			return [1];
	}
};
const getFacilityHiddenTabs = (currentFacility, parentsMap) => {
	const type = getFacilityType(currentFacility, parentsMap);

	switch (type) {
		case "CHILD": {
			return [1];
		}
		case "PARENT": {
			return [];
		}
		case "INDEPENDENT": {
			return [];
		}

		default:
			return [];
	}
};

// hides template tab for child & independent communities
const getHiddenTabs = (currentUser, currentFacility, parentsMap) => {
	// Details:
	// "By User":
	// 		- If 'FACILITY ADMIN', then block access to templates
	// 		- If 'ADMIN' or 'SUPER-USER', then enable access to ALL LOC
	// "By Facility Type":
	// 		- If 'CHILD', then hide 'LOC Template' tab
	// 		- If 'PARENT' or 'INDEPENDENT', then show everything
	const userTabs = getUserHiddenTabs(currentUser);
	const facilityTabs = getFacilityHiddenTabs(currentFacility, parentsMap);

	const merged = [...userTabs, ...facilityTabs];
	const cleaned = removeDups(merged);

	return cleaned;
};

const CustomTabButton = ({ label, handleClick }) => {
	return (
		<span className={styles.CustomTabButton} onClick={handleClick}>
			{label}
		</span>
	);
};

const LevelsOfCareView = ({
	history,
	globalState,
	currentUser,
	currentFacility,
	dispatchToState,
	dispatchAlert,
}) => {
	const [selectedFacility, setSelectedFacility] = useState(() => {
		return currentFacility?.communityName ?? "";
	});
	const [isLoading, setIsLoading] = useState(false);
	// dirty tab indicator
	const [showPrompt, setShowPrompt] = useState(false);

	// fires off 'facility loaded' alert
	const changeFacility = (selection) => {
		// fetch facility details here
		dispatchAlert("SUCCESS", {
			heading: `Loaded Facility`,
			subheading: `Loaded ${selection}`,
		});
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(async () => {
		if (isEmptyVal(selectedFacility)) {
			return dispatchToState({ type: "CLEAR_FACILITY" });
		}
		// test loader
		setIsLoading(true);

		const { token } = currentUser;
		const facilityRecord = matchUserFacilityByName(
			selectedFacility,
			currentUser?.facilities
		);
		const { facilityID } = facilityRecord;
		const { facilityInfo, facilityUsers, facilityLockouts } =
			await getFacilityInfoAndSummary(token, facilityID);

		setIsLoading(false);
		dispatchToState({
			type: "SET_FACILITY_USERS",
			data: {
				facilityRecord: facilityRecord,
				facilityInfo: { ...processFacilityInfo(facilityInfo) },
				facilityUsers: [...processUserList(facilityUsers)],
				facilityLockouts: [...processLockoutsList(facilityLockouts)],
			},
		});
	}, [currentUser, dispatchToState, selectedFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	return (
		<>
			<ViewContainer title={viewDetails.title} desc={viewDetails.desc}>
				<div className={styles.LevelsOfCareView}>
					<div className={styles.LevelsOfCareView_selector}>
						{/* COMMUNITY SELECTOR */}
						<ALASelector
							key={`LOC_PAGE_SELECTOR`}
							disableResident={true} // facility only selector
							isAdmin={true} // admin only route
							facilities={currentUser?.facilities}
							defaultFacility={selectedFacility}
							syncFacility={setSelectedFacility}
							loadFacility={changeFacility}
						/>
					</div>
					{isEmptyVal(currentFacility.facilityID) && (
						<div className={styles.LevelsOfCareView_msg}>
							Please load a facility to get started!
						</div>
					)}
					{isLoading && (
						<LoadingMsg>Loading care levels...please wait.</LoadingMsg>
					)}

					{!isEmptyVal(currentFacility?.facilityID) && (
						<div className={styles.LevelsOfCareView_tabs}>
							<FullPageTabsController
								key={`LOC-TABS-${currentFacility?.parentID}`}
								defaultIndex={0}
								disabledIndices={[]}
								hiddenIndices={getHiddenTabs(
									currentUser,
									currentFacility,
									globalState.parentsMap
								)}
							>
								<FullPageTabButtons>
									<FullPageTabButton>
										<CustomTabButton key="LOC-SET" label="Set Levels of Care" />
									</FullPageTabButton>
									<FullPageTabButton>
										<CustomTabButton
											key="LOC-EDIT"
											label="Create/Edit LOC Templates"
										/>
									</FullPageTabButton>
								</FullPageTabButtons>
								<FullPageTabPanels>
									{/* SET CARE LEVELS (eg. LOC) */}
									<FullPageTabPanel>
										{isLoading && (
											<LoadingMsg>
												Loading care levels...please wait.
											</LoadingMsg>
										)}
										<LOCController
											key={`LOC-${currentFacility?.facilityID}`}
											globalState={globalState}
											currentFacility={currentFacility}
											currentUser={currentUser}
											dispatchAlert={dispatchAlert}
											dispatchToState={dispatchToState}
										/>
									</FullPageTabPanel>
									{/* CREATE/EDIT LOC TEMPLATES */}
									<FullPageTabPanel>
										{isLoading && (
											<LoadingMsg>
												Loading care levels...please wait.
											</LoadingMsg>
										)}
										<LOCTemplateController
											key={`LOC-TEMPLATE-${currentFacility?.facilityID}`}
											globalState={globalState}
											currentFacility={currentFacility}
											currentUser={currentUser}
											dispatchAlert={dispatchAlert}
											dispatchToState={dispatchToState}
										/>
									</FullPageTabPanel>
								</FullPageTabPanels>
							</FullPageTabsController>
						</div>
					)}
				</div>
			</ViewContainer>

			{showPrompt && (
				<Dialog
					key="TAB-PROMPT"
					icon="WARN"
					title="Unsaved Changes"
					heading="Are you sure you want to leave?"
					subheading="Your changes will be lost."
					closeModal={() => setShowPrompt(false)}
				>
					<ButtonSM
						customStyles={customCSS.cancel}
						// handleClick={handleBlockNav}
					>
						Stay here
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.confirm}
						// handleClick={handleNewNav}
					>
						Yes, leave
					</ButtonSM>
				</Dialog>
			)}
		</>
	);
};

export default LevelsOfCareView;

LevelsOfCareView.defaultProps = {};

LevelsOfCareView.propTypes = {};
