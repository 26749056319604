import React, { useState, useEffect } from "react";
import styles from "../../css/user/EditFacilityAccess.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	registerFacilityAccess,
	formatAndSortUserFacilities,
	getRegisterRecordsFromSelections,
	getGrantAccessRecordsFromSelections,
	getDeSelections,
} from "../../helpers/utils_facility";
import { blueGrey } from "../../helpers/utils_styles";
import { getUserEmail } from "../../helpers/utils_user";
import { grantFacilityAccess } from "../../helpers/utils_security";
// components
import ButtonSM from "../shared/ButtonSM";
import FacilityAccessController from "../facility/FacilityAccessController";
import SuccessIndicator from "../shared/SuccessIndicator";

const customCSS = {
	save: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancel: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${blueGrey[400]}`,
		marginRight: "1rem",
	},
	icon: "#ffffff",
	msg: "#ffffff",
};

// checks if arrays contain same elements, even if different order
const isSameArray = (arr1, arr2) => {
	if (isEmptyArray(arr1) || isEmptyArray(arr2)) return true;

	const isSameLength = arr1?.length === arr2?.length;
	const sorted1 = arr1.sort((a, b) => a?.localeCompare(b));
	const sorted2 = arr2.sort((a, b) => a?.localeCompare(b));

	// compares without sorting
	const hasSameItems = arr1.every((item, idx) => {
		return item === arr2[idx];
	});

	// compares with sorting
	const hasSortedItems = sorted1.every((item, idx) => {
		return item === sorted2[idx];
	});

	return isSameLength && (hasSameItems || hasSortedItems);
};

const AccessSummary = ({ hasChanges, targetUser = {}, accessList = [] }) => {
	const facility = accessList?.length >= 2 ? `facilities` : `facility`;

	if (isEmptyArray(accessList) || !hasChanges) {
		return (
			<div className={styles.AccessSummary}>
				<div className={styles.AccessSummary_summary}>
					<span>No changes have been made, yet.</span>
				</div>
			</div>
		);
	}
	return (
		<div className={styles.AccessSummary}>
			<div className={styles.AccessSummary_summary}>
				<span>
					You are about to grant access to
					<b>
						{" "}
						{accessList?.length ?? 0} {facility}{" "}
					</b>
					for this user
					<b> ({getUserEmail(targetUser)})</b>
				</span>
			</div>
		</div>
	);
};

const CurrentAccess = ({ hasChanges, accessCount = 0 }) => {
	const facility = accessCount >= 2 ? `facilities` : `facility`;

	if (hasChanges) {
		return null;
	}
	return (
		<div className={styles.CurrentAccess}>
			<div className={styles.CurrentAccess_summary}>
				<span>
					This user <i>currently</i> has access to {accessCount ?? 0} {facility}
					.
				</span>
			</div>
		</div>
	);
};

const Success = () => {
	return (
		<div className={styles.Success}>
			<SuccessIndicator
				iconColor={customCSS.icon}
				msgColor={customCSS.msg}
				msg="Changes were saved!"
				icon="checks"
			/>
		</div>
	);
};

const EditFacilityAccess = ({
	targetUser,
	currentUser,
	syncFacilityAccessChanges,
	facilityAccessList = [], // initial selections
	allFacilities = [],
	dispatchAlert,
	dispatchToState,
	closeModal,
}) => {
	const [accessList, setAccessList] = useState(facilityAccessList);
	const [hasChanges, setHasChanges] = useState(false); // has pending/unsaved changes
	const [wasSaved, setWasSaved] = useState(false); // changes were saved (show 'success' UI)

	const handleMultiSelect = (selections) => {
		setAccessList(selections);
		if (isSameArray(accessList, selections)) {
			return setHasChanges(true);
		} else {
			return setHasChanges(true);
		}
	};

	// supports grant/deny access
	const saveFacilityAccess = async () => {
		const { token } = currentUser;
		const { userID } = targetUser;

		// get deselections
		const deSelections = getDeSelections(facilityAccessList, accessList);

		// update util to include facility access records to be removed
		const newRecords = getRegisterRecordsFromSelections(
			userID,
			accessList, // selections
			deSelections, // de-selections
			allFacilities
		);

		const accessWasUpdated = await registerFacilityAccess(token, newRecords);
		// const accessWasUpdated = true;

		if (accessWasUpdated) {
			setWasSaved(true);
			return setHasChanges(false);
		} else {
			return setWasSaved(false);
		}
	};

	const cancelChanges = (e) => {
		// should reset to initial access list
		setAccessList([...formatAndSortUserFacilities(facilityAccessList)]);
		setHasChanges(false);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (wasSaved) {
			timerID = setTimeout(() => {
				setWasSaved(false);
			}, 5000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasSaved]);

	return (
		<div className={styles.EditFacilityAccess}>
			<div className={styles.EditFacilityAccess_selector}>
				<FacilityAccessController
					handleMultiSelect={handleMultiSelect}
					initialSelections={accessList}
					options={[...formatAndSortUserFacilities(allFacilities)]}
					disabledOptions={[]}
				/>
			</div>
			{wasSaved && <Success />}
			{!wasSaved && (
				<div className={styles.EditFacilityAccess_summary}>
					<CurrentAccess
						key={`Current:${wasSaved}-${accessList?.length}`}
						hasChanges={hasChanges}
						accessCount={accessList?.length}
					/>
					<AccessSummary
						hasChanges={hasChanges}
						targetUser={targetUser}
						accessList={accessList}
					/>
				</div>
			)}
			<div className={styles.EditFacilityAccess_actions}>
				<ButtonSM
					type="button"
					customStyles={customCSS.cancel}
					handleClick={cancelChanges}
				>
					Cancel
				</ButtonSM>
				<ButtonSM
					type="button"
					customStyles={customCSS.save}
					isDisabled={!hasChanges}
					handleClick={saveFacilityAccess}
				>
					Save Changes
				</ButtonSM>
			</div>
		</div>
	);
};

export default EditFacilityAccess;

EditFacilityAccess.defaultProps = {};

EditFacilityAccess.propTypes = {};
