import React from "react";
import styles from "../../css/otp/HiddenPassword.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { range } from "../../helpers/utils_processing";
// components
import HiddenChar from "./HiddenChar";

const getHiddenPassword = (val) => {
	if (isEmptyVal(val) || !val) return "";
	if (!val?.length || val?.length <= 0) return "";
	const length = val.length;
	const passwordSplit = val?.split("");
	const list = range(0, length, (x) => x + 1);

	return (
		<>
			{!isEmptyArray(list) &&
				list.map((char, idx) => (
					<HiddenChar key={`${char}__${idx}`} char={passwordSplit[idx]} />
				))}
		</>
	);
};

const HiddenPassword = ({ value }) => {
	return (
		<div className={styles.HiddenPassword}>{getHiddenPassword(value)}</div>
	);
};

export default HiddenPassword;

HiddenPassword.defaultProps = {};

HiddenPassword.propTypes = {};
