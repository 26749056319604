import React, { useRef, useEffect } from "react";
import styles from "../../css/shared/ModalFull.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";

// ##TODOS:
// - Implement close via 'ESC' key ✓

const ModalFull = ({ title, closeModal, children }) => {
	const fullModalRef = useRef();
	const { isOutside } = useOutsideClick(fullModalRef);
	const userEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	// Closes modal when:
	// - clicking outside
	// - 'ESC' key is pressed
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || userEscaped) {
			return closeModal();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeModal, userEscaped]);
	return (
		<aside className={styles.ModalFull} ref={fullModalRef}>
			<section className={styles.ModalFull_top}>
				<h4 className={styles.ModalFull_top_title}>{title}</h4>
				<svg className={styles.ModalFull_top_icon} onClick={closeModal}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</section>
			<section className={styles.ModalFull_main}>{children}</section>
		</aside>
	);
};

export default ModalFull;

ModalFull.propTypes = {
	title: PropTypes.string,
	closeModal: PropTypes.func,
	children: PropTypes.element,
};
