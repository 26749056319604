import React from "react";
import styles from "../../css/user/AboutUser.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { matchFacilityByID } from "../../helpers/utils_facility";
import { isEmptyVal } from "../../helpers/utils_types";
import CopyText from "../forms/CopyText";

const customCSS = {
	copy: {
		fontSize: "1.6rem",
		fontWeight: "500",
	},
};

const getPhoneNumber = (user) => {
	const { phoneNumber } = user;
	if (!isEmptyVal(phoneNumber)) {
		return phoneNumber;
	} else {
		return `No phone number`;
	}
};
const getUserEmail = (user = {}) => {
	const email = user?.email ?? "Not found";
	return email;
};
const getUsername = (user = {}) => {
	const username = user?.username ?? user?.email ?? "Unknown";
	return username;
};
const getUserField = (fieldName, user = {}) => {
	const field = user?.[fieldName];
	return field;
};
const getCommunityName = (user = {}, allFacilities = []) => {
	const { facilityID } = user;
	const community = matchFacilityByID(facilityID, allFacilities);

	return community?.communityName ?? community?.CommunityName ?? "";
};
const getCommunityID = (user = {}) => {
	const { facilityID } = user;
	return facilityID;
};

const AboutUser = ({ user = {}, allFacilities = [] }) => {
	return (
		<div className={styles.AboutUser}>
			<div className={styles.AboutUser_inner}>
				<div className={styles.AboutUser_inner_item}>
					<svg className={styles.AboutUser_inner_item_icon}>
						<use xlinkHref={`${sprite}#icon-mail_outline`}></use>
					</svg>
					{/* EMAIL */}
					<div className={styles.AboutUser_inner_item_field}>
						Email:{" "}
						<b className={styles.AboutUser_inner_item_field_text}>
							<CopyText
								text={getUserEmail(user)}
								successStyles={customCSS.copy}
							/>
						</b>
					</div>
				</div>
				{/* USERNAME */}
				<div className={styles.AboutUser_inner_item}>
					<svg className={styles.AboutUser_inner_item_icon}>
						<use xlinkHref={`${sprite}#icon-contact_mail`}></use>
					</svg>
					<div className={styles.AboutUser_inner_item_field}>
						Username:{"  "}
						<b className={styles.AboutUser_inner_item_field_text}>
							<CopyText
								text={getUsername(user)}
								successStyles={customCSS.copy}
							/>
						</b>
					</div>
				</div>
				{/* PHONE */}
				<div className={styles.AboutUser_inner_item}>
					<svg className={styles.AboutUser_inner_item_icon}>
						<use xlinkHref={`${sprite}#icon-v-card`}></use>
					</svg>
					<div className={styles.AboutUser_inner_item_field}>
						Phone:{"  "}
						<b className={styles.AboutUser_inner_item_field_text}>
							{getPhoneNumber(user)}
						</b>
					</div>
				</div>
				<div className={styles.AboutUser_inner_item}>
					<svg className={styles.AboutUser_inner_item_icon}>
						<use xlinkHref={`${sprite}#icon-portrait`}></use>
					</svg>
					<div className={styles.AboutUser_inner_item_field}>
						Job Title:{"  "}
						<b className={styles.AboutUser_inner_item_field_text}>
							{" "}
							{getUserField("title", user)}
						</b>
					</div>
				</div>
				<div className={styles.AboutUser_inner_item}>
					<svg className={styles.AboutUser_inner_item_icon}>
						<use xlinkHref={`${sprite}#icon-public`}></use>
					</svg>
					<div className={styles.AboutUser_inner_item_field}>
						Community Name:{"  "}
						<b className={styles.AboutUser_inner_item_field_text}>
							{" "}
							{getCommunityName(user, allFacilities)}
						</b>
					</div>
				</div>
				<div className={styles.AboutUser_inner_item}>
					<svg className={styles.AboutUser_inner_item_icon}>
						<use xlinkHref={`${sprite2}#icon-location_city`}></use>
					</svg>
					<div className={styles.AboutUser_inner_item_field}>
						Community ID:{"  "}
						<b className={styles.AboutUser_inner_item_field_text}>
							{" "}
							<CopyText
								text={getCommunityID(user)}
								successStyles={customCSS.copy}
							/>
						</b>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUser;

AboutUser.defaultProps = {};

AboutUser.propTypes = {
	user: PropTypes.object,
	allFacilities: PropTypes.array,
};
