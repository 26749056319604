import React from "react";
import styles from "../../css/shared/DisabledIndicator.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const DisabledIndicator = () => {
	return (
		<div className={styles.DisabledIndicator}>
			<svg className={styles.DisabledIndicator_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose1`}></use>
			</svg>
			<span className={styles.DisabledIndicator_label}>Disabled</span>
		</div>
	);
};

export default DisabledIndicator;

DisabledIndicator.defaultProps = {};

DisabledIndicator.propTypes = {};
