import React from "react";
import styles from "../../css/loc/LOCPointsMinCell.module.scss";
import { PropTypes } from "prop-types";
import { green, red } from "../../helpers/utils_styles";
// components
import LOCTableCell from "./LOCTableCell";

// REPRESENTS 'PointsMin' column

const custom = {
	color: red[700],
};

const LOCPointsMinCell = ({
	rowData,
	updateTableData,
	rowIdx,
	cellIdx,
	isEditable = true,
	allowedChars = /^\d+$/gim,
}) => {
	return (
		<>
			<LOCTableCell
				key={`Row${rowIdx}--Cell-${cellIdx}`}
				disableEdit={!isEditable}
				rowIdx={rowIdx}
				cellIdx={cellIdx}
				cellData={rowData["PointsMin"]}
				cellKey="PointsMin"
				updateTableData={updateTableData}
				allowedChars={allowedChars}
				customStyles={custom}
				inputMode="numeric"
			/>
		</>
	);
};

export default LOCPointsMinCell;

LOCPointsMinCell.defaultProps = {};

LOCPointsMinCell.propTypes = {
	rowData: PropTypes.object,
	updateTableData: PropTypes.func,
	rowIdx: PropTypes.number,
	cellIdx: PropTypes.number,
	isEditable: PropTypes.bool,
	allowedChars: PropTypes.instanceOf(RegExp),
};
