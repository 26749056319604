import React, { useContext } from "react";
import styles from "../css/pages/SettingsPage.module.scss";
import sprite from "../assets/icons/settings.svg";
import sprite2 from "../assets/icons/modals-complete.svg";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { PropTypes } from "prop-types";
import {
	enableFeatureViaWhiteList,
	featuresWhiteList,
} from "../helpers/utils_permissions";
import { purple } from "../helpers/utils_styles";
import { initialSettings, useAlerts } from "../utils/useAlerts";
// components
import ViewContainer from "../components/app/ViewContainer";
import MaintenanceMode from "../components/app/MaintenanceMode";
import FullPageTabsController from "../components/tabs/FullPageTabsController";
import FullPageTabButtons from "../components/tabs/FullPageTabButtons";
import FullPageTabButton from "../components/tabs/FullPageTabButton";
import FullPageTabPanels from "../components/tabs/FullPageTabPanels";
import FullPageTabPanel from "../components/tabs/FullPageTabPanel";
import ManageUserTypes from "../components/settings/ManageUserTypes";
import ManageExceptions from "../components/settings/ManageExceptions";
import ManageCancellations from "../components/settings/ManageCancellations";

const maintenanceDetails = {
	title: `Under-Construction`,
	desc: `Check back later!`,
};

const viewDetails = {
	title: `App Settings`,
	desc: `Various application settings & configurations.`,
};

// maps the existing tabs' indices to their 'history.location.state.target' value
const tabMap = {
	exceptions: 0,
	cancellations: 1, // alias user
	userTypes: 2,
};

const tabIndexMap = {
	0: "exceptions",
	1: "cancellations",
	2: "userTypes",
};

const iconsMap = {
	cancellations: "clearclose",
	exceptions: "settings1",
	userTypes: "people_outline",
};

// gets active, disabled or hidden tab button styles
const getTabStyles = (isActive) => {
	if (isActive) {
		return {
			fill: purple[600],
			color: purple[600],
		};
	} else {
		return null;
	}
};
// determines which tabs to hide based off 'user-type'
const getHiddenTabs = (currentUser = {}) => {
	const { isFacilityAdmin, isRegionalAdmin, isSuperUser, isMedTechRestricted } =
		currentUser;
	switch (true) {
		case isSuperUser: {
			return [];
		}
		case isRegionalAdmin && !isSuperUser: {
			return [2, 5];
		}
		case isFacilityAdmin && !isRegionalAdmin: {
			return [2, 5];
		}
		case isMedTechRestricted: {
			return [0, 1, 2, 5];
		}

		default:
			return [];
	}
};

const CustomButton = ({
	icon,
	name,
	isActive = false,
	isDisabled = false,
	isHidden = false,
	setTab,
	children,
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<div
			className={
				isDisabled ? styles.CustomButton_isDisabled : styles.CustomButton
			}
			onClick={setTab}
		>
			<svg className={styles.CustomButton_icon} style={getTabStyles(isActive)}>
				<use xlinkHref={`${sprite}#icon-${iconsMap[icon]}`}></use>
			</svg>
			<span className={styles.CustomButton_name} style={getTabStyles(isActive)}>
				{name}
			</span>

			{children}
		</div>
	);
};

const SettingsPage = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentUser, currentFacility } = globalState;
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });

	return (
		<>
			<ViewContainer title={viewDetails.title} desc={viewDetails.desc}>
				<div className={styles.SettingsPage}>
					{enableFeatureViaWhiteList(currentUser?.userID) && (
						<div className={styles.SettingsPage_tabs}>
							<FullPageTabsController
								defaultIndex={0}
								disabledIndices={[]}
								hiddenIndices={[]}
							>
								<FullPageTabButtons>
									<FullPageTabButton>
										<CustomButton
											icon="exceptions"
											name="Manage Exception Types"
										/>
									</FullPageTabButton>
									<FullPageTabButton>
										<CustomButton
											icon="cancellations"
											name="Manage Cancellation Types"
										/>
									</FullPageTabButton>
									<FullPageTabButton>
										<CustomButton icon="userTypes" name="Manage User Types" />
									</FullPageTabButton>
								</FullPageTabButtons>
								<FullPageTabPanels>
									<FullPageTabPanel>
										<ManageExceptions
											dispatchToState={dispatchToState}
											globalState={globalState}
											currentUser={currentUser}
											currentFacility={currentFacility}
											dispatchAlert={dispatchAlert}
										/>
									</FullPageTabPanel>
									<FullPageTabPanel>
										<ManageCancellations
											dispatchToState={dispatchToState}
											globalState={globalState}
											currentUser={currentUser}
											currentFacility={currentFacility}
											dispatchAlert={dispatchAlert}
										/>
									</FullPageTabPanel>
									<FullPageTabPanel>
										<ManageUserTypes
											dispatchToState={dispatchToState}
											globalState={globalState}
											currentUser={currentUser}
											currentFacility={currentFacility}
											dispatchAlert={dispatchAlert}
										/>
									</FullPageTabPanel>
								</FullPageTabPanels>
							</FullPageTabsController>
						</div>
					)}
					{/* FALLBACK UI for users that are NOT supposed to access this page */}
					{!enableFeatureViaWhiteList(currentUser?.userID) && (
						<div className={styles.SettingsPage_maintenance}>
							<MaintenanceMode
								title={maintenanceDetails.title}
								desc={maintenanceDetails.desc}
							/>
						</div>
					)}
				</div>
			</ViewContainer>

			{AlertsHandler}
		</>
	);
};

export default SettingsPage;

SettingsPage.defaultProps = {};

SettingsPage.propTypes = {
	history: PropTypes.object,
};
