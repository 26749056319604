import React from "react";
import styles from "../../css/admin/UserAccessColumns.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const addPadding = (idx, appList) => {
	if (idx - 1 === appList.length) {
		return {
			marginLeft: ".1rem",
		};
	} else {
		return {};
	}
};

const checkIfSorted = (isSorted, sortType, column) => {
	if (!isSorted) return;
	return sortType === column;
};

const sortPos = {
	transform: "rotate(180deg)",
};

const UserAccessColumns = ({
	columns = [],
	isSorted = false,
	sortType = "User",
	handleUserSorting,
	handleAppSorting,
	handleSuspendSorting,
}) => {
	const getIconCSS = (isSorted, sortType, column) => {
		const ifSorted = checkIfSorted(isSorted, sortType, column);
		return ifSorted
			? { transform: "rotate(180deg)" }
			: { transform: "rotate(0deg)" };
	};

	return (
		<div className={styles.UserAccessColumns}>
			<div className={styles.UserAccessColumns_inner}>
				<div
					className={styles.UserAccessColumns_inner_userCol}
					onClick={() => handleUserSorting("User")}
				>
					<span className={styles.UserAccessColumns_inner_userCol_name}>
						User
					</span>
					<svg
						className={styles.UserAccessColumns_inner_userCol_icon}
						style={getIconCSS(isSorted, sortType, "User")}
					>
						<use xlinkHref={`${sprite}#icon-filter_list`}></use>
					</svg>
				</div>
				{/* COLUMNS */}
				{!isEmptyArray(columns) &&
					[...columns].map((app, idx) => {
						return (
							<div
								className={styles.UserAccessColumns_inner_item}
								key={app + idx}
								style={addPadding(idx, columns)}
								onClick={() => handleAppSorting(app)}
							>
								<span className={styles.UserAccessColumns_inner_item_name}>
									{app}
								</span>
								<svg
									className={styles.UserAccessColumns_inner_item_icon}
									style={getIconCSS(isSorted, sortType, app)}
								>
									<use xlinkHref={`${sprite}#icon-filter_list`}></use>
								</svg>
							</div>
						);
					})}

				{/* DEACTIVATE BUTTON */}
				<div className={styles.UserAccessColumns_inner_deactivate}>
					<div
						className={styles.UserAccessColumns_inner_deactivate_btn}
						onClick={handleSuspendSorting}
					>
						Suspend
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserAccessColumns;

UserAccessColumns.defaultProps = {};

UserAccessColumns.propTypes = {};
