import React, { useState } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/facility/IncidentNotificationList.module.scss";
import {
	getIncidentsRecipients,
	updateIncidentEmailList,
	removeFromIncidentsList, // ← might not be needed
	removeRecipientByListType,
} from "../../helpers/utils_notifications";
import FacilityNotificationsList from "./FacilityNotificationsList";
import AddAltEmail from "../user/AddAltEmail";
import { isEmptyObj } from "../../helpers/utils_types";

// ##TODOS:
// - Add updater request to 'removeRecipient'
// 		- Prolly can use 'updateXXXXEmailList()' instead of specific removal request type
// - Add updater request to 'addNewRecipient' ✓

const customCSS = {
	email: {
		backgroundColor: "#eaecef",
	},
};

const addEmailMsg = `An additional email address will be used to receive notifications and alerts. All notifications and alerts will be sent to the below list of recipients.To add another email enter an email and save changes.`;

const defaultRecord = {
	// EmailsTo: "",
	datStart: new Date().toISOString(),
};

const IncidentNotificationList = ({
	listType = "INCIDENTS",
	currentUser = {},
	currentFacility = {},
	notificationRecord = {},
	dispatchAlert,
	syncChangesHandler,
}) => {
	const [currentRecord, setCurrentRecord] = useState({ ...notificationRecord });
	const [emailList, setEmailList] = useState(() => {
		return getIncidentsRecipients(notificationRecord);
	});
	const [newEmailRecipient, setNewEmailRecipient] = useState("");

	const handleNewEmail = (e) => {
		const { value } = e.target;
		setNewEmailRecipient(value);
	};

	const addNewRecipient = () => {
		const newList = [newEmailRecipient, ...emailList];
		const newRecord = {
			...defaultRecord, // this gets overwritten if has existing record; otherwise it's used as base record
			...notificationRecord, // this also gets overwritten, if has existing record since it's stale
			...currentRecord, // this *should* be the most current/updated record; if one exists
			SentTo: newList.join("; "),
		};

		setEmailList(newList);
		setNewEmailRecipient("");
		setCurrentRecord(newRecord);

		// update 'currentRecord' here
		return updateAndAlert({
			...newRecord, // MIGHT NEED TO ADD THIS OR REMOVE IT???
			// ...currentRecord, // MIGHT NEED TO ADD THIS BACK TO PREVENT DUPLICATES
			EmailsTo: [newEmailRecipient, ...emailList].join("; "),
		});
	};

	// updates db & triggers alert UI
	const updateAndAlert = async (updatedRecord) => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const wasUpdated = await updateIncidentEmailList(
			token,
			facilityID,
			updatedRecord
		);

		if (wasUpdated) {
			syncChangesHandler(listType, updatedRecord);
			return dispatchAlert("SUCCESS", {
				heading: `Email list was updated!`,
			});
		} else {
			// setEmailList([...emailList.shift()]);
			return dispatchAlert("ERROR", {
				heading: `Email list was NOT updated.`,
				subheading: `Please try again!`,
			});
		}
	};

	const removeRecipient = (listType, emailToRemove) => {
		// removes email from record's list & returns updated record
		const updatedRecord = removeRecipientByListType(
			listType,
			emailToRemove,
			currentRecord
		);
		setEmailList(getIncidentsRecipients(updatedRecord));
		setCurrentRecord({ ...updatedRecord });
		// fire off removal request here...
		return updateAndAlert(updatedRecord);
	};

	return (
		<div className={styles.IncidentNotificationList}>
			<div className={styles.IncidentNotificationList_addRecipient}>
				<AddAltEmail
					key={"NEW_INCIDENT_EMAIL"}
					name="newIncidentEmail"
					id="newIncidentEmail"
					val={newEmailRecipient}
					btnText="Add Email Address"
					msg={addEmailMsg}
					customStyles={customCSS.email}
					handleChange={handleNewEmail}
					saveAddEmail={addNewRecipient}
				/>
			</div>
			<FacilityNotificationsList
				key={"INCIDENTS_LIST"}
				listType={listType}
				notificationsList={emailList}
				removeRecipient={removeRecipient}
			/>
		</div>
	);
};

export default IncidentNotificationList;

IncidentNotificationList.defaultProps = {
	listType: "INCIDENTS",
	currentUser: {},
	currentFacility: {},
	notificationRecord: {},
};

IncidentNotificationList.propTypes = {
	listType: PropTypes.string,
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	notificationRecord: PropTypes.object,
	dispatchAlert: PropTypes.func,
	syncChangesHandler: PropTypes.func,
};
