import React, { useRef } from "react";
import styles from "../../css/admin/UserAccessActions.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	enableFeatureViaInternalList,
	enableFeatureViaWhiteList,
} from "../../helpers/utils_permissions";

const UserAccessActions = ({
	searchVal,
	handleSearch,
	initCustomSearch,
	clearSearch,
	currentUser = {},
}) => {
	const searchRef = useRef();

	// clears search & refocus input
	const enterHandler = (e) => {
		if (e.key === "Enter") {
			clearSearch();
			return searchRef?.current?.focus();
		} else {
			return;
		}
	};

	return (
		<div className={styles.UserAccessActions}>
			<div className={styles.UserAccessActions_search}>
				<svg className={styles.UserAccessActions_search_icon}>
					<use xlinkHref={`${sprite}#icon-search`}></use>
				</svg>
				<input
					type="text"
					name="searchUsers"
					id="searchUsers"
					value={searchVal}
					onChange={handleSearch}
					placeholder="Search by name, id, location..."
					className={styles.UserAccessActions_search_input}
					ref={searchRef}
				/>

				{!isEmptyVal(searchVal) && (
					<button
						className={styles.UserAccessActions_search_clear}
						onClick={clearSearch}
						onKeyDown={enterHandler}
						tabIndex={0}
					>
						<svg className={styles.UserAccessActions_search_clear_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</button>
				)}
			</div>
			<div className={styles.UserAccessActions_customSearch}>
				{enableFeatureViaWhiteList(currentUser?.userID) && (
					<button
						type="button"
						className={styles.UserAccessActions_customSearch_button}
						onClick={initCustomSearch}
					>
						Advanced Search
					</button>
				)}
			</div>
		</div>
	);
};

export default UserAccessActions;

UserAccessActions.defaultProps = {};

UserAccessActions.propTypes = {
	searchVal: PropTypes.string.isRequired,
	handleSearch: PropTypes.func.isRequired,
	initCustomSearch: PropTypes.func,
};
