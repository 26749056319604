import React from "react";
import styles from "../../css/summary/AppAccessSummary.module.scss";
import { PropTypes } from "prop-types";
import EnabledIndicator from "../shared/EnabledIndicator";
import DisabledIndicator from "../shared/DisabledIndicator";

const AppAccessSummary = ({
	hasTrackerAccess,
	hasLegacyAccess,
	hasPortalAccess,
}) => {
	return (
		<div className={styles.AppAccessSummary}>
			<div className={styles.AppAccessSummary_main}>
				<div className={styles.AppAccessSummary_main_item}>
					<div className={styles.AppAccessSummary_main_item_label}>
						Care Tracker App
					</div>
					<div className={styles.AppAccessSummary_main_item_access}>
						{hasTrackerAccess ? <EnabledIndicator /> : <DisabledIndicator />}
					</div>
				</div>
				<div className={styles.AppAccessSummary_main_item}>
					<div className={styles.AppAccessSummary_main_item_label}>
						Senior Care (EHR) App
					</div>
					<div className={styles.AppAccessSummary_main_item_access}>
						{hasLegacyAccess ? <EnabledIndicator /> : <DisabledIndicator />}
					</div>
				</div>
				<div className={styles.AppAccessSummary_main_item}>
					<div className={styles.AppAccessSummary_main_item_label}>
						Care Portal (SSO) App
					</div>
					<div className={styles.AppAccessSummary_main_item_access}>
						{hasPortalAccess ? <EnabledIndicator /> : <DisabledIndicator />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppAccessSummary;

AppAccessSummary.defaultProps = {};

AppAccessSummary.propTypes = {};
