import React from "react";
import styles from "../../css/facility/EditFacilityInfo.module.scss";
import sprite from "../../assets/icons/us-states.svg";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { updateFacilityInfo } from "../../helpers/utils_facility";
import { red } from "../../helpers/utils_styles";
// components
import TextInput from "../shared/TextInput";
import PhoneInput from "../shared/PhoneInput";
import EmailValidator from "../forms/EmailValidator";
import Divider from "../forms/Divider";
import ButtonSM from "../shared/ButtonSM";
import { featureFlags } from "../../helpers/utils_permissions";
import { phoneReg } from "../../helpers/utils_validation";
import LimitedTextInput from "../shared/LimitedTextInput";

const customCSS = {
	name: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	address: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	city: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	state: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	zip: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	phone: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	fax: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	email: {
		marginBottom: "2rem",
	},
	save: {
		padding: ".7rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		marginLeft: ".5rem",
	},
	cancel: {
		backgroundColor: "transparent",
		color: red[600],
		padding: ".7rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
	},
	yardi: {
		width: "100%",
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	yardiWidth: {
		width: "100%",
	},
};

// - FIELDS TO CHECK FOR CHANGES:
// 		- street
// 		- suiteNumber
// 		- city
// 		- zip
// 		- phoneNumber
// 		- email
// 		- fax
// 		- execDirector
// 		- alaDirector
// 		- licenseNumber
// 		- yardiNumber (not active or needed!)

// deprecated, new version is available, with better logic
const DEPRECATED_enableBtn1 = (formState = {}) => {
	const { touched } = formState;
	if (isEmptyObj(touched)) return false;

	// check for valid fields
	const enable =
		touched?.street ||
		touched?.suiteNumber ||
		touched?.city ||
		touched?.zip ||
		touched?.phone ||
		touched?.email ||
		touched?.fax ||
		touched?.execDirector ||
		touched?.alaDirector ||
		touched?.licenseNumber ||
		touched?.yardiNumber;

	const disable =
		!isEmptyVal(touched?.street) &&
		!isEmptyVal(touched?.suiteNumber) &&
		!isEmptyVal(touched?.city) &&
		!isEmptyVal(touched?.zip) &&
		!isEmptyVal(touched?.phone) &&
		!isEmptyVal(touched?.email) &&
		!isEmptyVal(touched?.fax) &&
		!isEmptyVal(touched?.execDirector) &&
		!isEmptyVal(touched?.alaDirector) &&
		!isEmptyVal(touched?.licenseNumber) &&
		!isEmptyVal(touched?.yardiNumber);

	const isEnabled = enable && !disable;

	return enable && !disable;
};

const enableBtn = (formState = {}) => {
	// any field that's 'touched' MUST be 'filled' to enable the button
	const wasAddress = wasTouchedAndFilled("street", formState);
	const wasSuite = wasTouchedAndFilled("suiteNumber", formState);
	const wasCity = wasTouchedAndFilled("city", formState);
	const wasZip = wasTouchedAndFilled("zip", formState);
	const wasPhone = wasTouchedAndFilled("phone", formState);
	const wasEmail = wasTouchedAndFilled("email", formState);
	const wasFax = wasTouchedAndFilled("fax", formState);
	const wasExecDir = wasTouchedAndFilled("execDirector", formState);
	const wasALADir = wasTouchedAndFilled("alaDirector", formState);
	const wasLicense = wasTouchedAndFilled("licenseNumber", formState);
	const wasYardi = wasTouchedAndFilled("yardiNumber", formState);

	// checks all available fields:
	// - If ANY were 'touched':
	// 		- Then they MUST be filled in order to update (ie. to enable button)
	// 		- If 'touched' and NOT filled, then button is disabled
	const shouldBeEnabled =
		(wasAddress ||
			wasSuite ||
			wasCity ||
			wasZip ||
			wasPhone ||
			wasEmail ||
			wasFax ||
			wasExecDir ||
			wasALADir ||
			wasLicense ||
			wasYardi) ??
		false;

	return shouldBeEnabled;
};

// checks if a 'touched' field was also filled-in
const wasTouchedAndFilled = (keyName, formState = {}) => {
	const { touched, values } = formState;
	const wasTouched = touched[keyName] ?? false;
	const wasFilled = !isEmptyVal(values[keyName]) ?? false;

	if (keyName === "phone" || keyName === "fax") {
		// runs validation for 'phone number'
		const isValidPhone = phoneReg.test(values?.[keyName] ?? "");
		return wasTouched && wasFilled && isValidPhone;
	} else {
		// handles fields besides 'phoneNumber'
		return wasTouched && wasFilled;
	}
};

const showYardiNumber = (currentUser, isFeatureEnabled) => {
	const { isSuperUser, isExecutiveAdmin, isAdmin } = currentUser;
	const hasEAPerms = isSuperUser || isExecutiveAdmin || isAdmin;

	const enableYardiNumber = isFeatureEnabled && hasEAPerms;

	return enableYardiNumber;
};

const EditFacilityInfo = ({
	vals = {},
	formState = {},
	handleChange,
	handleCheckbox,
	handleCustomFields,
	cancelChanges,
	saveChanges,
	currentUser = {},
	currentFacility = {},
}) => {
	const { state } = currentFacility?.address ?? "NA";

	if (isEmptyVal(currentFacility?.facilityID)) {
		return null;
	}
	return (
		<div className={styles.EditFacilityInfo}>
			<header className={styles.EditFacilityInfo_header}>
				<div className={styles.EditFacilityInfo_header_badge}>
					<svg className={styles.EditFacilityInfo_header_badge_icon}>
						<use xlinkHref={`${sprite}#icon-state-${state}`}></use>
					</svg>
				</div>
			</header>

			<section className={styles.EditFacilityInfo_form}>
				{/* COMMUNITY NAME */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						label="Community Name"
						name="communityName"
						id="communityName"
						val={vals.communityName}
						handleChange={handleChange}
						isDisabled={true}
						readOnly={true}
						customStyles={customCSS.name}
					/>
				</div>
				{/* ADDRESS */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						label="Address"
						name="street"
						id="street"
						val={vals.street}
						handleChange={handleChange}
						customStyles={customCSS.address}
					/>
					<TextInput
						label="Suite/Bldg. #"
						name="suiteNumber"
						id="suiteNumber"
						val={vals.suiteNumber}
						handleChange={handleChange}
						customStyles={customCSS.address}
					/>
					{/* CITY => STATE => ZIP */}
					<div className={styles.EditFacilityInfo_form_section_multiInput}>
						<div
							className={styles.EditFacilityInfo_form_section_multiInput_city}
						>
							<TextInput
								label="City"
								name="city"
								id="city"
								val={vals.city}
								handleChange={handleChange}
								customStyles={customCSS.city}
							/>
						</div>
						<div
							className={styles.EditFacilityInfo_form_section_multiInput_state}
						>
							<TextInput
								label="State"
								name="state"
								id="state"
								val={vals.state}
								handleChange={handleChange}
								customStyles={customCSS.state}
								isDisabled={true}
								readOnly={true}
							/>
						</div>
						<div
							className={styles.EditFacilityInfo_form_section_multiInput_zip}
						>
							<TextInput
								label="Zip"
								name="zip"
								id="zip"
								val={vals.zip}
								handleChange={handleChange}
								customStyles={customCSS.zip}
							/>
						</div>
					</div>
				</div>
				{/* PHONE NUMBER */}
				<div className={styles.EditFacilityInfo_form_section}>
					<PhoneInput
						label="Phone Number"
						name="phone"
						id="phone"
						val={vals.phone}
						handlePhone={handleCustomFields}
						placeholder="XXX-XXX-XXXX"
						customStyles={customCSS.phone}
					/>
				</div>
				{/* FAX NUMBER */}
				<div className={styles.EditFacilityInfo_form_section}>
					<PhoneInput
						label="Fax"
						name="fax"
						id="fax"
						val={vals.fax}
						handlePhone={handleCustomFields}
						placeholder="XXX-XXX-XXXX"
						customStyles={customCSS.fax}
					/>
				</div>
				{/* EMAIL ADDRESS */}
				<div className={styles.EditFacilityInfo_form_section}>
					<EmailValidator
						label="Email"
						name="email"
						id="email"
						initialVal={vals.email}
						handleChange={handleChange}
						placeholder="Enter email..."
						customStyles={customCSS.email}
					/>
				</div>
				<Divider />
				{/* EXECUTIVE DIRECTOR */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						label="Executive Director"
						name="execDirector"
						id="execDirector"
						val={vals.execDirector}
						handleChange={handleChange}
						customStyles={customCSS.name}
						placeholder="Exec director's name..."
					/>
				</div>
				{/* AL DIRECTOR */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						label="AL Director"
						name="alaDirector"
						id="alaDirector"
						val={vals.alaDirector}
						handleChange={handleChange}
						customStyles={customCSS.name}
						placeholder="ALA director's name..."
					/>
				</div>
				<Divider />
				{/* LICENSE NUMBER */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						label="License #"
						name="licenseNumber"
						id="licenseNumber"
						val={vals.licenseNumber}
						handleChange={handleChange}
						customStyles={customCSS.name}
						placeholder="Community license..."
					/>
				</div>
				{/* YARDI NUMBER - ONLY SHOWS FOR SUPER USERS & EXECUTIVE-ADMINS */}
				{showYardiNumber(
					currentUser,
					featureFlags?.facility?.enableYardiNumber // enabled
				) && (
					<div className={styles.EditFacilityInfo_form_section}>
						<LimitedTextInput
							label="Yardi #"
							name="yardiNumber"
							id="yardiNumber"
							val={vals.yardiNumber}
							handleChange={handleChange}
							customStyles={customCSS.yardi}
							customWidth={customCSS.yardiWidth}
							placeholder="Yardi number..."
							enableMaxChars={true}
							maxChars={50}
						/>
					</div>
				)}

				{/* ACTIONS */}
				<div className={styles.EditFacilityInfo_form_actions}>
					<ButtonSM
						isDisabled={false}
						handleClick={cancelChanges}
						customStyles={customCSS.cancel}
					>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={!enableBtn(formState)}
						handleClick={saveChanges}
						customStyles={customCSS.save}
					>
						Save
					</ButtonSM>
				</div>
			</section>
		</div>
	);
};

export default EditFacilityInfo;

EditFacilityInfo.defaultProps = {
	vals: {},
	currentUser: {},
	currentFacility: {},
};

EditFacilityInfo.propTypes = {
	vals: PropTypes.object,
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	handleChange: PropTypes.func,
	handleCheckbox: PropTypes.func,
	cancelChanges: PropTypes.func,
	saveChanges: PropTypes.func,
};
