import React from "react";
import styles from "../../css/tabs/ControllableTabsButton.module.scss";
import { PropTypes } from "prop-types";

const getCSS = (activeIndex, tabIndex) => {
	if (activeIndex === tabIndex) {
		return styles.ControllableTabsButton_isActive;
	} else {
		return styles.ControllableTabsButton;
	}
};

const ControllableTabsButton = ({
	handleTabButton,
	activeIndex,
	tabIndex,
	isDisabled = false,
	isHidden = false,
	children,
	callback = null,
}) => {
	const tabHandler = () => {
		handleTabButton(tabIndex);
		if (callback) {
			return callback();
		} else {
			return;
		}
	};

	const withProps = React.Children.map(children, (child, idx) => {
		return React.cloneElement(child, {
			activeIndex: activeIndex,
			tabIndex: tabIndex,
			isActive: activeIndex === tabIndex,
			isDisabled: isDisabled,
			isHidden: isHidden,
		});
	});

	if (isHidden) {
		return null;
	}
	return (
		<button
			type="button"
			className={getCSS(activeIndex, tabIndex)}
			onClick={() => handleTabButton(tabIndex)}
			// onClick={tabHandler}
			disabled={isDisabled}
		>
			{withProps}
		</button>
	);
};

export default ControllableTabsButton;

ControllableTabsButton.defaultProps = {};

ControllableTabsButton.propTypes = {};
