import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../css/pages/PageNotFound.module.scss";
import { PropTypes } from "prop-types";
import { useWindowSize } from "../utils/useWindowSize";
import logo from "../assets/brand/logo.webp";
import fallbackLogo from "../assets/brand/logo.png";
// components
import ImgWithFallback from "../components/shared/ImgWithFallback";

const customCSS = {
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-start",
};

const PageNotFound = ({ history }) => {
	const changeRoute = (route) => history.replace(route);
	const { width } = useWindowSize();

	return (
		<div className={styles.PageNotFound}>
			<section className={styles.PageNotFound_logoContainer}>
				<ImgWithFallback
					src={logo}
					fallback={fallbackLogo}
					alt="AL Advantage Logo"
					size={width < 650 ? "LG" : "XLG"}
					customStyles={customCSS}
				/>
			</section>
			<section className={styles.PageNotFound_titles}>
				<h1 className={styles.PageNotFound_titles_title}>Sorry! 🧐</h1>
				<h2 className={styles.PageNotFound_titles_subtitle}>
					Page was not found!
				</h2>
			</section>
			<section className={styles.PageNotFound_redirects}>
				<button
					className={styles.PageNotFound_redirects_redirectBtn}
					onClick={() => history.goBack()}
				>
					Go Back
				</button>
				<button
					className={styles.PageNotFound_redirects_redirectBtn}
					onClick={() => changeRoute("/")}
				>
					Go Home
				</button>
			</section>
		</div>
	);
};

export default withRouter(PageNotFound);

PageNotFound.defaultProps = {};

PageNotFound.propTypes = {
	history: PropTypes.object,
};
