import React from "react";
import styles from "../../css/dashboard/DashboardTabNav.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { mobileSize } from "../../helpers/utils_styles";

const showBackBtn = (history, hideOnThesePages = []) => {
	const { state } = history.location;
	const from = state?.from;
	const loginPage = "/";
	// custom override
	if (hideOnThesePages.includes(history.location.pathname)) return false;

	if (history.length < 3 || from === loginPage) {
		// last route & current route
		return false;
	} else {
		return true;
	}
};

const showForwardBtn = (history, hideOnThesePages = []) => {
	if (hideOnThesePages.includes(history.location.pathname)) return false;
	if (history && history.action === "POP") {
		return true;
	} else {
		return false;
	}
};

const getBackBtnCSS = (isCollapsed, winSize = {}) => {
	const { width, height } = winSize;
	if (width <= mobileSize.width || height <= mobileSize.height) {
		return {
			left: "2rem",
		};
	} else if (isCollapsed) {
		return {
			left: "8rem",
		};
	} else {
		return {
			left: "21rem",
		};
	}
};

const DashboardTabNav = ({
	winSize = {},
	isCollapsed = false,
	history,
	backLabel = null,
	forwardLabel = null,
	goBackCB,
	goForwardCB,
	hideOnThesePages = [],
}) => {
	// removes entry from 'history' list
	const goBack = () => {
		// return history.goBack();
		if (!goBackCB) {
			return history.go(-1);
		} else {
			return goBackCB();
		}
	};
	// adds entry to 'history' list
	const goForward = () => {
		// return history.goForward();
		if (!goForwardCB) {
			return history.go(+1);
		} else {
			return goForwardCB();
		}
	};

	history.listen((location, action) => {
		if (location.pathname && location.pathname.includes("/portal")) {
			// remove in PRODUCTION
		}
	});

	return (
		<>
			{showBackBtn(history, hideOnThesePages) && (
				<button
					className={styles.DashboardTabNav_backBtn}
					onClick={goBack}
					style={getBackBtnCSS(isCollapsed, winSize)}
				>
					<svg className={styles.DashboardTabNav_backBtn_icon}>
						<use xlinkHref={`${sprite}#icon-arrow_back`}></use>
					</svg>
					{backLabel && (
						<span className={styles.DashboardTabNav_backBtn_backLabel}>
							{backLabel}
						</span>
					)}
				</button>
			)}
			{showForwardBtn(history, hideOnThesePages) && (
				<button
					className={styles.DashboardTabNav_forwardBtn}
					onClick={goForward}
				>
					<svg className={styles.DashboardTabNav_forwardBtn_icon}>
						<use xlinkHref={`${sprite}#icon-arrow_forward`}></use>
					</svg>
					{forwardLabel && (
						<span className={styles.DashboardTabNav_forwardBtn_forwardLabel}>
							{forwardLabel}
						</span>
					)}
				</button>
			)}
		</>
	);
};

export default withRouter(DashboardTabNav);

DashboardTabNav.defaultProps = {};

DashboardTabNav.propTypes = {
	history: PropTypes.object.isRequired,
};
