import React from "react";
import styles from "../../css/facility/FacilityAppAccessController.module.scss";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";
import CustomCheckbox from "../shared/CustomCheckbox";

const customCSS = {
	locked: {
		opacity: 0.4,
	},
	unlocked: {
		opacity: 1.0,
	},
};

const warningMsg = `NOTE: Disabling access for the community will disable access for ALL users at that community!`;

const FacilityAppAccessController = ({
	appName,
	label,
	isEnabled = false,
	isLocked = false,
	enableAllUsers = false,
	handleAccess,
	handleAllUsersAccess,
}) => {
	const custom = {
		opacity: isLocked ? ".4" : "1",
	};

	return (
		<div className={styles.FacilityAppAccessController}>
			<div className={styles.FacilityAppAccessController} style={custom}>
				<div className={styles.FacilityAppAccessController_app}>
					{label}
					{isLocked && (
						<span className={styles.FacilityAppAccessController_app_isLocked}>
							{" "}
							- Locked
						</span>
					)}
				</div>
				<div className={styles.FacilityAppAccessController_switch}>
					<ToggleSwitch
						key={`${appName}-Enabled:${isEnabled ? "true" : "false"}`}
						name={appName}
						id={appName}
						val={isEnabled}
						handleToggle={handleAccess}
						isDisabled={isLocked || !handleAccess}
					/>
				</div>
			</div>
			<div className={styles.FacilityAppAccessController_checkbox}>
				<CustomCheckbox
					key={`${appName}-Enable-All-Users`}
					name={appName}
					id={`${appName}-enableAllUsers`}
					label="Enable access for all users at this community"
					val={enableAllUsers}
					handleCheckbox={handleAllUsersAccess}
					isDisabled={isLocked || !handleAccess}
				/>
			</div>
			<div
				className={styles.FacilityAppAccessController_warning}
				style={custom}
			>
				{warningMsg}
			</div>
		</div>
	);
};

export default FacilityAppAccessController;

FacilityAppAccessController.defaultProps = {};

FacilityAppAccessController.propTypes = {};
