import React from "react";
import styles from "../../css/user/EditAvatar.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { featureFlags } from "../../helpers/utils_permissions";
// components
import UserAvatar from "./UserAvatar";
import ProtectedFeature from "../permissions/ProtectedFeature";

const EditAvatar = ({ userImgSrc = "", openEditAvatarModal }) => {
	return (
		<div className={styles.EditAvatar}>
			<div className={styles.EditAvatar_inner}>
				<div className={styles.EditAvatar_inner_source}>
					<UserAvatar size="LG" src={userImgSrc} />
				</div>
				<ProtectedFeature isEnabled={featureFlags.user.editAvatar}>
					<button
						type="button"
						className={styles.EditAvatar_inner_edit}
						onClick={openEditAvatarModal}
					>
						<svg className={styles.EditAvatar_inner_edit_icon}>
							<use xlinkHref={`${sprite}#icon-createmode_editedit1`}></use>
						</svg>
					</button>
				</ProtectedFeature>
			</div>
		</div>
	);
};

export default EditAvatar;

EditAvatar.defaultProps = {
	userImgSrc: "",
};

EditAvatar.propTypes = {
	userImgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	openEditAvatarModal: PropTypes.func.isRequired,
};
