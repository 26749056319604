import React, { useCallback, useEffect, useState } from "react";
import styles from "../../css/forms/EmailValidator.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { testEmail } from "../../helpers/utils_validation";
import { checkIfUsernameExists } from "../../helpers/utils_auth";
import { isEmptyVal } from "../../helpers/utils_types";

// ##TODOS:
// - Merge 'XXXXMsg' components together:
//    - Should ONLY render a single message at a time
//    - Should ONLY deal with a single UI at a time

const ValidMsg = ({ msg }) => {
	return (
		<div className={styles.ValidMsg}>
			<svg className={styles.ValidMsg_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle_outline`}></use>
			</svg>
			<span className={styles.ValidMsg_msg}>{msg}</span>
		</div>
	);
};

const ErrorMsg = ({ msg }) => {
	return (
		<li className={styles.ErrorMsg}>
			<svg className={styles.ErrorMsg_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose`}></use>
			</svg>
			<span className={styles.ErrorMsg_msg}>{msg}</span>
		</li>
	);
};

const Msg = ({ val, isValid = false, alreadyExists = false }) => {
	if (isEmptyVal(val)) {
		return null;
	}
	if (isValid && !alreadyExists) {
		return <ValidMsg msg="Looks Good!" />;
	}
	if (isValid && alreadyExists) {
		return <ErrorMsg msg="Sorry! That email is taken!" />;
	}
	return <ErrorMsg msg="Needs to be an email format!" />;
};

const EmailValidator = ({
	label,
	name,
	id,
	token,
	placeholder,
	initialVal = "",
	handleChange,
	refFromParent = null,
	autoComplete = "off",
	wasCleared = false,
	customStyles = {},
}) => {
	const [val, setVal] = useState(initialVal);
	const [validation, setValidation] = useState({
		isValid: false,
		errors: [],
	});
	const [alreadyExists, setAlreadyExists] = useState(false);

	const onChangeHandler = (e) => {
		const { value } = e.target;
		setVal(value);
		handleChange(e);
	};

	const runValidator = (e) => {
		const { value } = e.target;
		onChangeHandler(e);

		// execute validator test
		const results = testEmail(value);
		setValidation({ ...results });
	};

	const clearAll = () => {
		setVal("");
		setAlreadyExists(false);
		setValidation({
			isValid: false,
			errors: [],
		});
	};

	// runs anytime a re-render occurs & 'isValid'
	const checkIfExists = useCallback(async () => {
		if (isEmptyVal(val)) return;
		const existsInSystem = await checkIfUsernameExists(token, val);
		setAlreadyExists(existsInSystem);
	}, [token, val]);

	// check if login exists already
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (validation?.isValid) {
			// check if already exists
			checkIfExists();
		}

		return () => {
			isMounted = false;
		};
	}, [checkIfExists, validation?.isValid]);

	// run validation onMount IF 'initialVal' exists
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(initialVal)) {
			const results = testEmail(initialVal);
			setValidation({ ...results });
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// focus input on mount (ONLY when 'ref' is provided)
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (refFromParent) {
			refFromParent?.current.focus();
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (wasCleared) {
			clearAll();
		}

		return () => {
			isMounted = false;
		};
	}, [wasCleared]);

	return (
		<div className={styles.EmailValidator}>
			<div className={styles.EmailValidator_inputWrapper}>
				<label
					htmlFor={id}
					className={styles.EmailValidator_inputWrapper_label}
				>
					{label}
				</label>
				<input
					type="text"
					name={name}
					id={id}
					value={val}
					placeholder={placeholder}
					className={styles.EmailValidator_inputWrapper_input}
					onChange={runValidator}
					autoComplete={autoComplete}
					inputMode="email"
					style={customStyles}
					ref={refFromParent}
				/>
			</div>
			<div className={styles.EmailValidator_errors}>
				<Msg
					val={val}
					isValid={validation.isValid}
					alreadyExists={alreadyExists}
				/>
			</div>
		</div>
	);
};

export default EmailValidator;

EmailValidator.defaultProps = {};

EmailValidator.propTypes = {};
