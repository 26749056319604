import React from "react";
import styles from "../../css/summary/AccountUnlockNotice.module.scss";
import { PropTypes } from "prop-types";

const getUsername = (entry) => {
	const { firstName, lastName } = entry;
	return `${firstName} ${lastName}`;
};

const AccountUnlockNotice = ({ entry = {} }) => {
	return (
		<div className={styles.AccountUnlockNotice}>
			<div className={styles.AccountUnlockNotice_heading}>
				You are about to unlock <mark>{getUsername(entry)}'s</mark> account.
			</div>
			<div className={styles.AccountUnlockNotice_details}>
				Once you've unlocked this account:
				<ul className={styles.AccountUnlockNotice_details_list}>
					<li className={styles.AccountUnlockNotice_details_list_option}>
						The user can reset their password with the{" "}
						<mark>Forgot Password</mark> option on the login page
					</li>
					<li className={styles.AccountUnlockNotice_details_list_option}>
						OR you may (optionally) generate a{" "}
						<mark>one-time-password (OTP)</mark> to send to this user.
					</li>
				</ul>
			</div>
			<ul className={styles.AccountUnlockNotice_otpInfo}>
				<span className={styles.AccountUnlockNotice_otpInfo_title}>
					One-Time-Passwords (OTP):
				</span>
				<li className={styles.AccountUnlockNotice_otpInfo_item}>
					Valid for a maximum of 15 minutes.
				</li>
				<li className={styles.AccountUnlockNotice_otpInfo_item}>
					Valid for a single login attempt
				</li>
				<li className={styles.AccountUnlockNotice_otpInfo_item}>
					Will prompt the user to reset their password when logging in.
				</li>
			</ul>
		</div>
	);
};

export default AccountUnlockNotice;

AccountUnlockNotice.defaultProps = {};

AccountUnlockNotice.propTypes = {
	entry: PropTypes.object,
};
