import React from "react";
import styles from "../../css/user/UserAvatar.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

// ##TODOS:
// - Add support for 'custom color' w/ user initials

const SIZES = {
	XSM: {
		width: "1.3rem",
		height: "1.3rem",
	},
	SM: {
		width: "5rem",
		height: "5rem",
	},
	MD: {
		width: "7rem",
		height: "7rem",
	},
	LG: {
		width: "12rem",
		height: "12rem",
	},
	XLG: {
		width: "20rem",
		height: "20rem",
	},
};

const UserAvatar = ({ src = "", size = "MD", customStyles = {} }) => {
	const custom = {
		...SIZES[size],
		...customStyles,
	};

	return (
		<div className={styles.UserAvatar} style={custom}>
			<div className={styles.UserAvatar_inner} style={custom}>
				{isEmptyVal(src) && (
					<svg className={styles.UserAvatar_inner_fallback} style={custom}>
						<use xlinkHref={`${sprite}#icon-account_circle`}></use>
					</svg>
				)}
				{!isEmptyVal(src) && (
					<img
						src={src}
						alt="User Avatar"
						className={styles.UserAvatar_inner_img}
						style={custom}
					/>
				)}
			</div>
		</div>
	);
};

export default UserAvatar;

UserAvatar.defaultProps = {};

UserAvatar.propTypes = {
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
