import React from "react";
import styles from "../../css/app/List.module.scss";
import { PropTypes } from "prop-types";

const List = ({ isCollapsed = false, history, children }) => {
	const withState = React.Children.map(children, (child, i) => {
		if (!child) return;
		return React.cloneElement(child, {
			history: history,
			isCollapsed: isCollapsed,
		});
	});

	return (
		<div className={styles.List}>
			<ul className={styles.List_list}>{withState}</ul>
		</div>
	);
};

export default List;

List.defaultProps = {
	isCollapsed: false,
};

List.propTypes = {
	history: PropTypes.object,
	isCollapsed: PropTypes.bool,
	children: PropTypes.any,
};
