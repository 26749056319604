import React, { useContext } from "react";
import styles from "../css/pages/InternalToolsPage.module.scss";
import sprite from "../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { purple } from "../helpers/utils_styles";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { useAlerts, initialSettings } from "../utils/useAlerts";
// components
import ViewContainer from "../components/app/ViewContainer";
import FullPageTabsController from "../components/tabs/FullPageTabsController";
import FullPageTabButtons from "../components/tabs/FullPageTabButtons";
import FullPageTabButton from "../components/tabs/FullPageTabButton";
import FullPageTabPanels from "../components/tabs/FullPageTabPanels";
import FullPageTabPanel from "../components/tabs/FullPageTabPanel";
import AppMessagesPanel from "../components/messages/AppMessagesPanel";

const customCSS = {
	tabs: {
		width: "100%",
	},
	tabBtns: {
		display: "flex",
		justifyContent: "center",
	},
};

const viewDetails = {
	title: `Internal Tools`,
	subtitle: `Various internal tools for ALA Internal Use ONLY such as: White-List controller etc.`,
};

const TABS = {
	ICONS: {
		messages: "settings11",
		other: "public",
	},
	MAP: {
		messages: 0,
		other: 1,
	},
};

const getTabCSS = (isActive, isDisabled) => {
	if (isActive) {
		return {
			color: purple[600],
			fill: purple[600],
		};
	} else if (isDisabled) {
		return {
			opacity: 0.4,
			cursor: "not-allowed",
		};
	} else {
		return {};
	}
};

const getHiddenTabs = (currentUser = {}) => {
	const { isAdmin, isSuperUser } = currentUser;
	if (!isAdmin && !isSuperUser) {
		return [];
	} else {
		return [];
	}
};

const TabButton = ({
	icon = "messages",
	btnText,
	isActive = false,
	isHidden = false,
	isDisabled = false,
	activeIndex, // from 'FullPageTabsController'
	tabIndex, // from 'FullPageTabsController'
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<div className={styles.TabButton}>
			<svg
				className={styles.TabButton_icon}
				style={getTabCSS(isActive, isDisabled)}
			>
				<use xlinkHref={`${sprite}#icon-${TABS.ICONS[icon]}`}></use>
			</svg>
			<span
				className={styles.TabButton_text}
				style={getTabCSS(isActive, isDisabled)}
			>
				{btnText}
			</span>
		</div>
	);
};

const InternalToolsPage = () => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentUser } = globalState;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);
	return (
		<>
			<ViewContainer title={viewDetails.title} desc={viewDetails.subtitle}>
				<div className={styles.InternalToolsPage}>
					<FullPageTabsController
						defaultIndex={0}
						disabledIndices={[]}
						hiddenIndices={getHiddenTabs(currentUser)}
						customStyles={customCSS.tabs}
					>
						<FullPageTabButtons customStyles={customCSS.tabBtns}>
							<FullPageTabButton>
								<TabButton icon="messages" btnText="Login Page Message(s)" />
							</FullPageTabButton>
							<FullPageTabButton>
								<TabButton icon="other" btnText="Other" />
							</FullPageTabButton>
						</FullPageTabButtons>
						<FullPageTabPanels>
							<FullPageTabPanel>
								<AppMessagesPanel
									currentUser={currentUser}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
						</FullPageTabPanels>
					</FullPageTabsController>
				</div>
			</ViewContainer>

			{AlertsHandler}
		</>
	);
};

export default InternalToolsPage;

InternalToolsPage.defaultProps = {};

InternalToolsPage.propTypes = {};
