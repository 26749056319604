import React, { useReducer, createContext } from "react";

const initialState = {
	app: {
		isLoading: false,
		hasLoaded: false,
		hasUpdated: false,
	},
	currentUser: {
		firstName: null,
		lastName: null,
		username: null,
		password: null,
		token: null,
		userID: null,
		profileID: null,
		title: null,
		isAdmin: false,
		isSuperUser: false,
		isReadOnly: false,
		hasMultiFacility: false,
		facilityID: null,
		hasTrackerAccess: false,
		hasLegacyAccess: false,
		hasPortalAccess: false,
	},
	currentFacility: {
		communityName: null,
		facilityID: null,
		parentID: null,
		residents: [],
		users: [],
		shifts: [],
		address: {},
		exceptionTypes: [],
		cancellationTypes: [],
		tags: [],
		// optional
		licenseNumber: null,
		yardiNumber: null,
		execDirector: null,
		alaDirector: null,
		phone: null,
		fax: null,
		email: null,
		timeZone: null,
	},
	systemUser: {
		username: null,
		password: null,
		isAuthenticated: false,
		token: null,
		sessionExpiry: null,
	},
	// parent facilities map (eg. 'ids', 'names' & 'raw' records)
	parentsMap: {},
	facilities: [],
	securityQuestions: [],
};

const stateReducer = (state, action) => {
	switch (action.type) {
		case "SYNC_INITIAL_RESOURCE": {
			const { newState } = action.data;

			return { ...newState };
		}
		case "SET_FACILITY": {
			const { facilityRecord } = action.data;

			return {
				...state,
				currentFacility: { ...facilityRecord },
			};
		}
		case "SYNC_FACILITY_FOR_MIGRATION": {
			const { facilityResidents, facilityRecord, facilityUsers } = action.data;
			return {
				...state,
				currentFacility: {
					...facilityRecord,
					residents: facilityResidents,
					users: facilityUsers,
				},
			};
		}
		case "SET_FACILITY_USERS": {
			const { facilityInfo, facilityUsers, facilityRecord, facilityLockouts } =
				action.data;

			return {
				...state,
				currentFacility: {
					...facilityRecord,
					...facilityInfo,
					users: facilityUsers,
					lockouts: facilityLockouts,
				},
			};
		}
		case "CLEAR_FACILITY": {
			return {
				...state,
				currentFacility: {
					...initialState?.currentFacility,
				},
			};
		}
		case "SET_LOCKOUTS": {
			const { facilityLockouts } = action.data;
			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					lockouts: facilityLockouts,
				},
			};
		}
		case "SET_EXCEPTIONS": {
			const { defaultExceptions, facilityExceptions } = action.data;

			return {
				...state,
				defaultExceptions: [...defaultExceptions],
				currentFacility: {
					...state.currentFacility,
					exceptions: [...facilityExceptions],
				},
			};
		}
		case "UPDATE_FACILITY_INFO": {
			const { facilityInfo } = action;

			// APPLIES CHANGES TO 'FACILITYINFO':
			// - Address info
			// - Administration (exec dir, ala dir etc)
			// - Comms (phone, fax, email etc)
			// - License Number
			// - YardiNumber

			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					execDirector: facilityInfo?.execDirector,
					alaDirector: facilityInfo?.alaDirector,
					phone: facilityInfo?.phone,
					email: facilityInfo?.email,
					fax: facilityInfo?.fax,
					licenseNumber: facilityInfo?.licenseNumber,
					yardiNumber: facilityInfo?.yardiNumber,
					address: {
						...state?.currentFacility?.address,
						street: facilityInfo?.street,
						city: facilityInfo?.city,
						state: facilityInfo?.state,
						zip: facilityInfo?.zip,
						suiteNumber: facilityInfo?.suiteNumber,
					},
				},
			};
		}
		case "CREATE_NEW_USER": {
			// client-formatted user object
			const { newUser } = action.data;

			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					users: [newUser, ...state.currentFacility.users],
				},
			};
		}
		case "MANUAL_LOGOUT":
		case "FORCE_LOGOUT": {
			return { ...initialState };
		}
		case "USER_UPDATE": {
			const { jobTitle, phoneNumber, phoneExt } = action.data;

			return {
				...state,
				currentUser: {
					...state.currentUser,
					jobTitle: jobTitle,
					phoneNumber: phoneNumber,
					phoneExt: phoneExt,
				},
			};
		}
		case "SYNC_USER_DATA": {
			const { userTypes, userTitles } = action.data;
			const { phoneNumber, newJobTitle } = action.data;
			const desc = newJobTitle?.desc ?? "";
			const titleID = newJobTitle?.titleID ?? 0;

			return {
				...state,
				userTypes,
				userTitles,
				currentUser: {
					...state.currentUser,
					title: desc,
					jobTitle: desc,
					jobTitleID: titleID,
					phoneNumber: phoneNumber,
				},
			};
		}
		case "AUTH_SYSTEM_USER": {
			const { token, expiry } = action.data.user;

			return {
				...state,
				systemUser: {
					token,
					isAuthenticated: true,
					expiry: expiry,
				},
			};
		}
		case "UPDATE_USER_QUESTIONS": {
			const { userQAVals: newVals } = action.data;

			return {
				...state,
				currentUser: {
					...state.currentUser,
					security: {
						...state.currentUser.security,
						userQAs: {
							...newVals,
						},
					},
				},
			};
		}
		case "DELETE_USER": {
			const { userID } = action.data;
			const { users } = state.currentFacility;
			// remove user by 'userID'
			const newUsers = [...users].filter((user) => user?.userID !== userID);

			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					users: newUsers,
				},
			};
		}
		// THIS WAS REMOVED & PUSHED DOWN INTO A 'UserAccessTable' table dispatch action
		case "USER_NAME_CHANGE": {
			const { userID, newFirstName, newLastName } = action.data;
			const { users } = state?.currentFacility;

			// get usersList without targetUser & get target user record
			const withoutTargetUser = [...users].filter(
				(user) => user.userID !== userID
			);
			const targetUser = [...users].filter(
				(user) => user.userID === userID
			)?.[0];

			const updatedUser = {
				...targetUser,
				firstName: newFirstName,
				lastName: newLastName,
			};

			// find target user, update names and merge into state again

			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					users: [...withoutTargetUser, updatedUser],
				},
			};
		}
		default:
			return state;
	}
};

const GlobalStateContext = createContext(initialState);

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(stateReducer, initialState);

	return (
		<GlobalStateContext.Provider value={{ state, dispatch }}>
			{children}
		</GlobalStateContext.Provider>
	);
};

export { initialState, GlobalStateContext, GlobalStateProvider };
