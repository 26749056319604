import React from "react";
import styles from "../../css/illustrations/Illustration.module.scss";
import { PropTypes } from "prop-types";
import verifyEmail from "../../assets/images/VerifyEmail.svg";
import accessDenied from "../../assets/images/AccessDenied.svg";
import addUser from "../../assets/images/AddUser.svg";
import bugs from "../../assets/images/BugFixing.svg";
import dataViz from "../../assets/images/DataViz.svg";
import flagged from "../../assets/images/Flagged.svg";
import progress from "../../assets/images/InProgress.svg";
import noData from "../../assets/images/NoData.svg";
import serverUpdate from "../../assets/images/ServerUpdate.svg";
import serverDown from "../../assets/images/ServerDown.svg";
import update from "../../assets/images/Update.svg";
import warning from "../../assets/images/Warning.svg";

const assets = {
	verify: verifyEmail,
	denied: accessDenied,
	addUser: addUser,
	bugs: bugs,
	data: dataViz,
	flag: flagged,
	progress: progress,
	noData: noData,
	serverUpdate: serverUpdate,
	serverDown: serverDown,
	update: update,
	warning: warning,
};

const Illustration = ({ asset = "verify", alt }) => {
	return (
		<div className={styles.Illustration}>
			<img src={assets[asset]} alt={alt} className={styles.Illustration_img} />
		</div>
	);
};

export default Illustration;

Illustration.defaultProps = {};

Illustration.propTypes = {
	asset: PropTypes.string,
	alt: PropTypes.string,
};
