import React from "react";
import styles from "../../css/user/UserSecurityQuestionsSummary.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import HiddenChars from "../shared/HiddenChars";

// fallback text when security questions are NOT selected
const noSelection = `No question selected.`;

const QuestionPreview = ({ questionIdx, question }) => {
	return (
		<div className={styles.QuestionPreview}>
			<div className={styles.QuestionPreview_number}>{questionIdx}.</div>
			<div className={styles.QuestionPreview_text}>
				{isEmptyVal(question) ? noSelection : question}
			</div>
		</div>
	);
};

const AnswerPreview = ({ answer }) => {
	return (
		<div className={styles.AnswerPreview}>
			<HiddenChars
				chars={answer}
				fallbackMsg={"No answer provided."}
				showLabel="Answer"
			/>
		</div>
	);
};

const UserSecurityQuestionsSummary = ({ vals = {} }) => {
	const {
		userType,
		securityQuestion1,
		securityQuestion2,
		securityQuestion3,
		securityAnswer1,
		securityAnswer2,
		securityAnswer3,
	} = vals;

	if ([`Administrator`, `SuperUser`].includes(userType)) {
		return null;
	}
	return (
		<div className={styles.UserSecurityQuestionsSummary}>
			<div className={styles.UserSecurityQuestionsSummary_subtitle}>
				<div className={styles.UserSecurityQuestionsSummary_subtitle_text}>
					Security Questions:
				</div>
			</div>
			<div className={styles.UserSecurityQuestionsSummary_main}>
				<div className={styles.UserSecurityQuestionsSummary_main_item}>
					<QuestionPreview question={securityQuestion1} questionIdx={1} />
					<AnswerPreview answer={securityAnswer1} />
				</div>
				<div className={styles.UserSecurityQuestionsSummary_main_item}>
					<QuestionPreview question={securityQuestion2} questionIdx={2} />
					<AnswerPreview answer={securityAnswer2} />
				</div>
				<div className={styles.UserSecurityQuestionsSummary_main_item}>
					<QuestionPreview question={securityQuestion3} questionIdx={3} />
					<AnswerPreview answer={securityAnswer3} />
				</div>
			</div>
		</div>
	);
};

export default UserSecurityQuestionsSummary;

UserSecurityQuestionsSummary.defaultProps = {};

UserSecurityQuestionsSummary.propTypes = {};
