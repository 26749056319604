import React from "react";
import styles from "../../css/summary/LockoutsList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import LockoutEntry from "./LockoutEntry";

const LockoutsList = ({
	currentFacility = {},
	currentUser = {},
	userLockouts = [],
}) => {
	return (
		<div className={styles.LockoutsList}>
			{!isEmptyArray(userLockouts) &&
				userLockouts.map((entry, idx) => (
					<LockoutEntry
						key={`${entry.UserID}_${idx}`}
						lockoutEntry={entry}
						currentFacility={currentFacility}
						currentUser={currentUser}
					/>
				))}
		</div>
	);
};

export default LockoutsList;

LockoutsList.defaultProps = {
	currentFacility: {},
	currentUser: {},
	userLockouts: [],
};

LockoutsList.propTypes = {
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	userLockouts: PropTypes.array,
};
