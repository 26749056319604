import React from "react";
import styles from "../../css/residents/CustomResidentSearchResults.module.scss";
import { PropTypes } from "prop-types";
import {
	getResidentName,
	getResidentAge,
	getResidentID,
} from "../../helpers/utils_residents";
import { isEmptyArray } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
// components
import Spinner from "../shared/Spinner";
import Empty from "../shared/Empty";

const customCSS = {
	empty: {
		icon: {
			fill: red[600],
		},
		heading: {
			// color: red[600],
		},
		subheading: {
			color: red[600],
		},
	},
};

const ResidentSearchEntry = ({ resident = {}, viewResident }) => {
	return (
		<div className={styles.ResidentSearchEntry} onClick={viewResident}>
			<div className={styles.ResidentSearchEntry_inner}>
				<div className={styles.ResidentSearchEntry_inner_name}>
					<div className={styles.ResidentSearchEntry_inner_name_msg}>
						{getResidentName(resident)} <b>({resident?.residentID})</b>
					</div>

					<button
						type="button"
						className={styles.ResidentSearchEntry_inner_name_editBtn}
					>
						View resident
					</button>

					{resident?.isArchived && (
						<div
							title="Archived Resident"
							className={styles.ResidentSearchEntry_inner_name_isArchived}
						></div>
					)}
				</div>
			</div>
		</div>
	);
};

const CustomResidentSearchResults = ({
	isLoading = false,
	searchResults = [],
	viewResident,
}) => {
	if (isLoading) {
		return (
			<div className={styles.LoadingSearchResults}>
				<Spinner />
				<div className={styles.LoadingSearchResults_msg}>
					Loading search results...
				</div>
			</div>
		);
	}
	return (
		<div className={styles.CustomResidentSearchResults}>
			<ul className={styles.CustomResidentSearchResults_list}>
				{isEmptyArray(searchResults) && (
					<div className={styles.CustomResidentSearchResults_noResults}>
						<Empty
							customStyles={customCSS.empty}
							size="MD"
							heading="No matching results."
							icon="archive"
						/>
					</div>
				)}
				{!isEmptyArray(searchResults) &&
					searchResults.map((resident, idx) => (
						<ResidentSearchEntry
							key={`${resident?.ID}_${idx}`}
							resident={resident}
							viewResident={() => viewResident(resident)}
						/>
					))}
			</ul>
			{/*  */}
		</div>
	);
};

export default CustomResidentSearchResults;

CustomResidentSearchResults.defaultProps = {};

CustomResidentSearchResults.propTypes = {};
