import React, { useEffect } from "react";
import styles from "../../css/tabs/ControllableTabsWrapper.module.scss";
import { PropTypes } from "prop-types";

const ControllableTabsWrapper = ({
	activeIndex = 0,
	tabsController,
	disabledIndices = [],
	hiddenIndices = [],
	children,
	customStyles = {},
}) => {
	const withProps = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton: tabsController,
			activeIndex: activeIndex,
			disabledIndices: disabledIndices,
			hiddenIndices: hiddenIndices,
		})
	);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<article className={styles.ControllableTabsWrapper} style={customStyles}>
			{withProps}
		</article>
	);
};

export default ControllableTabsWrapper;

ControllableTabsWrapper.defaultProps = {
	activeIndex: 0,
	disabledIndices: [],
	hiddenIndices: [],
	children: PropTypes.any,
};

ControllableTabsWrapper.propTypes = {
	activeIndex: PropTypes.number,
	disabledIndices: PropTypes.arrayOf(PropTypes.number),
	hiddenIndices: PropTypes.arrayOf(PropTypes.number),
	children: PropTypes.any,
};
