import React, { useState } from "react";
import styles from "../../css/firstlogin/RequireSecurityQuestions.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { requiresSecurityQuestions } from "../../helpers/utils_user";
import AddQuestions from "./AddQuestions";
import ModalLG from "../shared/ModalLG";

const RequireSecurityQuestions = ({
	currentUser,
	globalState,
	dispatchToState,
}) => {
	const [showQuestionsModal, setShowQuestionsModal] = useState(false);

	// if (!requiresSecurityQuestions(currentUser?.security)) {
	// 	return null;
	// }
	return (
		<div className={styles.RequireSecurityQuestions}>
			<button
				type="button"
				onClick={() => setShowQuestionsModal(true)}
				className={styles.RequireSecurityQuestions_button}
			>
				<svg className={styles.RequireSecurityQuestions_button_icon}>
					<use xlinkHref={`${sprite}#icon-error_outline`}></use>
				</svg>
				<span>You Need to Add Security Questions</span>
			</button>

			{showQuestionsModal && (
				<ModalLG
					title="Create Security Questions"
					closeModal={() => setShowQuestionsModal(false)}
				>
					<AddQuestions
						showModal={showQuestionsModal}
						closeModal={() => setShowQuestionsModal(false)}
						currentUser={currentUser}
						globalState={globalState}
						dispatchToState={dispatchToState}
					/>
				</ModalLG>
			)}
		</div>
	);
};

export default RequireSecurityQuestions;

RequireSecurityQuestions.defaultProps = {};

RequireSecurityQuestions.propTypes = {};
