import React, { useState } from "react";
import styles from "../../css/admin/MakeUserAdmin.module.scss";
import { PropTypes } from "prop-types";
import { blueGrey } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { USER_TYPES as allUserTypes } from "../../helpers/utils_userTypes";
// components
import UserSelector from "./UserSelector";
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";

// ❌ UNFINISHED COMPONENT CODE!!! ❌

const customCSS = {
	save: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancel: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${blueGrey[400]}`,
		marginRight: "1rem",
	},
	types: {
		width: "30rem",
	},
};

const enableBtn = (selections, targetUserType) => {
	const hasSelection = !isEmptyArray(selections);
	const hasType = !isEmptyVal(targetUserType);

	return hasSelection && hasType;
};

const MakeUserAdmin = ({
	globalState,
	targetUser,
	facilityUsers = [],
	currentUser,
	dispatchAlert,
	dispatchToState,
}) => {
	const [userSelections, setUserSelections] = useState([]);
	const [selectedType, setSelectedType] = useState("");

	const handleUserSelect = (selections) => {
		setUserSelections(selections);
	};

	const handleUserType = (name, type) => {
		setSelectedType(type);
	};

	const saveChanges = async (e) => {
		const userType = selectedType;
		const usersToUpdate = [...userSelections];
	};
	const cancelChanges = (e) => {
		setUserSelections([]);
		setSelectedType("");
	};

	return (
		<div className={styles.MakeUserAdmin}>
			<div className={styles.MakeUserAdmin_about}>
				<div className={styles.MakeUserAdmin_about_desc}>
					Change one or more users permissions by making them an 'Admin',
					'Facility Admin' or other user type.
				</div>
			</div>
			<div className={styles.MakeUserAdmin_form}>
				<div className={styles.MakeUserAdmin_form_field}>
					<label
						htmlFor="userSelection"
						className={styles.MakeUserAdmin_form_field_label}
					>
						Select users to update:
					</label>
					<UserSelector
						handleUserSelector={handleUserSelect}
						initialSelections={[]}
						options={[...facilityUsers]}
						disabledOptions={[]}
					/>
				</div>
				<div className={styles.MakeUserAdmin_form_field}>
					<CustomDropdown
						name="userType"
						id="userType"
						label="Select a User Type for these users:"
						placeholder={`Choose a user type...`}
						selection={selectedType}
						setSelection={handleUserType}
						options={[...allUserTypes]}
						customStyles={customCSS.types}
					/>
				</div>

				<div className={styles.MakeUserAdmin_form_actions}>
					<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={!enableBtn(userSelections, selectedType)}
						handleClick={saveChanges}
						customStyles={customCSS.save}
					>
						Save Changes
					</ButtonSM>
				</div>
			</div>
		</div>
	);
};

export default MakeUserAdmin;

MakeUserAdmin.defaultProps = {};

MakeUserAdmin.propTypes = {};
