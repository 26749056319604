import React from "react";
import styles from "../../css/messages/HomePageMessage.module.scss";
import { PropTypes } from "prop-types";
import { getMsgPriorityColor, isMsgActive } from "../../helpers/utils_messages";

const getMsgPriorityCss = (priority) => {
	const color = getMsgPriorityColor(priority);

	return {
		color: color,
	};
};

const HomePageMessage = ({ message = {}, customMsgStyles = {} }) => {
	const { msg, msgPriority: priority, enableMsg } = message;

	if (!enableMsg || !isMsgActive(message)) {
		return null;
	}
	return (
		<div
			className={styles.HomePageMessage}
			style={{ ...getMsgPriorityCss(priority), ...customMsgStyles }}
		>
			{msg}
			{/*  */}
		</div>
	);
};

export default HomePageMessage;

HomePageMessage.defaultProps = {};

HomePageMessage.propTypes = {};
