import React from "react";
import styles from "../../css/facility/FacilityAccessOption.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";

const FacilityAccessOption = ({
	isDisabled = false,
	isSelected = false,
	option,
	handleSelect,
}) => {
	const custom = {
		opacity: isDisabled ? ".4" : "1",
		cursor: isDisabled ? "not-allowed" : "pointer",
	};
	return (
		<li
			className={
				isSelected
					? styles.FacilityAccessOption_isSelected
					: styles.FacilityAccessOption
			}
			onClick={isDisabled ? null : () => handleSelect(option)}
			style={custom}
		>
			<span>{option}</span>

			{isSelected && (
				<svg className={styles.FacilityAccessOption_icon}>
					<use xlinkHref={`${sprite}#icon-checkmark-outline`}></use>
				</svg>
			)}
		</li>
	);
};

export default FacilityAccessOption;

// single dropdown menu option
FacilityAccessOption.defaultProps = {
	isSelected: false,
};
FacilityAccessOption.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	handleSelect: PropTypes.func.isRequired,
};
