import React, { useCallback, useEffect, useContext, useState } from "react";
import styles from "../css/pages/UserManagementPage.module.scss";
import sprite from "../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { initialSettings, useAlerts } from "../utils/useAlerts";
import { matchUserFacilityByName } from "../helpers/utils_facility";
import { getUsersByFacility, processUserList } from "../helpers/utils_user";
import { isEmptyVal } from "../helpers/utils_types";
import { purple } from "../helpers/utils_styles";
// components
import ViewContainer from "../components/app/ViewContainer";
import FullPageTabsController from "../components/tabs/FullPageTabsController";
import FullPageTabButtons from "../components/tabs/FullPageTabButtons";
import FullPageTabButton from "../components/tabs/FullPageTabButton";
import FullPageTabPanels from "../components/tabs/FullPageTabPanels";
import FullPageTabPanel from "../components/tabs/FullPageTabPanel";
import ALASelector from "../components/app/ALASelector";
import UsersSummary from "../components/admin/UsersSummary";
import UserLockouts from "../components/summary/UserLockouts";
import UserPermissions from "../components/admin/UserPermissions";
import UserSearch from "../components/admin/UserSearch";

const customCSS = {
	tabs: {
		width: "100%",
	},
	tabBtns: {
		display: "flex",
		justifyContent: "center",
	},
};

const details = {
	title: `User Management`,
	desc: `Manage your facility's users all in one place.`,
};

const TABS = {
	ICONS: {
		lockouts: "lock",
		summary: "v-card",
		permissions: "key", // tools
		search: "search1",
	},
	MAP: {
		lockouts: 0,
		summary: 1,
		permissions: 2, // tools
		search: 3, // tools
	},
};

const getTabCSS = (isActive, isDisabled) => {
	if (isActive) {
		return {
			color: purple[600],
			fill: purple[600],
		};
	} else if (isDisabled) {
		return {
			opacity: 0.4,
			cursor: "not-allowed",
		};
	} else {
		return {};
	}
};

const getHiddenTabs = (currentUser = {}) => {
	const { isAdmin, isSuperUser } = currentUser;
	if (!isAdmin && !isSuperUser) {
		return [TABS.MAP["lockouts"], TABS.MAP["permissions"], TABS.MAP["search"]];
	} else {
		return [];
	}
};

const TabButton = ({
	icon = "lockouts",
	btnText,
	isActive = false,
	isHidden = false,
	isDisabled = false,
	activeIndex, // from 'FullPageTabsController'
	tabIndex, // from 'FullPageTabsController'
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<div className={styles.TabButton}>
			<svg
				className={styles.TabButton_icon}
				style={getTabCSS(isActive, isDisabled)}
			>
				<use xlinkHref={`${sprite}#icon-${TABS.ICONS[icon]}`}></use>
			</svg>
			<span
				className={styles.TabButton_text}
				style={getTabCSS(isActive, isDisabled)}
			>
				{btnText}
			</span>
		</div>
	);
};

const UserManagementPage = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } = useContext(
		GlobalStateContext
	);
	const { currentUser, currentFacility } = globalState;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);
	const [selectedFacility, setSelectedFacility] = useState(() => {
		return currentFacility?.communityName ?? "";
	});

	// triggers alert, nothing else
	const changeFacility = (selection) => {
		// fetch facility details here
		dispatchAlert("SUCCESS", {
			heading: `Loaded Facility`,
			subheading: `Loaded ${selection}`,
		});
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(async () => {
		if (isEmptyVal(selectedFacility)) {
			return dispatchToState({ type: "CLEAR_FACILITY" });
		}
		const { token } = currentUser;
		const facilityRecord = matchUserFacilityByName(
			selectedFacility,
			currentUser?.facilities
		);
		const { facilityID } = facilityRecord;
		const facilityUsers = await getUsersByFacility(token, facilityID, 0, 1000);

		dispatchToState({
			type: "SET_FACILITY_USERS",
			data: {
				facilityRecord: facilityRecord,
				facilityUsers: [...processUserList(facilityUsers)],
			},
		});
	}, [currentUser, dispatchToState, selectedFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	return (
		<>
			<ViewContainer title={details?.title} desc={details?.desc}>
				<div className={styles.UserManagementPage}>
					<header className={styles.UserManagementPage_header}>
						<div className={styles.UserManagementPage_header_facility}>
							{!isEmptyVal(currentFacility?.facilityID)
								? currentFacility?.communityName
								: ""}
						</div>
						<ALASelector
							key={`USER_MGMT_SELECTOR`}
							disableResident={true} // facility only selector
							isAdmin={true} // admin only route
							facilities={currentUser?.facilities}
							defaultFacility={selectedFacility}
							syncFacility={setSelectedFacility}
							loadFacility={changeFacility}
						/>
					</header>
					<section className={styles.UserManagementPage_tabs}>
						<FullPageTabsController
							defaultIndex={0}
							disabledIndices={[]}
							hiddenIndices={getHiddenTabs(currentUser)}
							customStyles={customCSS.tabs}
						>
							<FullPageTabButtons customStyles={customCSS.tabBtns}>
								<FullPageTabButton>
									<TabButton icon="lockouts" btnText="Lockouts" />
								</FullPageTabButton>
								<FullPageTabButton>
									<TabButton icon="summary" btnText="Summary" />
								</FullPageTabButton>
								<FullPageTabButton>
									<TabButton icon="permissions" btnText="Permissions" />
								</FullPageTabButton>
								<FullPageTabButton>
									<TabButton icon="search" btnText="Custom Search" />
								</FullPageTabButton>
							</FullPageTabButtons>

							<FullPageTabPanels>
								{/* ACCOUNT LOCKOUTS */}
								<FullPageTabPanel>
									<UserLockouts
										currentFacility={currentFacility}
										currentUser={currentUser}
										globalState={globalState}
										dispatchToState={dispatchToState}
									/>
								</FullPageTabPanel>

								{/* ALL USERS */}
								<FullPageTabPanel>
									<UsersSummary
										key={currentFacility?.users?.length}
										dispatchToState={dispatchToState}
										currentFacility={currentFacility}
										currentUser={currentUser}
										facilityUsers={currentFacility?.users}
									/>
								</FullPageTabPanel>

								{/* PERMISSIONS */}
								<FullPageTabPanel>
									<UserPermissions
										dispatchAlert={dispatchAlert}
										dispatchToState={dispatchToState}
										currentFacility={currentFacility}
										currentUser={currentUser}
									/>
								</FullPageTabPanel>

								{/* CUSTOM SEARCH */}
								<FullPageTabPanel>
									<UserSearch
										dispatchAlert={dispatchAlert}
										dispatchToState={dispatchToState}
										currentFacility={currentFacility}
										currentUser={currentUser}
									/>
								</FullPageTabPanel>
							</FullPageTabPanels>
						</FullPageTabsController>
					</section>
				</div>
			</ViewContainer>

			{AlertsHandler}
		</>
	);
};

export default UserManagementPage;

UserManagementPage.defaultProps = {};

UserManagementPage.propTypes = {
	history: PropTypes.object,
};
