import React, { useState } from "react";
import styles from "../../css/shared/HiddenChars.module.scss";
import sprite from "../../assets/icons/showhide.svg";
import { PropTypes } from "prop-types";
import { range } from "../../helpers/utils_processing";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import HiddenChar from "./HiddenChar";

const getHiddenChars = (chars, fallbackMsg) => {
	if (!chars || isEmptyVal(chars)) return fallbackMsg;
	const length = chars.length;
	const [...letters] = chars;
	const charList = range(0, length, (x) => x + 1);

	return (
		<>
			{charList.map((char, idx) => (
				<HiddenChar key={`${char}__${idx}`} char={letters[idx]} />
			))}
		</>
	);
};

const HiddenChars = ({ chars, fallbackMsg = "N/A", showLabel = "Show" }) => {
	const [revealHiddenChars, setRevealHiddenChars] = useState(false);

	const toggleHiddenState = () => {
		setRevealHiddenChars(!revealHiddenChars);
	};

	return (
		<div className={styles.HiddenChars}>
			{!revealHiddenChars && (
				<div className={styles.HiddenChars_hidden}>
					{getHiddenChars(chars, fallbackMsg)}
				</div>
			)}
			{revealHiddenChars && (
				<div className={styles.HiddenChars_revealed}>{chars}</div>
			)}

			<div className={styles.HiddenChars_toggle} onClick={toggleHiddenState}>
				<svg className={styles.HiddenChars_toggle_icon}>
					<use
						xlinkHref={`${sprite}#icon-view-${
							revealHiddenChars ? "show" : "hide"
						}`}
					></use>
				</svg>
				<span className={styles.HiddenChars_toggle_text}>
					{revealHiddenChars ? "Hide" : "Show"} {showLabel}
				</span>
			</div>
		</div>
	);
};

export default HiddenChars;

HiddenChars.defaultProps = {};

HiddenChars.propTypes = {};
