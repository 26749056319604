import { isEmptyObj, isEmptyVal } from "./utils_types";

//////////////////////////////////////////////////////////////////////////////
///////////////////////////// REGEX VALIDATORS /////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const validators = {
	specialChars: /[!@#$%^&*(),.?":{}|<>]/g,
	numbers: /\d/g,
	upperCase: /[A-Z]/g,
	lowerCase: /[a-z]/g,
	email:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	username: /^(?<username>[^<>%$]*$)/,
	password:
		/^(?<upper>(?=.*[A-Z]))(?<lower>(?=.*[a-z]))(?<num>(?=.*[0-9]))(?<special>(?=.*[!@#$%^&*(){},]))/,
	firstName: /^(?=.*[A-Z]{1})(?=.*[a-z])/,
	lastName: /^(?=.*[A-Z]{1})(?=.*[a-z])/,
	none: /^([A-Za-z0-9]){2,}/,
};

const { specialChars, numbers, upperCase, lowerCase } = validators;

// validates for "(XXX) XXX-XXXX" phone numbers
const phoneReg = /^(\(\d{3}\) \d{3}-\d{4})/gm;

const emailReg =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isSpecialChar = (str) => {
	const specReg = /[\[\]{}\-_+=`@!#$%^&*()|\\,.<>?\/]+/g;
	const isSpecial = specReg.test(str);
	return isSpecial;
};

//////////////////////////////////////////////////////////////////////////////
///////////////////////////// PATTERN MATCHER(S) /////////////////////////////
//////////////////////////////////////////////////////////////////////////////

/**
 * @description - A light 'wrapper' around String.prototype.test()
 * @param {String} val - A string to test.
 * @param {RegExp} pattern - A regex pattern to match/test for.
 * @returns {Boolean} - Returns true|false
 */
const matchesPattern = (val, pattern) => {
	const newPattern = new RegExp(pattern);
	const isMatch = newPattern.test(val);
	return isMatch;
};

/**
 * @description - Util that tests that two password entries are identical via regex.
 * @param {String} val - A string to test with.
 * @param {String} password - The 'original' password to match/test against.
 * @returns {Boolean} - Returns true|false
 */
const matchesPassword = (val, password) => {
	const pattern = new RegExp(password);
	const isMatch = pattern.test(val);
	return isMatch;
};
/**
 * @description - Tests that a string meets a minimum length.
 * @param {String} val - A string to test.
 * @param {Number} minLength - The length in characters to test for.
 * @returns {Boolean} - Returns true|false
 */
const matchesMinLength = (val, minLength = 6) => {
	return val.length >= minLength;
};

/**
 * Returns all regex match groups for a string value.
 * @param {String} val - Target string to test
 * @param {RegExp} pattern - RegExp to match for.
 * @returns {Object} - Returns an object of regex matches if found.
 */
const getMatchGroups = (val, pattern) => {
	if (matchesPattern(val, pattern)) {
		const matches = pattern.exec(val);
		const groups = matches?.groups ?? {};
		return { ...groups };
	} else {
		return {};
	}
};

//////////////////////////////////////////////////////////////////////////////
//////////////////////////// PASSWORD VALIDATORS ////////////////////////////
//////////////////////////////////////////////////////////////////////////////

/**
 * Default validation 'error' messages for handling password requirements.
 */
const ERROR_MSGS = {
	hasNum: `Must include a number.`,
	hasLower: `Must include a lowercase letter.`,
	hasUpper: `Must include a uppercase letter.`,
	hasSpecial: `Must include a special character.`,
	hasMinLength: `Must be at least 6 characters.`,
};

// forms 'minLength' error message
const getLengthMsg = (minLength = 6) => {
	return { hasMinLength: `- Must be at least ${minLength} characters` };
};

/**
 * @description - Determines which (if any) error messages apply to a password entry, based off password requirements.
 * @param {Object} results - The validation results object w/ 'hasXXXX' char type results.
 * @param {Object} msgOptions - An object map of error messages by char type 'name'.
 * @returns {Array} - Returns an array of string-form error messages.
 */
const getValidationMsgs = (results = {}, msgOptions = {}) => {
	const resultKeys = Object.keys(results);
	return resultKeys.reduce((allMsgs, key) => {
		if (!results[key]) {
			const msg = msgOptions[key];
			allMsgs = [...allMsgs, msg];
			return allMsgs;
		}
		return allMsgs;
	}, []);
};

/**
 * @description - Tests a password string and returns any error messages for failed requirements.
 * @param {String} val - A string 'password' to validate for.
 * @param {Number} minLength - The minimum length for the password/string.
 * @param {Object} errorMsgs - An object map of error messages for password requirements.
 * @returns {Array} - Returns an array of strings.
 */
const getErrMessages = (val, minLength = 6, errorMsgs = {}) => {
	const results = "FIX THIS LATER!!!";
	const errMessages = getValidationMsgs(results, {
		...errorMsgs,
		...getLengthMsg(minLength),
	});

	return errMessages;
};

/**
 * PASSWORD VALIDATOR UTILS & PATTERNS
 *
 */

/**
 * Password validation patterns:
 * - Numbers
 * - Uppercase/lowercase
 * - Special chars
 * - Min. length
 */
/**
 * REGEX PATTERNS
 * - Special chars, numbers, uppercase, lowercase
 */
const PATTERNS = {
	specialChars: /[!@#$%^&*(),.?":{}|<>]{1,}/gm,
	numbers: /(\d{1,})/gm,
	upperCase: /([A-Z]{1,})/gm,
	lowerCase: /([a-z]{1,})/gm,
};
/**
 * Password requirement(s) variations:
 * - Numbers
 * - Lowercase/uppercase
 * - Non-words
 * - Min. length (6)
 */
const PATTERN_VARIATIONS = {
	hasNum: /\d/,
	hasLower: /[a-z]/,
	hasUpper: /[A-Z]/,
	hasNonWord: /\W/,
	hasMinLength: /^.{6,}$/,
};
const PATTERNS_USERNAME = {
	hasNum: /\d{1,}/,
	hasLower: /[a-z]{1,}/,
	hasUpper: /[A-Z]{1,}/,
	hasMinLength: /^.{6}/,
};

const errorMsgMap = {
	hasNum: `Must include a number.`,
	hasUpper: `Must include an uppercase letter.`,
	hasLower: `Must include an lowercase letter.`,
	hasSpecial: `Must include a special character.`,
	hasLength: `Must be at least 6 characters.`,
};

/**
 * Calculates a password's approx. strength score.
 * - Validation methods:
 * - Counts unique letters
 * - Uppercase & lowercase
 * - Numbers
 * - Special characters/non-word characters
 *
 * @returns {Number} - Returns numeric score of password strength starting at 0. higher score is more secure password.
 */
const calcPasswordScore = (val, minLength = 6) => {
	let score = 0;
	if (!val) return score;

	// award every unique letter until 5 repetitions
	let letters = {};
	for (let i = 0; i < val.length; i++) {
		letters[val[i]] = (letters[val[i]] || 0) + 1;
		score += 5.0 / letters[val[i]];
	}

	// formats minLength regex
	const min = new RegExp(`^.{${minLength},}$`);

	// bonus points for mixing it up
	const variations = {
		hasNum: /\d/.test(val),
		hasLower: /[a-z]/.test(val),
		hasUpper: /[A-Z]/.test(val),
		hasNonWord: /\W/.test(val),
		hasMinLength: min.test(val),
	};

	// iterates thru keys in 'variations' & runs each test
	// each 'true' gives 1pt, each 'false' gives 0pt
	let variationCount = 0;
	for (let test in variations) {
		variationCount += variations[test] === true ? 1 : 0;
	}
	score += (variationCount - 1) * 10;

	return parseInt(score);
};

/**
 * @param {Number} score - The numeric strength score from 'calcPasswordScore'
 * Runs password score util & determines strength label
 * @returns {String} - Returns the strength type (ie 'Strong', 'Weak', 'Poor', etc.)
 */
const getPasswordStrength = (score) => {
	switch (true) {
		case score > 105: {
			return `Very Strong`;
		}
		case score > 100: {
			return `Strong`;
		}
		case score > 80: {
			return `Good`;
		}
		case score > 70: {
			return `Moderate`;
		}
		case score > 50: {
			return `Fair`;
		}
		case score > 30: {
			return `Weak`;
		}
		case score > 10: {
			return `Poor`;
		}
		case score > 5: {
			return `Very Poor`;
		}
		default:
			return "";
	}
};

//////////////////////////////////////////////////////////////////////////////
////////////////////////////// MISC VALIDATORS //////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const testUsername = (
	val,
	patterns = { ...PATTERNS_USERNAME },
	errorMsgs = {}
) => {
	// run tests...
	// get errors, if any
	const keys = Object.keys(patterns);
	const results = keys.every((key) => patterns[key].test(val));
	const errors = !results ? getErrors(val, keys) : [];

	// gets keys of failed tests
	function getErrors(val, keys) {
		return keys.map((key) => {
			const passes = patterns[key].test(val);
			if (!passes) {
				return errorMsgs[key];
			} else {
				return null;
			}
		});
	}
	// check if valid email format
	if (!results && isValidEmail(val)) {
		return { isValid: true, errors: [] };
	}

	return {
		isValid: results,
		errors: errors.filter(Boolean),
	};
};

/**
 * @description - Takes an object and an array of keys and checks if the keys are empty or not and returns true if valid and false if invalid(or empty)
 * @param {Object} vals - An object of key/value pairs (typically form values)
 * @param {Array} keysToCheck - An array of object keys that need to be checked/validated.
 */
const isValid = (vals = {}, keysToCheck = []) => {
	if (isEmptyObj(vals)) return false;
	const tests = keysToCheck.map((key) => {
		if (isEmptyVal(vals[key])) {
			return false;
		}
		return true;
	});
	return tests.includes(false) ? false : true;
};

const isValidEmail = (val = "") => {
	if (isEmptyVal(val)) return false;
	return emailReg.test(val);
};

const testEmail = (val) => {
	const isValid = isValidEmail(val);
	const msg = !isValid ? `MUST be a valid email address.` : "";
	return {
		isValid,
		errors: [msg],
	};
};

// other validator utils //

const typeFields = [
	`byALAAdmin`,
	`byMedTechRestricted`,
	`bySuperUser`,
	`byFacilityAdmin`,
	`byRegionalAdmin`,
];

const findSelected = (vals = {}) => {
	const keys = Object.keys(vals);
	const selected = keys.filter((key) => vals[key]);
	return selected;
};

const findTypeSelected = (selected) => {
	return selected.filter((key) => {
		if (typeFields.includes(key)) {
			return key;
		} else {
			return;
		}
	});
};

const findSelectedKey = (vals) => {
	const allSelected = findSelected(vals);
	const typeSelected = findTypeSelected(allSelected);
	const type = typeSelected?.[0] ?? null;
	return type;
};
const getKeyName = (type) => {
	const map = {
		byALAAdmin: "alaAdmin",
		byMedTechRestricted: "MedTechRestrictedAccess",
		bySuperUser: "superUser",
		byFacilityAdmin: "bitFacilityAdministrator",
		byRegionalAdmin: "",
	};
	return map?.[type];
};

// regex patterns
export { PATTERNS, PATTERN_VARIATIONS, PATTERNS_USERNAME };
// regex-validator utils
export {
	validators,
	specialChars,
	numbers,
	upperCase,
	lowerCase,
	emailReg,
	phoneReg,
};

// pattern-matcher utils
export {
	// capture group matchers
	getMatchGroups,
	// matcher utils
	matchesPattern,
	matchesPassword,
	matchesMinLength,
	isSpecialChar,
};

// password-validator utils
export {
	ERROR_MSGS,
	errorMsgMap,
	getLengthMsg,
	getValidationMsgs,
	getErrMessages,
};

// misc-validator utils
export { isValid, isValidEmail };

// password validators & utils
export { getPasswordStrength, calcPasswordScore, testEmail, testUsername };

// other validator utils
export { findSelected, findTypeSelected, findSelectedKey, getKeyName };
