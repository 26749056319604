import React, { useState, useEffect } from "react";
import styles from "../../css/facility/FacilitySearchResults.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import Spinner from "../shared/Spinner";
import TextInput from "../shared/TextInput";

const customCSS = {
	empty: {
		icon: {
			fill: red[600],
		},
		heading: {
			// color: red[600],
		},
		subheading: {
			color: red[600],
		},
	},
	search: {
		backgroundColor: "#ffffff",
		borderRadius: "5rem",
	},
};

const advancedSearch = (val, results = []) => {
	const normalVal = val?.toLowerCase();

	if (isEmptyVal(val)) {
		return results;
	} else {
		return results.filter((option) => {
			if (
				option?.CommunityName?.toLowerCase().includes(normalVal) ||
				option?.CommunityName?.toLowerCase().startsWith(normalVal) ||
				option?.Address1?.toLowerCase().includes(normalVal) ||
				option?.Address1?.toLowerCase().startsWith(normalVal) ||
				option?.guidFacility?.toLowerCase().startsWith(normalVal) ||
				option?.guidFacility?.toLowerCase().includes(normalVal) ||
				option?.guidFacility?.toLowerCase() === normalVal ||
				option?.guidParentFacility?.toLowerCase().includes(normalVal) ||
				option?.guidParentFacility?.toLowerCase().startsWith(normalVal) ||
				option?.guidParentFacility?.toLowerCase() === normalVal ||
				option?.Email?.toLowerCase().includes(normalVal) ||
				option?.Email?.toLowerCase().startsWith(normalVal)
			) {
				return option;
			} else {
				return;
			}
		});
	}
};

const FacilityEntry = ({ facility = {}, viewFacility }) => {
	return (
		<li className={styles.FacilityEntry} onClick={viewFacility}>
			<div className={styles.FacilityEntry_inner}>
				<span className={styles.FacilityEntry_inner_name}>
					{facility?.CommunityName}
				</span>

				{facility?.bitSuspendSubscription && (
					<div
						className={styles.FacilityEntry_isDeletedIndicator}
						title={`This community is suspended!`}
					></div>
				)}
			</div>
		</li>
	);
};

const FacilitySearchResults = ({
	isLoading = false,
	searchResults = [],
	setSelectedFacility,
	searchCSS,
}) => {
	const [results, setResults] = useState(searchResults);
	const [searchVal, setSearchVal] = useState("");

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);

		setResults([...advancedSearch(value, searchResults)]);
	};

	if (isLoading) {
		return (
			<div className={styles.LoadingSearchResults}>
				<Spinner />
				<div className={styles.LoadingSearchResults_msg}>
					Loading search results...
				</div>
			</div>
		);
	}
	return (
		<div className={styles.FacilitySearchResults}>
			<div className={styles.FacilitySearchResults_header}>
				<div className={styles.FacilitySearchResults_header_title}>
					Showing: <b>{results?.length}</b> out of{" "}
					<b>{searchResults?.length}</b>
				</div>
			</div>
			<div className={styles.FacilitySearchResults_searchResults}>
				<TextInput
					val={searchVal}
					name="searchVal"
					id="searchVal"
					handleChange={handleSearch}
					placeholder="Search results by address, email, facilityID, parentID..."
					customStyles={customCSS.search}
				/>
			</div>
			<ul className={styles.FacilitySearchResults_list}>
				{!isEmptyArray(results) &&
					results.map((facility, idx) => (
						<FacilityEntry
							key={`${facility?.facilityID}_${idx}`}
							facility={facility}
							viewFacility={() => setSelectedFacility(facility)}
						/>
					))}
			</ul>
		</div>
	);
};

export default FacilitySearchResults;

FacilitySearchResults.defaultProps = {};

FacilitySearchResults.propTypes = {};
