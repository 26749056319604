import React from "react";
import styles from "../../css/loc/LOCApiValidatorResults.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const hasError = (isValid) => {
	return !isValid;
};

const ErrorItem = ({ error }) => {
	return (
		<li className={styles.ErrorItem}>
			<svg className={styles.ErrorItem_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose`}></use>
			</svg>
			<div className={styles.ErrorItem_msg}>{error}</div>
		</li>
	);
};

const ErrorList = ({ floorUnit, errors = [] }) => {
	return (
		<div className={styles.ErrorList}>
			<ul className={styles.ErrorList_list}>
				{!isEmptyArray(errors) &&
					errors.map((error, idx) => (
						<ErrorItem key={`ERROR-${floorUnit}-${idx}`} error={error} />
					))}
			</ul>
		</div>
	);
};

const SuccessMsg = ({ floorUnit }) => {
	return (
		<div className={styles.SuccessMsg}>
			<svg className={styles.SuccessMsg_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
			<div className={styles.SuccessMsg_msg}>
				<b>"{floorUnit}"</b> care levels are ok!
			</div>
		</div>
	);
};

const LOCApiValidatorResults = ({ floorUnit, unitValidation = {} }) => {
	return (
		<div className={styles.LOCApiValidatorResults}>
			<div className={styles.LOCApiValidatorResults_title}>
				Care Levels Validation:
			</div>
			<div className={styles.LOCApiValidatorResults_issues}>
				{unitValidation?.isValid && <SuccessMsg floorUnit={floorUnit} />}
				{!unitValidation?.isValid && (
					<ErrorList floorUnit={floorUnit} errors={unitValidation?.errors} />
				)}
			</div>
			{hasError(unitValidation?.isValid) && (
				<div className={styles.LOCApiValidatorResults_details}>
					<span className={styles.LOCApiValidatorResults_details_msg}>
						Please fix issues before saving!
					</span>
				</div>
			)}
		</div>
	);
};

export default LOCApiValidatorResults;

LOCApiValidatorResults.defaultProps = {};

LOCApiValidatorResults.propTypes = {};
