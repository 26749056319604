import React from "react";
import styles from "../../css/residents/ResidentTarget.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { addMonths, subDays } from "date-fns";
// components
import CustomDropdown from "../shared/CustomDropdown";
import DatePickerSM from "../shared/DatePickerSM";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	resident: {
		width: "35rem",
		backgroundColor: "#ffffff",
	},
	facility: {
		width: "35rem",
		backgroundColor: "#ffffff",
		// marginBottom: "2rem",
	},
	load: {
		width: "max-content",
		minWidth: "max-content",
		padding: ".9rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: "1rem",
	},
	move: {
		width: "max-content",
		minWidth: "max-content",
		padding: ".9rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: "1rem",
		backgroundColor: red[600],
	},
	target: {
		width: "35rem",
		// marginTop: "2rem",
	},
	date: {
		width: "35rem",
	},
};

const WarningMessage = ({ vals }) => {
	const isReady =
		!isEmptyVal(vals?.targetFacilityName) && !isEmptyVal(vals?.residentName);

	return (
		<div className={styles.WarningMessage}>
			{isReady && (
				<div className={styles.WarningMessage_warning}>
					You are about to move <b>{vals?.residentName}</b> to the
					<b> {vals?.targetFacilityName}</b> facility on{" "}
					<b>{vals?.targetDate}</b>.
				</div>
			)}
			{!isReady && (
				<div className={styles.WarningMessage_warning}>
					Please select a resident and target facility.
				</div>
			)}
		</div>
	);
};

const ResidentTarget = ({
	vals = {},
	facilities = [],
	currentResident = {},
	handleSelection,
	handleFacilitySelect,
	moveResidentToFacility,
}) => {
	return (
		<div className={styles.ResidentTarget}>
			<div className={styles.ResidentTarget_item}>
				<CustomDropdown
					name="targetFacilityName"
					id="targetFacilityName"
					label="Which Facility To Transfer To?"
					placeholder="Select target facility..."
					selection={vals.targetFacilityName}
					setSelection={handleFacilitySelect}
					customStyles={customCSS.target}
					options={facilities.map((x) => x.communityName)}
				/>
			</div>
			<div className={styles.ResidentTarget_date}>
				<DatePickerSM
					label="When Should We Transfer This Resident?"
					name="targetDate"
					id="targetDate"
					val={vals?.targetDate}
					handleDate={handleSelection}
					focusMode={true}
					customStyles={customCSS.date}
					restrictions={{
						isActive: true,
						rangeStart: subDays(new Date(), 1),
						rangeEnd: addMonths(new Date(), 3),
					}}
				/>
			</div>
			<div className={styles.ResidentTarget_item}>
				<WarningMessage vals={vals} currentResident={currentResident} />
				<ButtonSM
					isDisabled={
						isEmptyVal(vals?.residentName) ||
						isEmptyVal(vals?.targetFacilityName)
					}
					customStyles={customCSS.move}
					handleClick={moveResidentToFacility}
				>
					Move Resident
				</ButtonSM>
			</div>
		</div>
	);
};

export default ResidentTarget;

ResidentTarget.defaultProps = {};

ResidentTarget.propTypes = {};
