import ReactGA from "react-ga";
import { isEmptyVal } from "./utils_types";
import { format } from "date-fns";

const hasAtSign = (val) => {
	const reg = /@/gm;
	return reg.test(val);
};

// provides list of domains/hostnames to remove from tracking
const doNotTrack = () => {
	const domainsToIgnore = [
		`localhost`,
		`127.0.0.1`,
		`trackertest.aladvantage.com`,
	];
	const localDomain = `localhost` || `127.0.0.1`;
	const { hostname } = window.location;
	return domainsToIgnore.includes(hostname) || hostname === localDomain;
};

const obscureEmail = (email) => {
	if (isEmptyVal(email))
		return `Unknown user logged in at ${format(Date.now(), "M/D/YYYY h:mm A")}`;
	// if username is NOT email just return username
	if (!hasAtSign(email)) return email;
	const user = email.split("@")[0];
	const domain = email.split("@")[1];
	domain.replace("@", "");
	return `${user} from ${domain}`;
};

const sendPageTracking = (username, options = {}) => {
	const obscuredUser = obscureEmail(username);
	// avoid tracking on local host
	// if (doNotTrack()) return;
	ReactGA.set({ userId: username });
	ReactGA.event({
		category: "User",
		action: `${obscuredUser} visited ${options?.pagename}`,
	});
	ReactGA.pageview(options.path);
};

const setUserGA = (user) => {
	const username = user?.username ?? user?.email;
	const cleanedUsername = obscureEmail(username);
	ReactGA.set({ userId: cleanedUsername });
};

export { obscureEmail, hasAtSign };
export { setUserGA, sendPageTracking };
