import React from "react";
import styles from "../../css/admin/UserAccessTableBody.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import UserAccessRow from "./UserAccessRow";

// normalises access list
const normaliseIDs = (ids = []) => {
	return ids.map((id) => id.toLowerCase());
};

// checks for match in raw access list and normalised access list
const checkAccess = (userID, accessList = []) => {
	const rawID = userID;
	const normalID = userID.toLowerCase();
	// list(s)
	const rawList = accessList;
	const normalList = normaliseIDs(accessList);
	// check in raw list & normalised list
	const existsInRaw = rawList.includes(rawID);
	const existsInNormalised = normalList.includes(normalID);

	return existsInRaw || existsInNormalised;
};

const UserAccessTableBody = ({
	allFacilities = [],
	trackerAccess = [],
	legacyAccess = [],
	portalAccess = [],
	emarAccess = [],
	ePayAccess = [],
	usersList = [],
	currentUser = {},
	currentFacility = {},
	updateAccessList,
	dispatchAlert,
	dispatchToState,
	tableDispatch,
	deleteUser,
	isLegacyOnly,
}) => {
	return (
		<div className={styles.UserAccessTableBody}>
			{!isEmptyArray(usersList) &&
				usersList.map((user, idx) => (
					<UserAccessRow
						key={`USER-ROW:${user.firstName}___${idx}`}
						user={user}
						trackerAccess={checkAccess(user?.userID, trackerAccess)}
						legacyAccess={checkAccess(user?.userID, legacyAccess)}
						portalAccess={checkAccess(user?.userID, portalAccess)}
						emarAccess={checkAccess(user?.userID, emarAccess)}
						ePayAccess={checkAccess(user?.userID, ePayAccess)}
						updateAccessList={updateAccessList}
						allFacilities={allFacilities}
						currentUser={currentUser}
						currentFacility={currentFacility}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						deleteUser={() => deleteUser(user?.userID)}
						tableDispatch={tableDispatch}
						isLegacyOnly={isLegacyOnly}
					/>
				))}
		</div>
	);
};

export default UserAccessTableBody;

UserAccessTableBody.defaultProps = {
	accessList: [],
	usersList: [],
};

UserAccessTableBody.propTypes = {
	usersList: PropTypes.array.isRequired,
	legacyAccess: PropTypes.arrayOf(PropTypes.string),
	trackerAccess: PropTypes.arrayOf(PropTypes.string),
	portalAccess: PropTypes.arrayOf(PropTypes.string),
	updateAccessList: PropTypes.func.isRequired,
};
