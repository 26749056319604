import React, { useState } from "react";
import styles from "../../css/forms/UsernameValidator.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	ERROR_MSGS as errorMsgs,
	testUsername,
	PATTERNS_USERNAME as variations,
	isValidEmail,
} from "../../helpers/utils_validation";

const statusMap = {
	isInvalid: "0 0 0 2px hsla(352, 70%, 50%, 0.6)",
	isValid: "0 0 0 2px hsla(170, 100%, 39%, 0.6)",
};

const getStatusColor = (val, isValid = false) => {
	if (isEmptyVal(val)) return;
	if (isValid) {
		return {
			boxShadow: statusMap.isValid,
		};
	} else {
		return {
			boxShadow: statusMap.isInvalid,
		};
	}
};

const ValidMsg = ({ msg, isValidEmail = false }) => {
	return (
		<div className={styles.ValidMsg}>
			<svg className={styles.ValidMsg_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle_outline`}></use>
			</svg>
			<span className={styles.ValidMsg_msg}>
				{isValidEmail ? `An Email Address is Acceptable!` : msg}
			</span>
		</div>
	);
};

const ErrorMsg = ({ msg }) => {
	return (
		<li className={styles.ErrorMsg}>
			<svg className={styles.ErrorMsg_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose`}></use>
			</svg>
			<span className={styles.ErrorMsg_msg}>{msg}</span>
		</li>
	);
};

const Errors = ({ val = "", errors = [] }) => {
	if (isEmptyVal(val)) {
		return null;
	}
	return (
		<ul className={styles.Errors}>
			{errors &&
				errors.map((err, idx) => <ErrorMsg key={`${idx}:${err}`} msg={err} />)}
		</ul>
	);
};

const UsernameValidator = ({
	label,
	name,
	id,
	initialVal = "",
	autoComplete = "off",
	placeholder = "Create a username...",
	handleChange,
	inputMode = "text",
	customStyles = {},
	disableErrors = false,
}) => {
	const [val, setVal] = useState(initialVal);
	const [validation, setValidation] = useState({
		errors: [],
		isValid: false,
	});

	const onChangeHandler = (e) => {
		const { value } = e.target;
		setVal(value);
		handleChange(e);
	};

	const runValidator = (e) => {
		const { value } = e.target;
		onChangeHandler(e);
		// execute validator test
		const results = testUsername(value, variations, errorMsgs);
		setValidation(results);
	};

	return (
		<div className={styles.UsernameValidator}>
			<div className={styles.UsernameValidator_inputWrapper}>
				<label
					htmlFor={id}
					className={styles.UsernameValidator_inputWrapper_label}
				>
					{label}
				</label>
				<input
					type="text"
					name={name}
					id={id}
					value={val}
					placeholder={placeholder}
					autoComplete={autoComplete}
					onChange={runValidator}
					className={styles.UsernameValidator_inputWrapper_input}
					style={getStatusColor(val, validation.isValid)}
					inputMode={inputMode}
				/>
			</div>
			<div className={styles.UsernameValidator_errors}>
				{!disableErrors && (
					<Errors key={val} val={val} errors={validation.errors} />
				)}
				{validation.isValid && (
					<ValidMsg isValidEmail={isValidEmail(val)} msg="Looks Good!" />
				)}
			</div>
		</div>
	);
};

export default UsernameValidator;

UsernameValidator.defaultProps = {
	autoComplete: "off",
	placeholder: "Create a username...",
	inputMode: "text",
	customStyles: {},
};

UsernameValidator.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	initialVal: PropTypes.string,
	val: PropTypes.string,
	autoComplete: PropTypes.string,
	placeholder: PropTypes.string,
	inputMode: PropTypes.string,
	handleChange: PropTypes.func,
	customStyles: PropTypes.object,
};
