import React, { useRef, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import styles from "../../css/app/PortalOptions.module.scss";
import sprite from "../../assets/icons/settings.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import internalSprite from "../../assets/icons/internal-tools.svg";
import dataSprite from "../../assets/icons/data.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	portalPageOptions,
	// final 'routes' renderer
	pageOptionsHandler,
} from "../../helpers/utils_routes";

// OPTIONS LIST:
// - 'Your Apps' 					✓ - not-restricted
// - 'User Profile' 			✓ - not-restricted
// - 'User Access' 				X - restricted
// - 'Facility Access' 		X - restricted
// - 'Manage Settings' 		X - restricted

const getSprite = (icon) => {
	// list of keys for 'sprite2'
	const optList = [
		"Facility Access",
		"User Summary",
		"Reset Password",
		"Contact Us",
	];
	const dataList = ["Migration Tool"]; // list of 'dataSprite' keys
	const internalList = ["Internal Tools", "Levels of Care"];

	if (internalList.includes(icon)) return internalSprite;
	if (dataList.includes(icon)) return dataSprite;
	if (optList.includes(icon)) return sprite2;
	return sprite;
};

const PortalPageCard = ({
	target,
	icon,
	name,
	path,
	history,
	activeTab = null,
}) => {
	const match = useRouteMatch("/portal");
	const [showPageLink, setShowPageLink] = useState(false); // toggles 'Go to ->' link
	const cardRef = useRef();

	return (
		<NavLink
			to={{
				query: { activeTab: activeTab },
				pathname: `${match.path}${path}`,
				state: {
					from: history.location.pathname,
					target: target,
				},
			}}
		>
			<aside
				className={styles.PortalPageCard}
				onMouseOver={() => setShowPageLink(true)}
				onMouseLeave={() => setShowPageLink(false)}
				ref={cardRef}
			>
				<section className={styles.PortalPageCard_top}>
					<svg className={styles.PortalPageCard_top_icon}>
						<use
							xlinkHref={`${getSprite(icon)}#icon-${
								portalPageOptions.icons[icon]
							}`}
						></use>
					</svg>
					<span className={styles.PortalPageCard_top_name}>{name}</span>
				</section>

				{showPageLink && (
					<div className={styles.PortalPageCard_pageLink}>
						<span className={styles.PortalPageCard_pageLink_text}>Go to</span>
						<svg className={styles.PortalPageCard_pageLink_icon}>
							<use xlinkHref={`${sprite}#icon-arrow-thin-right`}></use>
						</svg>
					</div>
				)}
			</aside>
		</NavLink>
	);
};

export { PortalPageCard };

PortalPageCard.propTypes = {
	target: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	history: PropTypes.object,
};

const PortalOptions = ({ currentUser = {}, history }) => {
	return (
		<div className={styles.PortalOptions}>
			<div className={styles.PortalOptions_grid}>
				{!isEmptyArray(portalPageOptions?.pages) && [
					...pageOptionsHandler(portalPageOptions?.pages, history, currentUser),
				]}
			</div>
		</div>
	);
};

export default PortalOptions;

PortalOptions.defaultProps = {};

PortalOptions.propTypes = {
	currentUser: PropTypes.object.isRequired,
};
