import React, { useState } from "react";
import styles from "../../css/summary/LockoutStatus.module.scss";
import { PropTypes } from "prop-types";
import { checkForUserLockout } from "../../helpers/utils_lockouts";

const LockoutStatus = ({ isLockedOut = false }) => {
	if (!isLockedOut) {
		return (
			<div className={styles.NoLockoutStatus}>
				<div className={styles.NoLockoutStatus_status}>
					This account is <b>NOT LOCKED</b>.
				</div>
			</div>
		);
	}
	return (
		<div className={styles.LockoutStatus}>
			<div className={styles.LockoutStatus_status}>
				This account is <b>LOCKED</b>.
			</div>
		</div>
	);
};

export default LockoutStatus;

LockoutStatus.defaultProps = {
	user: {},
	lockouts: [],
};

LockoutStatus.propTypes = {
	user: PropTypes.object,
	lockouts: PropTypes.array,
};
