import React from "react";
import styles from "../../css/residents/ResidentStatus.module.scss";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import { getResidentStatus } from "../../helpers/utils_residents";

const formatArchiveDate = (date) => {
	const aDate = format(date, "M/DD/YYYY");
	const aTime = format(date, "h:mm A");

	return `${aDate} at ${aTime}`;
};

const ArchiveStatus = ({ archiveDate = new Date() }) => {
	return (
		<div className={styles.ArchiveStatus}>
			Archived as of {formatArchiveDate(archiveDate)}
		</div>
	);
};
const ActiveStatus = () => {
	return <div className={styles.ActiveStatus}>Active</div>;
};

const ResidentStatus = ({ resident }) => {
	const renderStatus = (resident) => {
		const { status, archiveDate } = getResidentStatus(resident);
		switch (status) {
			case "Active": {
				return <ActiveStatus />;
			}
			case "Archived": {
				return <ArchiveStatus archiveDate={archiveDate} />;
			}
			case "LOA": {
				return <ArchiveStatus archiveDate={archiveDate} />;
			}
			default:
				return <ActiveStatus />;
		}
	};

	return <>{renderStatus(resident)}</>;
};

export default ResidentStatus;

ResidentStatus.defaultProps = {};

ResidentStatus.propTypes = {};
