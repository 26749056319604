import React from "react";
import styles from "../../css/admin/UserCardList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import UserCard from "./UserCard";
import { doesUserHaveAppAccess } from "../../helpers/utils_apps";

const UserCardList = ({
	currentFacility = {},
	currentUser = {},
	facilityUsers = [],
	trackerAccess = [],
	legacyAccess = [],
}) => {
	return (
		<div className={styles.UserCardList}>
			<div className={styles.UserCardList_about}>
				Click a user to view more info.
			</div>
			{!isEmptyVal(currentFacility?.facilityID) && (
				<div className={styles.UserCardList_cards}>
					{!isEmptyArray(facilityUsers) &&
						facilityUsers.map((user, idx) => (
							<UserCard
								key={`${user?.userID}-${idx}`}
								user={user}
								currentFacility={currentFacility}
								currentUser={currentUser}
								hasTrackerAccess={doesUserHaveAppAccess(
									user?.userID,
									trackerAccess
								)}
								hasLegacyAccess={doesUserHaveAppAccess(
									user?.userID,
									legacyAccess
								)}
							/>
						))}
				</div>
			)}
		</div>
	);
};

export default UserCardList;

UserCardList.defaultProps = {};

UserCardList.propTypes = {};
