import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/facility/FacilityAccessEntry.module.scss";
import sprite from "../../assets/icons/settings.svg";
import states from "../../assets/icons/us-states.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
import { isAdmin, isSuperUser } from "../../helpers/utils_userTypes";
// components
import Dialog from "../shared/Dialog";
import ButtonSM from "../shared/ButtonSM";
import ProtectedFeature from "../permissions/ProtectedFeature";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { featureFlags, isAccessEnabled } from "../../helpers/utils_permissions";

// REQUIREMENTS:
// - User's name
// - Facility name
// - Granted date
// - Granted by (eg which user)
// - Add Facility's State Icon via abbreviation (eg 'AZ', 'CA' etc)

const customCSS = {
	cancel: {
		padding: ".8rem 1.4rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
	},
	confirm: {
		padding: ".8rem 1.4rem",
		backgroundColor: red[600],
		color: "#ffffff",
		marginLeft: ".5rem",
		fontSize: "1.4rem",
	},
};

const getLocation = (accessRecord = {}) => {
	const { city, state } = accessRecord;
	if (isEmptyVal(city)) return state;
	return `${city}, ${state}`;
};

const ChangeNotice = ({ msg }) => {
	return (
		<div className={styles.ChangeNotice}>
			<div className={styles.ChangeNotice_inner}>
				<svg className={styles.ChangeNotice_inner_icon}>
					<use xlinkHref={`${sprite}#icon-check_circle`}></use>
				</svg>
				<span className={styles.ChangeNotice_inner_msg}>{msg}</span>
			</div>
		</div>
	);
};

const MenuOptions = ({ closeMenu }) => {
	const menuRef = useRef();
	const { isOutside } = useOutsideClick(menuRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside || wasEscaped) {
			closeMenu();
		}

		return () => {
			isMounted = false;
		};
	}, [closeMenu, isOutside, wasEscaped]);

	return (
		<aside className={styles.MenuOptions} ref={menuRef}>
			<div className={styles.MenuOptions_top}>
				<svg className={styles.MenuOptions_top_close} onClick={closeMenu}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</div>
			<ul className={styles.MenuOptions_list}>
				<li className={styles.MenuOptions_list_item}>Remove Access</li>
				<li className={styles.MenuOptions_list_item}>Make Primary Facility</li>
			</ul>
		</aside>
	);
};

const FacilityAccessEntry = ({
	accessRecord = {},
	removeFacilityAccess,
	currentUser,
}) => {
	const [showMenuOptions, setShowMenuOptions] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showChangeNotice, setShowChangeNotice] = useState(false);

	const showMenu = () => {
		setShowMenuOptions(true);
	};

	const initRemoveAccess = () => {
		setShowConfirmModal(true);
	};

	const cancelRemoveAccess = () => {
		setShowConfirmModal(false);
	};
	const confirmRemoveAccess = () => {
		removeFacilityAccess(accessRecord);
		setShowConfirmModal(false);
		setShowChangeNotice(true);
	};

	// reset alert notice after 3.5s
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let timerID;
		if (showChangeNotice) {
			timerID = setTimeout(() => {
				setShowChangeNotice(false);
			}, 3500);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [showChangeNotice]);

	return (
		<>
			{showChangeNotice && (
				<ChangeNotice
					msg={`${accessRecord.facilityName} access has been updated!`}
				/>
			)}
			<div className={styles.FacilityAccessEntry}>
				<div className={styles.FacilityAccessEntry_facility}>
					<div className={styles.FacilityAccessEntry_facility_badge}>
						<svg className={styles.FacilityAccessEntry_facility_badge_icon}>
							<use
								xlinkHref={`${states}#icon-state-${accessRecord?.state}`}
							></use>
						</svg>
					</div>
					<div className={styles.FacilityAccessEntry_facility_desc}>
						<div className={styles.FacilityAccessEntry_facility_desc_name}>
							{accessRecord?.facilityName}
						</div>
						<div className={styles.FacilityAccessEntry_facility_desc_location}>
							{getLocation(accessRecord)}
						</div>
					</div>
				</div>
				<div className={styles.FacilityAccessEntry_actions}>
					<div className={styles.FacilityAccessEntry_actions_icons}>
						<ProtectedFeature
							isEnabled={isAccessEnabled(
								"editOwnFacilityAccess",
								featureFlags.user,
								currentUser
							)}
						>
							<svg
								className={styles.FacilityAccessEntry_actions_icons_icon}
								onClick={showMenu}
							>
								<use xlinkHref={`${sprite}#icon-dots-three-horizontal`}></use>
							</svg>
						</ProtectedFeature>
						<ProtectedFeature
							isEnabled={isAccessEnabled(
								"editOwnFacilityAccess",
								featureFlags.user,
								currentUser
							)}
						>
							<svg
								className={styles.FacilityAccessEntry_actions_icons_icon}
								onClick={initRemoveAccess}
							>
								<use xlinkHref={`${sprite}#icon-clearclose`}></use>
							</svg>
						</ProtectedFeature>

						{showMenuOptions && (
							<MenuOptions closeMenu={() => setShowMenuOptions(false)} />
						)}
					</div>
				</div>
			</div>

			{showConfirmModal && (
				<Dialog
					icon="WARN2"
					title="Remove Facility Access"
					heading={`Are you sure you want to remove access to the ${accessRecord?.facilityName} facility?`}
					closeModal={() => setShowConfirmModal(false)}
				>
					<div className={styles.FacilityAccessEntry_confirmModal}>
						<ButtonSM
							handleClick={cancelRemoveAccess}
							customStyles={customCSS.cancel}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							handleClick={confirmRemoveAccess}
							customStyles={customCSS.confirm}
						>
							Remove Access
						</ButtonSM>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default FacilityAccessEntry;

FacilityAccessEntry.defaultProps = {};

FacilityAccessEntry.propTypes = {};
