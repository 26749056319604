import React, { useState } from "react";
import styles from "../../css/user/UserAccess.module.scss";
import { PropTypes } from "prop-types";
import { blueGrey } from "../../helpers/utils_styles";
import {
	formatAndSortUserFacilities,
	createUserFacilityAccessRecords,
	removeAccessRecord,
	getGrantAccessRecordsFromSelections,
	denyFacilityAccess,
} from "../../helpers/utils_facility";
import { grantFacilityAccess } from "../../helpers/utils_security";
// components
import FacilityAccessList from "../facility/FacilityAccessList";
import MultiSelect from "../shared/MultiSelect";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	multiselect: {
		backgroundColor: "#ffffff",
	},
	saveBtn: {
		padding: ".5rem 1.3rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelBtn: {
		padding: ".5rem 1.3rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
};

const UserAccess = ({ globalState = {}, dispatchToState, dispatchAlert }) => {
	const { currentUser } = globalState;
	const { facilities } = currentUser;
	const [accessSelections, setAccessSelections] = useState([
		...createUserFacilityAccessRecords(facilities, currentUser),
	]);

	const handleMultiSelect = (selections) => {
		setAccessSelections(selections);
	};

	const removeFacilityEntry = async (entry) => {
		const { token, userID } = currentUser;
		const { facilityID } = entry;

		const newList = removeAccessRecord(entry, accessSelections);
		setAccessSelections(newList);

		const wasSaved = await denyFacilityAccess(token, userID, [facilityID]);
		// - fire off request to remove record
		if (wasSaved) {
			dispatchAlert("SUCCESS", {
				heading: `Facility Access was updated!`,
			});
		} else {
			dispatchAlert("ERROR", {
				heading: `There was an error!`,
				subheading: `Your changes were NOT saved.`,
			});
		}
	};

	const saveAccessChanges = async () => {
		// wire up facility changes
		const { token, userID } = currentUser;
		// extracts list of facilityIDs
		const listOfIDs = getGrantAccessRecordsFromSelections(
			accessSelections,
			facilities
		);
		const accessWasUpdated = await grantFacilityAccess(
			token,
			userID,
			listOfIDs
		);

		if (accessWasUpdated) {
			return dispatchAlert("SUCCESS", {
				heading: `Changes Were Saved!`,
				subheading: `Facility Access was updated.`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `ERROR!`,
				subheading: `Something went wrong. Please try again.`,
			});
		}
	};
	const cancelAccessChanges = () => {
		setAccessSelections([
			...createUserFacilityAccessRecords(facilities, currentUser),
		]);
	};

	return (
		<div className={styles.UserAccess}>
			<header className={styles.UserAccess_header}>
				<div className={styles.UserAccess_header_desc}>
					Listed below are the facilities this user has access to currently.
				</div>
				<div className={styles.UserAccess_header_accessCount}>
					This user currently has access to <b>{facilities?.length}</b>{" "}
					facilities.
				</div>
			</header>
			<section className={styles.UserAccess_selector}>
				<MultiSelect
					readOnly={currentUser?.isReadOnly}
					initialSelections={accessSelections}
					options={[...formatAndSortUserFacilities(facilities)]}
					customStyles={customCSS.multiselect}
					handleMultiSelect={handleMultiSelect}
				/>
				<div className={styles.UserAccess_selector_actions}>
					<ButtonSM
						isDisabled={currentUser?.isReadOnly}
						handleClick={cancelAccessChanges}
						customStyles={customCSS.cancelBtn}
					>
						<span>Cancel</span>
					</ButtonSM>
					<ButtonSM
						isDisabled={currentUser?.isReadOnly}
						handleClick={saveAccessChanges}
						customStyles={customCSS.saveBtn}
					>
						<span>Save Changes</span>
					</ButtonSM>
				</div>
			</section>
			<section className={styles.UserAccess_list}>
				<div className={styles.UserAccess_list_label}>
					Current Facility Access:
				</div>
				{/* NEEDS FACILITY NAME & FACILITY ID */}
				<FacilityAccessList
					isLoading={false}
					accessList={accessSelections}
					removeEntry={removeFacilityEntry}
				/>
			</section>
		</div>
	);
};

export default UserAccess;

UserAccess.defaultProps = {
	globalState: {},
};

UserAccess.propTypes = {
	globalState: PropTypes.object,
	dispatchToState: PropTypes.func,
	dispatchAlert: PropTypes.func,
};
