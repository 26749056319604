import React from "react";
import styles from "../../css/app/VerifyUser.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";
import SpinnerSM from "../shared/SpinnerSM";

const customCSS = {
	verifyBtn: {
		marginTop: "1rem",
		fontSize: "1.3rem",
		fontWeight: "600",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

const VerifyUser = ({
	confirmUsername,
	handleUsername,
	verifyUsername,
	isVerifying = false,
}) => {
	return (
		<div className={styles.VerifyUser}>
			<div className={styles.VerifyUser_top}>
				<div className={styles.VerifyUser_top_title}>Reset Your Password</div>
				<p className={styles.VerifyUser_top_desc}>
					Please enter your username or email address.
				</p>
			</div>
			<TextInput
				name="confirmUsername"
				id="confirmUsername"
				label="Username or Email"
				val={confirmUsername}
				handleChange={handleUsername}
			/>
			<ButtonSM
				type="button"
				customStyles={customCSS.verifyBtn}
				handleClick={verifyUsername}
				isDisabled={isEmptyVal(confirmUsername)}
			>
				{!isVerifying && <span>Submit</span>}
				{isVerifying && (
					<>
						<span style={{ marginRight: ".5rem" }}>Checking...</span>
						<SpinnerSM color="#ffffff" />
					</>
				)}
			</ButtonSM>
		</div>
	);
};

export default VerifyUser;

VerifyUser.defaultProps = {};

VerifyUser.propTypes = {};
