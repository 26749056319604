import React, { useRef, useState, useEffect } from "react";
import styles from "../../css/app/ChangePasswordModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import sprite2 from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { green, purple, red } from "../../helpers/utils_styles";
import { SYSTEM_USER } from "../../helpers/utils_systemUser";
import {
	changeUserPassword,
	getUserLoginByUsername,
	getUserLoginsByUsername,
} from "../../helpers/utils_user";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
// components
import ButtonSM from "../shared/ButtonSM";
import PasswordValidator from "../forms/PasswordValidator";
import ConfirmedIndicator from "../forms/ConfirmedIndicator";
import DoNotMatchIndicator from "../forms/DoNotMatchIndicator";
import PasswordSuccess from "./PasswordSuccess";

const customCSS = {
	cancel: {
		padding: ".8rem 1.3rem",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: ".5rem",
		fontSize: "1.5rem",
	},
	change: {
		padding: "1rem 1.3rem",
		backgroundColor: green[200],
		color: purple[700],
		fontSize: "1.5rem",
	},
};

const isSamePassword = (pwd1, pwd2) => {
	if (!isEmptyVal(pwd1) && !isEmptyVal(pwd2)) {
		return pwd1 === pwd2;
	} else {
		return false;
	}
};

const ChangePasswordModal = ({ systemUser, closeModal, currentUser = {} }) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();
	// values
	const { formState, handleChange, handleReset } = useForm({
		newPassword: "",
		confirmNewPassword: "",
	});
	const [userData, setUserData] = useState(null);
	const { newPassword, confirmNewPassword } = formState.values;
	const [passwordWasChanged, setPasswordWasChanged] = useState(false); // change back to false
	// for when changing a password fails for some reason
	const [passwordFailed, setPasswordFailed] = useState(false);
	// is passwords entered match
	const [passwordsMatch, setPasswordsMatch] = useState(false); // change back to false

	// save new password
	const saveNewPassword = async () => {
		const { token } = systemUser;
		const { otpData } = currentUser;
		const { otp } = otpData;
		const { newPassword } = formState.values;
		// fire off request
		const wasChanged = await changeUserPassword(token, {
			userID: userData.UserLoginID,
			oldPassword: otp,
			newPassword: newPassword,
		});

		// fire off request
		// need to figure out user's existing password
		if (wasChanged) {
			return setPasswordWasChanged(true);
		} else {
			setPasswordFailed(true);
			return setPasswordWasChanged(false);
		}
	};
	const cancelChangePassword = (e) => {
		handleReset(e);
		closeModal();
	};

	// fetch user's login record & data by username
	const fetchUserData = async () => {
		const token = systemUser?.token ?? SYSTEM_USER.token;
		const { username } = currentUser;
		// const userLogin = await getUserLoginByUsername(token, username);
		const userLogin = await getUserLoginsByUsername(token, username);
		if (!isEmptyObj(userLogin)) {
			return setUserData(userLogin);
		} else {
			return setUserData(null);
		}
	};

	// validate matching passwords on key entry
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setPasswordsMatch(false);
		if (!isEmptyVal(newPassword) && !isEmptyVal(confirmNewPassword)) {
			setPasswordsMatch(isSamePassword(newPassword, confirmNewPassword));
		}

		return () => {
			isMounted = false;
		};
	}, [confirmNewPassword, newPassword]);

	// close modal
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || wasEscaped) {
			closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [closeModal, isOutside, wasEscaped]);

	// fetch userID onMount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchUserData();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<aside className={styles.ChangePasswordModal} ref={modalRef}>
				<div className={styles.ChangePasswordModal_top}>
					<div
						className={styles.ChangePasswordModal_top_close}
						onClick={closeModal}
					>
						<svg className={styles.ChangePasswordModal_top_close_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</div>
				</div>
				<div className={styles.ChangePasswordModal_header}>
					<div className={styles.ChangePasswordModal_header_iconWrapper}>
						<svg className={styles.ChangePasswordModal_header_iconWrapper_icon}>
							<use xlinkHref={`${sprite2}#icon-lock_open`}></use>
						</svg>
					</div>
					<div className={styles.ChangePasswordModal_header_title}>
						Reset Password
					</div>
				</div>
				<div className={styles.ChangePasswordModal_main}>
					<PasswordValidator
						name="newPassword"
						id="newPassword"
						label="Create Password"
						initialVal={formState.values.newPassword}
						handleChange={handleChange}
					/>
					<PasswordValidator
						name="confirmNewPassword"
						id="confirmNewPassword"
						label="Re-enter Password"
						initialVal={formState.values.confirmNewPassword}
						handleChange={handleChange}
					/>
				</div>
				<div className={styles.ChangePasswordModal_indicators}>
					{passwordsMatch && (
						<ConfirmedIndicator confirmedMsg="Passwords Match!" />
					)}
					{!passwordsMatch && !isEmptyVal(confirmNewPassword) && (
						<DoNotMatchIndicator failedMsg="Passwords Do Not Match!" />
					)}
				</div>
				<div className={styles.ChangePasswordModal_actions}>
					<ButtonSM
						handleClick={cancelChangePassword}
						customStyles={customCSS.cancel}
					>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={!passwordsMatch}
						handleClick={saveNewPassword}
						customStyles={customCSS.change}
					>
						Change Password
					</ButtonSM>
				</div>
				{passwordWasChanged && <PasswordSuccess closeModal={closeModal} />}
				{passwordFailed && (
					<div className={styles.Failure}>
						<div className={styles.Failure_top}>
							<svg
								className={styles.Failure_top_closeIcon}
								onClick={() => {
									setPasswordWasChanged(false);
									setPasswordFailed(false);
								}}
							>
								<use xlinkHref={`${sprite}#icon-clearclose`}></use>
							</svg>
						</div>
						<div className={styles.Failure_wrapper}>
							<svg className={styles.Failure_wrapper_icon}>
								<use xlinkHref={`${sprite2}#icon-error`}></use>
							</svg>
						</div>
						<div className={styles.Failure_msg}>
							Ooops! There was an error. Try again.
						</div>
					</div>
				)}
			</aside>
		</>
	);
};

export default ChangePasswordModal;

ChangePasswordModal.defaultProps = {};

ChangePasswordModal.propTypes = {};
