import React, { useState } from "react";
import styles from "../../css/admin/UserSearch.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { blueGrey, red } from "../../helpers/utils_styles";
import { processUserList, searchForUserBy } from "../../helpers/utils_user";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import FormSection from "../forms/FormSection";
import TextInput from "../shared/TextInput";
import CustomCheckbox from "../shared/CustomCheckbox";
import CustomDropdown from "../shared/CustomDropdown";
import ButtonSM from "../shared/ButtonSM";
import UserSearchResults from "../user/UserSearchResults";
import ModalLG from "../shared/ModalLG";
import UserDetails from "../user/UserDetails";
import UserSearchDetails from "../user/UserSearchDetails";

const customCSS = {
	checkbox: {
		marginBottom: ".6rem",
	},
	searchBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
	},
	cancelBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "1rem",
	},
	clearBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "auto",
	},
	dropdown: {
		width: "35rem",
	},
	searchInput: {
		backgroundColor: "#ffffff",
	},
};

const noTypeSelected = (vals = {}) => {
	const {
		// bools
		byEmail,
		byFirstName,
		byLastName,
		byFacility,
		byALAAdmin,
		byMedTechRestricted,
		// fields
		emailSearch,
		firstNameSearch,
		lastNameSearch,
		facilitySearch,
	} = vals;
	// no search type or search value supplied
	const hasNoType =
		!byEmail &&
		!byFirstName &&
		!byLastName &&
		!byFacility &&
		!byALAAdmin &&
		!byMedTechRestricted;
	const hasNoSearch =
		isEmptyVal(emailSearch) &&
		isEmptyVal(firstNameSearch) &&
		isEmptyVal(lastNameSearch) &&
		isEmptyVal(facilitySearch);

	return hasNoSearch && hasNoType;
};

const CustomSearch = ({
	vals = {},
	handleChange,
	handleSettings,
	allFacilities = [],
}) => {
	// handles which to render
	const renderSearch = (vals) => {
		switch (true) {
			case vals?.byEmail: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							label="Search By Email Address"
							name="emailSearch"
							id="emailSearch"
							placeholder="Enter an email..."
							val={vals.emailSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFirstName: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							label="Search By First Name"
							name="firstNameSearch"
							id="firstNameSearch"
							placeholder="Enter User's First Name..."
							val={vals.firstNameSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byLastName: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							label="Search By Last Name"
							name="lastNameSearch"
							id="lastNameSearch"
							placeholder="Enter User's Last Name..."
							val={vals.lastNameSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFacility: {
				return (
					<div className={styles.CustomSearch}>
						<CustomDropdown
							name="facilitySearch"
							id="facilitySearch"
							label="Choose a facility"
							placeholder="Select a facility"
							selection={vals.facilitySearch}
							setSelection={handleSettings}
							options={allFacilities}
							customStyles={customCSS.dropdown}
						/>
					</div>
				);
			}
			default:
				return null;
		}
	};

	return <>{renderSearch(vals)}</>;
};

const UserSearch = ({ currentUser = {}, currentFacility = {} }) => {
	const {
		formState,
		setFormState,
		handleChange,
		handleCheckbox,
		handleReset,
	} = useForm({
		byFirstName: false,
		byLastName: false,
		byFacility: false,
		// by user type
		byALAAdmin: false,
		byMedTechRestricted: false,
		bySuperUser: false,
		// searches
		emailSearch: "",
		firstNameSearch: "",
		lastNameSearch: "",
	});
	const { values } = formState;
	const [searchResults, setSearchResults] = useState(null);
	const [isLoadingResults, setIsLoadingResults] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showUserDetails, setShowUserDetails] = useState(false);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// fires off request w/ search values
	const runSearch = async (e) => {
		const { token, facilities } = currentUser;
		setIsLoadingResults(true);
		const results = await searchForUserBy(token, facilities, values);

		if (results) {
			setIsLoadingResults(false);
			return setSearchResults(results);
		} else {
			return setIsLoadingResults(false);
		}
	};
	// clear form & close search window
	const cancelSearch = (e) => {
		handleReset(e);
	};
	// clear our form fields
	const clearSearch = (e) => {
		handleReset(e);
		setSearchResults([]);
	};

	// selects search result & opens user in 'UserDetails' modal
	const viewCustomSearchUser = (user) => {
		setShowUserDetails(true);
		setSelectedUser(user);
	};

	return (
		<>
			<div className={styles.UserSearch}>
				<header className={styles.UserSearch_header}>
					<h2 className={styles.UserSearch_header_title}>
						Search for a User By Email, Name, Facility Etc.
					</h2>
					<h6 className={styles.UserSearch_header_desc}>
						Enter a custom search via the below settings and select one of the
						resulting users that displays.
					</h6>
				</header>
				<section className={styles.UserSearch_main}>
					{/* SEARCH BY: EMAIL, NAME, FACILITY */}
					<FormSection
						title="Search By:"
						icon="checkbox"
						hoverText="Search by a user-related value."
					>
						<CustomCheckbox
							isDisabled={
								values?.byFirstName || values?.byLastName || values?.byFacility
							}
							label="By Email"
							name="byEmail"
							id="byEmail"
							val={values?.byEmail}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byEmail || values?.byLastName || values?.byFacility
							}
							label="By First Name"
							name="byFirstName"
							id="byFirstName"
							val={values?.byFirstName}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byFirstName || values?.byEmail || values?.byFacility
							}
							label="By Last Name"
							name="byLastName"
							id="byLastName"
							val={values?.byLastName}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byEmail || values?.byLastName || values?.byFirstName
							}
							label="By Facility"
							name="byFacility"
							id="byFacility"
							val={values?.byFacility}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						{/* CUSTOM SEARCH INPUT RENDERER */}
						<CustomSearch
							vals={values}
							handleChange={handleChange}
							handleSettings={handleSettings}
							allFacilities={currentUser?.facilities}
						/>
					</FormSection>

					{/* USER TYPES */}
					<FormSection
						title="Find By User Type"
						icon="userCircle"
						hoverText="Find By User Type"
					>
						<CustomCheckbox
							isDisabled={values?.byMedTechRestricted}
							label="ALA Admins"
							name="byALAAdmin"
							id="byALAAdmin"
							val={values?.byALAAdmin}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={values?.byALAAdmin}
							label="By Med-Tech Restricted"
							name="byMedTechRestricted"
							id="byMedTechRestricted"
							val={values?.byMedTechRestricted}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
					</FormSection>

					{/* ACTIONS */}
					<div className={styles.UserSearch_main_actions}>
						<ButtonSM
							customStyles={customCSS.clearBtn}
							handleClick={clearSearch}
						>
							<span>Clear Search</span>
						</ButtonSM>
						<ButtonSM
							customStyles={customCSS.cancelBtn}
							handleClick={cancelSearch}
						>
							<span>Cancel Search</span>
						</ButtonSM>
						<ButtonSM
							isDisabled={noTypeSelected(values)}
							customStyles={customCSS.searchBtn}
							handleClick={runSearch}
						>
							<svg className={styles.UserSearch_main_actions_icon}>
								<use xlinkHref={`${sprite}#icon-search2`}></use>
							</svg>
							<span>Run Search</span>
						</ButtonSM>
					</div>
				</section>
				<section className={styles.UserSearch_results}>
					<UserSearchResults
						key={`SEARCH_RESULTS_${isLoadingResults}`}
						isLoading={isLoadingResults}
						searchResults={[...processUserList(searchResults)]}
						setSelectedUser={viewCustomSearchUser}
					/>
				</section>
			</div>

			{showUserDetails && (
				<ModalLG
					title={`Custom Search`}
					closeModal={() => setShowUserDetails(false)}
				>
					<UserSearchDetails
						selectedUser={selectedUser}
						currentFacility={currentFacility}
						currentUser={currentUser}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default UserSearch;

UserSearch.defaultProps = {};

UserSearch.propTypes = {};
