import React, { useState, useEffect } from "react";
import styles from "../../css/epay/EPayAppLogin.module.scss";
import ePayLogo from "../../assets/brand/LOGO-EPAY.svg";
import ePayLogo2 from "../../assets/brand/LOGO-EPAY2.svg";
import { PropTypes } from "prop-types";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { getAppAccessDeniedReason } from "../../helpers/utils_apps";
import { purple, red } from "../../helpers/utils_styles";
import { matchFacilityByID } from "../../helpers/utils_facility";
import { openInNewTab, openInSameTab } from "../../helpers/utils_params";
import { staxSSO } from "../../helpers/utils_stax";
// components
import Dialog from "../shared/Dialog";
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";

const sampleSSOUrl = `https://omni-auth.auth0.com/login?state=hKFo2SBpX1VnUWhROWV3NjBZMUZ3SVhwU1lMVll3QWI3R284ZaFupWxvZ2luo3RpZNkgRXpId0Y3dDAxWHNXamh1SGVrV25SaDlqb3M0V3FmS3OjY2lk2SBGTkJHbDdNTW9aRjJUcU56MnNadGsyTHJFSm0xMzdkbw&client=FNBGl7MMoZF2TqNz2sZtk2LrEJm137do&protocol=oauth2&redirect_uri=https%3A%2F%2Fauthservice.fattlabs.com%2Fauth0%2Fcallback&scope=openid%20profile%20email&response_type=code&connection=`;

const customCSS = {
	confirm: {
		padding: ".6rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: ".5rem",
		backgroundColor: purple[600],
		color: purple[100],
	},
	cancel: {
		padding: ".6rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
	},
};

// whitelist access to apps by user
const appsWhitelist = [
	`dandemo@aladvantage.com`,
	`sgore99@aladvantage.com`,
	`trainingsb@aladvantage.com`,
	`trainingsb2@aladvantage.com`,
];

// ##TODOS:
// - Replace svg icons w/ app logos

const appNames = {
	AdvantageTracker: `Care Tracker`,
	// SeniorCareVB: ` EHR: Care Manager`,
	SeniorCareVB: ` Senior Care EHR`,
	AdminPortal: `ALA Portal`,
	ChartMedsInterface: "Chart Meds (EMAR)",
	StaxInterface: `ePay (Stax)`,
};

const assets = {
	ePay: ePayLogo,
	StaxInterface: ePayLogo,
};

const getAccessStyle = (isAccessible) => {
	if (isAccessible) {
		return { opacity: "1", cursor: "pointer" };
	} else {
		return { opacity: ".4", cursor: "not-allowed" };
	}
};

const hasMultipleEmarOptions = (ePayFacilities = []) => {
	return ePayFacilities?.length >= 2;
};

const disableAppAccess = (appName, isAccessible) => {
	if (appName === "SeniorCareVB") return false;
	return !isAccessible;
};

const getAppTitle = (appName, isEnabled) => {
	return `${appName}: ${isEnabled ? "Enabled" : "Disabled"}`;
};

const getInitialSelection = (
	currentUser = {},
	currentFacility = {},
	ePayFacilities = [],
	allFacilities = []
) => {
	const { facilityID: userPrimaryFacilityID, facilities } = currentUser;
	const { facilityID: currentFacilityID } = currentFacility;

	// if facility is loaded, default to that, but allow changing via facility selector
	if (!isEmptyVal(currentFacilityID)) {
		const facility = matchFacilityByID(currentFacilityID, facilities);
		return facility?.communityName;
	} else {
		const facility = ePayFacilities?.includes(userPrimaryFacilityID)
			? matchFacilityByID(userPrimaryFacilityID, facilities)
			: {};
		return facility?.communityName ?? "";
	}
};

const getInitialFacility = (
	selectedFacility = "",
	ePayFacilities = [],
	allFacilities = []
) => {
	if (isEmptyVal(selectedFacility)) {
		// check how many 'ePayFacilities'
		const base = ePayFacilities?.[0] ?? "";

		return base;
	} else {
		return selectedFacility;
	}
};

const EPayConfirmationDialog = ({
	app = {},
	selectedFacility,
	handleSelection,
	currentFacility = {},
	ePayFacilities = [],
	allFacilities = [],
	confirmRedirect,
	cancelRedirect,
}) => {
	return (
		<Dialog
			icon="INFO"
			title={`Redirect to Stax (ePay)?`}
			subheading={
				hasMultipleEmarOptions(ePayFacilities)
					? "Select community for ePay (Stax)"
					: ""
			}
			closeModal={cancelRedirect}
		>
			<div className={styles.EPayConfirmationDialog}>
				<CustomDropdown
					key={`ePAY-REDIRECT-FACILITY-${getInitialFacility(
						selectedFacility,
						ePayFacilities,
						allFacilities
					)}`}
					id="ePayFacility"
					name="ePayFacility"
					placeholder="Choose facility..."
					selection={getInitialFacility(
						selectedFacility,
						ePayFacilities,
						allFacilities
					)}
					setSelection={handleSelection}
					options={ePayFacilities}
				/>
			</div>
			<div className={styles.EPayActions}>
				<ButtonSM customStyles={customCSS.cancel} handleClick={cancelRedirect}>
					Stay Here
				</ButtonSM>
				<ButtonSM
					customStyles={customCSS.confirm}
					handleClick={() =>
						confirmRedirect({
							...app,
							selectedFacility: selectedFacility,
						})
					}
				>
					Open App
				</ButtonSM>
			</div>
		</Dialog>
	);
};

// shows reason for application being disabled
const AccessDisabledReason = ({
	reason,
	callToAction,
	isAccessible = false,
	showReason = false,
}) => {
	if (isAccessible && !showReason) {
		return null;
	}
	return (
		<div className={styles.AccessDisabledReason}>
			<div className={styles.AccessDisabledReason_text}>{reason}</div>
			<div className={styles.AccessDisabledReason_cta}>{callToAction}</div>
		</div>
	);
};

const EPayAppLogin = ({
	app = {},
	// goToApp,
	availableApps = [],
	currentUser = {},
	currentFacility = {},
	ePayFacilities = [],
}) => {
	const {
		ApplicationId: appID,
		ApplicationName: appName,
		Alias: appAlias,
		IsAccessible: isAccessible,
	} = app;
	const { facilities } = currentUser;
	const [showFacilitySelector, setShowFacilitySelector] = useState(false);
	const [selectedFacility, setSelectedFacility] = useState(
		getInitialSelection(currentUser, currentFacility, ePayFacilities)
	);
	const [showDisabledReason, setShowDisabledReason] = useState(!isAccessible);
	const [deniedReason, setDeniedReason] = useState({
		reason: null,
		cta: null,
	});

	const getDeniedReason = async () => {
		if (isAccessible || isEmptyObj(app)) {
			setDeniedReason({ reason: null, cta: null });
			return setShowDisabledReason(false);
		}
		const { token, userID, facilityID } = currentUser;
		const cause = await getAppAccessDeniedReason(token, {
			userID: userID,
			facilityID: facilityID,
			appID: appID,
		});

		setDeniedReason({ ...cause });
	};

	const handleRedirect = async () => {
		// check emar facilities
		// open

		const residentID = 0;
		const { token, facilityID, userID } = currentUser;
		// const ePayURL = await staxSSO(token, {
		// 	facilityID,
		// 	userID,
		// 	residentID,
		// });

		setShowFacilitySelector(true);
		// if no loaded facility
		if (isEmptyVal(selectedFacility)) {
			const { facilityID, facilities } = currentUser;

			const match = matchFacilityByID(facilityID, facilities);
			const name = match?.FacilityId ?? match?.facilityID ?? match?.FacilityID;

			// return goToApp({
			// 	...app,
			// 	selectedFacility: name,
			// 	selectedFacilityID: facilityID,
			// });
		} else {
			const name = !isEmptyVal(selectedFacility) ? selectedFacility : "";
			const id = "";

			// return goToApp({
			// 	...app,
			// 	selectedFacility: "TBD",
			// 	selectedFacilityID: "TBD",
			// });
		}

		// pass 'app' object
		// pass 'selectedFacility' value
	};

	const confirmRedirect = async () => {
		const residentID = 0;
		const { token, facilityID, userID } = currentUser;
		const { selectedFacility } = app;
		const facID = "E7E97361-E602-4A61-B7E1-E2BE440699CC";
		// ##TODOS:
		// - FIGURE OUT WHICH FACILITY ID TO USE FOR A GIVEN USER
		const ePayURL = await staxSSO(token, {
			facilityID,
			userID,
			residentID,
		});

		if (!isEmptyVal(ePayURL)) {
			// return openInSameTab(ePayURL);
			return openInNewTab(ePayURL);
		} else {
			return alert("Whoops! There was an issue.");
		}
	};

	const cancelRedirect = () => {
		setShowFacilitySelector(false);
		setSelectedFacility("");
	};

	const handleSelection = (name, val) => {
		if (isEmptyVal(val)) {
			return setSelectedFacility("");
		} else {
			return setSelectedFacility(val);
		}
	};

	// fetch disabled reason(s) & cta
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getDeniedReason();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isEmptyObj(app) || isEmptyArray(ePayFacilities)) {
		return null;
	}
	return (
		<>
			<button
				className={styles.EPayAppLogin}
				style={getAccessStyle(isAccessible)}
				onClick={() => handleRedirect()}
				type="button"
				disabled={disableAppAccess(appName, isAccessible)}
				title={getAppTitle(appName, isAccessible)}
			>
				<img
					src={ePayLogo2}
					alt={`${appName} Logo`}
					className={styles.EPayAppLogin_logo}
				/>

				<span className={styles.EPayAppLogin_name}>{appNames[appName]}</span>
				{showDisabledReason && !isAccessible && (
					<AccessDisabledReason
						key={`ACCESS-REASON-${showDisabledReason}`}
						showReason={showDisabledReason}
						reason={deniedReason?.reason}
						callToAction={deniedReason.cta}
					/>
				)}
			</button>

			{showFacilitySelector && (
				<EPayConfirmationDialog
					key={`EPAY-CONFIRM-DIALOG`}
					app={app}
					selectedFacility={selectedFacility}
					currentFacility={currentFacility}
					ePayFacilities={ePayFacilities}
					allFacilities={facilities}
					handleSelection={handleSelection}
					confirmRedirect={confirmRedirect}
					cancelRedirect={cancelRedirect}
				/>
			)}
		</>
	);
};

export default EPayAppLogin;

EPayAppLogin.defaultProps = {};

EPayAppLogin.propTypes = {
	app: PropTypes.object,
};
