import React from "react";
import styles from "../../css/migration/MigrationPanelHeader.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const AppItem = ({ app }) => {
	return <li className={styles.AppItem}>{app}</li>;
};

const MigrationAppsList = ({ appsList = [] }) => {
	return (
		<div className={styles.MigrationAppsList}>
			<ul className={styles.MigrationAppsList_list}>
				{!isEmptyArray(appsList) &&
					appsList.map((app, idx) => (
						<AppItem key={`App:${app}-${idx}`} app={app} />
					))}
			</ul>
		</div>
	);
};

const MigrationPanelHeader = ({ title, desc, about, app, appsList = [] }) => {
	return (
		<div className={styles.MigrationPanelHeader}>
			<div className={styles.MigrationPanelHeader_top}>
				<div className={styles.MigrationPanelHeader_top_title}>{title}</div>
				<p className={styles.MigrationPanelHeader_top_desc}>
					{desc} <b>{app}</b>.
				</p>
				<p className={styles.MigrationPanelHeader_top_desc}>{about}</p>
				<MigrationAppsList appsList={appsList} />
			</div>
		</div>
	);
};

export default MigrationPanelHeader;

MigrationPanelHeader.defaultProps = {};

MigrationPanelHeader.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	about: PropTypes.string,
	app: PropTypes.string,
	appsList: PropTypes.arrayOf(PropTypes.string),
};
