import React, { useState } from "react";
import styles from "../../css/admin/UserCard.module.scss";
import { PropTypes } from "prop-types";
import { getUserName } from "../../helpers/utils_user";
import { addEllipsis } from "../../helpers/utils_processing";
import {
	blueGrey,
	orange,
	red,
	teal,
	yellow,
} from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import ModalLG from "../shared/ModalLG";
import UserManagement from "../user/UserManagement";
import UserAccessIndicator from "./UserAccessIndicator";

const getUsersInitials = (user) => {
	const { firstName, lastName } = user;
	let first = firstName?.split("")?.[0] ?? "N";
	let last = lastName?.split("")?.[0] ?? "A";

	first = first?.toUpperCase();
	last = last?.toUpperCase();

	return `${first}${last}`;
};
// username/email
const getUserLogin = (user, maxLength = 25) => {
	const { email } = user;
	return addEllipsis(email, maxLength);
};
const getUserTitle = (user) => {
	if (isEmptyVal(user?.title)) return "";
	return `(${user?.title})`;
};

const userTypesMap = {
	isSuperUser: "Super User",
	isFacilityAdmin: "Facility Admin",
	isRegionalAdmin: "Regional Admin",
	isMedTechRestricted: "Med-Tech Restricted",
	isBasicUser: "Standard",
};
const userTypesCSS = {
	isSuperUser: {
		border: `1px solid ${red[600]}`,
		color: red[600],
		backgroundColor: red[100],
	},
	isRegionalAdmin: {
		border: `1px solid ${orange[600]}`,
		color: orange[600],
		backgroundColor: orange[100],
	},
	isFacilityAdmin: {
		border: `1px solid ${yellow[600]}`,
		color: yellow[600],
		backgroundColor: yellow[100],
	},
	isMedTechRestricted: {
		border: `1px solid ${teal[600]}`,
		color: teal[600],
		backgroundColor: teal[100],
	},
	isBasicUser: {
		border: `1px solid ${blueGrey[600]}`,
		color: blueGrey[600],
		backgroundColor: blueGrey[100],
	},
};

const UserTypeBadge = ({ user = {} }) => {
	const { isFacilityAdmin, isRegionalAdmin, isSuperUser, isMedTechRestricted } =
		user;

	switch (true) {
		case isSuperUser: {
			return (
				<div
					className={styles.UserTypeBadge}
					style={userTypesCSS["isSuperUser"]}
				>
					<div
						className={styles.UserTypeBadge_label}
						style={{ ...userTypesCSS["isSuperUser"], border: "none" }}
					>
						{userTypesMap["isSuperUser"]}
					</div>
				</div>
			);
		}
		case isRegionalAdmin: {
			return (
				<div
					className={styles.UserTypeBadge}
					style={userTypesCSS["isRegionalAdmin"]}
				>
					<div
						className={styles.UserTypeBadge_label}
						style={{ ...userTypesCSS["isRegionalAdmin"], border: "none" }}
					>
						{userTypesMap["isRegionalAdmin"]}
					</div>
				</div>
			);
		}
		case isFacilityAdmin && !isRegionalAdmin: {
			return (
				<div
					className={styles.UserTypeBadge}
					style={userTypesCSS["isFacilityAdmin"]}
				>
					<div
						className={styles.UserTypeBadge_label}
						style={{ ...userTypesCSS["isFacilityAdmin"], border: "none" }}
					>
						{userTypesMap["isFacilityAdmin"]}
					</div>
				</div>
			);
		}
		case isMedTechRestricted: {
			return (
				<div
					className={styles.UserTypeBadge}
					style={userTypesCSS["isMedTechRestricted"]}
				>
					<div
						className={styles.UserTypeBadge_label}
						style={{ ...userTypesCSS["isMedTechRestricted"], border: "none" }}
					>
						{userTypesMap["isMedTechRestricted"]}
					</div>
				</div>
			);
		}
		case !isMedTechRestricted && !isFacilityAdmin && !isRegionalAdmin: {
			return (
				<div
					className={styles.UserTypeBadge}
					style={userTypesCSS["isBasicUser"]}
				>
					<div
						className={styles.UserTypeBadge_label}
						style={{ ...userTypesCSS["isBasicUser"], border: "none" }}
					>
						{userTypesMap["isBasicUser"]}
					</div>
				</div>
			);
		}
		default:
			return null;
	}
};

const UserCard = ({
	currentFacility = {},
	currentUser = {},
	user = {},
	hasTrackerAccess = false,
	hasLegacyAccess = false,
}) => {
	const [showUserInfo, setShowUserInfo] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [userAppAccess, setUserAppAccess] = useState({
		isTrackerEnabled: hasTrackerAccess,
		isLegacyEnabled: hasLegacyAccess,
	});

	const selectUser = () => {
		setShowUserInfo(true);
		setIsSelected(true);
	};

	const closeInfoModal = () => {
		setShowUserInfo(false);
		setIsSelected(false);
	};

	return (
		<>
			<div
				className={isSelected ? styles.UserCard_isSelected : styles.UserCard}
				onClick={selectUser}
			>
				<section className={styles.UserCard_user}>
					<div className={styles.UserCard_user_badge}>
						<div className={styles.UserCard_user_badge_initials}>
							{getUsersInitials(user)}
						</div>
					</div>
					<div className={styles.UserCard_user_about}>
						<div className={styles.UserCard_user_about_name}>
							{getUserName(user, 20)}
						</div>
						<div
							className={styles.UserCard_user_about_login}
							title={user?.email}
						>
							{getUserLogin(user, 20)}
						</div>
						<div
							className={styles.UserCard_user_about_title}
							title={user?.email}
						>
							{getUserTitle(user)}
						</div>
					</div>
					<div className={styles.UserCard_user_type}>
						<UserTypeBadge user={user} />
					</div>
				</section>
				<section className={styles.UserCard_middle}>
					<div className={styles.UserCard_middle_access}>
						<UserAccessIndicator
							appName="Care Tracker"
							hasAccess={userAppAccess?.isTrackerEnabled}
						/>
						<UserAccessIndicator
							appName="Care Manager"
							hasAccess={userAppAccess?.isLegacyEnabled}
						/>
					</div>
				</section>
			</div>

			{showUserInfo && (
				<ModalLG title={`User Info`} closeModal={closeInfoModal}>
					<UserManagement
						currentUser={currentUser}
						currentFacility={currentFacility}
						selectedUser={user}
						hasTrackerAccess={userAppAccess?.isTrackerEnabled}
						hasLegacyAccess={userAppAccess?.isLegacyEnabled}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default UserCard;

UserCard.defaultProps = {
	currentFacility: {},
	user: {},
};

UserCard.propTypes = {
	currentFacility: PropTypes.object,
	user: PropTypes.object,
};
