import React, { useState } from "react";
import styles from "../../css/shared/LimitedTextInput.module.scss";
import { PropTypes } from "prop-types";
import TextInput from "./TextInput";

// REQUIREMENTS:
// - Injects <TextInput/> component w/:
//    - Max. character limit
//    - Adds 'max char' message indicator

const LimitedTextInput = ({
	type = "text",
	label,
	name,
	id,
	placeholder,
	required = false,
	val,
	handleChange,
	handleBlur,
	handleFocus,
	handleReset,
	autoComplete = "off",
	addRequiredFlag = false,
	readOnly = false,
	isDisabled = false,
	inputMode = "text",
	customStyles = {},
	customWidth = {},
	inputRef = null,
	enableMaxChars = true,
	maxChars = 20,
}) => {
	const [hitMax, setHitMax] = useState(false);

	const handleLimit = (e) => {
		const { value } = e.target;
		if (enableMaxChars) {
			if (value.length >= maxChars) {
				return setHitMax(true);
			} else {
				handleChange(e);
				return setHitMax(false);
			}
		}
		return handleChange(e);
	};

	return (
		<div className={styles.LimitedTextInput}>
			<TextInput
				name={name}
				id={id}
				val={val}
				type={type}
				label={label}
				placeholder={placeholder}
				required={required}
				inputMode={inputMode}
				isDisabled={isDisabled}
				readOnly={readOnly}
				addRequiredFlag={addRequiredFlag}
				autoComplete={autoComplete}
				handleBlur={handleBlur}
				handleFocus={handleFocus}
				handleReset={handleReset}
				handleChange={handleLimit}
				customStyles={customStyles}
				customWidth={customWidth}
				inputRef={inputRef}
			/>
			{hitMax && enableMaxChars && (
				<span className={styles.LimitedTextInput_maxChars}>
					{maxChars} max characters!
				</span>
			)}
		</div>
	);
};

export default LimitedTextInput;

LimitedTextInput.defaultProps = {};

LimitedTextInput.propTypes = {};
