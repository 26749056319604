import React from "react";
import styles from "../../css/app/MaintenanceModePage.module.scss";
import brandLogo from "../../assets/brand/logo.png";
import { PropTypes } from "prop-types";
import MaintenanceMode from "./MaintenanceMode";

const details = {
	title: `Maintenance Mode`,
	desc: `AL Advantage's Admin Portal is currently undergoing maintenance.`,
	extra: `Sorry for the inconvenience. We'll be back shortly!`,
};

const MaintenanceMessage = ({ title }) => {
	return (
		<div className={styles.MaintenanceMessage}>
			<h2 className={styles.MaintenanceMessage_title}>{title}</h2>
			<a
				href="https://www.aladvantage.com"
				className={styles.MaintenanceMessage_link}
			>
				Visit AL Advantage LLC.
			</a>
			<a
				href="https://app.aladvantage.com/Account/Login.aspx"
				className={styles.MaintenanceMessage_link}
			>
				Visit Senior Care App (EHR)
			</a>
		</div>
	);
};

const MaintenanceModePage = () => {
	return (
		<div className={styles.MaintenanceModePage}>
			<div className={styles.MaintenanceModePage_logo}>
				<img
					src={brandLogo}
					alt="AL Advantage Senior Care Software Logo"
					className={styles.MaintenanceModePage_logo_image}
				/>
			</div>
			<MaintenanceMode
				disableNav={true}
				title={details.title}
				desc={details.desc}
			>
				<MaintenanceMessage title={details.extra} />
			</MaintenanceMode>
		</div>
	);
};

export default MaintenanceModePage;

MaintenanceModePage.defaultProps = {};

MaintenanceModePage.propTypes = {};
