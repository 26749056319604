import React from "react";
import styles from "../../css/user/UserSecurityAnswer.module.scss";
import { PropTypes } from "prop-types";

const UserSecurityAnswer = ({
	label = `Your Answer:`,
	name,
	id,
	placeholder,
	handleAnswer,
	isDisabled = false,
	readOnly = false,
	vals = {},
}) => {
	return (
		<div className={styles.UserSecurityAnswer}>
			<div className={styles.UserSecurityAnswer_wrapper}>
				<label htmlFor={id} className={styles.UserSecurityAnswer_wrapper_label}>
					{label}
				</label>
				<input
					type="text"
					name={name}
					id={id}
					value={vals[name]}
					className={styles.UserSecurityAnswer_wrapper_input}
					onChange={handleAnswer}
					placeholder={placeholder}
					disabled={isDisabled}
					readOnly={readOnly}
				/>
			</div>
		</div>
	);
};

export default UserSecurityAnswer;

UserSecurityAnswer.defaultProps = {};

UserSecurityAnswer.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	vals: PropTypes.object.isRequired,
	handleAnswer: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	readOnly: PropTypes.bool,
};
