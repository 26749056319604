import React, { useReducer, useState, useEffect } from "react";
import styles from "../../css/loctemplate/LOCNewTemplateWrapper.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	defaultSchema,
	fetchAndProcessLOCApiValidation,
	mergeLOCIntoArray,
	validateAllLOC,
} from "../../helpers/utils_loc";
import { getDefaultLOC } from "../../helpers/utils_defaultLOC";
import {
	createLevelsOfCareTemplate,
	prepareNewLOCTemplate,
} from "../../helpers/utils_loctemplate";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { green } from "../../helpers/utils_styles";
import { Prompt } from "react-router-dom";
// components
import ButtonSM from "../shared/ButtonSM";
import LOCModal from "../loc/LOCModal";
import LOCTemplateDescInput from "./LOCTemplateDescInput";
import LOCTemplateNameInput from "./LOCTemplateNameInput";
import LOCTemplateTable from "./LOCTemplateTable";
import LOCApiValidator from "../loc/LOCApiValidator";
import LOCNewTemplateValidator from "./LOCNewTemplateValidator";

// REQUIREMENTS:
// - NEW LOC Templates:
//    - Edit levels
//    - Edit template name
//    - Edit template desc

const customCSS = {
	save: {
		padding: "1.4rem 2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: green[500],
		color: "#ffffff",
	},
};

const promptMsg = `There are unsaved changes!. Still want to leave?`;

const templateReducer = (state, action) => {
	switch (action.type) {
		case "LOAD_NEW_TEMPLATE": {
			return { ...state };
		}
		case "SAVE_NEW_TEMPLATE": {
			return { ...state };
		}
		// sync changes from child components (eg. <Cell/>) to local state
		case "SYNC_CHANGES": {
			const { targetFloorUnit, newData } = action.data;

			return {
				...state,
				template: {
					...state.template,
					[targetFloorUnit]: newData,
				},
				hasChanges: true,
			};
		}
		case "RESET_TABLE": {
			const { tableName } = action.data;
			const defaultSchema = getDefaultLOC();
			const tableSchema = defaultSchema[tableName];

			return {
				...state,
				template: {
					...state.template,
					[tableName]: tableSchema,
				},
			};
		}

		default:
			return state;
	}
};

// disables 'Save New Template' button
const disableSave = (vals, hasChanges) => {
	const { templateName: name, templateDesc: desc } = vals;
	const isEmpty = isEmptyVal(name) || isEmptyVal(desc);
	if (hasChanges) return false;
	// either val is empty
	return isEmpty;
};

// initial validation state
const baseValidation = {
	"Assisted Living": {},
	"Memory Care": {},
	"Personal Care": {},
	Independent: {},
};

// initial template state
const initialState = {
	template: {
		"Assisted Living": [],
		"Memory Care": [],
		"Personal Care": [],
		Independent: [],
	},
	hasChanges: true,
};

const defaultNewTemplate = {
	templateName: "",
	templateDesc: "",
	template: {
		...getDefaultLOC(),
	},
};

const LOCNewTemplateWrapper = ({
	isEditable = true,
	locAreAdminLocked = false,
	facilityTemplates = {},
	currentUser = {},
	currentFacility = {},
	// syncTableData,
	dispatchAlert,
	syncCreatedTemplate,
}) => {
	// confirm save modal
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const { formState, handleChange } = useForm({
		isEditable: isEditable,
		locAreAdminLocked: locAreAdminLocked,
		// new vals
		templateName: defaultNewTemplate?.templateName ?? "",
		templateDesc: defaultNewTemplate?.templateDesc ?? "",
		isDefault: false,
	});
	const { values } = formState;
	// NEW template state
	const [templateState, templateAction] = useReducer(templateReducer, {
		...initialState,
		template: {
			...getDefaultLOC(),
		},
		hasChanges: true,
	});
	// template validation
	const [templateValidation, setTemplateValidation] = useState({
		...baseValidation,
	});

	// sync table changes to local reducer state
	const syncTableData = (tableName, tableData) => {
		templateAction({
			type: "SYNC_CHANGES",
			data: {
				targetFloorUnit: tableName,
				newData: tableData?.data,
			},
		});
	};

	const saveNewTemplate = async () => {
		const { token } = currentUser;
		setShowConfirmModal(true);
		const editedTemplate = templateState?.template;

		// ORIGINAL VALIDATION (via client code) //
		// const allResults = validateAllLOC(editedTemplate);
		// setTemplateValidation({ ...allResults });

		// merge all LOC unit types into single array & run validation via API
		const merged = mergeLOCIntoArray(editedTemplate);
		const validation = await fetchAndProcessLOCApiValidation(token, merged);

		if (!isEmptyObj(validation)) {
			return setTemplateValidation({ ...validation });
		} else {
			return setTemplateValidation({});
		}
	};

	// confirm request handler
	const confirmNewTemplate = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const { template } = templateState;
		const preparedTemplate = prepareNewLOCTemplate(facilityID, template);

		// const wasSaved = false;

		// fire off request

		const wasSaved = await createLevelsOfCareTemplate(token, {
			facilityID,
			templateName: values?.templateName,
			templateDesc: values?.templateDesc,
			isDefault: values?.isDefault,
			templateLevels: preparedTemplate,
		});

		if (wasSaved) {
			setShowConfirmModal(false);
			// sync new template
			dispatchAlert("SUCCESS", {
				heading: `New Template Was Created!`,
			});
			return syncCreatedTemplate();
		} else {
			setShowConfirmModal(false);
			return dispatchAlert("ERROR", {
				heading: `Template Failed!`,
				subheading: `...`,
			});
		}
	};

	// cancel new template
	const cancelSaveTemplate = async () => {
		setShowConfirmModal(false);
		setTemplateValidation({ ...baseValidation });
	};

	const handleTableReset = (tableName) => {
		templateAction({
			type: "RESET_TABLE",
			data: {
				tableName: tableName,
			},
		});
	};

	return (
		<>
			<div className={styles.LOCNewTemplateWrapper}>
				<div className={styles.LOCNewTemplateWrapper_newName}>
					<LOCTemplateNameInput
						key={`NEW-NAME`}
						vals={values}
						handleChange={handleChange}
					/>
					<LOCTemplateDescInput
						key={`NEW-DESC`}
						vals={values}
						handleChange={handleChange}
					/>
				</div>
				<div className={styles.LOCNewTemplateWrapper_inner}>
					{/* ASSISTED LIVING */}
					<LOCTemplateTable
						key={`NEW-AL-${templateState?.template?.["Assisted Living"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Assisted Living"
						title="Assisted Living"
						subtitle="Edit or add new care levels to be applied to 'Assisted Living' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Assisted Living"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
					/>
					{/* MEMORY CARE */}
					<LOCTemplateTable
						key={`NEW-MC-${templateState?.template?.["Memory Care"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Memory Care"
						title="Memory Care"
						subtitle="Edit or add new care levels to be applied to 'Memory Care' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Memory Care"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
					/>
					{/* INDEPENDENT */}
					<LOCTemplateTable
						key={`NEW-IND-${templateState?.template?.["Independent"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Independent"
						title="Independent"
						subtitle="Edit or add new care levels to be applied to 'Independent Living' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Independent"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
					/>

					{/* PERSONAL CARE */}
					<LOCTemplateTable
						key={`NEW-PC-${templateState?.template?.["Personal Care"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Personal Care"
						title="Personal Care"
						subtitle="Edit or add new care levels to be applied to 'Personal Care' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Personal Care"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
					/>
				</div>
				<div className={styles.LOCNewTemplateWrapper_actions}>
					<ButtonSM
						isDisabled={disableSave(values, templateState.hasChanges)}
						customStyles={customCSS.save}
						handleClick={saveNewTemplate}
					>
						Save New Template
					</ButtonSM>
				</div>
			</div>

			{/* DIRTY PAGE PROMPT (ie. page has changes) */}
			<Prompt when={templateState.hasChanges} message={promptMsg} />

			{/* MODAL: CONFIRM LOC CHANGES */}
			{showConfirmModal && (
				<LOCModal
					key={`VALIDATE-NEW-TEMPLATE`}
					title={`Confirm New Template?`}
					closeModal={() => setShowConfirmModal(false)}
				>
					<LOCNewTemplateValidator
						key={`VALIDATOR-TEMPLATE-NEW`}
						vals={values}
						currentFacility={currentFacility}
						templateValidation={templateValidation}
						confirmNewTemplate={confirmNewTemplate}
						cancelSaveTemplate={cancelSaveTemplate}
					/>
				</LOCModal>
			)}
		</>
	);
};

export default LOCNewTemplateWrapper;

LOCNewTemplateWrapper.defaultProps = {};

LOCNewTemplateWrapper.propTypes = {};
