import React, { useRef } from "react";
import styles from "../../css/residents/ResidentsTableActions.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const ResidentsTableActions = ({
	searchVal,
	handleSearch,
	initCustomSearch,
	clearSearch,
}) => {
	const searchRef = useRef();

	// clears search & refocus input
	const enterHandler = (e) => {
		if (e.key === "Enter") {
			clearSearch();
			return searchRef?.current?.focus();
		} else {
			return;
		}
	};

	return (
		<div className={styles.ResidentsTableActions}>
			<div className={styles.ResidentsTableActions_search}>
				<svg className={styles.ResidentsTableActions_search_icon}>
					<use xlinkHref={`${sprite}#icon-search`}></use>
				</svg>
				<input
					type="text"
					name="searchResidents"
					id="searchResidents"
					value={searchVal}
					onChange={handleSearch}
					placeholder="Search by name, room, id..."
					className={styles.ResidentsTableActions_search_input}
					ref={searchRef}
				/>

				{!isEmptyVal(searchVal) && (
					<button
						className={styles.ResidentsTableActions_search_clear}
						onClick={clearSearch}
						onKeyDown={enterHandler}
						tabIndex={0}
					>
						<svg className={styles.ResidentsTableActions_search_clear_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</button>
				)}
			</div>
			<div className={styles.ResidentsTableActions_customSearch}>
				<button
					type="button"
					className={styles.ResidentsTableActions_customSearch_button}
					onClick={initCustomSearch}
				>
					Advanced Search
				</button>
			</div>
		</div>
	);
};

export default ResidentsTableActions;

ResidentsTableActions.defaultProps = {};

ResidentsTableActions.propTypes = {
	searchVal: PropTypes.string.isRequired,
	handleSearch: PropTypes.func.isRequired,
	initCustomSearch: PropTypes.func,
};
