import { subDays, subWeeks } from "date-fns";
import { emar, facility, generic } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { genericGet } from "./utils_params";
import {
	enforceStrMaxLength,
	sortAlphaAscByKey,
	sortNumAscByKey,
	sortNumDescByKey,
} from "./utils_processing";
import { isEmptyArray, isEmptyObj } from "./utils_types";
import { generateRedirectUrl } from "./utils_apps";

////////////////////////////////////////////////////////////////
/////////////////////// EMAR OPTIONS //////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Fetches a list of available EMAR Interfaces
 * @param {String} token - Auth token
 * @returns {Array} - Returns list of available EMAR Interfaces
 */
const getEmarsList = async (token) => {
	const params = {
		...genericGet?.application,
		ApplicationTypeID: 8,
	};
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches EMAR error summary for ALL communities for a given user and application.
 * @param {String} token - Auth token
 * @param {Number} applicationId - Target application id (ChartMeds: 19)
 * @param {Date} startDate - Start date of target range
 * @param {Date} endDate - End date of target range
 * @returns {Array} - Returns an array-format error summary of all facilities with emar-related errors.
 */
const getEmarErrorSummary = async (
	token,
	applicationId = 19,
	startDate = subDays(new Date(), 10).toISOString(),
	endDate = new Date().toISOString()
) => {
	let url = currentEnv.base + emar.facility.getErrorSummary;
	url += "?" + new URLSearchParams({ applicationId });
	url += "&" + new URLSearchParams({ startDate, endDate });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const emarOptions = {
	applicationId: 19,
	startDate: subDays(new Date(), 3).toISOString(),
	endDate: new Date().toISOString(),
	facilityId: null,
	userId: null,
	residentId: null,
};

/**
 * Fetches EMAR errors list for a given user's facilities.
 * @param {String} token - Auth token
 * @param {Number} applicationId - Target application id (ChartMeds: 19)
 * @param {Date} startDate - Start date of target range
 * @param {Date} endDate - End date of target range
 * @returns {Array} - Returns an array-format error list for all facilities with emar-related errors that the user has access to.
 */
const getEmarErrors = async (token, options = { ...emarOptions }) => {
	const { applicationId, startDate, endDate, facilityId, userId, residentId } =
		options;
	let url = currentEnv.base + emar.facility.getErrors;
	url += "?" + new URLSearchParams({ applicationId });
	url += "&" + new URLSearchParams({ startDate, endDate });
	url += "&" + new URLSearchParams({ userId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
///////////////////////// AUTH EMAR ////////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Single-sign-on for ChartMeds for a given user, facility & resident
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @param {Numer} residentId - Resident id
 * @returns {Object}
 */
// const emarSSO = async (token, facilityId, userId, residentId = null) => {
const emarSSO = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.sso;
	// url += "?" + new URLSearchParams({ facilityId, userId, residentId });
	url += "?" + new URLSearchParams({ facilityId, userId, residentId: 0 });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
/////////////////////// FACILITY EMAR //////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Adds a facility to an EMAR and auto-enables that facility for EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {Number} applicationId - Application number for specific EMAR
 * @returns {Object}
 */
const addFacilityToEmar = async (token, facilityId, applicationId) => {
	let url = currentEnv.base + emar.facility.addFacility;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Enables a disabled facility's EMAR access EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const enableFacilityToEmar = async (token, facilityId) => {
	let url = currentEnv.base + emar.facility.enableFacility;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Adds a facility to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const disableFacilityToEmar = async (token, facilityId) => {
	let url = currentEnv.base + emar.facility.disableFacility;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Checks EMAR access for a given facility. The API already knows what EMAR that facility is associated with.
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const checkFacilityEmarAccess = async (token, facilityId) => {
	let url = currentEnv.base + emar.facility.checkAccess;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
///////////////////////// USER EMAR ////////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Adds a user to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @returns {Object}
 */
const addUserToEmar = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.addUser;
	url += "?" + new URLSearchParams({ facilityId, userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Adds a user to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @returns {Object}
 */
const enableUserToEmar = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.enableUser;
	url += "?" + new URLSearchParams({ facilityId, userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Adds a user to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @returns {Object}
 */
const disableUserToEmar = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.disableUser;
	url += "?" + new URLSearchParams({ facilityId, userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Checks EMAR access for a given user. The API already knows what EMAR that user is associated with.
 * @param {String} token - Auth token
 * @param {String} userId - User guid
 * @returns {Object}
 */
const checkUserEmarAccess = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.checkAccess;
	url += "?" + new URLSearchParams({ userId, facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
/////////////////////// RESIDENT EMAR //////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Adds a facility to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const addResidentToEmar = async (token, facilityId, residentId) => {
	let url = currentEnv.base + emar.resident.addResident;
	url += "?" + new URLSearchParams({ facilityId, residentId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Adds a facility to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const enableResidentToEmar = async (token, facilityId, residentId) => {
	let url = currentEnv.base + emar.resident.enableResident;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Adds a facility to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const disableResidentToEmar = async (token, facilityId, residentId) => {
	let url = currentEnv.base + emar.resident.disableResident;
	url += "?" + new URLSearchParams({ facilityId, residentId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Checks EMAR access for a given resident. The API already knows what EMAR that resident is associated with.
 * @param {String} token - Auth token
 * @param {String} residentId - resident guid
 * @returns {Object}
 */
const checkResidentEmarAccess = async (token, residentId) => {
	let url = currentEnv.base + emar.user.checkAccess;
	url += "?" + new URLSearchParams({ residentId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
//////////////////// EMAR ERROR SUMMARY ///////////////////////
////////////////////////////////////////////////////////////////

const emarSummaryHasErrors = (summary = []) => {
	const hasErrors = !isEmptyArray(summary);

	return hasErrors;
};

// returns a list of user facilities' error summary records
const emarSummaryForUserFacilities = (userFacilities = [], summary = []) => {
	// Create error summary map by ID
	const errorMapByID = summary.reduce((map, record) => {
		const FacilityId = record?.FacilityId ?? record?.FacilityID;

		if (!map[FacilityId]) {
			map[FacilityId] = record;
			return map;
		}

		return map;
	}, {});

	// list of facilityIDs in lower-case that have errors in the summary
	const errorIDs = summary.map(({ FacilityId }) => FacilityId.toLowerCase());

	// check if facilityID exists in list of error's facility IDs
	const newSummary = userFacilities.reduce((newSummaryList, record) => {
		const facilityID = (
			record?.facilityID ??
			record?.FacilityID ??
			record?.FacilityId
		).toLowerCase();

		if (errorIDs.includes(facilityID)) {
			const matchingError = errorMapByID?.[facilityID] ?? {};
			newSummaryList = [...newSummaryList, matchingError];
			return newSummaryList;
		} else {
			return newSummaryList;
		}
	}, []);

	return newSummary;
};

/**
 * Processes a single error summary & returns an error summary text message string.
 * @param {Object} errorEntry - A single facility error entry summary record
 * @returns {String} - Returns a human-readable text summary of errors
 * @example '<communityName> - <count> issues,'
 */
const processFacilityErrorEntry = (errorEntry = {}) => {
	const {
		FacilityName: facilityName,
		FacilityId: facilityID,
		Totals: errorCount,
	} = errorEntry;

	const name = enforceStrMaxLength(facilityName, 20);
	const summary = `${name} (${errorCount}), `;

	return summary;
};
/**
 * Processes error summary & returns an error summary text message string.
 * @param {Object} errorEntry - A single facility error entry summary record
 * @returns {String} - Returns a human-readable text summary of errors
 * @example '<communityName> - <count> issues, <communityName> - <count> issues, etc etc.'
 */
const processFacilityErrorEntries = (errorList = []) => {
	const summary = errorList.reduce((msg, entry) => {
		const summaryMsg = processFacilityErrorEntry(entry);

		msg += `${summaryMsg}`;
		return msg;
	}, "");

	// find last occurence of ',' & replace w/ a '.'
	// - replace last comma with period
	const addPeriod = summary.replace(/(\B,\B)(?!.*\1)/, ".");

	return addPeriod;
};

/**
 * Processes and generates an error message string based off error summary data from API.
 * @param {Object} errorSummaryData - Error summary object
 * @returns {Object} - Returns object w/ details regarding errors & effected communities.
 * @property {Boolean} hasErrors - Error boolean. Whether or not errors exist
 * @property {String} summary - Text string summary
 */
const generateEmarErrorSummary = (rawSummary = []) => {
	// checks each record's "Totals" field
	const hasErrors = emarSummaryHasErrors(rawSummary);

	// const hasErrors = true; // for testing purposes!!!

	if (hasErrors) {
		// sort facility error list by error count (highest-lowest)
		const sortedByErrorCount = sortNumDescByKey("Totals", rawSummary);
		const errorMsgs = processFacilityErrorEntries(sortedByErrorCount);
		const mainMsg = `${errorMsgs}`;

		return {
			hasErrors: true,
			summary: mainMsg,
		};
	} else {
		return {
			hasErrors: false,
			summary: null,
		};
	}
};

// EMAR ISSUES REDIRECT UTILS //

const getEmarIssuesRedirectUrl = (
	selectedApp = "SeniorCareEHR",
	currentUser = {}
) => {
	// generate legacy url
	const url = generateRedirectUrl(selectedApp, {
		userID: currentUser?.userID,
		token: currentUser?.token,
		username: currentUser?.username,
		password: btoa(currentUser?.password),
	});

	return url;
};

export { getEmarsList, getEmarErrorSummary, getEmarErrors };

// auth
export { emarSSO };

// facility request utils
export {
	addFacilityToEmar,
	enableFacilityToEmar,
	disableFacilityToEmar,
	checkFacilityEmarAccess,
};

// user request utils
export {
	addUserToEmar,
	enableUserToEmar,
	disableUserToEmar,
	checkUserEmarAccess,
};
// resident request utils
export {
	addResidentToEmar,
	enableResidentToEmar,
	disableResidentToEmar,
	checkResidentEmarAccess,
};

// error summary utils
export {
	processFacilityErrorEntry,
	processFacilityErrorEntries,
	generateEmarErrorSummary,
	emarSummaryForUserFacilities,
};

// issues redirect utils
export { getEmarIssuesRedirectUrl };
