import React from "react";
import styles from "../../css/login/EmailVerifiedSuccess.module.scss";
import { PropTypes } from "prop-types";
import Illustration from "../illustrations/Illustration";

const EmailVerifiedSuccess = () => {
	return (
		<div className={styles.EmailVerifiedSuccess}>
			<div className={styles.EmailVerifiedSuccess_ui}>
				<Illustration />
			</div>
			<div className={styles.EmailVerifiedSuccess_details}>
				<h2 className={styles.EmailVerifiedSuccess_details_title}>
					Email Verified!
				</h2>
				<p className={styles.EmailVerifiedSuccess_details_desc}>
					Your email has been verified! Welcome to the ALA Care Portal!!
				</p>
			</div>
		</div>
	);
};

export default EmailVerifiedSuccess;

EmailVerifiedSuccess.defaultProps = {};

EmailVerifiedSuccess.propTypes = {};
