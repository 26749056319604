import React from "react";
import styles from "../../css/migration/MigrationScheduler.module.scss";
import { PropTypes } from "prop-types";
// components
import DatePickerSM from "../shared/DatePickerSM";

const customCSS = {
	datePicker: {
		width: "100%",
		maxWidth: "45rem",
	},
};

const MigrationScheduler = ({
	scheduleFor = "",
	availableRange = {},
	handleDate,
}) => {
	return (
		<div className={styles.MigrationScheduler}>
			<DatePickerSM
				label="Schedule Migration for a Future Date"
				val={scheduleFor}
				name="scheduleFor"
				id="scheduleFor"
				placeholder="Select a date..."
				handleDate={handleDate}
				focusMode={true}
				restrictions={availableRange}
				customStyles={customCSS.datePicker}
			/>
		</div>
	);
};

export default MigrationScheduler;

MigrationScheduler.defaultProps = {};

MigrationScheduler.propTypes = {};
