import React, { useEffect, useState } from "react";
import styles from "../../css/otp/RequestOTP.module.scss";
import { PropTypes } from "prop-types";
import OTPPreviewer from "./OTPPreviewer";

const RequestOTP = ({ otp, generateOTP }) => {
	const [wasCopied, setWasCopied] = useState(false);

	const copyOTP = () => {
		navigator.clipboard.writeText(otp);
		setWasCopied(true);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (wasCopied) {
			timerID = setTimeout(() => setWasCopied(false), 3000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasCopied]);

	return (
		<div className={styles.RequestOTP}>
			<div className={styles.RequestOTP_sendRequest}>
				<button
					onClick={generateOTP}
					className={styles.RequestOTP_sendRequest_btn}
				>
					Request OTP
				</button>
			</div>
			<div className={styles.RequestOTP_preview}>
				<OTPPreviewer wasCopied={wasCopied} otp={otp} copyOTP={copyOTP} />
			</div>
		</div>
	);
};

export default RequestOTP;

RequestOTP.defaultProps = {};

RequestOTP.propTypes = {
	otp: PropTypes.string,
	generateOTP: PropTypes.func,
};
