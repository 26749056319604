import React, { useState, useReducer } from "react";
import styles from "../../css/facility/FacilityAccessTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	sortAlphaAscByKey,
	sortAlphaDescByKey,
	sortBoolAscByKey,
	sortBoolDescByKey,
} from "../../helpers/utils_processing";
// components
import FacilityAccessHeader from "./FacilityAccessHeader";
import FacilityAccessActions from "./FacilityAccessActions";
import FacilityAccessColumns from "./FacilityAccessColumns";
import FacilityAccessTableBody from "./FacilityAccessTableBody";
import ModalLG from "../shared/ModalLG";
import CustomFacilitySearchWindow from "./CustomFacilitySearchWindow";

// const columns = [`Facility`, `SeniorCareEHR`, `CareTracker`];
// const columns = [`Facility`, `SeniorCareEHR`, `CareTracker`, `EMAR`];
const columns = [`Facility`, `SeniorCareEHR`, `CareTracker`, `EMAR`, `ePay`];

// CONSIDERATIONS:
// - Consider handling searching, sorting from FacilityAccessTable

const searchFacilities = (val = "", allFacilities = []) => {
	val = val.toLowerCase();

	return [...allFacilities].filter((facility) => {
		if (
			facility?.communityName?.toLowerCase().startsWith(val) ||
			facility?.address?.state?.toLowerCase().startsWith(val) ||
			facility?.address?.city?.toLowerCase().startsWith(val) ||
			facility?.facilityID?.toLowerCase() === val
		) {
			return facility;
		}
		return null;
	});
};

const tableReducer = (state, action) => {
	switch (action.type) {
		case "SEARCH": {
			// ADD SUPPORT FOR SEARCHING BY FACILITY TYPE
			const { searchVal, rawFacilities } = action.data;
			const { facilities } = state;

			if (isEmptyVal(searchVal)) {
				return {
					...state,
					facilities: [...rawFacilities],
				};
			} else {
				return {
					...state,
					facilities: [...searchFacilities(searchVal, facilities)],
				};
			}
		}
		case "SORT": {
			const { isSorted, facilities } = state;

			if (isSorted) {
				return {
					...state,
					isSorted: !isSorted,
					facilities: [...sortAlphaAscByKey("CommunityName", facilities)],
				};
			} else {
				return {
					...state,
					isSorted: !isSorted,
					facilities: [...sortAlphaDescByKey("CommunityName", facilities)],
				};
			}
		}
		case "SORT_BY_APP": {
			const { sortType } = action.data;
			const { facilities, accessList } = state;

			if (sortType === `AdvantageTracker`) {
				return {
					...state,
					facilities: [
						...facilities.filter(
							({ FacilityId }) => !accessList.includes(FacilityId)
						),
					],
				};
			} else {
				return { ...state };
			}
		}
		default:
			return { ...state };
	}
};

const initialState = {
	facilities: [],
	currentFacility: {},
	isSorted: false,
	sortType: "",
	trackerAccess: [],
	emarAccess: [],
};

const FacilityAccessTable = ({
	currentUser = {},
	accessLists = {},
	allFacilities = [],
	parentsMap = {},
	updateAccessList,
	dispatchAlert,
}) => {
	// facility search window
	const [showCustomSearchWindow, setShowCustomSearchWindow] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [tableState, dispatchAction] = useReducer(tableReducer, {
		...initialState,
		facilities: [...allFacilities],
		// accessList: [...accessList],
		trackerAccess: accessLists?.trackerAccess,
		emarAccess: accessLists?.emarAccess,
		ePayAccess: accessLists?.ePayAccess,
	});

	// search by: 'community', 'city', & 'state'
	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		dispatchAction({
			type: "SEARCH",
			data: {
				searchVal: value,
				rawFacilities: [...allFacilities],
			},
		});
	};

	const handleFacilitySorting = (sortType) => {
		dispatchAction({
			type: "SORT",
			data: {
				sortType: sortType,
				rawFacilities: [...allFacilities],
			},
		});
	};

	const handleAppSorting = (sortType) => {
		dispatchAction({
			type: "SORT_BY_APP",
			data: {
				sortType: sortType,
				rawFacilities: [...allFacilities],
			},
		});
	};

	// open custom search window
	const initCustomSearch = () => {
		setShowCustomSearchWindow(true);
	};

	const clearSearch = () => {
		setSearchVal("");
		dispatchAction({
			type: "SEARCH",
			data: {
				searchVal: "",
				rawFacilities: [...allFacilities],
			},
		});
	};

	return (
		<>
			<div className={styles.FacilityAccessTable}>
				<section className={styles.FacilityAccessTable_header}>
					<FacilityAccessHeader allFacilities={tableState.facilities} />
					<FacilityAccessActions
						initCustomSearch={initCustomSearch}
						searchVal={searchVal}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						currentUser={currentUser}
					/>
				</section>
				<section className={styles.FacilityAccessTable_main}>
					<FacilityAccessColumns
						columns={columns}
						handleFacilitySorting={handleFacilitySorting}
						handleAppSorting={handleAppSorting}
					/>
					<FacilityAccessTableBody
						key={`FACILITY-TABLE-${tableState?.trackerAccess?.length}`}
						parentsMap={parentsMap}
						// accessList={accessList}
						trackerAccessList={accessLists?.trackerAccess ?? []}
						emarAccessList={accessLists?.emarAccess ?? []}
						ePayAccessList={accessLists?.ePayAccess ?? []}
						currentUser={currentUser}
						updateAccessList={updateAccessList}
						allFacilities={tableState.facilities}
						dispatchAlert={dispatchAlert}
					/>
				</section>
			</div>

			{/* CUSTOM SEARCH WINDOW */}
			{showCustomSearchWindow && (
				<ModalLG
					title="Custom Facility Search"
					closeModal={() => setShowCustomSearchWindow(false)}
				>
					<CustomFacilitySearchWindow
						currentUser={currentUser}
						closeWindow={() => setShowCustomSearchWindow(false)}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default FacilityAccessTable;

FacilityAccessTable.defaultProps = {
	currentUser: {},
	accessList: [],
	allFacilities: [],
};

FacilityAccessTable.propTypes = {
	currentUser: PropTypes.object,
	accessList: PropTypes.arrayOf(PropTypes.string),
	allFacilities: PropTypes.arrayOf(PropTypes.object),
	updateAccessList: PropTypes.func,
};
