import React from "react";
import styles from "../../css/shared/EnabledIndicator.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const EnabledIndicator = () => {
	return (
		<div className={styles.EnabledIndicator}>
			<svg className={styles.EnabledIndicator_icon}>
				<use xlinkHref={`${sprite}#icon-check-circle`}></use>
			</svg>
			<span className={styles.EnabledIndicator_label}>Enabled</span>
		</div>
	);
};

export default EnabledIndicator;

EnabledIndicator.defaultProps = {};

EnabledIndicator.propTypes = {};
