const saveToStorage = (key, val) => {
	return window.localStorage.setItem(key, JSON.stringify(val));
};
// fetches items (w/ key) from localStorage
// if items exist, parse and return; else return {}
const getFromStorage = (key = null) => {
	const storageValues = window.localStorage.getItem(key);
	return storageValues !== null ? JSON.parse(storageValues) : null;
};

const updateItemInStorage = (key, val) => {
	return window.localStorage.setItem(key, JSON.stringify(val));
};

const removeFromStorage = (key) => {
	return window.localStorage.removeItem(key);
};

const clearStorage = () => {
	return window.localStorage.clear();
};

export {
	saveToStorage,
	getFromStorage,
	updateItemInStorage,
	removeFromStorage,
	clearStorage,
};
