import React from "react";
import styles from "../../css/user/UserResetMethods.module.scss";
import { PropTypes } from "prop-types";
import CustomCheckbox from "../shared/CustomCheckbox";

// EMAIL
// QUESTIONS
// ADMIN

// REQUIREMENTS:
// - IF 'Reset By Email' is selected:
// 		- Email resets will be the ONLY method support, other than admin (eg. questions won't work)

const customCSS = {
	checkbox: {
		marginBottom: "1rem",
	},
};

const UserResetMethods = ({ vals = {}, handleCheckbox }) => {
	return (
		<div className={styles.UserResetMethods}>
			<CustomCheckbox
				name="isPwdResetByEmail"
				id="isPwdResetByEmail"
				label="Reset By Email"
				val={vals.isPwdResetByEmail}
				handleCheckbox={handleCheckbox}
				customStyles={customCSS.checkbox}
			/>
			<CustomCheckbox
				name="isPwdResetByQuestions"
				id="isPwdResetByQuestions"
				label="Reset By Questions"
				val={vals.isPwdResetByQuestions}
				handleCheckbox={handleCheckbox}
				customStyles={customCSS.checkbox}
			/>
			<CustomCheckbox
				name="isPwdResetByAdmin"
				id="isPwdResetByAdmin"
				label="Reset By Admin"
				val={vals.isPwdResetByAdmin}
				handleCheckbox={handleCheckbox}
				customStyles={customCSS.checkbox}
			/>
		</div>
	);
};

export default UserResetMethods;

UserResetMethods.defaultProps = {};

UserResetMethods.propTypes = {
	vals: PropTypes.object,
	handleCheckbox: PropTypes.func,
};
