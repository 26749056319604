import {
	isBase64,
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
	hasProp,
} from "./utils_types";
import { deleteKeysMany } from "./utils_processing";

/**
 * Available database names for use w/ the 'GENERIC' CRUD APIs.
 * - 'alaDB': ADVANTAGE database
 * - 'alaSystemDB': ALADVSystem database
 * - 'warehouseDB': ALAWarehouse database
 */

const alaDB = {
	"db-meta": "ADVANTAGE",
};
const alaSystemDB = {
	"db-meta": "ALADVSYSTEM",
};
const warehouseDB = {
	"db-meta": "ADVWAREHOUSE",
};

/**
 * Generic query params for 'GET' requests
 */
const defaultParams = {
	index: 0,
	rows: 1000,
};

const params = {
	user: {
		...alaDB,
		source: "ADVUSER",
	},
	facility: {
		...alaDB,
		source: "FACILITY",
	},
	residents: {
		...alaDB,
		source: "RESIDENTS",
	},
	apps: {
		...alaSystemDB,
		source: "Application",
	},
	appsByFacility: {
		...alaSystemDB,
		source: "ApplicationByFacility",
	},
	appsByUser: {
		...alaSystemDB,
		source: "ApplicationByUser",
	},
	settings: {
		...alaSystemDB,
		source: "Setting",
	},
	appSettings: {
		...alaSystemDB,
		source: "SettingApplication",
	},
	facilitySettings: {
		...alaSystemDB,
		source: "SettingFacility",
	},
	messages: {
		...alaSystemDB,
		source: "ApplicationMessage",
	},
	// GENERIC GET2'S
	genericGet: {
		resident: {
			...alaDB,
			source: "RESIDENTS",
		},
		residentDetails: {
			loa: {
				...alaDB,
				source: "LEAVE_OF_ABSENCE",
			},
			incidents: {
				...alaDB,
				source: "INCIDENT",
			},
			meds: {
				...alaDB,
				source: "MEDICATIONS",
			},
			plans: {
				...alaDB,
				source: "PLAN",
			},
			planDetails: {
				...alaDB,
				source: "PLAN_DETAILS",
			},
			summary: {
				...alaDB,
				source: "SUMMARY",
			},
			weights: {
				...alaDB,
				source: "RESIDENT_WEIGHT",
			},
			vitals: {
				...alaDB,
				source: "PLAN",
			},
		},
		facility: {
			...alaDB,
			source: "FACILITY",
		},
		facilityWH: {
			...warehouseDB,
			source: "FACILITY",
		},
		application: {
			...alaSystemDB,
			source: "Application",
		},
	},
};

// ACCESSING-PARAM(S) UTILS //

/**
 * Checks if a URL has query params.
 */
const hasParams = () => {
	return !isEmptyVal(window.location.search);
};

/**
 * @description - Extracts a target list of query params from a url and decodes them, if needed. Supports (base64 decoding)
 * @param {URL} url - Complete URL string including query params.
 * @param {Array} list - An array of strings; each representing a param variable to be extracted from a URL.
 * @example
 * - extractParams(urlWithParams, ['token', 'facilityID', 'residentID'])
 */
const extractParams = (url, list = []) => {
	if (isEmptyArray(list)) return {};
	const urlParams = new URL(url).searchParams;

	return list.reduce((params, key) => {
		if (!params[key]) {
			params[key] = isBase64(urlParams.get(key))
				? atob(urlParams.get(key))
				: urlParams.get(key);
			return params;
		}
		return params;
	}, {});
};

/**
 * Iterates thru param keys & extracts the raw param value into an object. DOES NOT HANDLE DECODING!!!
 * @param {URL} url - A URL query string w/ encoded params added.
 * @param {Array} list - An array of param keys to iterate thru.
 * @returns {Object} - Returns an object w/ each param key as a value.
 */
const extractRawParams = (url, list = []) => {
	if (isEmptyArray(list)) return {};
	const urlParams = new URL(url).searchParams;

	return list.reduce((params, key) => {
		if (!params[key]) {
			params[key] = urlParams.get(key);

			return params;
		}
		return params;
	}, {});
};

/**
 * Extracts ALL query params in the current URL and formats them into an object w/ key/value pairs.
 * @param {URL} url - A url such as window.location or history.location(if using React).
 * @returns {Object} - Returns an object with ALL query params
 */
const extractAllParams = (url) => {
	const urlParams = new URL(url)?.searchParams;
	const searchParams = new URLSearchParams(urlParams);
	const entries = searchParams.entries();
	const params = {};
	// uses Iterator to access 'key' & 'value's
	for (const [key, value] of entries) {
		params[key] = value;
	}
	return params;
};

/**
 * @description - Accepts a domain url & an object of params and encodes the params & merges into the url.
 * @param {URL} baseURL - A string url, that's used as the "base".
 * @param {Object} params - An object of custom URL query params via { key: "name" } pairs.
 * @returns {String} - Returns the encoded url string w/ domain, query string and query params attached.
 * @example
 * const urlWithParams = generateURL('https://example.com', {
 * 	token: btoa('some-token'),
 * 	facilityID: btoa('some facility-id')
 * })
 */
const generateURL = (baseURL, params = {}) => {
	let url = baseURL;
	url += "?" + new URLSearchParams({ ...params });

	return url;
};

/**
 * Retrieves the 'document.referrer' and removes any trailing slashes. (ie. where this user was redirected from)
 * @returns {String|URL} - Retrieves the 'document.referrer' url string & removes any trailing slashes (eg. '/')
 */
const getReferrerUrl = () => {
	const trailingSlash = /(\/)$/gm; // matches '/' at end of url
	const referrerUrl = document.referrer;
	const cleanedUrl = referrerUrl.replace(trailingSlash, ""); // removes '/' at end of url
	return cleanedUrl;
};
/**
 * Retrieves the current window's url origin (ie. 'http://localhost:1234' or 'https://portaltest.aladvantage.com')
 * @returns {String|URL} - Extracts & retrieves the current URL's protocol & host & merges them into a valid URL.
 */
const getCurrentOrigin = () => {
	const { protocol, host } = window.location;
	const currentOrigin = `${protocol}//${host}`;

	return currentOrigin;
};

/**
 * Determines whether referrer and current location are same origin.
 * @returns {Boolean} - Returns whether the 'referred url' and the 'current url' are the same origin.
 */
const isSameOrigin = () => {
	const currentOrigin = getCurrentOrigin();

	// referred location (if applicable)
	const referredOrigin = getReferrerUrl();

	return currentOrigin === referredOrigin;
};

// processes, decodes & returns query params form hot-link redirect url
const processRedirectURLParams = (url) => {
	const params = extractRawParams(url, [
		"activeTab",
		"residentID",
		"token",
		"facilityID",
	]);

	return {
		activeTab: params?.activeTab, // don't decode, as it's plaintext
		residentID: atob(params?.residentID),
		facilityID: atob(params?.facilityID),
		token: atob(params?.token),
	};
};

// QUERY PARAM(S) UTILS //

/**
 * Forms and maps a set of key/value pairs to a url string, then opens formed URL in a NEW tab.
 * @param {URL} url - Ready-to-use and formulated URL string
 * @returns {NewTab}
 */
const openInNewTab = (url) => {
	window.open(url);
};
/**
 * Forms and maps a set of key/value pairs to a url string, then opens formed URL in a SAME tab.
 * @param {URL} url - Ready-to-use and formulated URL string
 * @returns {SameTab}
 */
const openInSameTab = (url) => {
	window.open(url, "_self");
};

/**
 * Constructs a URL string w/ optional query params, then opens the URL in a NEW tab.
 * @param {URL} url - A raw un-constructed URL string
 * @param {Object} params - An object of key/value pairs to map as query params in a URL string.
 */
const generateUrlAndOpenInNewTab = (url, params) => {
	const newUrl = generateURL(url, params);
	window.open(newUrl);
};
/**
 * Constructs a URL string w/ optional query params, then opens the URL in a SAME tab.
 * @param {URL} url - A raw un-constructed URL string
 * @param {Object} params - An object of key/value pairs to map as query params in a URL string.
 */
const generateUrlAndOpenInSameTab = (url, params) => {
	const newUrl = generateURL(url, params);
	window.open(newUrl, "_self");
};

/**
 * Accepts the 'window.location' and reconstructs a fresh URL instance via 'new URL()'
 * @param {URL} url - The current URL, typically 'window.location'.
 */
const constructURL = (url = window?.location) => {
	const { protocol, host, pathname } = new URL(url);
	let newURL = `${protocol}//${host}${pathname}`;
	return newURL;
};

/**
 * Adds a single query param to the current URL, without page refresh.
 * @param {URL} url - The current URL, typically 'window.location'
 * @param {Any} key - The 'key' in the key/value pair
 * @param {Any} val - The value in the key/value pair.
 */
const setParam = (url, key, val) => {
	const searchParams = new URLSearchParams(url);
	if (hasProp(searchParams, key)) {
		searchParams.set(key, val);
		let newURL = constructURL(url);
		newURL += "?" + new URLSearchParams(searchParams);
		return window.history.pushState({ ...searchParams }, "", newURL);
	} else {
		searchParams.set(key, val);
		let newURL = constructURL(url);
		newURL += "?" + new URLSearchParams(searchParams);
		return window.history.pushState({ ...searchParams }, "", newURL);
	}
};

/**
 * Adds/appends one or more query params to the current URL, without page refresh.
 * @param {URL} url - The current URL, typically 'window.location'
 * @param {Object} params - An object of key/value pairs to transform into query params in the URL.
 */
const setParamsMany = (url, params = {}) => {
	if (!hasParams(url.search)) {
		url += "?" + new URLSearchParams({ ...params });
		return window.history.pushState({ ...params }, "", url);
	} else {
		// if params currently exist; extract them & merge w/ new params
		const allParams = {
			...extractAllParams(url),
			...params,
		};

		let newURL = constructURL(url);
		newURL += "?" + new URLSearchParams({ ...allParams });
		return window.history.pushState({ ...allParams }, "", newURL);
	}
};

/**
 * Removes one or more query params by their key.
 * @param {URL} url - The current URL, typically 'window.location'
 * @param {Array} keysToRemove - An array of param keys to be removed.
 */
const removeParamsMany = (url, keysToRemove = []) => {
	const allParams = extractAllParams(url);

	// iterate thru 'keysToRemove' & delete from params obj
	const updatedParams = deleteKeysMany(allParams, keysToRemove);

	// construct new url & add updated params
	let newURL = constructURL(url);
	// if params remain, then add them
	if (!isEmptyObj(updatedParams)) {
		newURL += "?" + new URLSearchParams(updatedParams);
	}
	// push to state
	return window.history.pushState({ ...updatedParams }, "", newURL);
};

/**
 * Clears ALL query params currently in a URL.
 * - Removes the params and replaces the url, without refreshing page.
 */
const clearAllParams = (url = window.location) => {
	const newURL = constructURL(url);

	return window.history.pushState({ path: newURL }, "", newURL);
};

// params destructure
const { genericGet } = params;

// database names
export { alaDB, alaSystemDB, warehouseDB };

// query params for generic APIs
export { params, defaultParams, genericGet };

// params utils
export {
	hasParams,
	extractParams,
	extractRawParams,
	extractAllParams,
	processRedirectURLParams,
	constructURL,
	generateURL,
	// url tab/openers
	openInNewTab,
	openInSameTab,
	generateUrlAndOpenInNewTab,
	generateUrlAndOpenInSameTab,
	// referrer/redirect utils
	getReferrerUrl,
	getCurrentOrigin,
	isSameOrigin,
};

// query param updaters
export { setParam, setParamsMany, removeParamsMany, clearAllParams };
