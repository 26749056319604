import React from "react";
import styles from "../../css/ui/LoadingMsg.module.scss";
import { PropTypes } from "prop-types";
import Spinner from "../shared/Spinner";

const LoadingMsg = ({ children }) => {
	return (
		<div className={styles.LoadingMsg}>
			<div className={styles.LoadingMsg_loader}>
				<Spinner />
			</div>
			<div className={styles.LoadingMsg_msg}>{children}</div>
		</div>
	);
};

export default LoadingMsg;

LoadingMsg.defaultProps = {};

LoadingMsg.propTypes = {
	msg: PropTypes.string,
};
