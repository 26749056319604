import React from "react";
import styles from "../../css/loctemplate/LOCTemplateTableColumns.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import LOCTemplateTableHeading from "./LOCTemplateTableHeading";

const LOCTemplateTableColumns = ({ cols = [], handleColumnSort }) => {
	return (
		<header className={styles.LOCTemplateTableColumns}>
			<div className={styles.LOCTemplateTableColumns_inner}>
				{!isEmptyArray(cols) &&
					cols.map((col, colIdx) => (
						<LOCTemplateTableHeading
							key={`Column:${col}-${colIdx}`}
							column={col}
							columnIdx={colIdx}
							handleColumn={() => handleColumnSort(col)}
						/>
					))}
			</div>
		</header>
	);
};

export default LOCTemplateTableColumns;

LOCTemplateTableColumns.defaultProps = {};

LOCTemplateTableColumns.propTypes = {};
