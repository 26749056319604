import React, { useState } from "react";
import styles from "../../css/residents/CustomResidentSearch.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { blueGrey, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { processUserList } from "../../helpers/utils_user";
import { formatAndSortUserFacilities } from "../../helpers/utils_facility";
import {
	processResidentsList,
	residentUnitTypes,
	searchForResidentBy,
} from "../../helpers/utils_residents";
import { UNIT_TYPES } from "../../helpers/utils_options";
// components
import FormSection from "../forms/FormSection";
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";
import CustomResidentSearchResults from "./CustomResidentSearchResults";
import TextInput from "../shared/TextInput";
import NumberInput from "../shared/NumberInput";
import CustomDropdown from "../shared/CustomDropdown";
import ModalLG from "../shared/ModalLG";
import ResidentSearchDetails from "./ResidentSearchDetails";

// REQUIREMENTS:
// - Resident search in the "Search" tab of the "AdminPage"
// - Supports searching by:
// 		- Age
// 		- First and/or Last name
// 		- Full name (eg. first & last)
// 		- Resident ID
// 		- Unit type (eg. 'Assisted Living', 'Memory Care', 'Personal Care', 'Independent')
// 		- Room number
// 		- Archive status

const customCSS = {
	age: {
		backgroundColor: "#ffffff",
	},
	inputs: {
		backgroundColor: "#ffffff",
	},
	first: {
		width: "25rem",
		backgroundColor: "#ffffff",
	},
	last: {
		width: "25rem",
		backgroundColor: "#ffffff",
	},
	unitTypes: {
		width: "40rem",
	},

	checkbox: {
		marginBottom: ".6rem",
	},
	searchBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
	},
	cancelBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "1rem",
	},
	clearBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "auto",
	},
	dropdown: {
		width: "35rem",
	},
	unitType: {
		width: "35rem",
	},
};

const noTypeSelected = (vals = {}) => {
	const {
		// bools
		byAge,
		byResidentFirstName,
		byResidentLastName,
		byFullName,
		byResidentID,
		byFacility,
		byUnitType,
		byRoomNum,
		byArchive,
		// fields
		residentFirstName,
		residentLastName,
		residentAgeSearch,
		residentRoomSearch,
		firstNameSearch,
		lastNameSearch,
		facilitySearch,
		residentIDSearch,
		// archive search fields
		archiveResidentID,
		archiveResidentFirstName,
		archiveResidentLastName,
		archiveResidentRoomSearch,
	} = vals;
	// no search type or search value supplied
	const hasNoType =
		!byAge &&
		!byResidentFirstName &&
		!byResidentLastName &&
		!byFullName &&
		!byFacility &&
		!byResidentID &&
		!byRoomNum &&
		!byUnitType &&
		!byArchive;
	const hasNoSearch =
		isEmptyVal(residentAgeSearch) &&
		isEmptyVal(firstNameSearch) &&
		isEmptyVal(lastNameSearch) &&
		isEmptyVal(residentFirstName) &&
		isEmptyVal(residentLastName) &&
		isEmptyVal(facilitySearch) &&
		isEmptyVal(residentRoomSearch) &&
		isEmptyVal(residentIDSearch) &&
		isEmptyVal(archiveResidentID) &&
		isEmptyVal(archiveResidentFirstName) &&
		isEmptyVal(archiveResidentLastName) &&
		isEmptyVal(archiveResidentRoomSearch);

	return hasNoSearch && hasNoType;
};

const ResidentSearchUI = ({
	vals,
	handleChange,
	handleCheckbox,
	handleSettings,
	allFacilities = [],
}) => {
	const renderSearch = (vals) => {
		const {
			// bools
			byAge,
			byResidentFirstName,
			byResidentLastName,
			byFullName,
			byResidentID,
			byFacility,
			byUnitType,
			byRoomNum,
			byArchive,
			byYardiNumber,
		} = vals;

		switch (true) {
			case byAge: {
				return (
					<NumberInput
						val={vals?.residentAgeSearch}
						label="Enter resident age"
						name="residentAgeSearch"
						id="residentAgeSearch"
						handleChange={handleChange}
						customStyles={customCSS.age}
					/>
				);
			}
			case byResidentFirstName: {
				return (
					<TextInput
						val={vals?.firstNameSearch}
						label="Enter resident first name"
						name="firstNameSearch"
						id="firstNameSearch"
						handleChange={handleChange}
						customStyles={customCSS.inputs}
					/>
				);
			}
			case byResidentLastName: {
				return (
					<TextInput
						val={vals?.lastNameSearch}
						label="Enter resident last name"
						name="lastNameSearch"
						id="lastNameSearch"
						handleChange={handleChange}
						customStyles={customCSS.inputs}
					/>
				);
			}
			case byFullName: {
				return (
					<div className={styles.ResidentSearchUI_fullName}>
						<div className={styles.ResidentSearchUI_fullName_first}>
							<TextInput
								val={vals?.residentFirstName}
								label="First Name"
								name="residentFirstName"
								id="residentFirstName"
								handleChange={handleChange}
								customStyles={customCSS.first}
							/>
						</div>
						<div className={styles.ResidentSearchUI_fullName_last}>
							<TextInput
								val={vals?.residentLastName}
								label="Last Name"
								name="residentLastName"
								id="residentLastName"
								handleChange={handleChange}
								customStyles={customCSS.last}
							/>
						</div>
					</div>
				);
			}
			case byResidentID: {
				return (
					<TextInput
						val={vals?.residentIDSearch}
						label="Enter resident ID"
						name="residentIDSearch"
						id="residentIDSearch"
						handleChange={handleChange}
						customStyles={customCSS.inputs}
					/>
				);
			}
			case byUnitType: {
				return (
					<CustomDropdown
						name="residentUnitSearch"
						id="residentUnitSearch"
						label="Select unit type"
						options={[...UNIT_TYPES]}
						selection={vals.residentUnitSearch}
						setSelection={handleSettings}
						customStyles={customCSS.unitTypes}
					/>
				);
			}
			case byRoomNum: {
				return (
					<TextInput
						val={vals?.residentRoomSearch}
						label="Enter room number"
						name="residentRoomSearch"
						id="residentRoomSearch"
						handleChange={handleChange}
						customStyles={customCSS.inputs}
					/>
				);
			}
			case byArchive: {
				return (
					<div className={styles.ResidentSearchUI_archive}>
						<div className={styles.ResidentSearchUI_archive_checkbox}>
							<CustomCheckbox
								label="Is Archived?"
								name="isArchived"
								id="isArchived"
								val={vals?.isArchived}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
						</div>
						<div className={styles.ResidentSearchUI_archive_input}>
							<TextInput
								val={vals?.archiveResidentID}
								label="Enter resident ID (optional)"
								name="archiveResidentID"
								id="archiveResidentID"
								handleChange={handleChange}
								customStyles={customCSS.inputs}
							/>
						</div>
						<div className={styles.ResidentSearchUI_archive_input}>
							<TextInput
								val={vals?.archiveResidentFirstName}
								label="Resident first name (optional)"
								name="archiveResidentFirstName"
								id="archiveResidentFirstName"
								handleChange={handleChange}
								customStyles={customCSS.inputs}
							/>
						</div>
						<div className={styles.ResidentSearchUI_archive_input}>
							<TextInput
								val={vals?.archiveResidentLastName}
								label="Resident last name (optional)"
								name="archiveResidentLastName"
								id="archiveResidentLastName"
								handleChange={handleChange}
								customStyles={customCSS.inputs}
							/>
						</div>
						<div className={styles.ResidentSearchUI_archive_input}>
							<TextInput
								val={vals?.residentRoomSearch}
								label="Resident's room number (optional)"
								name="archiveResidentRoomSearch"
								id="archiveResidentRoomSearch"
								handleChange={handleChange}
								customStyles={customCSS.inputs}
							/>
						</div>
					</div>
				);
			}
			case byYardiNumber: {
				return (
					<TextInput
						val={vals?.yardiNumberSearch}
						label="Enter Yardi Number"
						name="yardiNumberSearch"
						id="yardiNumberSearch"
						handleChange={handleChange}
						customStyles={customCSS.inputs}
					/>
				);
			}
			default:
				return;
		}
	};

	return <div className={styles.ResidentSearchUI}>{renderSearch(vals)}</div>;
};

const CustomResidentSearch = ({
	currentUser,
	currentFacility,
	globalState = {},
	facilities = [],
	parentsMap = {},
}) => {
	// const { facilities, parentsMap } = globalState;
	const { allFacilities } = currentUser;
	const { formState, setFormState, handleCheckbox, handleChange, handleReset } =
		useForm({
			// by resident field
			byAge: false,
			byResidentFirstName: false,
			byResidentLastName: false,
			byFullName: false,
			byResidentID: false,
			byUnitType: false,
			byFacility: false,
			byRoomNum: false,
			byArchive: false,
			byYardiNumber: false,
			// searches
			residentAgeSearch: "",
			firstNameSearch: "",
			lastNameSearch: "",
			// full-name search
			residentFirstName: "",
			residentLastName: "",
			residentIDSearch: "",
			residentUnitSearch: "",
			isArchived: true,
			// other searches
			residentYardiNumber: "",
			// archive search fields
			archiveResidentID: "",
			archiveResidentFirstName: "",
			archiveResidentLastName: "",
			archiveResidentRoomSearch: "",
			// yardi search
			yardiNumberSearch: "",
		});
	const { values } = formState;
	const [searchResults, setSearchResults] = useState([]);
	const [isLoadingResults, setIsLoadingResults] = useState(false);
	const [showResidentDetails, setShowResidentDetails] = useState(false);
	const [selectedResident, setSelectedResident] = useState(null);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// fires off request w/ search values
	const runSearch = async (e) => {
		const { token } = currentUser;
		setIsLoadingResults(true);
		const results = await searchForResidentBy(token, allFacilities, values);

		if (results) {
			setIsLoadingResults(false);
			return setSearchResults(results);
		} else {
			return setIsLoadingResults(false);
		}
	};
	// clear form & close search window
	const cancelSearch = (e) => {
		handleReset(e);
	};
	// clear our form fields
	const clearSearch = (e) => {
		handleReset(e);
		setSearchResults([]);
	};

	// selects search result & opens user in 'UserDetails' modal
	const viewCustomSearchResident = (resident) => {
		setShowResidentDetails(true);
		setSelectedResident(resident);
	};

	return (
		<>
			<div className={styles.CustomResidentSearch}>
				<section className={styles.CustomResidentSearch_main}>
					<FormSection
						title="Search By:"
						icon="checkbox"
						hoverText="Search by a resident-related value."
					>
						<CustomCheckbox
							isDisabled={
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byFacility ||
								values?.byRoomNum ||
								values?.byUnitType ||
								values?.byResidentID ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By Age"
							name="byAge"
							id="byAge"
							val={values?.byAge}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byFacility ||
								values?.byRoomNum ||
								values?.byUnitType ||
								values?.byResidentID ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By First Name"
							name="byResidentFirstName"
							id="byResidentFirstName"
							val={values?.byResidentFirstName}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byFullName ||
								values?.byFacility ||
								values?.byRoomNum ||
								values?.byUnitType ||
								values?.byResidentID ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By Last Name"
							name="byResidentLastName"
							id="byResidentLastName"
							val={values?.byResidentLastName}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFacility ||
								values?.byRoomNum ||
								values?.byUnitType ||
								values?.byResidentID ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By Full Name"
							name="byFullName"
							id="byFullName"
							val={values?.byFullName}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byRoomNum ||
								values?.byFacility ||
								values?.byUnitType ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By ResidentID"
							name="byResidentID"
							id="byResidentID"
							val={values?.byResidentID}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byRoomNum ||
								values?.byResidentID ||
								values?.byUnitType ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By Facility"
							name="byFacility"
							id="byFacility"
							val={values?.byFacility}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byResidentID ||
								values?.byRoomNum ||
								values?.byFacility ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By Unit Type"
							name="byUnitType"
							id="byUnitType"
							val={values?.byUnitType}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byResidentID ||
								values?.byUnitType ||
								values?.byFacility ||
								values?.byArchive ||
								values?.byYardiNumber
							}
							label="By Room Number"
							name="byRoomNum"
							id="byRoomNum"
							val={values?.byRoomNum}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byResidentID ||
								values?.byUnitType ||
								values?.byFacility ||
								values?.byYardiNumber
							}
							label="By Archive Status"
							name="byArchive"
							id="byArchive"
							val={values?.byArchive}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byAge ||
								values?.byResidentFirstName ||
								values?.byResidentLastName ||
								values?.byFullName ||
								values?.byResidentID ||
								values?.byUnitType ||
								values?.byFacility
							}
							label="By Yardi Number"
							name="byYardiNumber"
							id="byYardiNumber"
							val={values?.byYardiNumber}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						{/* CUSTOM SEARCH INPUT RENDERER */}
						<ResidentSearchUI
							vals={values}
							handleChange={handleChange}
							handleSettings={handleSettings}
							allFacilities={[
								...formatAndSortUserFacilities(currentUser?.facilities),
							]}
						/>
					</FormSection>

					{/* ACTIONS- SEARCH/CLEAR ETC. */}
					<div className={styles.CustomResidentSearch_main_actions}>
						<ButtonSM
							customStyles={customCSS.clearBtn}
							handleClick={clearSearch}
						>
							<span>Clear Search</span>
						</ButtonSM>
						<ButtonSM
							customStyles={customCSS.cancelBtn}
							handleClick={cancelSearch}
						>
							<span>Cancel Search</span>
						</ButtonSM>
						<ButtonSM
							isDisabled={noTypeSelected(values)}
							customStyles={customCSS.searchBtn}
							handleClick={runSearch}
						>
							<svg className={styles.CustomResidentSearch_main_actions_icon}>
								<use xlinkHref={`${sprite}#icon-search2`}></use>
							</svg>
							<span>Run Search</span>
						</ButtonSM>
					</div>
				</section>
				{/* SEARCH RESULTS */}
				<section className={styles.CustomResidentSearch_results}>
					<span>
						Results: <b>{searchResults?.length ?? 0}</b> matching resident(s)
					</span>
					<CustomResidentSearchResults
						key={`RESIDENT-RESULTS-${searchResults?.length}`}
						isLoading={isLoadingResults}
						searchResults={[...processResidentsList(searchResults)]}
						viewResident={viewCustomSearchResident}
						facilities={facilities}
						parentsMap={parentsMap}
						globalState={globalState}
					/>
				</section>
			</div>

			{showResidentDetails && (
				<ModalLG
					key={`RESIDENT-SEARCH-MODAL`}
					title={`Custom Resident Search`}
					closeModal={() => setShowResidentDetails(false)}
				>
					<ResidentSearchDetails
						key={`SEARCH-RESIDENT-DETAILS-${selectedResident?.residentID}`}
						resident={selectedResident}
						currentFacility={currentFacility}
						currentUser={currentUser}
						facilities={facilities}
						parentsMap={parentsMap}
						globalState={globalState}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default CustomResidentSearch;

CustomResidentSearch.defaultProps = {};

CustomResidentSearch.propTypes = {};
