import React, { useState, useEffect } from "react";
import styles from "../../css/login/VerifyEmailController.module.scss";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router";
import { getMatchGroups } from "../../helpers/utils_validation";
// components
import EmailVerifiedSuccess from "./EmailVerifiedSuccess";
import EmailVerifiedError from "./EmailVerifiedError";
import Spinner from "../shared/Spinner";
import VerifyEmail from "../app/VerifyEmail";

// EMAIL VERIFICATION FLOW:
// - User acount w/ email is created
// - Then an email is sent to that email:
//   - Email contains:
//     - Custom link w/ OTP attached to the end as a 'pathname'
//       - Eg. OTP: 'Xe9Dch1' => 'https://portal.aladvantage.com/verify/Xe9Dch1'
// - User clicks on custom link in email:
//  - They're taken to this page:
//    - I fire off a request that toggles 'IsVerifiedEmail' to 'true'

/**
 * Extracts a verification code from the url's pathname.
 * @param {String} path - A pathname via 'window.location.pathname'
 * @returns {String} - Returns string-form verifcation code from url's pathname
 */
const getPathnameCode = (path) => {
	const reg = /(\/{1,}(?<code>(\w{1,}|\d{1,}))$)/gm;
	const matches = getMatchGroups(path, reg);

	return matches?.code;
};

const ActionButton = ({ handleAction, children }) => {
	return (
		<button
			type="button"
			onClick={handleAction}
			className={styles.ActionButton}
		>
			{children}
		</button>
	);
};

const IsVerifyingEmail = () => {
	return (
		<div className={styles.IsVerifyingEmail}>
			<div className={styles.IsVerifyingEmail_loader}>
				<Spinner />
			</div>
			<div className={styles.IsVerifyingEmail_info}>
				<div className={styles.IsVerifyingEmail_info_msg}>
					Verifying Email...please wait
				</div>
			</div>
		</div>
	);
};

const VerifyEmailController = ({ history }) => {
	const location = useLocation();
	// verification code (pathname) (eg. '..../verify/<code>')
	const [code, setCode] = useState(() => {
		return getPathnameCode(location.pathname);
	});
	const [emailVerification, setEmailVerification] = useState({
		isVerifying: true,
		isEmailConfirmed: false,
	});
	const { isEmailConfirmed, isVerifying } = emailVerification;

	const goToLogin = () => {
		history.replace("/");
	};

	const verifyCode = async () => {
		// send request to API to verify email code
		const wasVerified = false;

		if (wasVerified) {
			return setEmailVerification({
				isVerifying: false,
				isEmailConfirmed: true,
			});
		} else {
			return setEmailVerification({
				isVerifying: false,
				isEmailConfirmed: false,
			});
		}
	};

	// resends verification email w/ code
	const resendVerifyCode = async () => {
		// fetch user's loginID & fires off code
	};

	// runs 'verification' request handler 'onMount'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let timerID;
		if (isVerifying) {
			timerID = setTimeout(() => {
				verifyCode();
			}, 3000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.VerifyEmailController}>
			<div className={styles.VerifyEmailController_main}>
				{isVerifying && <IsVerifyingEmail />}
				{isEmailConfirmed && <EmailVerifiedSuccess />}
				{!isVerifying && !isEmailConfirmed && <EmailVerifiedError />}
			</div>
			<div className={styles.VerifyEmailController_alt}>
				{!isVerifying && !isEmailConfirmed && (
					<VerifyEmail sendConfirmationEmail={resendVerifyCode} />
				)}
			</div>
			<div className={styles.VerifyEmailController_actions}>
				<ActionButton handleAction={goToLogin}>Go to login</ActionButton>
			</div>
		</div>
	);
};

export default VerifyEmailController;

VerifyEmailController.defaultProps = {};

VerifyEmailController.propTypes = {
	history: PropTypes.object,
};
