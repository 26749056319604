import React, { useEffect, useCallback, useState } from "react";
import styles from "../../css/admin/UsersSummary.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
// components
import UserCardList from "./UserCardList";
import UsersSummarySearch from "./UsersSummarySearch";
import { getUserFacilityID } from "../../helpers/utils_facility";
import { getProcessedFacililtyUserInfo } from "../../helpers/utils_security";

// REQUIREMENTS:
// - User's First & Last name
// - User's username/email
// - Application Access:
// 		- Care Tracker
// 		- Care Portal

const searchUsers = (val, allUsers = []) => {
	val = val?.toLowerCase();

	return allUsers.filter((user) => {
		if (
			user?.firstName?.toLowerCase().startsWith(val) ||
			user?.lastName?.toLowerCase().startsWith(val) ||
			user?.email?.toLowerCase().startsWith(val) ||
			user?.title?.toLowerCase().startsWith(val)
		) {
			return user;
		} else {
			return null;
		}
	});
};

const filterUsersByType = (allUsers = [], vals = {}) => {
	const { onlyFacilityAdmin, onlyRegionalAdmin, onlyMedTechRestricted } = vals;
	const showAll =
		!onlyFacilityAdmin && !onlyRegionalAdmin && !onlyMedTechRestricted;

	switch (true) {
		// facility admin ONLY
		case onlyFacilityAdmin && !onlyRegionalAdmin && !onlyMedTechRestricted: {
			return allUsers.filter((user) => {
				if (!user?.isFacilityAdmin) {
					return null;
				} else {
					return user;
				}
			});
		}
		// regional admin ONLY
		case onlyRegionalAdmin && !onlyFacilityAdmin && !onlyMedTechRestricted: {
			return allUsers.filter((user) => {
				if (!user?.isRegionalAdmin) {
					return null;
				} else {
					return user;
				}
			});
		}
		case onlyMedTechRestricted && !onlyFacilityAdmin && !onlyRegionalAdmin: {
			return allUsers.filter((user) => {
				if (!user?.isMedTechRestricted) {
					return null;
				} else {
					return user;
				}
			});
		}
		case showAll:
		default:
			return allUsers;
	}
};

const UsersSummary = ({
	currentUser = {},
	currentFacility = {},
	facilityUsers = [],
}) => {
	const [searchVal, setSearchVal] = useState("");
	const [allUsers, setAllUsers] = useState([...facilityUsers]);
	const [isLoading, setIsLoading] = useState(false);
	const [accessLists, setAccessLists] = useState({
		trackerAccess: [],
		legacyAccess: [],
	});
	const { trackerAccess, legacyAccess } = accessLists;

	const { formState, handleCheckbox } = useForm({
		onlyFacilityAdmin: false,
		onlyRegionalAdmin: false,
		onlyMedTechRestricted: false,
	});
	const { values } = formState;

	// fetches app access, (advusers as well, but this is discarded)
	const fetchUserData = useCallback(async () => {
		setIsLoading(true);
		const { facilityID } = currentFacility;
		const { token } = currentUser;

		// fire off request for users & access records
		const data = await getProcessedFacililtyUserInfo(token, facilityID);
		const { appAccess } = data;

		if (!isEmptyObj(data)) {
			setIsLoading(false);
			return setAccessLists({
				trackerAccess: appAccess?.trackerAccess?.userIDs,
				legacyAccess: appAccess?.legacyAccess?.userIDs,
			});
		} else {
			return setIsLoading(false);
		}
	}, [currentFacility, currentUser]);

	// fetches users on mount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(currentFacility?.facilityID)) {
			fetchUserData();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility?.facilityID, fetchUserData]);

	const handleSearch = (e) => {
		const { value } = e.target;
		if (isEmptyVal(value)) {
			setSearchVal(value);
			return setAllUsers([...facilityUsers]);
		} else {
			setSearchVal(value);
			return setAllUsers([...searchUsers(value, allUsers)]);
		}
	};

	const handleMoreOptions = (e) => {
		const { name, checked } = e.target;
		handleCheckbox(e);
		setAllUsers([
			...filterUsersByType(facilityUsers, {
				...values,
				[name]: checked,
			}),
		]);
	};

	return (
		<div className={styles.UsersSummary}>
			<div className={styles.UsersSummary_count}>
				<div className={styles.UsersSummary_count_text}>
					Viewing <b>{allUsers?.length}</b> of <b>{facilityUsers?.length}</b>
				</div>
			</div>
			<UsersSummarySearch
				searchVal={searchVal}
				handleSearch={handleSearch}
				handleCheckbox={handleMoreOptions}
				filterVals={values}
			/>

			<UserCardList
				key={currentFacility?.facilityID + allUsers?.length}
				currentFacility={currentFacility}
				currentUser={currentUser}
				facilityUsers={allUsers}
				trackerAccess={trackerAccess}
				legacyAccess={legacyAccess}
			/>
		</div>
	);
};

export default UsersSummary;

UsersSummary.defaultProps = {};

UsersSummary.propTypes = {};
