import React, { useState } from "react";
import styles from "../../css/admin/UserBadge.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { main, flat } from "../../helpers/utils_styles";
import {
	getUserTypeColor,
	getUserTypeLabel,
} from "../../helpers/utils_userTypes";

const SIZES = {
	XSM: {
		width: "3.5rem",
		height: "3.5rem",
		minWidth: "3.5rem",
		maxWidth: "3.5rem",
		minHeight: "3.5rem",
		maxHeight: "3.5rem",
		fontSize: "1.3rem",
		fontWeight: "600",
	},
	SM: {
		width: "4rem",
		height: "4rem",
		minWidth: "4rem",
		maxWidth: "4rem",
		minHeight: "4rem",
		maxHeight: "4rem",
		fontSize: "1.5rem",
		fontWeight: "600",
	},
	MD: {
		width: "5rem",
		height: "5rem",
		minWidth: "5rem",
		maxWidth: "5rem",
		minHeight: "5rem",
		maxHeight: "5rem",
		fontSize: "1.7rem",
		fontWeight: "600",
	},
	LG: {
		width: "7rem",
		height: "7rem",
		minWidth: "7rem",
		maxWidth: "7rem",
		minHeight: "7rem",
		maxHeight: "7rem",
		fontSize: "2rem",
		fontWeight: "600",
	},
	XLG: {
		width: "10rem",
		height: "10rem",
		minWidth: "10rem",
		maxWidth: "10rem",
		minHeight: "10rem",
		maxHeight: "10rem",
		fontSize: "3.2rem",
		fontWeight: "600",
	},
};

const COLORS = {
	purple: {
		backgroundColor: main.main,
		color: "#ffffff",
	},
	lightpurple: {
		backgroundColor: flat.main,
		color: "#ffffff",
	},
	red: {
		backgroundColor: main.red,
		color: "#ffffff",
	},
	lightred: {
		backgroundColor: flat.red,
		color: "#ffffff",
	},
	blue: {
		backgroundColor: main.blue,
		color: "#ffffff",
	},
	lightblue: {
		backgroundColor: flat.blue,
		color: "#ffffff",
	},
	green: {
		backgroundColor: main.green,
		color: "#ffffff",
	},
	lightgreen: {
		backgroundColor: flat.green,
		color: "#ffffff",
	},
	orange: {
		backgroundColor: main.orange,
		color: "#ffffff",
	},
	lightorange: {
		backgroundColor: flat.orange,
		color: "#ffffff",
	},
	violet: {
		backgroundColor: main.violet,
		color: "#ffffff",
	},
	lightviolet: {
		backgroundColor: flat.violet,
		color: "#ffffff",
	},
	teal: {
		backgroundColor: main.teal,
		color: "#ffffff",
	},
	lightteal: {
		backgroundColor: flat.teal,
		color: "#ffffff",
	},
	neongreen: {
		backgroundColor: main.neonGreen,
		color: "#ffffff",
	},
	lightneongreen: {
		backgroundColor: flat.neonGreen,
		color: "#ffffff",
	},
};

const getUserInitials = (user) => {
	const firstName = user?.firstName ?? user?.strFirstName;
	const lastName = user?.lastName ?? user?.strLastName;

	if (isEmptyVal(firstName) || isEmptyVal(lastName)) {
		return `NA`;
	} else {
		const first = firstName.slice(0, 1);
		const last = lastName.slice(0, 1);
		return `${first}${last}`;
	}
};
const getUserName = (user) => {
	const firstName = user?.firstName ?? user?.strFirstName;
	const lastName = user?.lastName ?? user?.strLastName;

	if (isEmptyVal(firstName) && isEmptyVal(lastName)) {
		return `Unknown`;
	} else {
		return `${firstName} ${lastName}`;
	}
};
const getUserTypeCss = (user) => {
	const userType = getUserTypeLabel(user);
	const userTypeColor = getUserTypeColor(userType);
	return {
		backgroundColor: userTypeColor,
	};
};

const UserTypePill = ({ user = {} }) => {
	const label = getUserTypeLabel(user);
	const css = getUserTypeCss(user);

	return (
		<div className={styles.UserTypePill} style={css}>
			<span className={styles.UserTypePill_label}>{label}</span>
		</div>
	);
};

const UserToolTip = ({ user = {} }) => {
	return (
		<div className={styles.UserToolTip}>
			<div className={styles.UserToolTip_userName}>{getUserName(user)}</div>
		</div>
	);
};

const UserBadge = ({ user = {}, size = "SM", color = "purple", children }) => {
	const [showName, setShowName] = useState(false);

	const custom = {
		...SIZES[size],
		...COLORS[color],
	};

	return (
		<div
			className={styles.UserBadge}
			style={{ ...custom, ...getUserTypeCss(user) }}
			onMouseOver={() => setShowName(true)}
			onMouseOut={() => setShowName(false)}
		>
			<div className={styles.UserBadge_initials}>{getUserInitials(user)}</div>
			{showName && <UserToolTip user={user} />}
			{children}
		</div>
	);
};

export default UserBadge;
export { UserToolTip, UserTypePill };

UserBadge.defaultProps = {
	user: {},
	size: "SM",
	color: "purple",
};

UserBadge.propTypes = {
	user: PropTypes.object.isRequired,
	size: PropTypes.string,
	color: PropTypes.string,
};

UserToolTip.defaultProps = {
	user: {},
};
UserToolTip.propTypes = {
	user: PropTypes.object.isRequired,
};
