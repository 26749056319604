import React from "react";
import styles from "../../css/user/CreateUserStep5.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";

// REQUIREMENTS:
// - Application Access:
// 		- Enable access to various ALA Apps
// - Permissions:
// 		- THIS IS A PHASE 2 FEATURE
// 		- Enable/manage a new user's permissions:
// 			- Pages they can view
// 			- Enable/disable deletions
// 			- Report creation
// 			- Task creation
// 			- Etc.

const Enabled = () => {
	return (
		<div className={styles.Enabled}>
			<svg className={styles.Enabled_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle_outline`}></use>
			</svg>
			<div className={styles.Enabled_label}>Enabled</div>
		</div>
	);
};
const Disabled = () => {
	return (
		<div className={styles.Disabled}>
			<svg className={styles.Disabled_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose1`}></use>
			</svg>
			<div className={styles.Disabled_label}>Disabled</div>
		</div>
	);
};

const CreateUserStep5 = ({
	vals = {},
	handleCheckbox,
	isTrackerLocked = true,
}) => {
	const custom = {
		opacity: isTrackerLocked ? ".4" : "1",
	};
	return (
		<div className={styles.CreateUserStep5}>
			<div className={styles.CreateUserStep5_access}>
				<div className={styles.CreateUserStep5_access_title} style={custom}>
					Care Tracker App {isTrackerLocked && <b>(Locked)</b>}
				</div>
				<div className={styles.CreateUserStep5_access_switch}>
					<ToggleSwitch
						size="MD"
						name="hasTrackerAccess"
						id="hasTrackerAccess"
						val={vals?.hasTrackerAccess}
						handleToggle={handleCheckbox}
						isDisabled={isTrackerLocked}
					/>
					<div className={styles.CreateUserStep5_access_switch_state}>
						{vals?.hasTrackerAccess && <Enabled />}
						{!vals?.hasTrackerAccess && <Disabled />}
					</div>
				</div>
			</div>
			<div className={styles.CreateUserStep5_access}>
				<div className={styles.CreateUserStep5_access_title}>
					Senior Care (EHR) App
				</div>
				<div className={styles.CreateUserStep5_access_switch}>
					<ToggleSwitch
						size="MD"
						name="hasLegacyAccess"
						id="hasLegacyAccess"
						val={vals?.hasLegacyAccess}
						handleToggle={handleCheckbox}
						isDisabled={false}
					/>
					<div className={styles.CreateUserStep5_access_switch_state}>
						{vals?.hasLegacyAccess && <Enabled />}
						{!vals?.hasLegacyAccess && <Disabled />}
					</div>
				</div>
			</div>
			<div className={styles.CreateUserStep5_access}>
				<div className={styles.CreateUserStep5_access_title}>
					Care Portal (SSO) App
				</div>
				<div className={styles.CreateUserStep5_access_switch}>
					<ToggleSwitch
						size="MD"
						name="hasPortalAccess"
						id="hasPortalAccess"
						val={vals?.hasPortalAccess}
						handleToggle={handleCheckbox}
						isDisabled={false}
					/>
					<div className={styles.CreateUserStep5_access_switch_state}>
						{vals?.hasPortalAccess && <Enabled />}
						{!vals?.hasPortalAccess && <Disabled />}
					</div>
				</div>
			</div>

			{/* PERMISSIONS: PAGE ACCESS, CREATE-TASK, DELETIONS etc */}
			<div className={styles.CreateUserStep5_permissions}>
				{/*  */}
				{/*  */}
				{/*  */}
			</div>
		</div>
	);
};

export default CreateUserStep5;

CreateUserStep5.defaultProps = {
	vals: {},
};

CreateUserStep5.propTypes = {
	vals: PropTypes.object,
	handleCheckbox: PropTypes.func,
};
