import React from "react";
import styles from "../../css/summary/PasswordResetSummary.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { green, red } from "../../helpers/utils_styles";
// components
import InfoIcon from "../shared/InfoIcon";

const customCSS = {
	info: {
		marginTop: "-1rem",
	},
};

const enabled = {
	color: green[500],
};
const disabled = {
	color: red[600],
};

const ResetMethod = ({ label, isEnabled = false }) => {
	const on = "check-circle";
	const off = "clearclose1";

	return (
		<div className={styles.ResetMethod}>
			{/* RESET BY XXX */}
			<span className={styles.ResetMethod_name}>{label}</span>
			{/* ICON & ENABLED|DISABLED */}
			<div className={styles.ResetMethod_status}>
				{isEnabled && (
					<svg className={styles.ResetMethod_status_iconOn}>
						<use xlinkHref={`${sprite}#icon-${on}`}></use>
					</svg>
				)}
				{!isEnabled && (
					<svg className={styles.ResetMethod_status_iconOff}>
						<use xlinkHref={`${sprite}#icon-${off}`}></use>
					</svg>
				)}
				<span
					className={styles.ResetMethod_status_label}
					style={isEnabled ? enabled : disabled}
				>
					{isEnabled ? "Enabled" : "Disabled"}
				</span>
			</div>
		</div>
	);
};

const PasswordResetSummary = ({ vals = {} }) => {
	const { isPwdResetByAdmin, isPwdResetByEmail, isPwdResetByQuestions } = vals;

	return (
		<div className={styles.PasswordResetSummary}>
			<div className={styles.PasswordResetSummary_main}>
				<div className={styles.PasswordResetSummary_main_methods}>
					<div className={styles.PasswordResetSummary_main_methods_type}>
						<InfoIcon
							size="XSM"
							hoverText="Admins can reset your password."
							customStyles={customCSS.info}
						/>
						<ResetMethod
							key={`RESET-BY-ADMIN`}
							label="Reset By Admin"
							isEnabled={isPwdResetByAdmin}
						/>
					</div>
					<div className={styles.PasswordResetSummary_main_methods_type}>
						<InfoIcon
							size="XSM"
							hoverText="Password can be reset by email."
							customStyles={customCSS.info}
						/>
						<ResetMethod
							key={`RESET-BY-EMAIL`}
							label="Reset By Email"
							isEnabled={isPwdResetByEmail}
						/>
					</div>
					<div className={styles.PasswordResetSummary_main_methods_type}>
						<InfoIcon
							size="XSM"
							hoverText="Password can be reset by security questions."
							customStyles={customCSS.info}
						/>
						<ResetMethod
							key={`RESET-BY-QUESTIONS`}
							label="Reset By Security Questions"
							isEnabled={isPwdResetByQuestions}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordResetSummary;

PasswordResetSummary.defaultProps = {};

PasswordResetSummary.propTypes = {
	vals: PropTypes.object,
};
