import React, { useState } from "react";
import styles from "../../css/user/CreateUserSummary.module.scss";
import sprite from "../../assets/icons/showhide.svg";
import { PropTypes } from "prop-types";
import { blue, red } from "../../helpers/utils_styles";
import { range } from "../../helpers/utils_processing";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import ButtonSM from "../shared/ButtonSM";
import UserAppSummary from "./UserAppSummary";
import Divider from "../forms/Divider";
import UserFacilitySummary from "./UserFacilitySummary";
import UserSecuritySummary from "./UserSecuritySummary";
import TempPassword from "../app/TempPassword";

// REQUIREMENTS:
// - Summary of recently selected user-settings
// - Displays a new user's details prior to creating the user
// - Add 'Headings' for info types:
//    - 'Login Info'
//    - 'About User'
//    - 'App Access'
// - Summary:
//    - First & Last names
//    - Username & Email(if applicable)
//    - Password (hidden)
//    - Job Title
//    - User-Type???
//    - Security Questions
//    - Application Access

const customCSS = {
	cancelBtn: {
		padding: ".6rem 1.2rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.5rem",
		fontWeight: "600",
		marginRight: ".5rem",
	},
	createBtn: {
		padding: ".6rem 1.2rem",
		backgroundColor: blue[600],
		color: "#ffffff",
		fontSize: "1.5rem",
		fontWeight: "600",
	},
	divider: {
		opacity: ".2",
	},
};

const getNewUsersName = (vals = {}) => {
	const { firstName, lastName } = vals;
	if (isEmptyVal(firstName) && isEmptyVal(lastName)) return `Not Provided`;
	return `${firstName} ${lastName}`;
};
const getNewUsersTitle = (vals = {}) => {
	const { jobTitle } = vals;
	if (isEmptyVal(jobTitle)) return `Not Provided`;
	return jobTitle;
};
const getNewUsersLoginName = (vals = {}) => {
	const { username, email } = vals;
	if (isEmptyVal(username) && isEmptyVal(email)) {
		return `Not Provided`;
	} else if (isEmptyVal(email)) {
		return username;
	} else {
		return email;
	}
};
const getNewUserPhone = (vals = {}) => {
	const { phoneNumber } = vals;
	if (isEmptyVal(phoneNumber)) {
		return `No phone number provided.`;
	} else {
		return phoneNumber;
	}
};

const getHiddenPassword = (val) => {
	if (isEmptyVal(val)) return `No password provided!`;
	const length = val?.length;
	const passwordSplit = val?.split("") ?? "";
	const list = range(0, length, (x) => x + 1);

	return (
		<>
			{list.map((char, idx) => (
				<HiddenChar key={`${char}__${idx}`} char={passwordSplit[idx]} />
			))}
		</>
	);
};

const HiddenChar = () => {
	return <span>&#9679;</span>;
};

const HiddenPassword = ({ passwordVal }) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className={styles.HiddenPassword}>
			{!showPassword && (
				<div className={styles.HiddenPassword_val}>
					{getHiddenPassword(passwordVal)}
				</div>
			)}
			{showPassword && (
				<div className={styles.HiddenPassword_val}>{passwordVal}</div>
			)}
			<div className={styles.HiddenPassword_toggle}>
				<svg
					className={styles.HiddenPassword_toggle_icon}
					onClick={() => setShowPassword(!showPassword)}
				>
					<use
						xlinkHref={`${sprite}#icon-view-${showPassword ? "show" : "hide"}`}
					></use>
				</svg>
			</div>
		</div>
	);
};

const SummaryItem = ({ val, label }) => {
	return (
		<div className={styles.SummaryItem}>
			<div className={styles.SummaryItem_label}>{label}</div>
			<div className={styles.SummaryItem_value}>{val}</div>
		</div>
	);
};

const CreateUserSummary = ({
	vals = {},
	handleChange,
	createNewUser,
	cancelNewUser,
	facilityAccessList = [],
}) => {
	return (
		<div className={styles.CreateUserSummary}>
			<div className={styles.CreateUserSummary_summary}>
				<div className={styles.CreateUserSummary_summary_title}>
					New User Summary
				</div>
				<div className={styles.CreateUserSummary_summary_details}>
					{/* FULL NAME */}
					<SummaryItem val={getNewUsersName(vals)} label="Name" />

					{/* JOB TITLE */}
					<SummaryItem val={getNewUsersTitle(vals)} label="Title" />

					{/* PHONE NUMBER */}
					<SummaryItem val={getNewUserPhone(vals)} label="Phone" />

					{/* USERNAME  */}
					<SummaryItem
						val={getNewUsersLoginName(vals)}
						label="Username/Email"
					/>

					{/* PASSWORD */}
					<div className={styles.CreateUserSummary_summary_details_item}>
						<div
							className={styles.CreateUserSummary_summary_details_item_label}
						>
							Temp Password
						</div>

						<div
							className={styles.CreateUserSummary_summary_details_item_value}
						>
							<TempPassword
								tempPassword={vals.tempPassword}
								handleChange={handleChange}
								disableEdit={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<Divider customStyles={customCSS.divider} />
			{/* APP ACCESS SUMMARY */}
			<div className={styles.CreateUserSummary_more}>
				<UserAppSummary
					appAccess={{
						trackerAccess: vals?.hasTrackerAccess,
						legacyAccess: vals?.hasLegacyAccess,
						hasPortalAccess: true,
					}}
				/>
			</div>
			<Divider customStyles={customCSS.divider} />
			{/* FACILITY ACCESS SUMMARY */}
			<div className={styles.CreateUserSummary_more}>
				<UserFacilitySummary facilityAccessList={facilityAccessList} />
			</div>
			<Divider customStyles={customCSS.divider} />
			{/* SECURITY QUESTIONS & RESET METHODS SUMMARY */}
			<div className={styles.CreateUserSummary_more}>
				<UserSecuritySummary vals={vals} />
			</div>
			{/* ACTIONS: SAVE/CANCEL */}
			<div className={styles.CreateUserSummary_actions}>
				<ButtonSM
					type="button"
					customStyles={customCSS.cancelBtn}
					handleClick={cancelNewUser}
				>
					<span>Cancel</span>
				</ButtonSM>
				<ButtonSM
					type="button"
					customStyles={customCSS.createBtn}
					handleClick={createNewUser}
				>
					<span>Create New User</span>
				</ButtonSM>
			</div>
		</div>
	);
};

export default CreateUserSummary;

CreateUserSummary.defaultProps = {
	vals: {},
};

CreateUserSummary.propTypes = {
	vals: PropTypes.object,
	createNewUser: PropTypes.func,
	cancelNewUser: PropTypes.func,
};
