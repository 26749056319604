import React from "react";
import styles from "../../css/forms/DoNotMatchIndicator.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const DoNotMatchIndicator = ({ failedMsg = `Passwords Do Not Match` }) => {
	return (
		<div className={styles.DoNotMatchIndicator}>
			<svg className={styles.DoNotMatchIndicator_icon}>
				<use xlinkHref={`${sprite}#icon-error_outline1`}></use>
			</svg>
			<span className={styles.DoNotMatchIndicator_msg}>{failedMsg}</span>
		</div>
	);
};

export default DoNotMatchIndicator;

DoNotMatchIndicator.defaultProps = {};

DoNotMatchIndicator.propTypes = {
	failedMsg: PropTypes.string,
};
