import React from "react";
import styles from "../../css/app/VerifyEmail.module.scss";
import { PropTypes } from "prop-types";

const VerifyEmail = ({
	welcomeMsg = `Welcome to AL Advantage!`,
	btnText = `Re-send Confirmation`,
	sendConfirmationEmail,
}) => {
	return (
		<div className={styles.VerifyEmail}>
			<div className={styles.VerifyEmail_header}>
				<div className={styles.VerifyEmail_header_title}>
					Verify your email address
				</div>
				<p className={styles.VerifyEmail_header_welcome}>{welcomeMsg}</p>
				<p className={styles.VerifyEmail_header_desc}>
					We'll send a special code to your email address. Please click the
					button below to send a verification code to your email.
				</p>
			</div>
			<div className={styles.VerifyEmail_action}>
				<button
					type="button"
					onClick={sendConfirmationEmail}
					className={styles.VerifyEmail_action_btn}
				>
					{btnText}
				</button>
			</div>
		</div>
	);
};

export default VerifyEmail;

VerifyEmail.defaultProps = {
	welcomeMsg: `Welcome to AL Advantage!`,
	btnText: `Re-send Confirmation`,
};

VerifyEmail.propTypes = {
	welcomeMsg: PropTypes.string,
	btnText: PropTypes.string,
	sendConfirmationEmail: PropTypes.func,
};
