import React from "react";
import styles from "../../css/widget/FeedbackSuccess.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";

const FeedbackSuccess = ({ msg, submitMore }) => {
	return (
		<div className={styles.FeedbackSuccess}>
			<svg className={styles.FeedbackSuccess_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
			<div className={styles.FeedbackSuccess_msg}>{msg}</div>
			<button
				onClick={submitMore}
				className={styles.FeedbackSuccess_submitMoreBtn}
			>
				Submit more feedback
			</button>
		</div>
	);
};

export default FeedbackSuccess;

FeedbackSuccess.defaultProps = {};

FeedbackSuccess.propTypes = {};
