import React from "react";
import styles from "../../css/settings/ManageExceptions.module.scss";
import { PropTypes } from "prop-types";

const ManageExceptions = () => {
	return (
		<div className={styles.ManageExceptions}>
			{/*  */}
			{/*  */}
			{/*  */}
		</div>
	);
};

export default ManageExceptions;

ManageExceptions.defaultProps = {};

ManageExceptions.propTypes = {};
