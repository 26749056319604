import React from "react";
import styles from "../../css/shared/DropdownOption.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const checkIcon = `done`;

const getOptionStyles = (isSelected = false, isDisabled = false) => {
	if (isDisabled) {
		return styles.DropdownOption_isDisabled;
	} else if (isSelected) {
		return styles.DropdownOption_isSelected;
	} else {
		return styles.DropdownOption;
	}
};

const DropdownOption = ({
	option,
	hasFocus = false,
	isDisabled = false,
	isSelected = false,
	handleFocus,
	handleSelection,
	handleSelectionByKey,
}) => {
	return (
		<li
			tabIndex={0}
			className={getOptionStyles(isSelected, isDisabled)}
			onClick={isDisabled ? null : () => handleSelection()}
			onKeyDown={handleSelectionByKey}
			onFocus={handleFocus}
			title={isDisabled ? "Disabled" : ""}
		>
			<span className={styles.DropdownOption_label}>{option}</span>

			{isSelected && (
				<svg className={styles.DropdownOption_checked}>
					<use xlinkHref={`${sprite}#icon-${checkIcon}`}></use>
				</svg>
			)}
		</li>
	);
};

export default DropdownOption;

DropdownOption.defaultProps = {};

DropdownOption.propTypes = {};
