import React from "react";
import styles from "../../css/loc/LOCValidator.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { isValidLOC, validateAllLOCUnits } from "../../helpers/utils_loc";
import { red } from "../../helpers/utils_styles";
// components
import ButtonSM from "../shared/ButtonSM";
import LOCValidatorSummary from "./LOCValidatorSummary";
import LOCApiValidatorSummary from "./LOCApiValidatorSummary";

const customCSS = {
	cancel: {
		padding: ".7rem 1.5rem",
		fontSize: "1.6rem",
		color: red[600],
		backgroundColor: "transparent",
		marginRight: "1rem",
	},
	save: {
		padding: ".7rem 1.5rem",
		fontSize: "1.6rem",
		color: "#ffffff",
		backgroundColor: red[600],
	},
};

const enableSave = (processedValidation = {}, vals = {}) => {
	const isValid = isValidLOC(processedValidation);
	const hasVals =
		!isEmptyVal(vals.templateName) && !isEmptyVal(vals.templateDesc);

	return isValid && hasVals;
};

const LOCApiValidator = ({
	vals = {},
	currentFacility = {},
	locDetails = {}, // validation for all unit types
	cancelSave,
	confirmSave,
}) => {
	const units = Object.keys(locDetails);
	return (
		<div className={styles.LOCValidator}>
			<div className={styles.LOCValidator_results}>
				{!isEmptyArray(units) &&
					units.map((unitKey, idx) => (
						<LOCApiValidatorSummary
							key={`VALIDATE-LOC-${idx}`}
							isValid={locDetails?.[unitKey]}
							unitType={unitKey}
							validationSummary={locDetails?.[unitKey]}
						/>
					))}
			</div>
			<div className={styles.LOCValidator_actions}>
				<ButtonSM customStyles={customCSS.cancel} handleClick={cancelSave}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={!enableSave(locDetails, vals)}
					customStyles={customCSS.save}
					handleClick={confirmSave}
				>
					Yes, Save
				</ButtonSM>
			</div>
		</div>
	);
};

export default LOCApiValidator;

LOCApiValidator.defaultProps = {};

LOCApiValidator.propTypes = {};
