import { useState } from "react";
import { getNetworkMeta } from "../helpers/utils_auth";

const useOfflineIndicator = () => {
	const [isOffline, setIsOffline] = useState(() => {
		const meta = getNetworkMeta();
		return !meta?.isOnline;
	});

	return {
		isOffline,
	};
};

export { useOfflineIndicator };
