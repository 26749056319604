import React, { useState, useEffect, useReducer } from "react";
import { Prompt } from "react-router-dom";
import styles from "../../css/loctemplate/LOCLoadedTemplateWrapper.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { green, red } from "../../helpers/utils_styles";
import { isEmptyObj } from "../../helpers/utils_types";
import {
	defaultSchema,
	fetchAndProcessLOCApiValidation,
	mergeLOCIntoArray,
	validateAllLOC,
} from "../../helpers/utils_loc";
import {
	deleteFacilityLOCTemplate,
	prepareNewLOCTemplate,
	updateFacilityLOCTemplate,
} from "../../helpers/utils_loctemplate";
// components
import LOCTemplateTable from "./LOCTemplateTable";
import LOCTemplateNameInput from "./LOCTemplateNameInput";
import LOCLoadedTemplateValidator from "./LOCLoadedTemplateValidator";
import LOCTemplateDescInput from "./LOCTemplateDescInput";
import LOCModal from "../loc/LOCModal";
import ButtonSM from "../shared/ButtonSM";
import DeleteTemplate from "./DeleteTemplate";

// REQUIREMENTS:
// - Existing LOC Templates:
//    - Edit levels
//    - Edit template name
//    - Edit template desc

const customCSS = {
	save: {
		padding: "1.2rem 2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: green[500],
		color: "#ffffff",
	},
	delete: {
		padding: "1.2rem 2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: red[600],
		color: "#ffffff",
		marginRight: "auto",
	},
};

const promptMsg = `There are unsaved changes!. Still want to leave?`;

// intial validation state
const baseValidation = {
	"Assisted Living": {},
	"Memory Care": {},
	"Personal Care": {},
	Independent: {},
};

const templateReducer = (state, action) => {
	switch (action.type) {
		case "LOAD_EXISTING_TEMPLATE": {
			return { ...state };
		}
		case "SAVE_EXISTING_TEMPLATE": {
			return { ...state };
		}
		case "SYNC_CHANGES": {
			const { targetFloorUnit, newData } = action.data;
			const { template } = state;

			return {
				...state,
				template: {
					...template,
					[targetFloorUnit]: [...newData],
				},
				hasChanges: true,
			};
		}
		case "HAS_CHANGES": {
			return {
				...state,
				hasChanges: true,
			};
		}
		case "RESET": {
			return {
				...initialState,
			};
		}

		default:
			return state;
	}
};

const initialState = {
	template: {
		"Assisted Living": [],
		"Memory Care": [],
		"Personal Care": [],
		Independent: [],
	},
	hasChanges: false,
};

const LOCLoadedTemplateWrapper = ({
	isEditable = true,
	locAreAdminLocked = false,
	isDefault = false,
	selectedTemplate = {},
	facilityTemplates = {},
	currentUser,
	currentFacility,
	dispatchAlert,
	syncTemplateChanges,
	syncDeletedTemplate,
}) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	// confirm save modal
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const { formState, handleChange } = useForm({
		isEditable: isEditable,
		locAreAdminLocked: locAreAdminLocked,
		// template values
		templateID: selectedTemplate?.templateID,
		categoryID: selectedTemplate?.categoryID,
		templateName: selectedTemplate.templateName,
		templateDesc: selectedTemplate.templateDesc,
		isActive: selectedTemplate?.isActive,
		isDefault: selectedTemplate?.isDefault ?? false,
	});
	const { values } = formState;
	// existing template state
	const [templateState, templateAction] = useReducer(templateReducer, {
		...initialState,
		template: {
			...selectedTemplate?.template,
		},
		hasChanges: false,
	});
	// per table valdiation results
	const [templateValidation, setTemplateValidation] = useState({
		...baseValidation,
	});

	// handles 'name' & 'desc' fields & marks 'hasChanges' to true
	const handleFields = (e) => {
		handleChange(e);
		templateAction({
			type: "HAS_CHANGES",
		});
	};

	// init save template changes; runs validation
	const saveTemplateChanges = async () => {
		const { token } = currentUser;
		setShowConfirmModal(true);
		const editedTemplate = templateState?.template;

		const merged = mergeLOCIntoArray(editedTemplate);
		const validation = await fetchAndProcessLOCApiValidation(token, merged);

		if (!isEmptyObj(validation)) {
			return setTemplateValidation({ ...validation });
		} else {
			return setTemplateValidation({});
		}
	};

	// save loaded template changes
	const confirmSaveTemplate = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const { template } = templateState;
		const preparedTemplate = prepareNewLOCTemplate(facilityID, template);

		// const wasSaved = false;
		const wasSaved = await updateFacilityLOCTemplate(token, {
			...values,
			templateLevels: preparedTemplate,
		});

		if (wasSaved) {
			setShowConfirmModal(false);
			syncTemplateChanges();
			return dispatchAlert("SUCCESS", {
				heading: `Your template was updated!`,
			});
		} else {
			setShowConfirmModal(false);
			return dispatchAlert("ERROR", {
				heading: `Template update failed!`,
				subheading: `PUT ERROR MESSAGE HERE...`,
			});
		}
	};

	const cancelSaveTemplate = async () => {
		setShowConfirmModal(false);
		setTemplateValidation({ ...baseValidation });
	};

	// inits 'DELETE' action
	const deleteTemplate = async () => {
		setShowDeleteModal(true);
	};
	// fires off 'DELETE' request & action
	const confirmDeleteTemplate = async () => {
		const { token } = currentUser;
		const { templateID } = values;
		const wasDeleted = await deleteFacilityLOCTemplate(token, templateID);
		// const wasDeleted = false;

		if (wasDeleted) {
			// forces a re-fetch in <LOCTemplateController/> //
			syncDeletedTemplate();
			setShowDeleteModal(false);

			// templateAction({
			// 	type: "RESET",
			// });
			return dispatchAlert("SUCCESS", {
				heading: `Template was deleted!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error Occurred:`,
				subheading: `${wasDeleted}`,
			});
		}
	};
	// cancels delete action
	const cancelDeleteTemplate = async () => {
		setShowDeleteModal(false);
	};

	// sync table changes to local reducer state
	const syncTableData = (tableName, tableData) => {
		templateAction({
			type: "SYNC_CHANGES",
			data: {
				targetFloorUnit: tableName,
				newData: tableData?.data,
			},
		});
	};

	const handleTableReset = (tableName) => {
		templateAction({
			type: "RESET_TABLE",
			data: {
				tableName: tableName,
			},
		});
	};

	return (
		<>
			<div className={styles.LOCLoadedTemplateWrapper}>
				<div className={styles.LOCLoadedTemplateWrapper_name}>
					<LOCTemplateNameInput
						key={`EXISTING-NAME`}
						vals={values}
						handleChange={handleFields}
					/>
					<LOCTemplateDescInput
						key={`EXISTING-DESC`}
						vals={values}
						handleChange={handleFields}
					/>
				</div>
				<div className={styles.LOCLoadedTemplateWrapper_inner}>
					{/* ASSISTED LIVING */}
					<LOCTemplateTable
						key={`EXISTING-AL-${templateState?.template?.["Assisted Living"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Assisted Living"
						title="Assisted Living"
						subtitle="Edit or add new care levels to be applied to 'Assisted Living' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Assisted Living"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
						enableTableReset={false}
					/>
					{/* MEMORY CARE */}
					<LOCTemplateTable
						key={`EXISTING-MC-${templateState?.template?.["Memory Care"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Memory Care"
						title="Memory Care"
						subtitle="Edit or add new care levels to be applied to 'Memory Care' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Memory Care"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
						enableTableReset={false}
					/>
					{/* INDEPENDENT */}
					<LOCTemplateTable
						key={`EXISTING-IND-${templateState?.template?.["Independent"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Independent"
						title="Independent"
						subtitle="Edit or add new care levels to be applied to 'Independent Living' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Independent"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
						enableTableReset={false}
					/>
					{/* PERSONAL CARE */}
					<LOCTemplateTable
						key={`EXISTING-PC-${templateState?.template?.["Personal Care"]?.length}`}
						isEditable={values?.Editable}
						btnText="Save Personal Care"
						title="Personal Care"
						subtitle="Edit or add new care levels to be applied to 'Personal Care' unit types at your community(s)."
						schema={{
							cols: defaultSchema?.cols,
							data: templateState?.template?.["Personal Care"],
						}}
						syncTableData={syncTableData}
						handleTableReset={handleTableReset}
						enableTableReset={false}
					/>
				</div>
				<div className={styles.LOCLoadedTemplateWrapper_actions}>
					<ButtonSM
						isDisabled={false}
						customStyles={customCSS.delete}
						handleClick={deleteTemplate}
					>
						Delete Template
					</ButtonSM>
					<ButtonSM
						isDisabled={!templateState?.hasChanges}
						customStyles={customCSS.save}
						handleClick={saveTemplateChanges}
					>
						Save Template Changes
					</ButtonSM>
				</div>
			</div>

			{/* MODAL: CONFIRM TEMPLATE CHANGES */}
			{showConfirmModal && (
				<LOCModal
					key={`VALIDATE-EXISTING-TEMPLATE`}
					title={`Confirm Template Changes?`}
					closeModal={() => setShowConfirmModal(false)}
				>
					<LOCLoadedTemplateValidator
						key={`VALIDATOR-EXISTING`}
						currentFacility={currentFacility}
						vals={values}
						templateValidation={templateValidation}
						confirmSave={confirmSaveTemplate}
						cancelSave={cancelSaveTemplate}
					/>
				</LOCModal>
			)}

			{/* DIRTY PAGE PROMPT (ie. page has changes) */}
			<Prompt when={templateState.hasChanges} message={promptMsg} />

			{/* MODAL: CONFIRM DELETE TEMPLATE */}
			{showDeleteModal && (
				<LOCModal
					key={`DELETE-EXISTING-TEMPLATE`}
					title={`Delete this Template?`}
					closeModal={() => setShowDeleteModal(false)}
				>
					<DeleteTemplate
						templateName={values?.templateName}
						confirmDelete={confirmDeleteTemplate}
						cancelDelete={cancelDeleteTemplate}
					/>
				</LOCModal>
			)}
		</>
	);
};

export default LOCLoadedTemplateWrapper;

LOCLoadedTemplateWrapper.defaultProps = {};

LOCLoadedTemplateWrapper.propTypes = {};
