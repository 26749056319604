import React, { useState, useEffect } from "react";
import styles from "../../css/user/ResetViaOTP.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { registerOtp } from "../../helpers/utils_otp";
import { generateTempPassword } from "../../helpers/utils_processing";
// components
import OTPPreviewer from "../otp/OTPPreviewer";

const Success = () => {
	return (
		<div className={styles.Success}>
			<svg className={styles.Success_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle1`}></use>
			</svg>
			<span>OTP was saved!</span>
		</div>
	);
};

const ResetViaOTP = ({ currentUser, targetUser }) => {
	// const [otp, setOtp] = useState("");
	const [otp, setOtp] = useState(generateTempPassword(6));
	const [isSaving, setIsSaving] = useState(false);
	const [wasSaved, setWasSaved] = useState(false);
	const [wasCopied, setWasCopied] = useState(false);

	const generateOTP = async () => {
		const { token } = currentUser;
		const { userID } = targetUser;
		setIsSaving(true);
		// generates & sets 'targetUser's password to an OTP in place of their actual password
		const wasCreated = await registerOtp(token, {
			userLoginID: userID,
			password: otp,
			expires: null,
		});
		// removed, for clarity in UI flow
		// const createdOTP = await createAndRegisterOtp(token, {
		// 	userLoginID: userID,
		// 	expires: null,
		// });

		if (wasCreated) {
			setIsSaving(false);
			return setWasSaved(true);
		} else {
			setIsSaving(false);
			setOtp("");
			return setWasCopied(false);
		}
	};

	const copyOtp = () => {
		navigator.clipboard.writeText(otp);
		setWasCopied(true);
	};

	// reset timer for copy state
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let timerID;
		if (wasCopied) {
			setTimeout(() => {
				setWasCopied(false);
			}, 2000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasCopied]);

	// reset timer wasSaved state
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let timerID;
		if (wasSaved) {
			setTimeout(() => {
				setWasSaved(false);
			}, 3000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasSaved]);

	return (
		<div className={styles.ResetViaOTP}>
			<div className={styles.ResetViaOTP_main}>
				<button
					type="button"
					disabled={isSaving}
					className={styles.ResetViaOTP_main_btn}
					onClick={generateOTP}
				>
					{isSaving ? "Saving OTP..." : "Save OTP For This User"}
				</button>
				<OTPPreviewer otp={otp} wasCopied={wasCopied} copyOTP={copyOtp} />
			</div>
			<div className={styles.ResetViaOtp_messages}>
				{wasSaved && <Success />}
			</div>
		</div>
	);
};

export default ResetViaOTP;

ResetViaOTP.defaultProps = {};

ResetViaOTP.propTypes = {};
