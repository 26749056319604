import { currentEnv } from "./utils_env";
import { emails } from "./utils_endpoints";

/**
 * Data-structure for sending an email to multiple users.
 * - 'userLoginId': a user login id.
 * - 'subject': email subject field
 * - 'body': email body field. Supports strings or html content.
 * - 'isBodyHtml': signifies the content type for the 'body' field.
 * - 'isAdminNotify': signifies whether the user's admins should be notified, respectively.
 */
const userEmailData = {
	userLoginId: "",
	subject: "",
	body: "",
	isBodyHtml: true,
	isAdminNotify: false,
};
/**
 * Data-structure for sending an email to multiple users.
 * - 'userLoginIds': an array of user login ids.
 * - 'subject': email subject field
 * - 'body': email body field. Supports strings or html content.
 * - 'isBodyHtml': signifies the content type for the 'body' field.
 * - 'isAdminNotify': signifies whether the user's admins should be notified, respectively.
 */
const userEmailsData = {
	userLoginIds: [],
	subject: "",
	body: "",
	isBodyHtml: true,
	isAdminNotify: false,
};

/**
 * Sends an email to a target user, and their admin if requested.
 * @param {String} token - Security token
 * @param {String} userEmail - A user's email.
 * @param {Object} emailContent - An object of various email content: 'Subject', 'Body' etc. Supports HTML body.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Set to 'true' to send email to admin, as well as target user.
 * @property {String} emailContent.subject - Email 'Subject' field.
 * @property {String|HTMLDocument} emailContent.body - Email's 'Body' content. Supports text or html format.
 * @property {Boolean} emailContent.isBodyHtml - Boolean indicating whether the 'body' is HTML or not.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Boolean to trigger an additional email to the user's Admin.
 */
const sendUserEmail = async (token, emailContent = { ...userEmailData }) => {
	let url = currentEnv.base + emails.sendEmail;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json; charset=UTF-8",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Sends an email to one or more users via their 'userLoginId'
 * @param {String} token - Security token
 * @param {String} userEmail - A user's email.
 * @param {Object} emailContent - An object of various email content: 'Subject', 'Body' etc. Supports HTML body.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Set to 'true' to send email to admin, as well as target user.
 * @property {String} emailContent.subject - Email 'Subject' field.
 * @property {String|HTMLDocument} emailContent.body - Email's 'Body' content. Supports text or html format.
 * @property {Boolean} emailContent.isBodyHtml - Boolean indicating whether the 'body' is HTML or not.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Boolean to trigger an additional email to the user's Admin.
 */
const sendUsersEmail = async (token, emailContent = { ...userEmailsData }) => {
	let url = currentEnv.base + emails.sendEmails;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json; charset=UTF-8",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * List of emails/userIDs to receive 'feedback' emails
 *
 */
const FEEDBACK_LIST = [
	"e99c5c77-68b7-4ccf-aa5f-5dd5601aff16", // User: sgore99@aladvantage.com/steven@aladvantage.com
	"59926816-733B-4D61-ACED-7E4F9E869E64", // User: feedback@aladvantage.com
	"5bd328cc-120d-4d18-b9c0-fa2d94c0c30e", // User: seth@aladvantage.com
];

export { sendUserEmail, sendUsersEmail };

export { FEEDBACK_LIST };
