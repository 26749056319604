import { user } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { UserTitleModel } from "./utils_models";

// Request utils //

/**
 * Saves/creates a new 'UserTitle' record.
 * @param {String} token - Auth token
 * @param {Object} titleModel - A 'UserTitle' object structure model
 * @returns {Boolean} - Returns whether request was successful and the title record saved.
 */
const saveUserTitle = async (token, titleModel = {}) => {
	let url = currentEnv.base + user.titles.save;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(titleModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
const updateUserTitle = async (token, titleModel = {}) => {
	let url = currentEnv.base + user.titles.update;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(titleModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// populates model & fires off request
const createNewJobTitle = async (token, newJobTitle) => {
	const noSpaces = newJobTitle.replace(/\s/gm, "");
	const model = updateUserTitleModel({
		jobTitle: noSpaces,
		jobTitleDesc: newJobTitle,
	});
	const wasSaved = await saveUserTitle(token, model);

	if (wasSaved) {
		return wasSaved;
	} else {
		return wasSaved;
	}
};

/**
 * Searches database for a matching 'UserTitle' record based off a provided search value.
 * @param {String} token - Auth token
 * @param {String} searchVal - A string search value to find matches for
 * @returns {Object[]} - Returns an array of 'UserTitle' records as possible matches
 */
const findUserTitleMatches = async (token, searchVal) => {
	let url = currentEnv.base + user.titles.findMatches;
	url += "?" + new URLSearchParams({ title: searchVal });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// User Title updater utils //

/**
 * Populates the required UserTitle object structure.
 * @param {Object} vals - Object of user title values (eg. 'jobTitle' and 'jobTitleDesc')
 * @returns {UserTitleModel} - Returns 'UserTitleModel' object structure
 */
const updateUserTitleModel = (vals = {}) => {
	const base = new UserTitleModel(vals);
	const model = base.getModel();

	return model;
};

export {
	saveUserTitle,
	updateUserTitle,
	findUserTitleMatches,
	createNewJobTitle,
};

export { updateUserTitleModel };
