import React, { useState } from "react";
import styles from "../../css/loc/LOCTableRow.module.scss";
import sprite from "../../assets/icons/tables.svg";
import { PropTypes } from "prop-types";
// components
import LOCDescCell from "./LOCDescCell";
import LOCLevelCell from "./LOCLevelCell";
import LOCPointsMinCell from "./LOCPointsMinCell";
import LOCPointsMaxCell from "./LOCPointsMaxCell";
import LOCPriceCell from "./LOCPriceCell";

const DeleteButton = ({ isDisabled = false, handleDeleteRow }) => {
	return (
		<button
			type="button"
			onClick={handleDeleteRow}
			disabled={isDisabled}
			className={styles.DeleteButton}
		>
			<svg className={styles.DeleteButton_icon}>
				<use xlinkHref={`${sprite}#icon-delete`}></use>
			</svg>
		</button>
	);
};

const LOCTableRow = ({
	rowData = {},
	rowIdx,
	isDeleteDisabled = false,
	isEditable = true,
	handleDeleteRow,
	updateTableData,
}) => {
	return (
		<div data-rowidx={rowIdx} className={styles.LOCTableRow}>
			<div className={styles.LOCTableRow_inner}>
				<LOCDescCell
					isEditable={isEditable}
					rowIdx={rowIdx}
					cellIdx={0}
					rowData={rowData}
					cellKey="LevelDescription"
					updateTableData={updateTableData}
				/>

				<LOCLevelCell
					isEditable={false}
					rowIdx={rowIdx}
					cellIdx={1}
					rowData={rowIdx + 1}
					cellKey="LevelNumber"
					updateTableData={updateTableData}
				/>
				<LOCPointsMinCell
					isEditable={isEditable}
					rowIdx={rowIdx}
					cellIdx={2}
					rowData={rowData}
					cellKey="PointsMin"
					updateTableData={updateTableData}
				/>
				<LOCPointsMaxCell
					isEditable={isEditable}
					rowIdx={rowIdx}
					cellIdx={3}
					rowData={rowData}
					cellKey="PointsMax"
					updateTableData={updateTableData}
				/>
				<LOCPriceCell
					isEditable={isEditable}
					rowIdx={rowIdx}
					cellIdx={4}
					rowData={rowData}
					cellKey="Pricing"
					updateTableData={updateTableData}
				/>
			</div>
			<DeleteButton
				key={`Delete-${rowIdx}`}
				isDisabled={isDeleteDisabled || !isEditable}
				handleDeleteRow={handleDeleteRow}
			/>
		</div>
	);
};

export default LOCTableRow;

LOCTableRow.defaultProps = {
	rowIdx: null,
	rowData: PropTypes.shape({
		ID: 0,
		FacilityID: null,
	}),
	isDeleteDisabled: false,
	isEditable: true,
};

LOCTableRow.propTypes = {
	rowIdx: PropTypes.number,
	rowData: PropTypes.shape({
		ID: PropTypes.number,
		FacilityID: PropTypes.string,
		FloorUnit: PropTypes.string,
		LOCType: PropTypes.string,
		LevelDescription: PropTypes.string,
		LevelNumber: PropTypes.number,
		PointsMax: PropTypes.number,
		PointsMin: PropTypes.number,
		Pricing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	isDeleteDisabled: PropTypes.bool,
	isEditable: PropTypes.bool,
	handleDeleteRow: PropTypes.func,
	updateTableData: PropTypes.func,
};
