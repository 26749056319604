import React, { useState } from "react";
import styles from "../../css/user/UserSearchResults.module.scss";
import { PropTypes } from "prop-types";
import { getUserName, isEmailDeleted } from "../../helpers/utils_user";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import Spinner from "../shared/Spinner";
import Empty from "../shared/Empty";
import { red } from "../../helpers/utils_styles";
import { addEllipsis } from "../../helpers/utils_processing";
import TextInput from "../shared/TextInput";

const customCSS = {
	empty: {
		icon: {
			fill: red[600],
		},
		heading: {
			// color: red[600],
		},
		subheading: {
			color: red[600],
		},
	},
	search: {
		backgroundColor: "#ffffff",
		borderRadius: "5rem",
	},
};

const advancedSearch = (val, results = []) => {
	const normalVal = val?.toLowerCase();

	if (isEmptyVal(val)) {
		return results;
	} else {
		return results.filter((option) => {
			if (
				option?.email?.toLowerCase().includes(normalVal) ||
				option?.email?.toLowerCase().startsWith(normalVal) ||
				option?.userID?.toLowerCase().startsWith(normalVal) ||
				option?.userID?.toLowerCase().includes(normalVal) ||
				option?.firstName?.toLowerCase().includes(normalVal) ||
				option?.firstName?.toLowerCase().startsWith(normalVal) ||
				option?.lastName?.toLowerCase().includes(normalVal) ||
				option?.lastName?.toLowerCase().startsWith(normalVal) ||
				option?.title?.toLowerCase().startsWith(normalVal) ||
				option?.title?.toLowerCase().includes(normalVal)
			) {
				return option;
			} else {
				return;
			}
		});
	}
};

const UserEntry = ({ user = {}, viewUser }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<li className={styles.UserEntry}>
			<div className={styles.UserEntry_inner}>
				<div className={styles.UserEntry_inner_name}>
					<span className={styles.UserEntry_inner_name_msg}>
						{getUserName(user)}
					</span>

					{isEmailDeleted(user?.email) && (
						<div
							className={styles.UserEntry_isDeletedIndicator}
							title={`This user was deleted.`}
						></div>
					)}

					<button
						type="button"
						onClick={viewUser}
						className={styles.UserEntry_inner_name_editBtn}
					>
						View User
					</button>
				</div>
				<div className={styles.UserEntry_inner_email} title={user?.email}>
					{addEllipsis(user?.email, 30)}
				</div>
				<button
					type="button"
					onClick={() => setShowMore(!showMore)}
					className={styles.UserEntry_inner_showMoreBtn}
				>
					{showMore ? "Hide" : "Show"}
				</button>
			</div>
			{showMore && (
				<div className={styles.UserEntry_showMore}>
					<div className={styles.UserEntry_showMore_section}>
						<div className={styles.UserEntry_showMore_section_desc}>
							User Type(s):
						</div>
						<div className={styles.UserEntry_showMore_section_details}>
							Med-Tech Restricted?{" "}
							<b>{user?.isMedTechRestricted ? "Yes" : "No"}</b>
						</div>
						<div className={styles.UserEntry_showMore_section_details}>
							ALA Admin? <b>{user?.isFacilityAdmin ? "Yes" : "No"}</b>
						</div>
						<div className={styles.UserEntry_showMore_section_details}>
							Regional Admin? <b>{user?.isRegionalAdmin ? "Yes" : "No"}</b>
						</div>
						<div className={styles.UserEntry_showMore_section_details}>
							Super User? <b>{user?.isSuperUser ? "Yes" : "No"}</b>
						</div>
					</div>
					<div className={styles.UserEntry_showMore_options}>
						<div className={styles.UserEntry_showMore_options_item}>
							Job Title: {user?.title}
						</div>
					</div>
				</div>
			)}
		</li>
	);
};

const UserSearchResults = ({
	isLoading = false,
	searchResults = [],
	setSelectedUser,
	searchCSS,
}) => {
	const [results, setResults] = useState([...searchResults]);
	const [searchVal, setSearchVal] = useState("");

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);

		setResults([...advancedSearch(value, searchResults)]);
	};

	if (isLoading) {
		return (
			<div className={styles.LoadingSearchResults}>
				<Spinner />
				<div className={styles.LoadingSearchResults_msg}>
					Loading search results...
				</div>
			</div>
		);
	}
	return (
		<div className={styles.UserSearchResults}>
			<div className={styles.UserSearchResults_header}>
				<div className={styles.UserSearchResults_header_title}>Results:</div>
			</div>
			<div className={styles.UserSearchResults_searchResults}>
				<TextInput
					val={searchVal}
					name="searchVal"
					id="searchVal"
					handleChange={handleSearch}
					placeholder="Search results by name, email, title..."
					customStyles={searchCSS || customCSS.search}
				/>
			</div>
			<ul className={styles.UserSearchResults_list}>
				{(!results || isEmptyArray(results)) && (
					<div className={styles.UserSearchResults_noResults}>
						<Empty
							customStyles={customCSS.empty}
							size="MD"
							heading="No matching results."
							icon="archive"
						/>
					</div>
				)}
				{!isEmptyArray(results) &&
					results.map((user, idx) => (
						<UserEntry
							key={`${user?.userID}_${idx}`}
							user={user}
							viewUser={() => setSelectedUser(user)}
						/>
					))}
			</ul>
		</div>
	);
};

export default UserSearchResults;

UserSearchResults.defaultProps = {};

UserSearchResults.propTypes = {
	searchResults: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.instanceOf(null),
	]),
};
