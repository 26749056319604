import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/loctemplate/LOCTemplateController.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal, isEmptyArray } from "../../helpers/utils_types";
import {
	getFacilityLOCTemplates,
	matchLOCTemplateByName,
	processFacilityTemplates,
} from "../../helpers/utils_loctemplate";
import { sortByAlphaAsc } from "../../helpers/utils_processing";
// loc components
// new loc template components: existing & new
import LOCControllerContainer from "./LOCControllerContainer";
import LOCNewTemplateWrapper from "./LOCNewTemplateWrapper";
import LOCLoadedTemplateWrapper from "./LOCLoadedTemplateWrapper";
import LOCTemplateActions from "./LOCTemplateActions";
import { isChildFacility } from "../../helpers/utils_facility";

const FacilityNotice = ({ currentFacility }) => {
	if (isEmptyVal(currentFacility.facilityID)) {
		return (
			<div className={styles.FacilityNotice}>
				<div className={styles.FacilityNotice_msg}>
					Please load a facility above!
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const FacilityIndicator = ({ currentFacility }) => {
	if (isEmptyVal(currentFacility.facilityID)) {
		return null;
	}

	return (
		<div className={styles.FacilityIndicator}>
			({currentFacility?.communityName})
		</div>
	);
};

// whether facility templates have been loaded or not
const isLoaded = (templateState = {}, currentFacility = {}) => {
	if (isEmptyVal(currentFacility.facilityID)) return false;
	const levelsAL = templateState?.template?.["Assisted Living"];
	const levelsMC = templateState?.template?.["Memory Care"];
	const levelsIND = templateState?.template?.["Independent"];
	const levelsPC = templateState?.template?.["Personal Care"];

	const hasLevels =
		!isEmptyArray(levelsAL) ||
		!isEmptyArray(levelsMC) ||
		!isEmptyArray(levelsIND) ||
		!isEmptyArray(levelsPC);

	return hasLevels;
};

const showTemplateUI = (currentFacility) => {
	const { facilityID } = currentFacility;
	const hasFacility = !isEmptyVal(facilityID);
	// loaded facility & facility is NOT a child or independent
	const isChild = isChildFacility(currentFacility);

	return hasFacility && !isChild;
};

const LOCTemplateController = ({
	globalState,
	currentUser,
	currentFacility,
	dispatchToState,
	dispatchAlert,
}) => {
	// all facility LOC templates
	const [facilityTemplates, setFacilityTemplates] = useState({
		templates: [],
		names: [],
	});

	// 'NEW' or 'EXISTING'
	const [templateType, setTemplateType] = useState(null);
	// selected template's string name
	const [selectedTemplate, setSelectedTemplate] = useState("");
	const [currentTemplate, setCurrentTemplate] = useState({});

	const initNewTemplate = () => {
		setTemplateType("NEW");
		setSelectedTemplate("");
		setCurrentTemplate({});
		dispatchAlert("SUCCESS", {
			heading: `New Template Loaded!`,
		});
	};
	// loads selected template
	const loadExistingTemplate = () => {
		if (isEmptyVal(selectedTemplate)) {
			return setTemplateType("");
		}
		setTemplateType("EXISTING");
		dispatchAlert("SUCCESS", {
			heading: `"${selectedTemplate}" Template Loaded!`,
		});
	};

	// template selector handler
	const selectTemplate = (name, templateName) => {
		if (isEmptyVal(templateName)) {
			setTemplateType(null);
			setSelectedTemplate("");
			return setCurrentTemplate({});
		} else {
			setTemplateType(null);
			const matchingTemplate = matchLOCTemplateByName(
				templateName,
				facilityTemplates.templates
			);

			setSelectedTemplate(templateName);
			return setCurrentTemplate(matchingTemplate);
		}
	};

	// for new templates (re-fetches LOC/template data)
	const syncCreatedTemplate = async () => {
		fetchFacilityTemplates();
		setTemplateType(null);
		setSelectedTemplate("");
		setCurrentTemplate({});
	};

	const syncDeletedTemplate = async () => {
		fetchFacilityTemplates();
		setTemplateType(null);
		setSelectedTemplate("");
		setCurrentTemplate({});
	};

	// for existing templates (re-fetches LOC/template data)
	const syncEditedTemplate = async () => {
		fetchFacilityTemplates();
	};

	// fetch facility templates
	const fetchFacilityTemplates = useCallback(async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const rawTemplates = await getFacilityLOCTemplates(token, facilityID);

		if (!isEmptyArray(rawTemplates)) {
			const templatesAndNames = processFacilityTemplates(rawTemplates);
			const sortedTemplates = {
				templates: templatesAndNames?.templates,
				names: sortByAlphaAsc(templatesAndNames?.names),
			};

			// setFacilityTemplates({ ...templatesAndNames });
			setFacilityTemplates({ ...sortedTemplates });
		} else {
			return setFacilityTemplates({
				templates: [],
				names: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch templates upon facility load
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(currentFacility.facilityID)) {
			fetchFacilityTemplates();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility.facilityID, fetchFacilityTemplates]);

	return (
		<>
			<div className={styles.LOCTemplateController}>
				<div className={styles.LOCTemplateController_top}>
					<h2 className={styles.LOCTemplateController_top_title}>
						LOC Templates{" "}
						<FacilityIndicator
							key={`LOC-TEMP-${currentFacility?.facilityID}`}
							currentFacility={currentFacility}
						/>
					</h2>
					<p className={styles.LOCTemplateController_top_desc}>
						Create new LOC templates or edit existing loc templates, which are
						then made accessible to your child communities.
					</p>
				</div>

				<FacilityNotice currentFacility={currentFacility} />

				{/* {!isEmptyVal(currentFacility.facilityID) && ( */}
				{showTemplateUI(currentFacility) && (
					<LOCControllerContainer>
						{/* TEMPLATE SELECTOR & 'NEW' TEMPLATE BUTTON */}
						<LOCTemplateActions
							key={`ACTIONS-${facilityTemplates?.names?.length}`}
							facilityTemplates={facilityTemplates}
							selectTemplate={selectTemplate}
							selectedTemplate={selectedTemplate}
							currentTemplate={currentTemplate}
							initNewTemplate={initNewTemplate}
							loadExistingTemplate={loadExistingTemplate}
							templateType={templateType}
						/>

						{/* NEW FACILITY TEMPLATE */}
						{templateType === "NEW" && (
							<LOCNewTemplateWrapper
								currentUser={currentUser}
								currentFacility={currentFacility}
								dispatchAlert={dispatchAlert}
								syncCreatedTemplate={syncCreatedTemplate}
							/>
						)}

						{/* EXISTING FACILITY TEMPLATES */}
						{templateType === "EXISTING" && !isEmptyVal(selectedTemplate) && (
							<LOCLoadedTemplateWrapper
								key={`EXISTING-${currentTemplate?.templateID}`}
								currentUser={currentUser}
								currentFacility={currentFacility}
								selectedTemplate={currentTemplate}
								selectTemplate={selectTemplate}
								dispatchAlert={dispatchAlert}
								syncTemplateChanges={syncEditedTemplate}
								syncDeletedTemplate={syncDeletedTemplate}
							/>
						)}
					</LOCControllerContainer>
				)}
			</div>
		</>
	);
};

export default LOCTemplateController;

LOCTemplateController.defaultProps = {};

LOCTemplateController.propTypes = {};
