import React, { useState, useEffect } from "react";
import styles from "../../css/admin/NewUserTab.module.scss";
import { PropTypes } from "prop-types";
import CreateUserForm from "../user/CreateUserForm";
import { isEmptyArray } from "../../helpers/utils_types";

const shouldFetch = (userTypes, userTitles, userApps) => {
	const isAnyEmpty =
		isEmptyArray(userTypes) ||
		isEmptyArray(userTitles) ||
		isEmptyArray(userApps);
	return isAnyEmpty;
};

const NewUserTab = ({
	globalState = {},
	dispatchToState,
	currentUser = {},
	currentFacility = {},
	userTypes = [],
	userTitles = [],
	allApps = [],
	dispatchAlert,
}) => {
	return (
		<div className={styles.NewUserTab}>
			<header className={styles.NewUserTab_header}>
				<h2 className={styles.NewUserTab_header_title}>
					Create a New User Account
				</h2>
				<h6 className={styles.NewUserTab_header_desc}>
					Setup one or more new user accounts and enable application access,
					facility access, set up their logins and more.
				</h6>
			</header>
			<div className={styles.NewUserTab_main}>
				<CreateUserForm
					key={`NEW_USER:${globalState?.userTypes?.length}`}
					currentUser={currentUser}
					dispatchToState={dispatchToState}
					dispatchAlert={dispatchAlert}
					userTypes={globalState?.userTypes}
					userTitles={globalState?.userTitles}
					allApps={globalState?.apps}
				/>
			</div>
		</div>
	);
};

export default NewUserTab;

NewUserTab.defaultProps = {
	globalState: {},
	currentUser: {},
	currentFacility: {},
	userTypes: [],
	userTitles: [],
	allApps: [],
};

NewUserTab.propTypes = {
	globalState: PropTypes.object,
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	userTypes: PropTypes.array,
	userTitles: PropTypes.array,
	allApps: PropTypes.array,
	dispatchToState: PropTypes.func,
	dispatchAlert: PropTypes.func,
	closeModal: PropTypes.func,
};
