import { getMatchGroups } from "./utils_validation";

/**
 * Extracts the level description's number from the string description (eg. 'Level 7' => 7)
 * @param {String} desc - A string 'LevelDescription' field value
 * @returns {Number} - Returns the level number, as a number
 */
const getLevelDesc = (desc) => {
	const reg = /^(Level\s(?<level>\d{1,}))/gim;
	const mg = getMatchGroups(desc, reg);
	return +mg?.level;
};

/**
 * Creates a 'Default' LOC object by 'FloorUnit'
 * @param {String} floorUnit - String 'FloorUnit' field
 * @param {Number} idx - Index of current item/record
 * @param {Object} prevRecord - Object of previous care level record in an array.
 * @returns {Object} - Returns newly created LOC object for a given 'FloorUnit'
 */
const createLOCObj = (floorUnit, idx, prevRecord) => {
	if (idx === 0) {
		const firstObj = {
			ID: 0,
			LevelDescription: `Level ${idx}`,
			PointsMin: 0,
			PointsMax: 50,
			FacilityID: null,
			FloorUnit: floorUnit,
			LevelNumber: 1,
			Pricing: Number(0.0).toFixed(2),
			LOCType: "Default",
		};
		return firstObj;
	} else if (idx === 8) {
		const { LevelNumber: prevNum, PointsMax: prevMax } = prevRecord;
		const lastObj = {
			ID: 0,
			LevelDescription: `Level ${prevNum + 1}`,
			PointsMin: prevMax + 1,
			PointsMax: 99999,
			FacilityID: null,
			FloorUnit: floorUnit,
			LevelNumber: prevNum + 1,
			Pricing: Number(0.0).toFixed(2),
			LOCType: "Default",
		};
		return lastObj;
	} else {
		const { LevelNumber: prevNum, PointsMax: prevMax } = prevRecord;

		const currentObj = {
			ID: 0,
			LevelDescription: `Level ${idx}`,
			PointsMin: prevMax + 1,
			PointsMax: prevMax + 50,
			FacilityID: null,
			FloorUnit: floorUnit,
			LevelNumber: prevNum + 1,
			Pricing: Number(0.0).toFixed(2),
			LOCType: "Default",
		};
		return currentObj;
	}
};
/**
 * Creates a 'Default' LOC object by 'FloorUnit'
 * @param {String} floorUnit - String 'FloorUnit' field
 * @param {Object} prevRecord - Object of previous care level record in an array.
 * @returns {Object} - Returns newly created LOC object for a given 'FloorUnit'
 */
const createLastLOC = (floorUnit, prevRecord) => {
	const {
		LevelDescription: prevDesc,
		LevelNumber: prevNum,
		PointsMax: prevMax,
		FacilityID: facilityID,
	} = prevRecord;
	const desc = getLevelDesc(prevDesc);

	const newObj = {
		ID: 0,
		LevelDescription: `Level ${desc + 1}`,
		PointsMin: prevMax + 1,
		PointsMax: 99999,
		FacilityID: facilityID,
		FloorUnit: floorUnit,
		LevelNumber: prevNum + 1,
		Pricing: Number(0.0).toFixed(2),
		LOCType: "Default",
	};

	return newObj;
};

/**
 * Generates 'Default' LOC records for a given 'FloorUnit'
 * @param {String} floorUnit -- Target 'FloorUnit' value
 * @returns {Object[]} - Returns an array of 'Default' LOC records
 */
const generateDefaultLOCByType = (floorUnit) => {
	const levels = [];
	for (let i = 0; i < 8; i++) {
		if (i === 0) {
			// handle first LOC object: PointsMin: 0
			const cur = createLOCObj(floorUnit, i, {});
			levels.push(cur);
		} else if (i === 7) {
			// handle last LOC object: PointsMax: 99999
			const last = createLastLOC(floorUnit, levels[i - 1]);
			levels.push(last);
		} else {
			// handle middle LOC object(s)
			const cur = createLOCObj(floorUnit, i, levels[i - 1]);
			levels.push(cur);
		}
	}

	return levels;
};

const getDefaultLOC = () => {
	const types = [
		"Assisted Living",
		"Independent",
		"Memory Care",
		"Personal Care",
	];

	const defaultLOC = types.reduce((allTypes, type) => {
		const typeLevels = generateDefaultLOCByType(type);
		if (!allTypes[type]) {
			allTypes[type] = typeLevels;
			return allTypes;
		}
		return allTypes;
	}, {});

	return defaultLOC;
};

export { getLevelDesc };
export { generateDefaultLOCByType, getDefaultLOC };
