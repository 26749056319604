import React from "react";
import styles from "../../css/shared/SuccessIndicator.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const icons = {
	check: "done",
	checks: "done_all",
	checkCircle: "checkmark-outline",
	checkCircle2: "check-circle",
	checkCircleDark: "check_circle1",
	checkCircle3: "check_circle_outline",
	checkOutline: "done_outline",
};

const SuccessIndicator = ({ msg, msgColor, iconColor, icon = "checks" }) => {
	const custom = {
		color: msgColor,
	};

	return (
		<div className={styles.SuccessIndicator}>
			<svg className={styles.SuccessIndicator_icon}>
				<use xlinkHref={`${sprite}#icon-${icons[icon]}`} fill={iconColor}></use>
			</svg>
			<span className={styles.SuccessIndicator_msg} style={custom}>
				{msg}
			</span>
		</div>
	);
};

export default SuccessIndicator;

SuccessIndicator.defaultProps = {};

SuccessIndicator.propTypes = {
	msg: PropTypes.string,
	msgColor: PropTypes.any,
	iconColor: PropTypes.any,
	icon: PropTypes.string,
};
