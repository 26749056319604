import React from "react";
import styles from "../../css/loc/LOCPriceCell.module.scss";
import { PropTypes } from "prop-types";
import { green } from "../../helpers/utils_styles";
// component
import LOCTableCell from "./LOCTableCell";

// REPRESENTS 'Pricing' column

const custom = {
	color: green[700],
};

const LOCPriceCell = ({
	rowData,
	updateTableData,
	rowIdx,
	cellIdx,
	isEditable = true,
	allowedChars = /^\d*\.?\d*$/gim,
}) => {
	return (
		<>
			<LOCTableCell
				key={`Row${rowIdx}--Cell-${cellIdx}`}
				disableEdit={!isEditable}
				rowIdx={rowIdx}
				cellIdx={cellIdx}
				cellData={rowData["Pricing"]}
				cellKey="Pricing"
				updateTableData={updateTableData}
				allowedChars={allowedChars}
				customStyles={custom}
				inputMode="decimal"
			/>
		</>
	);
};

export default LOCPriceCell;

LOCPriceCell.defaultProps = {};

LOCPriceCell.propTypes = {
	rowData: PropTypes.object,
	updateTableData: PropTypes.func,
	rowIdx: PropTypes.number,
	cellIdx: PropTypes.number,
	isEditable: PropTypes.bool,
	allowedChars: PropTypes.instanceOf(RegExp),
};
