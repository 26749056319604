import React from "react";
import styles from "../../css/loctemplate/LOCNewTemplateValidator.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { green, red } from "../../helpers/utils_styles";
// components
import LOCApiValidator from "../loc/LOCApiValidator";
import Divider from "../forms/Divider";

const custom = {
	pass: {
		icon: "check_circle",
		css: {
			fill: green[500],
		},
	},
	fail: {
		icon: "clearclose",
		css: {
			fill: red[600],
		},
	},
};

const { pass, fail } = custom;

const Name = ({ vals }) => {
	const { templateName } = vals;
	const isEmpty = isEmptyVal(templateName);
	return (
		<div className={styles.Name}>
			<svg className={styles.Name_icon} style={isEmpty ? fail.css : pass.css}>
				<use
					xlinkHref={`${sprite}#icon-${isEmpty ? fail.icon : pass.icon}`}
				></use>
			</svg>
			<div
				className={styles.Name_text}
				style={isEmpty ? { color: red[600] } : {}}
			>
				{!isEmptyVal(templateName)
					? "Template 'name' is ok!"
					: "Template 'name' is required!"}
			</div>
		</div>
	);
};
const Desc = ({ vals }) => {
	const { templateDesc } = vals;
	const isEmpty = isEmptyVal(templateDesc);
	return (
		<div
			className={styles.Desc}
			style={isEmptyVal(templateDesc) ? { color: red[600] } : {}}
		>
			<svg className={styles.Desc_icon} style={isEmpty ? fail.css : pass.css}>
				<use
					xlinkHref={`${sprite}#icon-${isEmpty ? fail.icon : pass.icon}`}
				></use>
			</svg>
			<div
				className={styles.Desc_text}
				style={isEmpty ? { color: red[600] } : {}}
			>
				{!isEmptyVal(templateDesc)
					? "Template 'description' is ok!"
					: "Template 'description' is required!"}
			</div>
		</div>
	);
};

const LOCNewTemplateValidator = ({
	vals = {},
	currentFacility,
	templateValidation = {},
	confirmNewTemplate,
	cancelSaveTemplate,
}) => {
	return (
		<div className={styles.LOCNewTemplateValidator}>
			<div className={styles.LOCNewTemplateValidator_info}>
				<Name vals={vals} />
				<Desc vals={vals} />
			</div>
			<Divider />
			<div className={styles.LOCNewTemplateValidator_summary}>
				<LOCApiValidator
					key={`VALIDATOR-NEW`}
					vals={vals}
					currentFacility={currentFacility}
					locDetails={templateValidation}
					confirmSave={confirmNewTemplate}
					cancelSave={cancelSaveTemplate}
				/>
			</div>
		</div>
	);
};

export default LOCNewTemplateValidator;

LOCNewTemplateValidator.defaultProps = {};

LOCNewTemplateValidator.propTypes = {};
