import React from "react";
import styles from "../../css/facility/FacilityAccessSwitch.module.scss";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";

const FacilityAccessSwitch = ({
	appName,
	label,
	isEnabled = false,
	isLocked = false,
	handleAccess,
}) => {
	const custom = {
		opacity: isLocked ? ".4" : "1",
	};

	return (
		<div className={styles.FacilityAccessSwitch} style={custom}>
			<div className={styles.FacilityAccessSwitch_app}>
				{label}
				{isLocked && (
					<span className={styles.FacilityAccessSwitch_app_isLocked}>
						{" "}
						- Locked
					</span>
				)}
			</div>
			<div className={styles.FacilityAccessSwitch_switch}>
				<ToggleSwitch
					key={`Enabled:${isEnabled ? "true" : "false"}`}
					name={appName}
					id={appName}
					val={isEnabled}
					handleToggle={handleAccess}
					isDisabled={isLocked || !handleAccess}
				/>
			</div>
		</div>
	);
};

export default FacilityAccessSwitch;

FacilityAccessSwitch.defaultProps = {};

FacilityAccessSwitch.propTypes = {};
