import React from "react";
import styles from "../../css/settings/ManageCancellations.module.scss";
import { PropTypes } from "prop-types";

const ManageCancellations = () => {
	return (
		<div className={styles.ManageCancellations}>
			{/*  */}
			{/*  */}
			{/*  */}
		</div>
	);
};

export default ManageCancellations;

ManageCancellations.defaultProps = {};

ManageCancellations.propTypes = {};
