import React from "react";
import styles from "../../css/facility/FacilitySummary.module.scss";
import { PropTypes } from "prop-types";
import { getFacilityAddress } from "../../helpers/utils_facility";

// REQUIREMENTS:
// - Community Name
// - Exec Director
//  - Address, City, State, Zip

const FacilitySummary = ({ facility = {} }) => {
	return (
		<div className={styles.FacilitySummary}>
			<div className={styles.FacilitySummary_name}>
				{facility?.communityName}
			</div>
			<div className={styles.FacilitySummary_address}>
				{getFacilityAddress(facility)}
			</div>
			<div className={styles.FacilitySummary_residents}>
				Number of Users: <b>{facility?.users?.length ?? 0}</b>
			</div>
		</div>
	);
};

export default FacilitySummary;

FacilitySummary.defaultProps = {
	facility: {},
};

FacilitySummary.propTypes = {
	facility: PropTypes.object,
};
