import React from "react";
import styles from "../../css/loctemplate/LOCTemplateDescInput.module.scss";
import { PropTypes } from "prop-types";
import Textarea from "../shared/Textarea";

const customCSS = {
	input: {
		width: "70rem",
		backgroundColor: "#ffffff",
		// margin: "0 auto",
	},
};

const LOCTemplateDescInput = ({ vals = {}, handleChange }) => {
	return (
		<div className={styles.LOCTemplateDescInput}>
			<Textarea
				name="templateDesc"
				id="templateDesc"
				label="Template Description (required)"
				placeholder="Enter any template information you'd like..."
				val={vals?.templateDesc}
				handleChange={handleChange}
				enableCharCount={true}
				maxChar={250}
				customStyles={customCSS.input}
				required={true}
			/>
		</div>
	);
};

export default LOCTemplateDescInput;

LOCTemplateDescInput.defaultProps = {};

LOCTemplateDescInput.propTypes = {};
