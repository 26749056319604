import React, { useRef, useState } from "react";
import styles from "../../css/messages/LoginPageMessages.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyObj } from "../../helpers/utils_types";
import {
	fetchLoginPageMessages,
	processMessages,
	ACTIVE_MESSAGES,
} from "../../helpers/utils_messages";
import { blue } from "../../helpers/utils_styles";
import LoginPageMessage from "./LoginPageMessage";

// const MsgWithEmail = ({ msgStart, email, msgEnd }) => {
// 	return (
// 		<span className={styles.MsgWithEmail}>
// 			{msgStart}{" "}
// 			<a className={styles.MsgWithEmail_email} href={`mailto:${email}`}>
// 				{email}
// 			</a>{" "}
// 			{msgEnd}
// 		</span>
// 	);
// };

// const MESSAGES = [
// 	{
// 		// msg: `Advantage Care Tracker is now ready for use. Please contact support@aladvantage.com to start your 30 day free trial`,
// 		msg: (
// 			<MsgWithEmail
// 				msgStart="Advantage Care Tracker is now ready for use. Please contact"
// 				email="support@aladvantage.com"
// 				msgEnd="to start your 30 day free trial"
// 			/>
// 		),
// 		msgExpiry: new Date(2022, 6, 1),
// 		msgPriority: "MEDIUM",
// 		enableMsg: true,
// 	},
// ];

const LoginPageMessages = ({ systemUser, disableAll = true }) => {
	const [messages, setMessages] = useState([...ACTIVE_MESSAGES?.loginPage]);
	// Messages stored in database - NOT WORKING YET!!!
	// IMPLEMENT LATER!!!
	// const [messages, setMessages] = useState(async () => {
	// 	const { token } = systemUser;
	// 	const rawMsgs = await fetchLoginPageMessages(token);
	// 	const loginMsgs = processMessages(rawMsgs);
	// 	return loginMsgs;
	// });

	if (disableAll) {
		return null;
	}
	return (
		<div className={styles.LoginPageMessages}>
			{!isEmptyArray(messages) &&
				messages.map((msg, idx) => (
					<LoginPageMessage
						key={`LOGIN-MESSAGE-${idx + 1}`}
						message={msg}
						customMsgStyles={{ color: blue[600] }}
					/>
				))}
		</div>
	);
};

export default LoginPageMessages;

LoginPageMessages.defaultProps = {};

LoginPageMessages.propTypes = {};
