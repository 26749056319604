import React, { useState } from "react";
import styles from "../../css/errors/LoginError.module.scss";
import { PropTypes } from "prop-types";
import { getErrorFromUrl } from "../../helpers/utils_errors";
// error-code assets
import uiAlert from "../../assets/images/Alert.svg"; // E000
import uiWarning from "../../assets/images/Warning.svg"; // E100
import uiDenied from "../../assets/images/AccessDenied.svg"; // E200 & E500
import uiFlagged from "../../assets/images/Flagged.svg"; // E300
import uiPassword from "../../assets/images/ForgotPassword.svg"; // E400
import uiServerDown from "../../assets/images/ServerDown.svg"; // E600
import uiNotFound from "../../assets/images/PageNotFound.svg"; // E700
// components
import ForgotPasswordModal from "../app/ForgotPasswordModal";
import ForgotPassword from "../app/ForgotPassword";

// REQUIREMENTS:
// - Extract 'errorCode' from URL query params
// - Determine error message to display
// - Provide actions for user:
//    - Go back to login?
//    - Init password reset?

const errors = [
	`Account Exception`,
	`Account Expired`,
	`Account Locked`,
	`Account Suspended`,
	`Invalid Credentials`,
	`Permission Denied`,
	`Unknown Error`,
	`User Does Not Exist`,
];

const artMap = {
	E000: {
		src: uiAlert, // DONE!!
		alt: "Account Exception Illustration",
	},
	E100: {
		src: uiWarning, // DONE!!
		alt: "Account Expired Illustration",
	},
	E200: {
		src: uiDenied, // DONE!!
		alt: "Account Locked Illustration",
	},
	E300: {
		src: uiFlagged, // DONE!!
		alt: "Account Suspended Illustration",
	},
	E400: {
		src: uiPassword, // DONE!!
		alt: "Invalid Credentials Illustration",
	},
	E500: {
		src: uiDenied, // DONE!!
		alt: "Permission Denied Illustration",
	},
	E600: {
		src: uiServerDown, // DONE!!
		alt: "Unknown Error Illustration",
	},
	E700: {
		src: uiNotFound, // DONE!!
		alt: "User Does Not Exist Illustration",
	},
};

const DesignUI = ({ errorCode = `E700` }) => {
	const { alt, src } = artMap[errorCode];

	return (
		<div className={styles.DesignUI}>
			<img src={src} alt={alt} className={styles.DesignUI_img} />
		</div>
	);
};

const ErrorDetails = ({ errorInfo }) => {
	return (
		<div className={styles.ErrorDetails}>
			<div className={styles.ErrorDetails_reason}>{errorInfo.name}</div>
			<div className={styles.ErrorDetails_desc}>{errorInfo.desc}</div>
		</div>
	);
};

const LoginAction = ({ goToLogin }) => {
	return (
		<button type="button" onClick={goToLogin} className={styles.LoginAction}>
			Go to Login Page
		</button>
	);
};
const ResetAction = ({ initReset }) => {
	return (
		<button type="button" onClick={initReset} className={styles.ResetAction}>
			Reset Password
		</button>
	);
};

const LoginError = ({ history }) => {
	const [error, setError] = useState(() => {
		return getErrorFromUrl(window.location);
	});
	// reset state(s)
	const [showResetModal, setShowResetModal] = useState(false);

	const goToLogin = () => {
		history.replace("/");
	};
	const initReset = () => {
		setShowResetModal(true);
	};

	return (
		<>
			<div className={styles.LoginError}>
				<DesignUI errorCode={error.code} />
				<ErrorDetails errorInfo={error} />
				<div className={styles.LoginError_actions}>
					<LoginAction goToLogin={goToLogin} />
					<ResetAction initReset={initReset} />
				</div>
			</div>

			{showResetModal && (
				<ForgotPasswordModal
					key={`ERROR-PAGE-MODAL`}
					closeModal={() => setShowResetModal(false)}
				>
					<ForgotPassword
						key={`ERROR-PAGE-RESET`}
						closeModal={() => setShowResetModal(false)}
					/>
				</ForgotPasswordModal>
			)}
		</>
	);
};

export default LoginError;
export { DesignUI, ErrorDetails, LoginAction, ResetAction };

LoginError.defaultProps = {};

LoginError.propTypes = {};
