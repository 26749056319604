import React from "react";
import styles from "../../css/loc/LOCDescCell.module.scss";
import { PropTypes } from "prop-types";
import LOCTableCell from "./LOCTableCell";

const LOCDescCell = ({
	rowData,
	updateTableData,
	rowIdx,
	cellIdx,
	isEditable = true,
	allowedChars = /.*/gim,
}) => {
	return (
		<>
			<LOCTableCell
				key={`Row${rowIdx}--Cell-${cellIdx}`}
				disableEdit={!isEditable}
				rowIdx={rowIdx}
				cellIdx={cellIdx}
				cellData={rowData["LevelDescription"]}
				cellKey="LevelDescription"
				updateTableData={updateTableData}
				allowedChars={allowedChars}
				inputMode="text"
			/>
		</>
	);
};

export default LOCDescCell;

LOCDescCell.defaultProps = {};

LOCDescCell.propTypes = {
	rowData: PropTypes.object,
	updateTableData: PropTypes.func,
	rowIdx: PropTypes.number,
	cellIdx: PropTypes.number,
	isEditable: PropTypes.bool,
	allowedChars: PropTypes.instanceOf(RegExp),
};
