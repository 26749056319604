import React from "react";
import styles from "../../css/loctemplate/LOCTemplateTableBody.module.scss";
import { PropTypes } from "prop-types";

const LOCTemplateTableBody = ({ isEditable = true, children }) => {
	return (
		<fieldset disabled={!isEditable} className={styles.LOCTemplateTableBody}>
			<div className={styles.LOCTemplateTableBody_body}>{children}</div>
		</fieldset>
	);
};

export default LOCTemplateTableBody;

LOCTemplateTableBody.defaultProps = {};

LOCTemplateTableBody.propTypes = {};
