import React from "react";
import styles from "../../css/migration/MigrationPanelActions.module.scss";
import { PropTypes } from "prop-types";
import { blueGrey, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	cancelBtn: {
		padding: "1rem 1.6rem",
		backgroundColor: "transparent",
		// color: red[600],
		color: blueGrey[700],
		fontSize: "1.7rem",
		fontWeight: "600",
		marginRight: "2rem",
	},
	startBtn: {
		padding: "1rem 1.6rem",
		backgroundColor: red[600],
		color: red[50],
		fontSize: "1.7rem",
		fontWeight: "600",
	},
};

const enableMigrationBtn = (currentFacility) => {
	return !isEmptyVal(currentFacility?.facilityID);
};

const MigrationPanelActions = ({
	currentFacility = {},
	cancelMigration,
	startMigration,
}) => {
	return (
		<div className={styles.MigrationPanelActions}>
			<ButtonSM
				type="button"
				customStyles={customCSS.cancelBtn}
				handleClick={cancelMigration}
			>
				<span>Cancel</span>
			</ButtonSM>
			<ButtonSM
				type="button"
				isDisabled={!enableMigrationBtn(currentFacility)}
				customStyles={customCSS.startBtn}
				handleClick={startMigration}
			>
				<span>Start Migration</span>
			</ButtonSM>
		</div>
	);
};

export default MigrationPanelActions;

MigrationPanelActions.defaultProps = {};

MigrationPanelActions.propTypes = {
	cancelMigration: PropTypes.func,
	startMigration: PropTypes.func,
};
