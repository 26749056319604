import React from "react";
import styles from "../../css/widget/FeedbackOption.module.scss";
import sprite from "../../assets/icons/settings.svg";
import bugIcon from "../../assets/icons/bug.svg";
import lightIcon from "../../assets/icons/lightbulb.svg";
import dotsIcon from "../../assets/icons/dots.svg";
import { PropTypes } from "prop-types";

const ICONS = {
	bug: "bug",
	lightbulb: "light-on",
};

const BugIcon = () => {
	return <img src={bugIcon} alt="Issue Icon" className={styles.BugIcon} />;
};
const LightIcon = () => {
	return <img src={lightIcon} alt="Issue Icon" className={styles.LightIcon} />;
};
const DotsIcon = () => {
	return (
		<svg className={styles.DotsIcon}>
			<use xlinkHref={`${sprite}#icon-dots-three-horizontal`}></use>
		</svg>
	);
};

const FeedbackOption = ({ goToStep, stepIcon, stepTitle }) => {
	return (
		<button className={styles.FeedbackOption} onClick={goToStep}>
			{stepIcon === "bug" && <BugIcon />}
			{stepIcon === "lightbulb" && <LightIcon />}
			{stepIcon === "other" && <DotsIcon />}
			<span className={styles.FeedbackOption_stepTitle}>{stepTitle}</span>
		</button>
	);
};

export default FeedbackOption;

FeedbackOption.defaultProps = {};

FeedbackOption.propTypes = {};
