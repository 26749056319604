import React, { useState, useEffect } from "react";
import styles from "../../css/user/EditUsername.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { blueGrey, red } from "../../helpers/utils_styles";
import { changeUsername } from "../../helpers/utils_user";
// components
import EmailValidator from "../forms/EmailValidator";
import ButtonSM from "../shared/ButtonSM";
import { isEmptyVal } from "../../helpers/utils_types";
import CustomCheckbox from "../shared/CustomCheckbox";
import { isValidEmail } from "../../helpers/utils_validation";

// REQUIREMENTS:
// - Edit 'username/email'
// - For saving changes:
// 		- MUST save to 'ADVUSER' table
// 		- MUST save to 'UserLogin' record

const customCSS = {
	cancel: {
		padding: ".6rem 1.5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.5rem",
		fontWeight: "500",
		borderRadius: "5rem",
	},
	save: {
		padding: ".7rem 1.5rem",
		backgroundColor: blueGrey[700],
		color: "#ffffff",
		fontSize: "1.5rem",
		fontWeight: "500",
		borderRadius: "5rem",
		marginLeft: ".5rem",
	},
	username: {
		backgroundColor: "#eaecef",
	},
};

// confirms user entered new username & it's in 'email' format
const enableBtn = (formState, targetUser) => {
	const { values, touched } = formState;
	const { editUsername } = values;
	const { editUsername: wasTouched } = touched;
	const isEmailFormat = isValidEmail(editUsername);
	// original username/email
	const oldUsername = targetUser?.username ?? targetUser?.email;

	// REQUIREMENTS:
	// - 'editUsername' is filled in
	// - 'editUsername' is NOT the same as their original username
	// - 'editUsername' was touched
	// - 'editUsername' is in email format (eg. 'email@example.com')
	const shouldEnable =
		!isEmptyVal(editUsername) && editUsername !== oldUsername && wasTouched;

	return shouldEnable && isEmailFormat;
};

const EditUsername = ({
	globalState,
	targetUser,
	currentUser,
	dispatchAlert,
	dispatchToState,
}) => {
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			editUsername: "",
			isValidEmail: false,
			isPrimaryEmail: false, // not implemented, yet
			isEmailVerified: false, // not implemented, yet
		});
	const { values } = formState;
	// enables/disables 'save' button
	const [isDisabled, setIsDisabled] = useState(true);
	const [wasCleared, setWasCleared] = useState(false);

	const handleEmailCheck = (e) => {
		const { name, checked } = e.target;
		if (name === "isValidEmail" && checked) {
			return setFormState({
				...formState,
				values: {
					...values,
					[name]: checked,
					isEmailVerified: true,
				},
			});
		} else {
			return setFormState({
				...formState,
				values: {
					...values,
					[name]: checked,
				},
			});
		}
	};

	// saves 'new username'
	const saveUsernameChange = async () => {
		const { token } = currentUser;
		const wasChanged = await changeUsername(token, {
			userID: targetUser?.userID, // target user's ID
			editUsername: values?.editUsername,
			isValidEmail: values?.isValidEmail,
		});

		if (wasChanged) {
			return dispatchAlert("SUCCESS", {
				heading: `Username was updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Update failed!`,
				subheading: `Please try again!`,
			});
		}
	};

	const cancelChanges = (e) => {
		handleReset(e);
		setWasCleared(true);
	};

	// enables 'save' button after new username is entered
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setIsDisabled(true);
		if (enableBtn(formState, targetUser)) {
			setIsDisabled(false);
		}

		return () => {
			isMounted = false;
		};
	}, [formState, targetUser]);

	// resets 'wasCleared' state
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (wasCleared) {
			timerID = setTimeout(() => {
				setWasCleared(false);
			}, 800);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasCleared]);

	return (
		<div className={styles.EditUsername}>
			<div className={styles.EditUsername_about}>
				<div className={styles.EditUsername_about_desc}>
					Edit this user's login name such as username or email. Please proceed
					with caution!
				</div>
				<p className={styles.EditUsername_about_warning}>
					Notice: MUST be in email format. (eg. 'something@something.com')
				</p>
			</div>
			<div className={styles.EditUsername_form}>
				<div className={styles.EditUsername_form_field}>
					<EmailValidator
						key={`NEW-USERNAME`}
						label="Edit Username"
						name="editUsername"
						id="editUsername"
						// initialVal={values?.editUsername}
						wasCleared={wasCleared}
						handleChange={handleChange}
						token={currentUser?.token}
						customStyles={customCSS.username}
					/>
				</div>
				<div className={styles.EditUsername_form_field}>
					<CustomCheckbox
						key="IS-EMAIL-VALID"
						label="Is this a working email?"
						name="isValidEmail"
						id="isValidEmail"
						val={values.isValidEmail}
						handleCheckbox={handleEmailCheck}
						// handleCheckbox={handleCheckbox}
					/>
				</div>
				{!isDisabled && (
					<div className={styles.EditUsername_form_info}>
						<p className={styles.EditUsername_form_info_warning}>
							Please logout completely after saving to see changes reflected!
						</p>
					</div>
				)}
				<div className={styles.EditUsername_form_actions}>
					<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={isDisabled}
						handleClick={saveUsernameChange}
						customStyles={customCSS.save}
					>
						Save Changes
					</ButtonSM>
				</div>
			</div>
		</div>
	);
};

export default EditUsername;

EditUsername.defaultProps = {};

EditUsername.propTypes = {
	targetUser: PropTypes.object,
	currentUser: PropTypes.object,
	dispatchAlert: PropTypes.func,
};
