import React, { useState } from "react";
import styles from "../../css/summary/QuickSummary.module.scss";
import sprite from "../../assets/icons/alerts.svg";
import { PropTypes } from "prop-types";
import AlertBanner from "../alerts/AlertBanner";

// REQUIREMENTS:
// - Message: "5 Users Need Assistance!!"

const banner = {
	error: {
		heading: `8 Users Need Help!`,
		subheading: `You have 8 lockout notices.`,
		type: "ERROR",
	},
	success: {
		heading: `You're all set!`,
		subheading: `No alerts right now. Check back later!!`,
		type: "SUCCESS",
	},
	warning: {
		heading: `Warning!`,
		subheading: `3 Users have 1 or more failed login attempts.`,
		type: "WARNING",
	},
	info: {
		heading: `Info!`,
		subheading: `We've made some improvements. Learn more!`,
		type: "INFO",
	},
};

const QuickSummary = ({ handleViewLockouts }) => {
	const [showAlertBanner, setShowAlertBanner] = useState(true);

	return (
		<div className={styles.QuickSummary}>
			{showAlertBanner && (
				<AlertBanner
					type={banner["error"].type}
					heading={banner["error"].heading}
					subheading={banner["error"].subheading}
					closeAlert={() => setShowAlertBanner(false)}
				/>
			)}
		</div>
	);
};

export default QuickSummary;

QuickSummary.defaultProps = {};

QuickSummary.propTypes = {};
