import React, { useState } from "react";
import styles from "../../css/userTypes/UserType.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";

const getStatusCss = (isActive) => {
	if (isActive) return {};

	return {
		opacity: ".6",
	};
};

const StatusBadge = ({ isActive, disableUserType, enableUserType }) => {
	if (!isActive) {
		return (
			<button
				type="button"
				onClick={enableUserType}
				className={styles.InActiveBadge}
				title="Click to enable"
			>
				<div className={styles.InActiveBadge_text}>Inactive</div>
			</button>
		);
	}
	return (
		<button
			type="button"
			onClick={disableUserType}
			className={styles.ActiveBadge}
			title="Click to disable"
		>
			<div className={styles.ActiveBadge_text}>Active</div>
		</button>
	);
};

const UserType = ({
	userType = {},
	removeUserType,
	editUserType,
	disableUserType,
	enableUserType,
}) => {
	const [isActive, setIsActive] = useState(userType?.isActive);

	const handleDisable = () => {
		setIsActive(false);
		disableUserType(userType);
	};
	const handleEnable = () => {
		setIsActive(true);
		enableUserType(userType);
	};

	return (
		<div className={styles.UserType} style={getStatusCss(isActive)}>
			<div className={styles.UserType_left}>
				<div className={styles.UserType_left_id}>
					<span>{userType.typeID}</span>
				</div>
			</div>
			<div className={styles.UserType_main}>
				<div className={styles.UserType_main_details}>
					<div className={styles.UserType_main_details_name}>
						{userType?.name}
					</div>
					<div className={styles.UserType_main_details_desc}>
						{userType?.desc}
					</div>
				</div>
				<div className={styles.UserType_main_status}>
					<StatusBadge
						isActive={isActive}
						enableUserType={handleEnable}
						disableUserType={handleDisable}
					/>
				</div>
			</div>
			<div className={styles.UserType_actions}>
				<div className={styles.UserType_actions_remove}>
					<svg
						className={styles.UserType_actions_remove_icon}
						onClick={removeUserType}
					>
						<use xlinkHref={`${sprite}#icon-delete`}></use>
					</svg>
				</div>
				<div className={styles.UserType_actions_edit}>
					<svg
						className={styles.UserType_actions_edit_icon}
						onClick={editUserType}
					>
						<use xlinkHref={`${sprite}#icon-createmode_editedit`}></use>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default UserType;

UserType.defaultProps = {};

UserType.propTypes = {
	userType: PropTypes.shape({
		typeID: PropTypes.number,
		name: PropTypes.string,
		desc: PropTypes.string,
		isActive: PropTypes.bool,
	}),
};
