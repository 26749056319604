import React from "react";
import styles from "../../css/admin/UserSelectorOption.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";
import { getUserEmail, getUserName } from "../../helpers/utils_user";
import { purple } from "../../helpers/utils_styles";

// styles ONLY the top-leve user's name (eg. first/last)
const getNameCSS = (isSelected, isDisabled) => {
	if (isDisabled) {
		return {
			opacity: ".6",
			cursor: "not-allowed",
		};
	} else if (isSelected) {
		return {
			color: purple[600],
		};
	} else {
		return {};
	}
};

const UserSelectorOption = ({
	isDisabled = false,
	isSelected = false,
	option = {},
	handleSelect,
}) => {
	const custom = {
		opacity: isDisabled ? ".4" : "1",
		cursor: isDisabled ? "not-allowed" : "pointer",
	};
	return (
		<li
			className={
				isSelected
					? styles.UserSelectorOption_isSelected
					: styles.UserSelectorOption
			}
			onClick={isDisabled ? null : () => handleSelect(option)}
			style={custom}
		>
			<div className={styles.UserSelectorOption_text}>
				<div
					className={styles.UserSelectorOption_text_name}
					style={getNameCSS(isSelected, isDisabled)}
				>
					{getUserName(option, 45)}
				</div>
				<div className={styles.UserSelectorOption_text_email}>
					{getUserEmail(option)}
				</div>
			</div>
			{isSelected && (
				<svg className={styles.UserSelectorOption_icon}>
					<use xlinkHref={`${sprite}#icon-checkmark-outline`}></use>
				</svg>
			)}
		</li>
	);
};

export default UserSelectorOption;

// single dropdown menu option
UserSelectorOption.defaultProps = {
	isSelected: false,
};
UserSelectorOption.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	handleSelect: PropTypes.func.isRequired,
};
