import React from "react";
import styles from "../../css/shared/HiddenChar.module.scss";
import { PropTypes } from "prop-types";

const HiddenChar = ({ char, customStyles = {} }) => {
	return (
		<span className={styles.HiddenChar} style={customStyles}>
			&#9679;
		</span>
	);
};

export default HiddenChar;

HiddenChar.defaultProps = {};

HiddenChar.propTypes = {
	char: PropTypes.string,
};
