import React from "react";
import styles from "../../css/residents/ResidentsTableColumn.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

const checkIfSorted = (isSorted, sortType, column) => {
	if (!isSorted) return;
	return sortType === column;
};

const ResidentsTableColumn = ({ column, isSorted = false, sortType }) => {
	const getIconCSS = (isSorted, sortType, column) => {
		const ifSorted = checkIfSorted(isSorted, sortType, column);
		return ifSorted
			? { transform: "rotate(180deg)" }
			: { transform: "rotate(0deg)" };
	};

	return (
		<div className={styles.ResidentsTableColumn}>
			<div className={styles.ResidentsTableColumn_inner}>
				<span className={styles.ResidentsTableColumn_inner_column}>
					{column}
				</span>
				<svg
					className={styles.ResidentsTableColumn_inner_icon}
					style={getIconCSS(isSorted, sortType, column)}
				>
					<use xlinkHref={`${sprite}#icon-filter_list`}></use>
				</svg>
			</div>
		</div>
	);
};

export default ResidentsTableColumn;

ResidentsTableColumn.defaultProps = {};

ResidentsTableColumn.propTypes = {};
