import React from "react";
import styles from "../../css/app/SidebarVersion.module.scss";
import { PropTypes } from "prop-types";
import { versionRelease } from "../../helpers/utils_version";

const SidebarVersion = ({ isCollapsed = false, winSize = {} }) => {
	const { width, height } = winSize;

	if (isCollapsed) {
		return null;
	}
	return (
		<div className={styles.SidebarVersion}>
			<span className={styles.SidebarVersion_version}>{versionRelease}</span>
		</div>
	);
};

export default SidebarVersion;

SidebarVersion.defaultProps = {};

SidebarVersion.propTypes = {
	isCollapsed: PropTypes.bool,
};
