import React, { useState, useEffect } from "react";
import styles from "../../css/admin/ChangeUserType.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	getAllowedUserTypes,
	USER_TYPES as allUserTypes,
} from "../../helpers/utils_userTypes";
import { blueGrey } from "../../helpers/utils_styles";
import { updateUserType } from "../../helpers/utils_userTypes";
// components
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";
import SuccessIndicator from "../shared/SuccessIndicator";

// REQUIREMENTS:
// - Updated user types to only show user types that user should have access to.

const customCSS = {
	save: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancel: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${blueGrey[400]}`,
		marginRight: "1rem",
	},
	types: {
		width: "30rem",
	},
};

const enableBtn = (targetUserType) => {
	const hasType = !isEmptyVal(targetUserType);

	return hasType;
};

const Success = () => {
	return (
		<div className={styles.Success}>
			<SuccessIndicator
				icon="checks"
				msg="User Type was updated!"
				msgColor="#ffffff"
				iconColor="#ffffff"
			/>
		</div>
	);
};

const ChangeUserType = ({
	globalState,
	targetUser,
	currentUser,
	dispatchAlert,
	dispatchToState,
	tableDispatch,
}) => {
	const [selectedType, setSelectedType] = useState("");
	const [userTypeWasUpdated, setUserTypeWasUpdated] = useState(false);

	const handleUserType = (name, type) => {
		setSelectedType(type);
	};

	const saveChanges = async (e) => {
		const { token } = currentUser;
		const { userID } = targetUser;
		const userWasUpdated = await updateUserType(token, userID, selectedType);

		if (userWasUpdated) {
			setUserTypeWasUpdated(true);
			return dispatchToState({
				type: "USER_TYPE_CHANGE",
				data: {
					targetUserID: userID,
					newUserType: selectedType,
				},
			});
		} else {
			return setUserTypeWasUpdated(false);
		}
	};

	const cancelChanges = (e) => {
		setSelectedType("");
	};

	// resets 'was updated' state after 4secs
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (userTypeWasUpdated) {
			timerID = setTimeout(() => {
				setUserTypeWasUpdated(false);
			}, 4000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [userTypeWasUpdated]);

	return (
		<div className={styles.ChangeUserType}>
			<div className={styles.ChangeUserType_about}>
				<div className={styles.ChangeUserType_about_desc}>
					Change this user's user type to 'Admin', 'Facility Admin' etc.
				</div>
			</div>
			<div className={styles.ChangeUserType_form}>
				<div className={styles.ChangeUserType_form_field}>
					<CustomDropdown
						name="userType"
						id="userType"
						placeholder={`Choose a user type...`}
						selection={selectedType}
						setSelection={handleUserType}
						options={[...getAllowedUserTypes(currentUser, allUserTypes)]}
						customStyles={customCSS.types}
					/>
				</div>

				<div className={styles.ChangeUserType_form_msg}>
					{userTypeWasUpdated && <Success />}
				</div>

				<div className={styles.ChangeUserType_form_actions}>
					<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={!enableBtn(selectedType)}
						handleClick={saveChanges}
						customStyles={customCSS.save}
					>
						Save Changes
					</ButtonSM>
				</div>
			</div>
		</div>
	);
};

export default ChangeUserType;

ChangeUserType.defaultProps = {};

ChangeUserType.propTypes = {
	targetUser: PropTypes.object,
	currentUser: PropTypes.object,
	dispatchToState: PropTypes.func,
	tableDispatch: PropTypes.func,
	dispatchAlert: PropTypes.func,
};
