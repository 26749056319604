import React from "react";
import styles from "../../css/user/UserAppSummary.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

// REQUIREMENTS:
// - Show Application Access settings

const Enabled = () => {
	return (
		<div className={styles.Enabled}>
			<svg className={styles.Enabled_icon}>
				<use xlinkHref={`${sprite}#icon-check-circle`}></use>
			</svg>
			<span className={styles.Enabled_label}>Enabled</span>
		</div>
	);
};
const Disabled = () => {
	return (
		<div className={styles.Disabled}>
			<svg className={styles.Disabled_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose1`}></use>
			</svg>
			<span className={styles.Disabled_label}>Disabled</span>
		</div>
	);
};

const UserAppSummary = ({ appAccess = {} }) => {
	const { trackerAccess, legacyAccess } = appAccess;

	return (
		<article className={styles.UserAppSummary}>
			<div className={styles.UserAppSummary_header}>
				<div className={styles.UserAppSummary_header_title}>
					Application Access Summary
				</div>
			</div>
			<div className={styles.UserAppSummary_main}>
				<div className={styles.UserAppSummary_main_item}>
					<div className={styles.UserAppSummary_main_item_label}>
						Care Tracker App
					</div>
					<div className={styles.UserAppSummary_main_item_access}>
						{trackerAccess ? <Enabled /> : <Disabled />}
					</div>
				</div>
				<div className={styles.UserAppSummary_main_item}>
					<div className={styles.UserAppSummary_main_item_label}>
						Senior Care (EHR) App
					</div>
					<div className={styles.UserAppSummary_main_item_access}>
						{legacyAccess ? <Enabled /> : <Disabled />}
					</div>
				</div>
			</div>
		</article>
	);
};

export default UserAppSummary;

UserAppSummary.defaultProps = {};

UserAppSummary.propTypes = {
	appAccess: PropTypes.shape({
		trackerAccess: PropTypes.bool,
		legacyAccess: PropTypes.bool,
	}),
};
