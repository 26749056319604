import React from "react";
import styles from "../../css/loctemplate/LOCTemplateTableRow.module.scss";
import sprite from "../../assets/icons/tables.svg";
import { PropTypes } from "prop-types";
import { allowedCharsByCell } from "../../helpers/utils_loctemplate";
// components
import LOCTemplateTableCell from "./LOCTemplateTableCell";

const customCSS = {
	levelCell: {
		opacity: ".7",
		cursor: "not-allowed",
	},
};

const { LevelDescription, LevelNumber, PointsMin, PointsMax, Pricing } =
	allowedCharsByCell;

const DeleteButton = ({ isDisabled = false, handleDeleteRow }) => {
	return (
		<button
			type="button"
			onClick={handleDeleteRow}
			disabled={isDisabled}
			className={styles.DeleteButton}
		>
			<svg className={styles.DeleteButton_icon}>
				<use xlinkHref={`${sprite}#icon-delete`}></use>
			</svg>
		</button>
	);
};

const LOCTemplateTableRow = ({
	rowData = {},
	rowIdx,
	isDeleteDisabled = false,
	isEditable = true,
	handleDeleteRow,
	updateTableData,
}) => {
	return (
		<div className={styles.LOCTemplateTableRow}>
			<div className={styles.LOCTemplateTableRow_inner}>
				<LOCTemplateTableCell
					cellIdx={0}
					cellKey="LevelDescription"
					rowIdx={rowIdx}
					disableEdit={!isEditable}
					cellData={rowData["LevelDescription"]}
					updateTableData={updateTableData}
					allowedChars={LevelDescription.allowed}
					inputMode={LevelDescription.mode}
				/>
				<LOCTemplateTableCell
					cellIdx={1}
					cellKey="LevelNumber"
					rowIdx={rowIdx}
					disableEdit={true}
					cellData={rowIdx + 1} // ONLY APPLIES HERE
					// cellData={rowData["LevelNumber"]} // removed this to overwrite 'LevelNumber' field in the UI
					updateTableData={updateTableData}
					allowedChars={LevelNumber.allowed}
					inputMode={LevelNumber.mode}
					customStyles={customCSS.levelCell}
				/>
				<LOCTemplateTableCell
					cellIdx={2}
					cellKey="PointsMin"
					rowIdx={rowIdx}
					disableEdit={!isEditable}
					cellData={rowData["PointsMin"]}
					updateTableData={updateTableData}
					allowedChars={PointsMin.allowed}
					inputMode={PointsMin.mode}
				/>
				<LOCTemplateTableCell
					cellIdx={3}
					cellKey="PointsMax"
					rowIdx={rowIdx}
					disableEdit={!isEditable}
					cellData={rowData["PointsMax"]}
					updateTableData={updateTableData}
					allowedChars={PointsMax.allowed}
					inputMode={PointsMax.mode}
				/>
				<LOCTemplateTableCell
					cellIdx={4}
					cellKey="Pricing"
					rowIdx={rowIdx}
					disableEdit={!isEditable}
					cellData={rowData["Pricing"]}
					updateTableData={updateTableData}
					allowedChars={Pricing.allowed}
					inputMode={Pricing.mode}
				/>
			</div>
			<DeleteButton
				key={`DELETE-${rowData?.FloorUnit}-${rowIdx}`}
				isDisabled={isDeleteDisabled || !isEditable}
				handleDeleteRow={handleDeleteRow}
			/>
		</div>
	);
};

export default LOCTemplateTableRow;

LOCTemplateTableRow.defaultProps = {
	rowData: {},
	isDeleteDisabled: false,
	isEditable: true,
};

LOCTemplateTableRow.propTypes = {
	rowData: PropTypes.shape({
		ID: PropTypes.number,
		FloorUnit: PropTypes.string,
		FacilityID: PropTypes.string,
		LOCType: PropTypes.string,
		LevelDescription: PropTypes.string,
		LevelNumber: PropTypes.number,
		PointsMin: PropTypes.number,
		PointsMax: PropTypes.number,
		Pricing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	rowIdx: PropTypes.number,
	isDeleteDisabled: PropTypes.bool,
	isEditable: PropTypes.bool,
	handleDeleteRow: PropTypes.func,
	updateTableData: PropTypes.func,
};
