import React from "react";
import styles from "../../css/app/MaintenanceMode.module.scss";
import sprite from "../../assets/icons/maintenance-mode.svg";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

const iconsMap = {
	goForward: "arrow-forward",
	goBack: "arrow-back",
	errorSolid: "error",
	errorOutline: "error_outline",
	warning: "warning",
	alertUrgent: "notification_important",
	alertNew: "new_releases",
	notAllowed: "not_interested",
	close: "clear",
	flag: "flag",
	caretLeft: "navigate_before",
	caretRight: "navigate_next",
	stoplight: "traffic",
	exclamationPoint: "priority_high",
	globe: "public",
	tool: "build",
	help: "help",
	infoSolid: "info",
	infoOutline: "info_outline",
	settings: "settings",
	lightbulb: "lightbulb_outline",
	hand: "pan_tool",
	inbox: "all_inbox",
	busAlert: "bus_alert",
	alertCircle: "circle_notifications",
	noDesktop: "desktop_access_disabled",
	coffee: "emoji_food_beverage",
	hanger: "checkroom",
	tools: "construction",
	noHand: "do_not_touch",
	fire: "local_fire_department",
	lockedClock: "lock_clock",
	clipboardPending: "pending_actions",
	pest: "pest_control_rodent",
	globe2: "globe",
	network: "network",
	trafficCone: "traffic-cone",
};

const MaintenanceMode = ({
	title,
	desc,
	icon = "trafficCone",
	history,
	children,
	disableNav = false,
	customStyles = {},
}) => {
	const goBack = () => {
		history.goBack();
	};

	return (
		<div className={styles.MaintenanceMode}>
			<div className={styles.MaintenanceMode_header}>
				<svg
					className={styles.MaintenanceMode_header_icon}
					style={customStyles.icon}
				>
					<use xlinkHref={`${sprite}#icon-${iconsMap[icon]}`}></use>
				</svg>
				<h2
					className={styles.MaintenanceMode_header_title}
					style={customStyles.title}
				>
					{title}
				</h2>
				<p
					className={styles.MaintenanceMode_header_desc}
					style={customStyles.desc}
				>
					{desc}
				</p>
			</div>
			{!disableNav && (
				<div className={styles.MaintenanceMode_nav}>
					<div className={styles.MaintenanceMode_nav_goBack} onClick={goBack}>
						<svg className={styles.MaintenanceMode_nav_goBack_icon}>
							<use xlinkHref={`${sprite}#icon-${iconsMap["goBack"]}`}></use>
						</svg>
						<span className={styles.MaintenanceMode_nav_goBack_label}>
							Go Back
						</span>
					</div>
				</div>
			)}
			<div className={styles.MaintenanceMode_children}>{children}</div>
		</div>
	);
};

export default withRouter(MaintenanceMode);

MaintenanceMode.defaultProps = {};

MaintenanceMode.propTypes = {
	history: PropTypes.any,
};
