import React from "react";
import { Route } from "react-router-dom";
import { isEmptyArray } from "./utils_types";
import { featuresWhiteList, internalWhiteList } from "./utils_permissions";
import { removeDups } from "./utils_processing";
import { isDevOrTest, isLocalhost } from "./utils_env";
// components
import ListItem from "../components/app/ListItem";
import SubList from "../components/app/SubList";
import SubListItem from "../components/app/SubListItem";
// routes, views & pages
import { PortalPageCard } from "../components/app/PortalOptions";
import Home from "../pages/Home";
import UserPage from "../pages/UserPage";
import AdminPage from "../pages/AdminPage";
import YourAppsPage from "../pages/YourAppsPage";
import MigrationToolPage from "../pages/MigrationToolPage"; // white-listed
import SettingsPage from "../pages/SettingsPage"; // internal-listed
import InternalToolsPage from "../pages/InternalToolsPage"; // internal-listed
import LevelsOfCarePage from "../pages/LevelsOfCarePage"; // white-listed
// IN-PROGRESS, DEPRECATED and/or DISABLED PAGES
import UserSummaryPage from "../pages/UserSummaryPage";
import UserManagementPage from "../pages/UserManagementPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import DocsPage from "../pages/DocsPage"; // internal-listed
import PermissionsPanelPage from "../pages/PermissionsPanelPage";
import ContactUsPage from "../pages/ContactUsPage";

// OPTIONS LIST:
// - 'Your Apps' 					✓ - not-restricted
// - 'Your Documents'			✓ - not-restricted
// - 'User Profile' 			✓ - not-restricted
// - 'User Access' 				X - restricted
// - 'Facility Access' 		X - restricted
// - 'Migration Tool'     X - restricted & white-listed
// - 'Manage Settings' 		X - restricted
// - 'Internal Tools'     X - restricted & disabled & internal-list ONLY

const WHITELISTED_OPTIONS = {
	hiddenRoutes: [
		"/migration",
		// "/contact",
		// isDevOrTest() && "/loc"
	],
	hiddenCards: [
		"Migration Tool",
		// "Contact Us",
		// isDevOrTest() && "Levels of Care"
	],
};

/**
 * For users on the 'internalWhiteList':
 * - ONLY sgore99
 */
const INTERNAL_ONLY_OPTIONS = {
	hiddenRoutes: [
		"/internal",
		"/settings",
		"/docs",
		// !isDevOrTest() && "/loc",
		"/permissions",
		// "/contact",
	],
	hiddenCards: [
		"Internal Tools",
		"Manage Settings",
		"Your Documents",
		// !isDevOrTest() && "Levels of Care",
		"Permissions Panel",
		// "Contact Us",
	],
};

/**
 * List of 'hidden' pages, views & portal-cards for 'PRODUCTION' environments.
 * - 'User Management'
 * - 'User Summary'
 */
const EXPERIMENTAL_OPTIONS = {
	hiddenRoutes: ["/summary", "/users", "/migration"],
	hiddenCards: [
		"User Management",
		"User Summary", // disabled
		"Migration Tool",
	],
};

/**
 * Default 'Portal' Page Options:
 * - Excludes 'Your Documents' and other views.
 */
const defaultPortalOptions = {
	icons: {
		"Your Apps": "dashboard",
		"User Profile": "person_outlineperm_identity",
		// "Facility Access": "location_city",
		"Facility Management": "location_city",
		"User Access": "people_outline",
		"Manage Settings": "build",
		"User Summary": "switch_account",
		"Migration Tool": "cloud-storage-2",
		"User Management": "people_outline",
	},
	pages: [
		{
			name: "Your Apps",
			icon: "Your Apps",
			path: "/apps",
			target: "apps",
			isRestricted: false,
			isActive: true,
		},
		{
			name: "User Profile",
			icon: "User Profile",
			path: "/profile",
			target: "profile",
			isRestricted: false,
			isActive: true,
		},
		{
			name: "Facility Management",
			icon: "Facility Management",
			path: "/access",
			target: "facility",
			isRestricted: true,
			isActive: false,
		},
		{
			name: "User Access",
			icon: "User Access",
			path: "/access",
			target: "user",
			isRestricted: true,
			isActive: false,
		},
		{
			name: "Manage Settings",
			icon: "Manage Settings",
			path: "/settings",
			target: "settings",
			isRestricted: true,
			isActive: false,
		},
		// NEW USER SUMMARY
		{
			name: "User Summary",
			icon: "User Summary",
			path: "/summary",
			target: "summary",
			isRestricted: true,
			isActive: true,
		},
		// MIGRATION TOOL
		{
			name: "Migration Tool",
			icon: "Migration Tool",
			path: "/migration",
			target: "migration",
			isRestricted: true,
			isActive: true,
		},
		// NEW USER MGMT - ONLY FOR LOCAL/TEST ENV
		{
			name: "User Management",
			icon: "User Management",
			path: "/users",
			target: "users",
			isRestricted: true,
			isActive: true,
		},
		// CONTACT US - ONLY FOR LOCAL/TEST ENV
		// {
		// 	name: "Contact Us",
		// 	icon: "Contact Us",
		// 	path: "/contact",
		// 	target: "contact",
		// 	isRestricted: true,
		// 	isActive: false,
		// },
	],
};

/**
 * Portal Page 'Card' Options:
 * - Includes icons' map.
 * - Includes page options' config in list-form.
 */
const portalPageOptions = {
	icons: {
		"Your Apps": "dashboard",
		"User Profile": "person_outlineperm_identity",
		"Facility Access": "location_city", // PREVIOUS NAMING #1 (oldest)
		// "Facility App Access": "location_city", // PREVIOUS NAMING #2 (newest)
		"Facility Management": "location_city", // CURRENT NAMING
		"Facility Info": "settings1", // NEW TAB VIEW (Facility Profile)
		"User Access": "people_outline",
		"Existing Users": "people_outline",
		"Manage Settings": "build", // NEW TAB ('UserTypes', 'Exceptions', 'Cancellations' etc)
		"Your Documents": "images",
		"Admin Panel": "tuning",
		"Custom Search": "search1",
		Search: "search1",
		"User Summary": "switch_account",
		"Migration Tool": "cloud-storage-2",
		"User Management": "people_outline",
		Lockouts: "lock",
		"Reset Password": "lock_open",
		"New User": "person_add",
		"Internal Tools": "user-secret",
		"Levels of Care": "table_view",
		"Permissions Panel": "lock_open", // httpslock
		"Manage Residents": "switch_account",
		"Contact Us": "perm_contact_calendar",
	},
	pages: [
		{
			name: "Your Apps",
			icon: "Your Apps",
			path: "/apps",
			target: "apps",
			activeTab: null,
			isRestricted: false, // restricts user-facing
			isActive: true, // enables user-facing
			isWhiteListed: false, // only for white-listed users
			isInternalOnly: false, // only for white-listed 'internal' users
		},
		{
			name: "User Profile",
			icon: "User Profile",
			path: "/profile",
			target: "profile",
			activeTab: "profile",
			isRestricted: false,
			isActive: true,
			isWhiteListed: false,
			isInternalOnly: false,
		},
		{
			name: "Admin Panel",
			icon: "Admin Panel",
			path: "/access",
			target: "admin",
			activeTab: null,
			isRestricted: true,
			isActive: true,
			isWhiteListed: false,
			isInternalOnly: false,
		},

		// MIGRATION TOOL
		{
			name: "Migration Tool",
			icon: "Migration Tool",
			path: "/migration",
			target: "migration",
			activeTab: null,
			isRestricted: true,
			isActive: false,
			isWhiteListed: true,
			isInternalOnly: false,
		},

		// IN-PROGRESS/MAINTENANCE PAGES //

		// MANAGE SETTINGS PAGE
		{
			name: "Manage Settings",
			icon: "Manage Settings",
			path: "/settings",
			target: "settings",
			isRestricted: true,
			isActive: false,
			isWhiteListed: false,
			isInternalOnly: true,
		},

		// LEVELS-OF-CARE PAGE
		{
			name: "Levels of Care",
			icon: "Levels of Care",
			path: "/loc",
			target: "loc",
			isRestricted: false, // should be readable by ALL users, only editable by admins/facilityadmins
			isActive: true, // false for PROD; true for TEST
			isWhiteListed: false,
			isInternalOnly: false, // true for PROD; false for TEST
		},
		{
			name: "Permissions Panel",
			icon: "Permissions Panel",
			path: "/permissions",
			target: "permissions",
			isRestricted: true,
			isActive: false,
			isWhiteListed: false,
			isInternalOnly: true,
		},

		// INTERNAL TOOLS (eg. 'WhiteList' etc)
		{
			name: "Internal Tools",
			icon: "Internal Tools",
			path: "/internal",
			target: "internal",
			isRestricted: true,
			isActive: false,
			isWhiteListed: false,
			isInternalOnly: true,
		},
		// CONTACT US - INTERNAL ONLY FOR NOW!!!
		// {
		// 	name: "Contact Us",
		// 	icon: "Contact Us",
		// 	path: "/contact",
		// 	target: "contact",
		// 	isRestricted: true,
		// 	isActive: false,
		// 	isWhiteListed: false,
		// 	isInternalOnly: false,
		// },
	],
};
/**
 * Default links for 'NON-ADMINS'.
 * - Does NOT include 'Admin' link w/ its children
 */
const defaultLinks = [
	{ icon: "dashboard", name: "Apps", path: "/apps", children: null },
	{
		icon: "line-graph",
		name: "Trends",
		path: "/trends",
		children: null,
	},
	{
		icon: "check_circle_outline",
		name: "Tasks",
		path: "/tasks",
		children: null,
	},

	{
		icon: "settings1",
		name: "Settings",
		path: "/settings",
		children: null,
	},
	{ icon: "images", name: "Docs", path: "/docs", children: null },
	{
		icon: "all_inbox",
		name: "Archive",
		path: "/archive",
		children: null,
	},
];
/**
 * List of <NavLink/> entries to render in the Sidebar component.
 */
const linksConfig = [
	{
		icon: "home",
		name: "Home",
		path: "/portal",
		children: null,
		restricted: false,
		isActive: true,
	},
	{
		icon: "dashboard",
		name: "Apps",
		path: "/portal/apps",
		children: null,
		restricted: false,
		isActive: true,
	},
	// DOCS
	// {
	// 	icon: "images",
	// 	name: "Docs",
	// 	path: "/portal/docs",
	// 	children: null,
	// 	restricted: false,
	// 	isActive: false,
	// },
	// TRENDS
	// {
	// 	icon: "line-graph",
	// 	name: "Trends",
	// 	path: "/portal/trends",
	// 	children: null,
	// 	restricted: false,
	// 	isActive: false,
	// },
	// SETTINGS (APP)
	// {
	// 	icon: "build",
	// 	name: "Settings",
	// 	path: "/portal/settings",
	// 	children: null,
	// 	restricted: true,
	// 	isActive: false,
	// },
	{
		icon: "person_outlineperm_identity",
		name: "Profile",
		path: "/portal/profile",
		children: null,
		restricted: true,
		isActive: true,
	},

	// WITH CHILD
	{
		icon: "people_outline",
		name: "Admin",
		path: "/portal/access",
		restricted: true,
		isActive: false,
		children: [
			{
				icon: null,
				name: "User Access",
				path: "/portal/access/user",
				children: null,
				restricted: true,
				isActive: true,
			},
			{
				icon: null,
				name: "Facility Access",
				path: "/portal/access/facility",
				children: null,
				restricted: true,
				isActive: true,
			},
		],
	},
];

/**
 * <Routes/> Config:
 * - Renders out a custom list of routes w/:
 * 		- Permissioned access built-in via 'restrict'
 * 		- Ability to enable/disable w/ a single boolean
 */
const routesConfig = [
	// GLOBAL PAGES (ie. NON-ADMIN)
	{
		name: "Dashboard Home",
		exact: true,
		path: "/portal",
		component: Home,
		restrict: false,
		isActive: true,
		isWhiteListed: false,
		isInternalOnly: false,
	},
	{
		name: "Your Apps",
		exact: false,
		path: "/portal/apps",
		component: YourAppsPage,
		restrict: false,
		isActive: true,
		isWhiteListed: false,
		isInternalOnly: false,
	},
	{
		name: "User Profile",
		exact: false,
		path: "/portal/profile",
		component: UserPage,
		restrict: false,
		isActive: true,
		isWhiteListed: false,
		isInternalOnly: false,
	},
	// ADMIN PAGES
	{
		name: "Admin Settings",
		exact: false,
		path: "/portal/access",
		component: AdminPage,
		restrict: true,
		isActive: true,
		isWhiteListed: false,
		isInternalOnly: false,
	},
	{
		name: "Facility Access",
		exact: false,
		path: "/portal/access",
		component: null,
		restrict: true,
		isActive: false,
		isWhiteListed: false,
		isInternalOnly: false,
	},
	// SUPER USER PAGES //

	// MIGRATION TOOL PAGE
	{
		name: "Migration Tool",
		exact: false,
		path: "/portal/migration",
		component: MigrationToolPage,
		restrict: true,
		isActive: true,
		isWhiteListed: true,
		isInternalOnly: false,
	},

	// IN-PROGRESS & HIDDEN PAGES
	{
		name: "Manage Settings",
		exact: false,
		path: "/portal/settings",
		component: SettingsPage,
		restrict: true,
		isActive: true,
		isWhiteListed: true,
		isInternalOnly: false,
	},
	{
		name: "Levels of Care",
		exact: false,
		path: "/portal/loc",
		component: LevelsOfCarePage,
		isActive: true,
		restrict: false,
		isWhiteListed: false,
		isInternalOnly: false,
		// restrict: true,
		// isWhiteListed: isDevOrTest(),
		// isInternalOnly: !isDevOrTest(),
	},
	{
		name: "Permissions Panel",
		exact: false,
		path: "/portal/permissions",
		component: PermissionsPanelPage,
		restrict: true,
		isActive: true,
		isWhiteListed: false,
		isInternalOnly: true,
	},

	// INTERNAL TOOLS PAGES //
	{
		name: "Internal Tools",
		exact: false,
		path: "/portal/internal",
		component: InternalToolsPage,
		restrict: true,
		isActive: true,
		isWhiteListed: false,
		isInternalOnly: true,
	},

	// YOUR DOCUMENTS PAGE
	// {
	// 	name: "Your Documents",
	// 	exact: false,
	// 	path: "/portal/docs",
	// 	component: DocsPage,
	// 	restrict: false,
	// 	isActive: true,
	// 	isWhiteListed: false,
	// 	isInternalOnly: true,
	// },

	// RESET PASSWORD PAGE
	// {
	// 	name: "Reset Password",
	// 	exact: false,
	// 	path: "/portal/reset",
	// 	component: ResetPasswordPage,
	// 	restrict: true,
	// 	isActive: false,
	// },
];

////////////////////////////////////////////////////////////
///////////////////// NAV LINK CONFIG /////////////////////
////////////////////////////////////////////////////////////

// renders 'SUPER-USER' access links (all including 'inactive' links)

const renderSuperUserLinks = (links) => {
	return links.map((link) => link);
};
// renders 'ADMIN' access links (all except 'inactive' links)
const renderAdminLinks = (links) => {
	return links.filter((link) => link?.isActive);
};
// renders 'NON-ADMIN' access links (only un-restricted & active links)
const renderNonAdminLinks = (links) => {
	return links.filter((link) => link?.isActive && !link?.restrict);
};
// determines which links to render & renders the <ListItem/>, <SubList/> or <SubListItem/> respectively
const linksHandler = (links, user) => {
	switch (true) {
		case user?.superUser: {
			return renderLinks([...renderSuperUserLinks(links)]);
		}
		case !user?.superUser && user?.isAdmin: {
			return renderLinks([...renderAdminLinks(links)]);
		}
		case !user?.isAdmin: {
			return renderLinks([...renderNonAdminLinks(links)]);
		}
		default:
			return [];
	}
};
// handles which links to render based off user type (ie 'ADMIN', 'SUPER-USER' etc)
const renderLinks = (links, user) => {
	if (isEmptyArray(links)) return [];

	return links.map((link, idx) => {
		if (isEmptyArray(link.children)) {
			// no child links
			return (
				<ListItem
					key={`${link?.path}__${idx}`}
					item={link}
					currentUser={user}
				/>
			);
		} else {
			// has child links
			return (
				<SubList
					key={`Sublist--${link?.name}`}
					name={link?.name}
					icon={link?.icon}
				>
					{!isEmptyArray(link.children) &&
						link.children.map((child, index) => (
							<SubListItem
								key={`${child.path}_${link?.name}_${index}`}
								item={child}
								currentUser={user}
							/>
						))}
				</SubList>
			);
		}
	});
};

/////////////////////////////////////////////////////////////////
/////////////// ROUTE AUTHENTICATORS & RENDERERS ///////////////
/////////////////////////////////////////////////////////////////

// enables experimental views for DEV ONLY!!!

const enableExpRoutes = (route) => {
	if (EXPERIMENTAL_OPTIONS["hiddenRoutes"].includes(route)) {
		return route;
	} else {
		return;
	}
};

/**
 * SUPER-USER ACCESS:
 * Renders ALL available routes, including restricted & 'inactive'.
 *
 * - Does NOT enable:
 * 		- 'White-Listed' cards
 * 		- 'Internal-Listed' cards
 */
const handleSuperUserRoutes = (routes) => {
	// return routes;
	return routes.filter(
		(route) => !route.isWhiteListed && !route.isInternalOnly
	);
};
// includes super AND whitelisted routes
const handleWhiteListRoutes = (routes) => {
	const { hiddenRoutes } = WHITELISTED_OPTIONS;
	const superUserRoutes = handleSuperUserRoutes(routes);
	const whiteListed = routes.filter(
		(route) => hiddenRoutes.includes(routes.path) || route.isWhiteListed
	);
	return removeDups([...superUserRoutes, ...whiteListed]);
};
/**
 * ADMIN ACCESS:
 * Render ALL 'active' routes, including restricted.
 */
const handleAdminRoutes = (routes) => {
	const allRoutes = routes.filter((route) => route.isActive);
	return allRoutes;
};
/**
 * NON-ADMIN ACCESS:
 * Renders ONLY 'active' & non-restricted routes.
 */
const handleNonAdminRoutes = (routes) => {
	const allRoutes = routes.filter((route) => route.isActive && !route.restrict);

	return allRoutes;
};
/**
 * READ-ONLY ACCESS:
 * Renders ONLY 'active' & non-restricted routes.
 */
const handleReadOnlyRoutes = (routes) => {
	const nonAdmin = handleNonAdminCards(routes);

	return nonAdmin;
};

// NOT IN USE ~ CURRENTLY!!
const handleInternalListRoutes = (routes) => {
	const { hiddenRoutes } = INTERNAL_ONLY_OPTIONS;

	const whiteListed = handleWhiteListRoutes(routes);
	const internalListed = routes.filter(
		(route) => hiddenRoutes.includes(route.path) || route.isInternalOnly
	);

	return removeDups([...whiteListed, ...internalListed]);
};

/**
 * @description - Generic render fn() that iterates thru each route.
 * @param {Array} routes - A pre-determined configuration of routes based off of user access.
 * @param {Object} props - Any props to passed down to a route's component.
 */
const renderRoutes = (routes = [], props = {}) => {
	if (isEmptyArray(routes)) return;
	const customProps = props;
	return routes.map((route, idx) => {
		if (route?.exact) {
			const { component: Component } = route;
			return (
				<Route
					key={route?.path + idx}
					exact
					path={route?.path}
					render={(props) => (
						<Component
							key={`${route?.name}__${idx}`}
							isCollapsed={props?.isCollapsed}
							winSize={customProps?.winSize}
							{...customProps}
						/>
					)}
					location={{
						state: {
							...props,
						},
					}}
				/>
			);
		} else {
			const { component: Component } = route;
			return (
				<Route
					key={route?.path}
					path={route?.path}
					render={(props) => (
						<Component
							key={`${route?.name}__${idx}`}
							isCollapsed={props?.isCollapsed}
							winSize={customProps?.winSize}
							{...customProps}
						/>
					)}
				/>
			);
		}
	});
};

/**
 * Handles rendering permission-based user routes based off a user's permissions/user-type. Renders 'React Router' <Route/>(s)
 * @param {Object[]} routes - An array of custom 'route' map objects
 * @param {Object} user - User object from global store (eg. 'currentUser')
 * @param {Object} props - An object of various merged props passed to the component to be forwarded to each route
 * @returns {ReactRouterRoute[]} - Returns an array of 'React Router' <Route/> components specific to each user type.
 * - Updated: 10/26/2021 at 9:57 AM
 * 		- Fixed user-type route handling logic!
 */
const routesHandler = (routes, user, props = {}) => {
	switch (true) {
		case user?.isSuperUser && internalWhiteList.includes(user?.userID): {
			const internalRoutes = handleInternalListRoutes(routes);

			return renderRoutes(internalRoutes, props);
		}
		// SUPER-USER (white-list) - MUST be 'isSuperUser' AND 'WhiteListed' user
		case user?.isSuperUser && featuresWhiteList.includes(user?.userID): {
			const whiteListedRoutes = handleWhiteListRoutes(routes);
			return renderRoutes(whiteListedRoutes, props);
		}
		// SUPER-USER (non-white-list)
		case user?.isSuperUser: {
			const superUserRoutes = handleSuperUserRoutes(routes);
			return renderRoutes(superUserRoutes, props);
		}
		// ADMIN-USER: 'Facility Admin', 'Regional Admin', 'Admin'
		case !user?.isSuperUser &&
			(user?.isAdmin || user?.isFacilityAdmin || user?.isRegionalAdmin): {
			const adminRoutes = handleAdminRoutes(routes);
			return renderRoutes(adminRoutes, props);
		}
		// NON-ADMIN-USER
		case !user?.isAdmin && !user?.isFacilityAdmin && !user?.isRegionalAdmin: {
			const nonAdminRoutes = handleNonAdminRoutes(routes);
			return renderRoutes(nonAdminRoutes, props);
		}
		// READ-ONLY ROUTES: 'Non-Admin' & ALL functionality is disabled
		case user?.isReadOnly: {
			const readOnlyRoutes = handleReadOnlyRoutes(routes);
			return renderRoutes(readOnlyRoutes, props);
		}
		default:
			return [];
	}
};

/////////////////////////////////////////////////////////////////
/////////////// PORTAL PAGE-SHORTCUTS & RENDERERS ///////////////
/////////////////////////////////////////////////////////////////

// Enables:
// - SuperUser
// - White-Listed ('WHITELISTED_OPTIONS')
// - Internal-Listed ('INTERNAL_ONLY_OPTIONS')
const handleInternalListCards = (cards) => {
	const { hiddenCards } = INTERNAL_ONLY_OPTIONS;
	const whiteList = handleWhiteListCards(cards);
	const internalList = cards.filter(
		(card) => hiddenCards.includes(card.name) || card.isInternalOnly
	);
	const allCards = removeDups([...whiteList, ...internalList]);

	return allCards;
};
// Enables:
// - SuperUser
// - White-Listed ('WHITELISTED_OPTIONS')
const handleWhiteListCards = (cards) => {
	const { hiddenCards } = WHITELISTED_OPTIONS;
	const superUserCards = handleSuperUserCards(cards);
	const whiteListCards = cards.filter(
		(card) => card.isWhiteListed || hiddenCards.includes(card.name)
	);
	const allCards = removeDups([...superUserCards, ...whiteListCards]);

	return allCards;
};

/**
 * Renders EVERY page option that's available, including 'inactive' pages.
 * - Does NOT include:
 * 		- 'Whitelisted'
 * 		- 'Internal-Listed'
 */
const handleSuperUserCards = (cards) => {
	return cards.filter((card) => !card.isWhiteListed && !card.isInternalOnly);
};
/**
 * Renders ONLY pages that are active. Disables 'inactive' pages.
 * - NOTE: the 'active' flag indicates 'user-facing' cards
 */
const handleAdminCards = (cards) => {
	return cards.filter((card) => card.isActive);
};
/**
 * Renders ONLY pages that both active and NOT restricted.
 * - NOTE: 'restricted' cards are for ADMIN and higher user types
 */
const handleNonAdminCards = (cards) => {
	return cards.filter((card) => !card.isRestricted && card.isActive);
};
/**
 * Renders ONLY pages that both active and NOT restricted.
 * - NOTE: 'restricted' cards are for ADMIN and higher user types
 */
const handleReadOnlyCards = (cards) => {
	const nonAdminCards = handleNonAdminCards(cards);

	return nonAdminCards;
};
/**
 * Determines which portal cards to enable/disable based off the user logged in & renders the cards UI in a list.
 * @param {Object[]} allOptions - An array of 'portalPageOptions.pages' (ie. array of 'card' objects).
 * @param {Object} history - A React-Router history object
 * @param {Object} currentUser - The currently authenticated user object
 * @returns {ReactRender} - Returns & renders the UI cards based off user-type/permissions.
 */
const pageOptionsHandler = (
	allOptions = [],
	history = {},
	currentUser = {}
) => {
	// ALA-Listed User Types
	const isWhiteListed = featuresWhiteList.includes(currentUser?.userID);
	const isInternalListed = internalWhiteList.includes(currentUser?.userID);
	// SPECIAL SUPER-USER WITH ACCESS TO EVERYTHING!!!
	const isSuperAllLists =
		currentUser?.isSuperUser && isWhiteListed && isInternalListed;
	const isSuperWhiteListed = currentUser?.isSuperUser && isWhiteListed;
	const isSuperNonListed = currentUser?.isSuperUser && !isWhiteListed;
	// Client-Facing User Types
	const isAdminListed =
		!currentUser?.isSuperUser &&
		(currentUser?.isAdmin ||
			currentUser?.isFacilityAdmin ||
			currentUser?.isRegionalAdmin);
	const isNonAdminListed = !isAdminListed;
	// newly added
	const isReadOnly = currentUser?.isReadOnly;

	switch (true) {
		// SUPER-USER (w/ whitelist AND internal-list)
		case isSuperAllLists: {
			const internalListCards = handleInternalListCards(allOptions);
			return renderPageCards(internalListCards, history, currentUser);
		}
		// SUPER-USER (w/ whitelist)
		case isSuperWhiteListed: {
			const whiteListCards = handleWhiteListCards(allOptions);
			return renderPageCards(whiteListCards, history, currentUser);
		}
		// SUPER-USER (non-whitelist)
		case isSuperNonListed: {
			const superUserCards = handleSuperUserCards(allOptions);
			return renderPageCards(superUserCards, history, currentUser);
		}
		// ADMIN-USER: 'Facility Admin', 'Regional Admin', 'Admin'
		case isAdminListed: {
			const adminCards = handleAdminCards(allOptions);
			return renderPageCards(adminCards, history, currentUser);
		}

		// NON-ADMIN-USER
		case isReadOnly:
		case isNonAdminListed: {
			const nonAdminCards = handleNonAdminCards(allOptions);
			return renderPageCards(nonAdminCards, history, currentUser);
		}

		// INVALID-USER OR UNRECOGNIZED-USER-TYPE
		default:
			return [];
	}
};

/**
 * Portal Page Card renderer:
 * - Renders a portal page card w/ the required props.
 * - Appears on 'Home' page
 * - Includes enabling/disabling:
 * 		- Experimental card short-cuts and pages
 */
const renderPortalCard = (
	idx,
	pageOption = {},
	history = {},
	currentUser = {}
) => {
	return (
		<PortalPageCard
			key={`${pageOption?.name}_+_${idx}`}
			path={pageOption?.path}
			icon={pageOption?.icon}
			name={pageOption?.name}
			target={pageOption?.target}
			history={history}
			currentUser={currentUser}
		/>
	);
};

const renderPageCards = (pageOptions = [], history = {}, currentUser = {}) => {
	if (isEmptyArray(pageOptions)) return [];

	return pageOptions.map((pageOption, idx) => {
		return renderPortalCard(idx, pageOption, history, currentUser);
	});
};
// HISTORY ROUTING UTILS //

/**
 * Extracts the 'history.location.state.from' field from 'HISTORY' object.
 * @param {Object} history - 'HISTORY' object from 'react-router-dom' library
 * @returns {String} - Returns a string-form path
 */
const getNavigatedFrom = (history) => {
	const { location } = history;
	const { state } = location;
	const from = state?.from;
	return from;
};

export { renderAdminLinks, renderNonAdminLinks, renderSuperUserLinks };

// auth-controlled <Route/> handler(s)
export { renderRoutes };

// links list and handler
export { defaultLinks, linksConfig, linksHandler };

// routes list and handler
export { routesConfig, routesHandler };

// portal-page options' handlers w/ auth
export {
	// page page options
	defaultPortalOptions,
	portalPageOptions,
	// portal page card enablers
	handleInternalListCards,
	handleWhiteListCards,
	handleSuperUserCards,
	handleAdminCards,
	handleNonAdminCards,
	// portal page card renderers
	renderPortalCard,
	renderPageCards,
	// final renderer
	pageOptionsHandler,
};

export { getNavigatedFrom };
