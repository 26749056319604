import React from "react";
import styles from "../../css/app/MobileSidebar.module.scss";
import { PropTypes } from "prop-types";

const MobileSidebar = ({ currentUser = {}, history, children }) => {
	const withState = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			index: i,
			history: history,
		});
	});

	return (
		<aside className={styles.MobileSidebar}>
			<nav className={styles.MobileSidebar_nav}>{withState}</nav>
		</aside>
	);
};

export default MobileSidebar;

MobileSidebar.defaultProps = {};

MobileSidebar.propTypes = {
	currentUser: PropTypes.object,
	history: PropTypes.object,
	children: PropTypes.any,
};
