import React, { useState } from "react";
import styles from "../../css/shared/PasswordInput.module.scss";
import sprite from "../../assets/icons/showhide.svg";

const PasswordInput = ({
	label,
	id,
	name,
	val,
	handleChange,
	handleFocus,
	required = false,
	readOnly = false,
	isDisabled = false,
	autoComplete = "off",
	placeholder,
	customStyles = {},
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);

	const toggleShowPassword = () => {
		return setShowPassword(!showPassword);
	};

	const checkForCapsLock = (e) => {
		const isOn = e.getModifierState("CapsLock");
		setIsCapsLockOn(isOn);
	};

	return (
		<div className={styles.PasswordInput}>
			<label htmlFor={id} className={styles.PasswordInput_label}>
				{label}
			</label>
			<div className={styles.PasswordInput_wrapper} style={customStyles}>
				<input
					type={showPassword ? "text" : "password"}
					name={name}
					id={id}
					value={val}
					onChange={handleChange}
					onFocus={handleFocus}
					className={styles.PasswordInput_wrapper_input}
					required={required}
					autoComplete={autoComplete}
					style={customStyles}
					placeholder={placeholder}
					aria-autocomplete="none"
					readOnly={readOnly}
					disabled={isDisabled}
					onKeyUp={checkForCapsLock}
				/>
				<svg
					className={styles.PasswordInput_wrapper_icon}
					onClick={isDisabled || readOnly ? null : (e) => toggleShowPassword(e)}
				>
					<use
						xlinkHref={`${sprite}#icon-view-${showPassword ? "show" : "hide"}`}
					/>
				</svg>
				{isCapsLockOn && (
					<div className={styles.PasswordInput_wrapper_capslock}>
						Caps Lock is On!
					</div>
				)}
			</div>
		</div>
	);
};

export default PasswordInput;
