import React, { useState } from "react";
import styles from "../../css/admin/UsersSummarySearch.module.scss";
import { PropTypes } from "prop-types";
import CustomCheckbox from "../shared/CustomCheckbox";

const UsersSummarySearch = ({
	filterVals = {},
	searchVal,
	handleSearch,
	handleCheckbox,
}) => {
	const [showMoreOptions, setShowMoreOptions] = useState(false);
	return (
		<div className={styles.UsersSummarySearch}>
			<div className={styles.UsersSummarySearch_search}>
				<input
					type="text"
					name="searchVal"
					id="searchVal"
					value={searchVal}
					onChange={handleSearch}
					className={styles.UsersSummarySearch_search_input}
					placeholder="Search by email, name, title..."
				/>
				<div
					className={styles.UsersSummarySearch_search_toggle}
					onClick={() => setShowMoreOptions(!showMoreOptions)}
				>
					{showMoreOptions ? "Hide" : "More"} Options
				</div>

				{showMoreOptions && (
					<div className={styles.UsersSummarySearch_search_options}>
						<CustomCheckbox
							label="Only Facility Admins"
							name="onlyFacilityAdmin"
							id="onlyFacilityAdmin"
							val={filterVals?.onlyFacilityAdmin}
							handleCheckbox={handleCheckbox}
						/>
						<CustomCheckbox
							label="Only Regional Admins"
							name="onlyRegionalAdmin"
							id="onlyRegionalAdmin"
							val={filterVals?.onlyRegionalAdmin}
							handleCheckbox={handleCheckbox}
						/>
						<CustomCheckbox
							label="Only Med-Tech Restricted"
							name="onlyMedTechRestricted"
							id="onlyMedTechRestricted"
							val={filterVals?.onlyMedTechRestricted}
							handleCheckbox={handleCheckbox}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default UsersSummarySearch;

UsersSummarySearch.defaultProps = {};

UsersSummarySearch.propTypes = {};
