import React, { useEffect, useState } from "react";
import styles from "../../css/user/EditUserInfo.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";
import { blueGrey, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { handleUserNameChanges } from "../../helpers/utils_user";

// REQUIREMENTS:
// - Edit name (first, last names)
// - Edit 'Active' community

const customCSS = {
	cancel: {
		padding: ".5rem 1.5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.5rem",
		fontWeight: "500",
	},
	save: {
		padding: ".5rem 1.5rem",
		backgroundColor: blueGrey[700],
		color: "#ffffff",
		fontSize: "1.5rem",
		fontWeight: "500",
	},
};

const enableSaveBtn = (touched = {}, vals = {}) => {
	const { editFirstName, editLastName } = touched;
	const wasTouched = editFirstName || editLastName;
	const isNotEmpty =
		!isEmptyVal(vals.editFirstName) && !isEmptyVal(vals.editLastName);

	if (isEmptyVal(vals.editFirstName) || isEmptyVal(vals.editLastName))
		return false;

	return wasTouched && isNotEmpty;
};

const EditUserInfo = ({
	targetUser = {},
	currentUser = {},
	dispatchToState,
	dispatchAlert,
	tableDispatch,
}) => {
	const { formState, handleChange, handleReset } = useForm({
		editFirstName: "",
		editLastName: "",
	});
	const { values, touched } = formState;
	const [isDisabled, setIsDisabled] = useState(true);

	const saveChanges = async (e) => {
		e.preventDefault();
		const { token } = currentUser;
		const { editFirstName, editLastName } = values;

		// update profile model & fire off request
		const wasChanged = await handleUserNameChanges(token, targetUser, {
			firstName: editFirstName,
			lastName: editLastName,
		});

		if (wasChanged?.savedProfile) {
			tableDispatch({
				type: "USER_NAME_CHANGE",
				data: {
					userID: targetUser?.userID,
					newFirstName: editFirstName,
					newLastName: editLastName,
				},
			});
			return dispatchAlert("SUCCESS", {
				heading: `User's name was changed!!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! Change was NOT saved!!`,
			});
		}
	};
	const cancelChanges = (e) => {
		handleReset(e);
	};

	// changes when these fields change
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setIsDisabled(() => {
			return !enableSaveBtn(touched, values);
		});

		return () => {
			isMounted = false;
		};
	}, [values, touched]);

	return (
		<div className={styles.EditUserInfo}>
			<div className={styles.EditUserInfo_about}>
				<div className={styles.EditUserInfo_about_desc}>
					Edit this user's profile information such as first name, last name &
					other fields.
				</div>
			</div>
			<div className={styles.EditUserInfo_form}>
				<div className={styles.EditUserInfo_form_field}>
					<TextInput
						val={values.editFirstName}
						id="editFirstName"
						name="editFirstName"
						label="Change First Name"
						placeholder={values?.editFirstName ?? `Edit name...`}
						handleChange={handleChange}
					/>
				</div>
				<div className={styles.EditUserInfo_form_field}>
					<TextInput
						val={values.editLastName}
						id="editLastName"
						name="editLastName"
						label="Change Last Name"
						placeholder={values?.editLastName ?? `Edit name...`}
						handleChange={handleChange}
					/>
				</div>
				<div className={styles.EditUserInfo_form_actions}>
					<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={isDisabled}
						handleClick={saveChanges}
						customStyles={customCSS.save}
					>
						Save Changes
					</ButtonSM>
				</div>
			</div>
		</div>
	);
};

export default EditUserInfo;

EditUserInfo.defaultProps = {};

EditUserInfo.propTypes = {};
