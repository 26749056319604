import React, { useRef, useEffect } from "react";
import styles from "../../css/app/ALASelectorModal.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { isEmptyVal } from "../../helpers/utils_types";
import ALAStepCounter from "./ALAStepCounter";

const stepDesc = {
	1: {
		icon: "location_city",
		text: "Choose a community",
	},
	2: {
		icon: "person",
		text: "Select a resident",
	},
	3: {
		icon: "",
		text: "Load resident data",
	},
};

const getTxt = (isAdmin = false, currentStep, disableResident = false) => {
	if (!isAdmin) return stepDesc[2].text;
	if (disableResident) return stepDesc[1].text;
	return stepDesc[currentStep]?.text;
};

const getIcon = (isAdmin = false, currentStep, disableResident = false) => {
	if (!isAdmin) return stepDesc[2].icon;
	if (disableResident) return stepDesc[1].icon;
	return stepDesc[currentStep]?.icon;
};

const defaultTitle = (isAdmin = false, disableResident = false) => {
	if (!isAdmin) return `Select Resident`;
	if (disableResident) return `Select Community`;
	return `Select Community/Resident`;
};

const ALASelectorModal = ({
	isAdmin = false,
	title,
	currentStep,
	goToNext,
	goToPrevious,
	closeSelector,
	disableResident = false,
	children,
}) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	useLockBodyScroll();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return closeSelector();
		}
		return () => {
			isMounted = false;
		};
	}, [closeSelector, isOutside]);

	return (
		<aside className={styles.ALASelectorModal} ref={modalRef}>
			<section className={styles.ALASelectorModal_top}>
				<h2 className={styles.ALASelectorModal_top_title}>
					{isEmptyVal(title) ? defaultTitle(isAdmin) : title}
				</h2>
				<svg
					className={styles.ALASelectorModal_top_closeIcon}
					onClick={() => closeSelector()}
				>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</section>
			{/* STEP DESC & ICON */}
			<section className={styles.ALASelectorModal_stepDesc}>
				<div className={styles.ALASelectorModal_stepDesc_wrapper}>
					<svg className={styles.ALASelectorModal_stepDesc_wrapper_icon}>
						<use
							xlinkHref={`${sprite}#icon-${
								getIcon(isAdmin, currentStep, disableResident)
								// !isAdmin ? stepDesc[2]?.icon : stepDesc[currentStep]?.icon
							}`}
						></use>
					</svg>
				</div>
				<div className={styles.ALASelectorModal_stepDesc_wrapper_text}>
					{getTxt(isAdmin, currentStep, disableResident)}
				</div>
			</section>
			{/* CHILDREN CONTENT - CUSTOM DROPDOWNS */}
			<section className={styles.ALASelectorModal_steps}>{children}</section>

			<ALAStepCounter
				isAdmin={isAdmin}
				currentStep={currentStep}
				goToNext={goToNext}
				goToPrevious={goToPrevious}
				disableResident={disableResident}
			/>
		</aside>
	);
};

export default ALASelectorModal;

ALASelectorModal.defaultProps = {
	isAdmin: false,
	disableResident: false,
};

ALASelectorModal.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	disableResident: PropTypes.bool.isRequired,
	title: PropTypes.string,
	currentStep: PropTypes.number.isRequired,
	goToNext: PropTypes.func.isRequired,
	goToPrevious: PropTypes.func.isRequired,
	closeSelector: PropTypes.func.isRequired,
	children: PropTypes.any,
};
