import React from "react";
import styles from "../../css/app/ALASelectorController.module.scss";
import { PropTypes } from "prop-types";
import { formatAndSortUserFacilities } from "../../helpers/utils_facility";
import { isEmptyVal, isEmptyArray } from "../../helpers/utils_types";
import { formatAndSortResidents } from "../../helpers/utils_residents";
import CustomDropdown from "../shared/CustomDropdown";

const custom = {
	width: "25rem",
};

// UPDATED ✓
// supports 'disableResident'
const showNext = (facilityVal, currentStep, disableResident = false) => {
	if (disableResident) {
		return false;
	} else {
		if (currentStep === 2) return false;
		if (isEmptyVal(facilityVal)) return false;
		return true;
	}
};

const showPrev = (currentStep, disableResident = false) => {
	if (disableResident) return false;
	if (currentStep === 2) return true;
	return false;
};

// UPDATED ✓
// supports 'disableResident' - 6/15/2020
const showLoadBtn = (
	facilityVal,
	residentVal,
	currentStep,
	disableResident
) => {
	if (disableResident) {
		if (isEmptyVal(facilityVal)) return false;
		return true;
	} else {
		if (currentStep !== 2) return false;
		if (isEmptyVal(residentVal) || isEmptyVal(facilityVal)) return false;
		return true;
	}
};

const showNonAdminLoadBtn = (residentVal) => {
	if (isEmptyVal(residentVal)) return false;
	return true;
};
// fallback UI for 'non admin' users
const renderNonAdminUI = (
	residents = [],
	residentVal,
	handleResident,
	loadResident
) => {
	return (
		<div className={styles.ALASelectorController}>
			<CustomDropdown
				name="selectedResident"
				id="selectedResident"
				selection={residentVal}
				setSelection={handleResident}
				options={[...formatAndSortResidents(residents)]}
				customStyles={custom}
			/>

			<section className={styles.ALASelectorController_actionButtons}>
				{showNonAdminLoadBtn(residentVal) && (
					<button
						className={styles.ALASelectorController_actionButtons_nextBtn}
						onClick={() => loadResident(residentVal)}
					>
						Load Resident
					</button>
				)}
			</section>
		</div>
	);
};
// checks for residents; formats & sorts
// other wise returns ['No residents found']
const handleResidents = (residents = []) => {
	if (isEmptyArray(residents)) return [`No residents found.`];
	return [...formatAndSortResidents(residents)];
};

const ALASelectorController = ({
	isAdmin = false,
	currentStep,
	facilityVal,
	residentVal,
	facilities = [],
	residents = [],
	handleFacility,
	handleResident,
	goToNext,
	goToPrev,
	loadResident,
	loadFacility,
	disableResident = false,
}) => {
	if (!isAdmin) {
		return (
			<>
				{renderNonAdminUI(residents, residentVal, handleResident, loadResident)}
			</>
		);
	}
	return (
		<div className={styles.ALASelectorController}>
			{currentStep === 1 && (
				<CustomDropdown
					name="selectedFacility"
					id="selectedFacility"
					selection={facilityVal}
					setSelection={handleFacility}
					options={[...formatAndSortUserFacilities(facilities)]}
					customStyles={custom}
					inputMode={facilities?.length > 20 ? "text" : "none"}
				/>
			)}
			{currentStep === 2 && (
				<CustomDropdown
					name="selectedResident"
					id="selectedResident"
					selection={residentVal}
					setSelection={handleResident}
					options={handleResidents(residents)}
					customStyles={custom}
					inputMode={residents?.length > 50 ? "text" : "none"}
				/>
			)}
			<section className={styles.ALASelectorController_actionButtons}>
				{showPrev(currentStep) && (
					<button
						className={styles.ALASelectorController_actionButtons_prevBtn}
						onClick={goToPrev}
					>
						Previous
					</button>
				)}
				{showNext(facilityVal, currentStep, disableResident) && (
					<button
						className={styles.ALASelectorController_actionButtons_nextBtn}
						onClick={goToNext}
					>
						Next
					</button>
				)}
				{showLoadBtn(
					facilityVal,
					residentVal,
					currentStep,
					disableResident
				) && (
					<button
						className={styles.ALASelectorController_actionButtons_nextBtn}
						onClick={
							disableResident
								? () => loadFacility(facilityVal)
								: () => loadResident(residentVal)
						}
					>
						Load {disableResident ? "Facility" : "Resident"}
					</button>
				)}
			</section>
		</div>
	);
};

export default ALASelectorController;

ALASelectorController.defaultProps = {
	isAdmin: false,
	disableResident: false,
	facilities: [],
	residents: [],
};

ALASelectorController.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	disableResident: PropTypes.bool.isRequired,
	currentStep: PropTypes.number.isRequired,
	facilityVal: PropTypes.string.isRequired,
	residentVal: PropTypes.string.isRequired,
	facilities: PropTypes.array.isRequired,
	residents: PropTypes.array.isRequired,
	handleFacility: PropTypes.func.isRequired,
	handleResident: PropTypes.func.isRequired,
	goToNext: PropTypes.func.isRequired,
	goToPrev: PropTypes.func.isRequired,
	loadResident: PropTypes.func.isRequired,
};
