import { utcToLocal } from "./utils_dates";
import { loc, templates } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { LevelOfCareModel } from "./utils_models";
import {
	groupBy,
	sortAlphaAscByKey,
	sortByNumAsc,
	sortNumAscByKey,
} from "./utils_processing";
import { isEmptyArray, isEmptyVal } from "./utils_types";

// LEVELS OF CARE REQUEST UTILS //

/**
 * Fetches care levels for a facility. Note: will always return something; either custom levels or default levels.
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object} - Returns object of care levels & relevant LOC settings
 */
const getFacilityLevelsOfCare = async (token, facilityId) => {
	let url = currentEnv.base + loc.get;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Saves a facility's LOC and relevant settings/flags.
 * @param {String} token - Auth token
 * @param {Stringn} facilityId - Facility guid
 * @param {String} floorUnit - String unit type (eg. 'Memory Care', 'Assisted Living' etc.)
 * @param {Object} careLevels - An object of care levels and required flag settings.
 * @returns {Boolean} - Returns whether 'save' action was successful
 */
const saveFacilityLevelsOfCare = async (token, facilityId, careLevels = {}) => {
	let url = currentEnv.base + loc.save;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(careLevels),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Updates a facility's LOC settings/flags in the 'FACILITY' table
 * @param {String} token - Auth token
 * @param {Object} locSettings - An 'FACILITY' object w/ ONLY the required flags & the 'guidFacility'
 * @param {String} locSettings.guidFacility - Facility guid for target facility to update.
 * @param {Boolean} locSettings.ApplyLOCToChildren - Boolean that applies parent settings to all children.
 * @param {Boolean} locSettings.DoNotAllowChildLOCOverride - Boolean that prevents children from editing their levels individually.
 * @param {Boolean} locSettings.LOCAreAdminLocked - Boolean that locks ability to edit LOC to only admins. If false, then other user types can edit LOC.
 * @returns {Boolean} - Returns true|false whether request was successful.
 */
const saveFacilityLOCSettings = async (token, locSettings = {}) => {
	let url = currentEnv.base + loc.saveSettings;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(locSettings),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Updates a facility's LOC settings/flags in the 'FACILITY' table
 * @param {String} token - Auth token
 * @param {Object} locSettings - An 'FACILITY' object w/ ONLY the required flags & the 'guidFacility'
 * @param {String} locSettings.facilityId - Facility guid for target facility to update.
 * @param {Boolean} locSettings.applyLOCToChildren - Boolean that applies parent settings to all children.
 * @param {Boolean} locSettings.doNotAllowChildLOCOverride - Boolean that prevents children from editing their levels individually.
 * @param {Boolean} locSettings.locAreAdminLocked - Boolean that locks ability to edit LOC to only admins. If false, then other user types can edit LOC.
 * @returns {Boolean} - Returns true|false whether request was successful.
 */
const saveFacilityLOCFlagSettings = async (token, locSettings = {}) => {
	let url = currentEnv.base + loc.flagSettings;
	url += "?" + new URLSearchParams({ ...locSettings });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Resets a facility's LOC back to default levels of care.
 * @param {String} token - Auth token
 * @param {Stringn} facilityId - Facility guid
 * @returns {Boolean} - Returns whether 'save' action was successful
 */
const resetFacilityLevelsOfCare = async (token, facilityId) => {
	let url = currentEnv.base + loc.reset;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Checks if a facility's levels of care are editable or locked.
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Boolean} - Returns true|false
 */
const isEditableLevelsOfCare = async (token, facilityId) => {
	let url = currentEnv.base + loc.isEditable;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Validates care levels .
 * @param {String} token - Auth token
 * @param {Array} careLevels - An array of care levels objects.
 * @returns {Object} - Returns validation results as an object.
 */
const validateLevelsOfCare = async (token, careLevels = []) => {
	let url = currentEnv.base + templates.validate.levels;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(careLevels),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// LOC CHILD LOCK/UNLOCK REQUESTS //

/**
 * Takes a parent facilityID and locks all that parent's child LOC.
 * @param {String} token - Auth token
 * @param {String} parentID - Parent Facility guid string.
 * @returns {Boolean} - Returns true|false whether request was successful
 */
const lockAllChildLOCByParent = async (token, parentID) => {
	let url = currentEnv.base + loc.lockAll;
	url += "?" + new URLSearchParams({ parentFacilityId: parentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Takes a parent facilityID and unlocks all that parent's child LOC.
 * @param {String} token - Auth token
 * @param {String} parentID - Parent Facility guid string.
 * @returns {Boolean} - Returns true|false whether request was successful
 */
const unlockAllChildLOCByParent = async (token, parentID) => {
	let url = currentEnv.base + loc.unlockAll;
	url += "?" + new URLSearchParams({ parentFacilityId: parentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches the 'SettingFacility' record for a given parent's LOCLock status. (check the 'Content' field)
 * @param {String} token - Auth token
 * @param {String} parentID - Parent facility guid
 * @returns {Object} - Returns parent's LockChildFacilities 'SettingFacility' record.
 */
const getLOCLockStatus = async (token, parentID) => {
	let url = currentEnv.base + loc.getLockStatus;
	url += "?" + new URLSearchParams({ Name: "LockChildFacilities" });
	url += "&" + new URLSearchParams({ FacilityID: parentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// LOCK/UNLOCK RECORD PROCESSING UTIL(S) //

// creates human-readable description of when LOC Lock Status was last changed & by whom
// e.g. "This facility's children were first locked on MM-DD-YYYY hh:mm:ss by UserID: XXXXX-XXXX-XXXXXX"
const processLOCInfoField = (
	isLocked = false,
	createdBy = "Unknown",
	dateModified = "Unknown"
) => {
	const changedWhen = utcToLocal(dateModified);

	const status = isLocked ? `locked` : `unlocked`;
	let info = `This facility's children were ${status} on ${changedWhen} by UserID: ${createdBy}`;

	return info;
};

// formats the relevant info from the 'SettingFacility' record that stores LOC lock status.
const processLOCLockRecord = (record = {}) => {
	const {
		Name: name,
		Description: desc,
		Content: isLockedSTRING,
		FacilityID: facilityID,
		IsActive: isActive,
		SettingFacilityID: settingFacilityID,
		SettingTypeID: settingTypeID,
		ModifiedDate: dateModified,
		CreatedDate: dateCreated,
		CreatedBy: createdBy,
	} = record;

	const isLocked = isLockedSTRING === "False" ? false : true;
	const info = processLOCInfoField(isLocked, createdBy, dateModified);

	const locLockStatus = {
		name,
		desc,
		isLocked,
		facilityID,
		isActive,
		settingFacilityID,
		settingTypeID,
		dateModified,
		dateCreated,
		info,
	};

	return locLockStatus;
};
// validator & processor

/**
 * FEtches & formats/processes LOC API validation results.
 * @param {String} token - Auth token
 * @param {Object[]} careLevels - Array of LOC objects (all floor units)
 * @returns {Object} - Returns all unit's validation results
 */
const fetchAndProcessLOCApiValidation = async (token, careLevels = []) => {
	const rawValidation = await validateLevelsOfCare(token, careLevels);

	const validation = processAPIValidation(rawValidation);

	return validation;
};

// LOC TABLE SCHEMA - DEFAULT SETTINGS

/**
 * Default "Levels of Care" settings w/ columns, default data & state
 * @property {String[]} defaultSchema.cols - An array of string-form column names.
 * @property {Object[]} defaultSchema.cols - The table's row data, as an array of objects w/ each property corresponding to a column name.
 * @property {Boolean} defaultSchema.isSorted - Indicates whether the rows have been sorted by column(s)
 * @property {Boolean} defaultSchema.hasMaxLevels - Indicates whether maximum number of care levels have been added (max = 10 unique levels)
 *
 */
const defaultSchema = {
	cols: [
		"Level Name",
		"Level",
		"Starting Points",
		"Ending Points",
		"Pricing ($)",
	],
	data: [
		{
			"Level Name": "Level 0",
			Level: 0,
			"Starting Points": 0,
			"Ending Points": 0,
		},
		{
			"Level Name": "Entry Level",
			Level: 1,
			"Starting Points": 0,
			"Ending Points": 50,
		},
		{
			"Level Name": "Level 2",
			Level: 2,
			"Starting Points": 51,
			"Ending Points": 100,
		},
		{
			"Level Name": "Assistance Level 1",
			Level: 3,
			"Starting Points": 101,
			"Ending Points": 150,
		},
	],
	isSorted: false,
	hasMaxLevels: false,
};

// LOC PROCESSING UTILS //

const formatLOC = (loc = {}) => {
	const {
		ID: levelID,
		LevelDescription: levelName,
		PointsMin: pointsMin,
		PointsMax: pointsMax,
	} = loc;

	const newRecord = {
		levelID,
		levelName,
		pointsMin,
		pointsMax,
	};

	return newRecord;
};

const processLOCRecords = (loc = []) => {
	const newRecords = loc.map((record) => formatLOC(record));

	return newRecords;
};

const formatLevel = (idx, level = {}) => {
	const fixed = `0.00`;
	const price = isEmptyVal(level?.Pricing) ? fixed : level?.Pricing;
	const levelNum = isEmptyVal(level?.LevelNumber) ? idx : level?.LevelNumber;

	const newLevel = {
		...level,
		Pricing: price,
		LevelNumber: levelNum,
	};
	return newLevel;
};

// formats a single unit type's care levels
// - adds default pricing
// - adds default level number
const formatLevels = (levels = []) => {
	const unitLevels = levels.map((level, idx) => {
		return formatLevel(idx, level);
	});
	return unitLevels;
};

// processes all levels
const processLOC = (loc = []) => {
	const grouped = groupBy(loc, (x) => x.FloorUnit);
	const keys = Object.keys(grouped);

	const processed = keys.reduce((allLevels, levelKey) => {
		const byType = grouped?.[levelKey] ?? [];
		const sorted = sortNumAscByKey("PointsMax", byType);
		const formatted = formatLevels(sorted);
		if (!allLevels[levelKey]) {
			allLevels[levelKey] = [...formatted];
			return allLevels;
		}
		return allLevels;
	}, {});

	return processed;
};

// CARE LEVEL PROCESSING UTILS //
const createCareLevelObj = (unitTypeLevels = []) => {
	const sorted = sortNumAscByKey("PointsMin", unitTypeLevels);
	const injectedLevels = sorted.map((level, idx) => {
		const {
			FacilityID: facilityID,
			ID: id,
			LevelDescription: levelDesc,
			PointsMin: pointsMin,
			PointsMax: pointsMax,
			FloorUnit: unitType,
		} = level;

		const unit = isEmptyVal(unitType) ? "Personal Care" : unitType;

		const levelObj = {
			"Level Name": levelDesc,
			Level: idx + 1,
			"Starting Points": pointsMin,
			"Ending Points": pointsMax,
			// extra, but required fields
			"Level ID": id,
			"Unit Type": unit,
		};
		return levelObj;
	});

	return injectedLevels;
};

/**
 * Processes, formats and groups all care levels by unit type for a given facility.
 * @param {Array} allLevels - Array of care level objects (for ALL levels for a facility)
 * @returns {Object} - Returns an object map of care levels by unit type, formatted.
 */
const groupAndProcessCareLevels = (allLevels = []) => {
	const grouped = groupBy(allLevels, (x) => x.FloorUnit);
	const keys = Object.keys(grouped);
	const processed = keys.reduce((levelsMap, levelKey) => {
		if (!levelsMap[levelKey]) {
			const key = isEmptyVal(levelKey) ? "Personal Care" : levelKey;
			levelsMap[key] = [...createCareLevelObj(grouped[levelKey])];
			return levelsMap;
		}
		return levelsMap;
	}, {});

	return processed;
};

// PREPARE NEW LEVELS OF CARE REQUESTS UTILS //

// merges all cares levels into a single array
// returns array with all care levels contained within it.
const mergeLOCIntoArray = (locByUnit = {}) => {
	const units = Object.keys(locByUnit);
	const allLevels = units.reduce((all, unitKey) => {
		const unitLevels = locByUnit[unitKey];
		all = [...all, ...unitLevels];

		return all;
	}, []);

	return allLevels;
};

// injects 'LevelNumber' for each unit type separately, then merges into a single array
const prepareAllCareLevels = (facilityID, newLevels = {}) => {
	const keys = Object.keys(newLevels);

	const prepared = keys.reduce((allResults, key, idx) => {
		const unitLevels = newLevels[key];
		const withLevelNum = prepareCareLevels(facilityID, unitLevels);
		allResults = [...allResults, ...withLevelNum];

		return allResults;
	}, []);

	return prepared;
};

// - Changes 'LOCType' to 'Custom'
// - Adds 'FacilityID', if it's empty
const prepareCareLevels = (facilityID, newLevels = []) => {
	const type = "Custom";
	return newLevels.map((level, idx) => {
		const newLevel = {
			...level,
			LOCType: type,
			FacilityID: isEmptyVal(level?.FacilityID) ? facilityID : level.FacilityID,
			// LevelNumber: idx , // OLD CODE
			LevelNumber: idx + 1,
		};
		return newLevel;
	});
};

/**
 * Prepares/formats LOC user values for the 'FACILITY' table
 * @param {String} facilityID - Facility guid string
 * @param {Object} locSettings - An object of LOC flag fields.
 * @param {String} locSettings.guidFacility - Facility guid for target facility.
 * @param {Boolean} locSettings.ApplyLOCToChildren - Flag for applying LOC to child communities.
 * @param {Boolean} locSettings.DoNotAllowChildLOCOverride - Flag for preventing child-level changes.
 * @param {Boolean} locSettings.LOCAreAdminLocked - Locks edit functionality to 'ADMINS' only.
 * @returns {Object} - Returns object to be saved to 'FACILITY' table.
 */
const prepareLOCSettings = (facilityID, locSettings = {}) => {
	const updatedFacility = {
		facilityId: facilityID,
		// ORIGINAL FLAGS CODE //
		// applyLOCToChildren: locSettings?.ApplyLOCToChildren,
		// doNotAllowChildLOCOverride: locSettings?.DoNotAllowChildLOCOverride,
		applyLOCToChildren: false,
		doNotAllowChildLOCOverride: false,
		locAreAdminLocked: locSettings?.LOCAreAdminLocked,
	};

	return updatedFacility;
};

// LOC VALIDATION UTILS //

// validates level 0
// - checks that start level is 0
const validateBaseLevel = (levels = []) => {
	const base = levels?.[0] ?? {};
	const { PointsMin, PointsMax } = base;

	const isValidMin = PointsMin === 0;
	const isValidMax = PointsMax >= 0;

	return {
		isValid: isValidMin && isValidMax,
		min: isValidMin,
		max: isValidMax,
	};
};

const validateLastLevel = (levels = []) => {
	const { length } = levels;
	const last = levels?.[length - 1];
	const prev = levels?.[length - 2];
	// const { PointsMax } = last;
	const PointsMax = last?.PointsMax ?? 99999;

	const isValidMin = prev.PointsMax < last.PointsMin;
	const isValidMax = PointsMax === 99999;

	return {
		isValid: isValidMax && isValidMin,
		min: isValidMin,
		max: isValidMax,
	};
};

// returns object: hasOverlap, errorMsg
const checkForOverlap = (current, idx, levels = []) => {
	if (idx === 0) {
		const next = levels[idx + 1];
		const hasOverlap = next.PointsMin <= current.PointsMax;
		const msg = hasOverlap
			? `There's an overlap between level ${idx} and level ${idx + 1}`
			: `No overlap`;

		return {
			id: idx,
			hasOverlap: hasOverlap,
			errorMsg: msg,
		};
	} else {
		const prev = levels[idx - 1];
		// results
		const hasPrevOverlap = prev.PointsMax >= current.PointsMin;
		const msg = hasPrevOverlap
			? `There's an overlap between level ${idx} and level ${idx - 1}`
			: `No overlap`;

		return {
			id: idx,
			hasOverlap: hasPrevOverlap,
			errorMsg: msg,
		};
	}
};

// returns object: hasGap, errorMsg
const checkForGap = (current, idx, levels = []) => {
	if (idx === 0) {
		// only check 'next' NOT 'prev'
		const next = levels[idx + 1];
		const hasGap = next.PointsMin !== current.PointsMax + 1;
		const msg = hasGap
			? `There's a gap between level ${idx} and level ${idx + 1}`
			: `No gap`;

		return {
			id: idx,
			hasGap: hasGap,
			errorMsg: msg,
		};
	} else {
		const prev = levels[idx - 1];
		const hasGap = prev.PointsMax !== current.PointsMin - 1;
		const msg = hasGap
			? `There's a gap between level ${idx - 1} and level ${idx}`
			: `No gap`;

		return {
			id: idx,
			hasGap: hasGap,
			errorMsg: msg,
		};
	}
};

/**
 * Validates care levels.
 * @param {Object[]} levels - An array of care level objects.
 * @returns {Object} - Returns object of validation results.
 */
const validateLOC = (levels = []) => {
	// sort by level
	const allLevels = levels.sort((a, b) => a.Level - b.Level);
	// validate base level & last level
	const { isValid: isBaseValid } = validateBaseLevel(allLevels);
	const { isValid: isLastValid } = validateLastLevel(allLevels);
	const errors = [];
	let hasGap = false;
	let hasOverlap = false;

	const results = allLevels.every((current, idx, levels) => {
		const gap = checkForGap(current, idx, levels);
		const lap = checkForOverlap(current, idx, levels);
		// errors
		const { errorMsg: gapMsg } = gap;
		const { errorMsg: lapMsg } = lap;

		//
		//
		//
		//
		if (gap.hasGap) {
			hasGap = true;
		}
		if (lap.hasOverlap) {
			hasOverlap = true;
		}

		const entry = {
			errors: [gapMsg, lapMsg],
		};

		errors.push(gapMsg, lapMsg);

		return entry;
	});

	return {
		...results,
		hasGap,
		hasOverlap,
		isBaseValid,
		isLastValid,
		errors: [...new Set(errors)],
	};
};

/**
 * Validates ALL floor units' care levels.
 * @param {Object} loc - An object of ALL floor unit's care levels.
 * @returns {Object} - Returns object w/ all floor unit's validation results.
 */
const validateAllLOC2 = (loc = {}) => {
	// floor units
	const al = "Assisted Living";
	const ind = "Independent";
	const mc = "Memory Care";
	const pc = "Personal Care";
	// const units = Object.keys(loc);
	// const sorted = sortAlphaAscByKey(units);
	// const [al, ind, mc, pc] = sorted;

	// ##TODOS:
	// - Make validation dynamic:
	// 		- Iterate thru LOC unit type keys to ONLY validate levels that apply (eg. 'AL', 'IND', 'MC' and maybe?? 'PC')

	// extract levels by floor unit
	const locAL = loc?.[al] ?? [];
	const locIND = loc?.[ind] ?? [];
	const locMC = loc?.[mc] ?? [];
	const locPC = loc?.[pc] ?? [];

	// validation by floor unit
	const errorsAL = validateLOC(locAL);
	const errorsIND = validateLOC(locIND);
	const errorsMC = validateLOC(locMC);
	const errorsPC = validateLOC(locPC);

	return {
		[al]: errorsAL,
		[ind]: errorsIND,
		[mc]: errorsMC,
		[pc]: errorsPC,
	};
};

const validateAllLOC = (loc = {}) => {
	const unitTypes = Object.keys(loc);

	const results = unitTypes.reduce((allResults, unitKey) => {
		const unitLevels = loc[unitKey];
		const validation = validateLOC(unitLevels);
		if (!allResults[unitKey]) {
			allResults[unitKey] = { ...validation };
			return allResults;
		}
		return allResults;
	}, {});

	return results;
};

/**
 * Checks a single floor unit's validation & returns whether it's valid/invalid.
 * @param {Object} unitValidation - Validation results for a single floor unit.
 * @returns {Boolean} - Returns whether care levels are valid (eg. no gaps/overlaps etc.)
 */
const validateUnitLOC = (unitValidation = {}) => {
	const { hasGap, hasOverlap, isBaseValid, isLastValid } = unitValidation;
	const noGapsOrOverlaps = !hasGap && !hasOverlap;
	const firstAndLast = isBaseValid && isLastValid;

	return noGapsOrOverlaps && firstAndLast;
};

/**
 * Checks all floor unit's validation results & returns whether all is valid.
 * @param {Object} loc - Validation results by floor unit.
 * @returns {Boolean} - Returns whether care levels are valid (eg. no gaps/overlaps etc.)
 */
const validateAllLOCUnits = (loc = {}) => {
	const units = Object.keys(loc);
	const results = units.every((unitKey) => {
		const unitResults = loc[unitKey];
		const state = validateUnitLOC(unitResults);

		return state;
	});

	return results;
};

// extract 'level X' and 'level X' gap range
const getGapRange = (gapMsg) => {
	if (isEmptyVal(gapMsg)) return { start: "", end: "" };

	const startReg = /(?<start>level \d) and (?<end>level \d)/im;
	const groups = gapMsg.match(startReg).groups;
	const start = groups?.start ?? "";
	const end = groups?.end ?? "";

	return {
		start: start,
		end: end,
	};
};
const getOverlapRange = (overlapMsg) => {
	if (isEmptyVal(overlapMsg)) return { start: "", end: "" };

	const startReg = /(?<start>level \d) and (?<end>level \d)/im;
	const groups = overlapMsg.match(startReg).groups;
	const start = groups?.start ?? "";
	const end = groups?.end ?? "";

	return {
		start: start,
		end: end,
	};
};

// sorts error messages into 'gaps' & 'overlaps'
const sortMessages = (errors = []) => {
	return errors.reduce(
		(map, msg) => {
			const gap = /a gap/gim;
			const overlap = /an overlap/gim;
			const hasGap = gap.test(msg);
			const hasOverlap = overlap.test(msg);

			if (hasGap) {
				map["gaps"].push(msg);
				return map;
			} else if (hasOverlap) {
				map["overlaps"].push(msg);
				return map;
			} else {
				return map;
			}
		},
		{ gaps: [], overlaps: [] }
	);
};

// LOC API VALIDATION UTILS //

/**
 * Checks all floor unit's validation results; returns true|false. Iterates thru all floor unit's results & checks the 'isValid' flag for each.
 * @param {Object} processedValidation - Object of 'processed' validation results.
 * @returns {Boolean} - Returns whether ALL LOC is valid care levels.
 */
const isValidLOC = (processedValidation = {}) => {
	const types = Object.keys(processedValidation);
	const isAllValid = types.every((type) => {
		const unitResults = processedValidation[type];
		return unitResults?.isValid;
	});

	return isAllValid;
};

/**
 * Returns boolean of a single floor unit's loc validation
 * @param {Object} unitValidation - A single floor unit's API validation.
 * @returns {Boolean} - Returns true|false, based off LOC validation
 */
const isUnitValid = (unitValidation = {}) => {
	const { Messages } = unitValidation;
	const isValid = isEmptyArray(Messages);

	return isValid;
};
/**
 * Extracts just error messages as an array of strings. Otherwise empty array.
 * @param {Object[]} messages - An array of API validation messages.
 * @returns {Object[]} - Returns array of strings messages
 */
const getUnitErrorMessages = (messages = []) => {
	if (!isEmptyArray(messages)) {
		const errors = messages.map(({ Message }) => Message);
		return errors;
	} else {
		return [];
	}
};

/**
 * Processes & formats API LOC validation response.
 * @param {Object[]} validation - Array of LOC validation objects.
 * @returns {Object} - Returns object of floor unit validation results, processed.
 */
const processAPIValidation = (validation = []) => {
	const unitsResults = validation.reduce((all, unitResponse) => {
		const { FloorUnit, Messages } = unitResponse;
		if (!all[FloorUnit]) {
			all[FloorUnit] = {
				isValid: isUnitValid(unitResponse),
				errors: getUnitErrorMessages(Messages),
			};
			return all;
		}
		return all;
	}, {});

	return unitsResults;
};

// care levels request utils
export {
	getFacilityLevelsOfCare,
	saveFacilityLevelsOfCare,
	saveFacilityLOCSettings,
	saveFacilityLOCFlagSettings,
	resetFacilityLevelsOfCare,
	isEditableLevelsOfCare,
	// validator
	fetchAndProcessLOCApiValidation,
	validateLevelsOfCare,
	// lock/unlock requests
	lockAllChildLOCByParent,
	unlockAllChildLOCByParent,
	getLOCLockStatus,
};

export { processLOCInfoField, processLOCLockRecord };

export { defaultSchema };

export {
	groupAndProcessCareLevels,
	formatLOC,
	processLOCRecords,
	processLOC,
	mergeLOCIntoArray,
	// request formatting utils
	prepareCareLevels,
	prepareAllCareLevels,
	prepareLOCSettings,
};

// validators
export {
	// validation utils
	getGapRange,
	getOverlapRange,
	sortMessages,
	// checkers
	checkForGap,
	checkForOverlap,
	// valdators
	validateBaseLevel,
	validateLastLevel,
	// wrapper validator
	validateLOC,
	validateAllLOC,
	// handles all tables (eg. floor units)
	validateAllLOCUnits,
	//
	// API VALIDATION UTILS
	isValidLOC,
	isUnitValid,
	getUnitErrorMessages,
	processAPIValidation,
};
