import React from "react";
import styles from "../../css/user/CreateUserStep6.module.scss";
import { PropTypes } from "prop-types";
// components
import MultiSelect from "../shared/MultiSelect";

// REQUIREMENTS:
// - Currently used for 'Creating' & 'Saving' new user
// - Replace this step's content with Granting Facility Access
// - Move 'CreateUserSummary' to "Step-7"

const CreateUserStep6 = ({
	facilitySelections = [],
	allFacilities = [],
	handleMultiSelect,
	handleSkip,
}) => {
	return (
		<div className={styles.CreateUserStep6}>
			<div className={styles.CreateUserStep6_selector}>
				<MultiSelect
					initialSelections={facilitySelections}
					handleMultiSelect={handleMultiSelect}
					options={allFacilities}
				/>
			</div>
			<div className={styles.CreateUserStep6_selections}>
				<div className={styles.CreateUserStep6_selections_count}>
					This user will have access to{" "}
					<b className={styles.CreateUserStep6_selections_count_value}>
						{facilitySelections.length ?? 0}{" "}
					</b>
					facilities.
				</div>
			</div>
		</div>
	);
};

export default CreateUserStep6;

CreateUserStep6.defaultProps = {
	vals: {},
};

CreateUserStep6.propTypes = {
	vals: PropTypes.object,
	cancelNewUser: PropTypes.func,
	createNewUser: PropTypes.func,
};
