import React, { useState } from "react";
import styles from "../../css/alerts/OfflineIndicator.module.scss";
import sprite from "../../assets/icons/offline.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";

import { PropTypes } from "prop-types";
import { useOfflineIndicator } from "../../utils/useOfflineIndicator";

const OfflineIndicator = ({
	title = `You're Offline!`,
	subtitle = `Please check your network connection and try again.`,
}) => {
	const { isOffline } = useOfflineIndicator();
	const [forceClose, setForceClose] = useState(false);

	const handleClose = () => {
		setForceClose(true);
	};

	if (!isOffline || forceClose) {
		return null;
	}
	return (
		<div className={styles.OfflineIndicator}>
			<div className={styles.OfflineIndicator_top}>
				<svg className={styles.OfflineIndicator_top_icon} onClick={handleClose}>
					<use xlinkHref={`${sprite2}#icon-clearclose`}></use>
				</svg>
			</div>
			<div className={styles.OfflineIndicator_wrapper}>
				<svg className={styles.OfflineIndicator_wrapper_icon}>
					<use xlinkHref={`${sprite}#icon-error-cloud-5`}></use>
					{/* <use xlinkHref={`${sprite}#icon-wifi-off`}></use> */}
				</svg>
			</div>
			<div className={styles.OfflineIndicator_message}>
				<div className={styles.OfflineIndicator_message_title}>{title}</div>
				<div className={styles.OfflineIndicator_message_subtitle}>
					{subtitle}
				</div>
			</div>
		</div>
	);
};

export default OfflineIndicator;

OfflineIndicator.defaultProps = {};

OfflineIndicator.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
};
