import React from "react";
import styles from "../../css/user/UserTypes.module.scss";
import { PropTypes } from "prop-types";
import CustomCheckbox from "../shared/CustomCheckbox";

const customCSS = {
	checkbox: {
		marginBottom: "1rem",
	},
};

const userTypesMap = {
	isAdmin: "Admin",
	isMedTechRestricted: "Med-Tech Restricted Access",
	isSuperUser: "Super User",
};

const TypeDescription = ({ vals = {} }) => {
	const renderDesc = (vals) => {
		switch (true) {
			case vals?.isMedTechRestricted: {
				return (
					<div className={styles.TypeDescription}>
						<div className={styles.TypeDescription_text}>
							<b className={styles.TypeDescription_text_type}>
								{userTypesMap.isMedTechRestricted}{" "}
							</b>
							<span>
								may limit a user's access to only certain parts that are
								relevant to them such as 'Charting', and 'Residents' etc. They
								will not be able to edit, change or view other users.
							</span>
						</div>
					</div>
				);
			}
			case vals?.isAdmin: {
				return (
					<div className={styles.TypeDescription}>
						<div className={styles.TypeDescription_text}>
							<b className={styles.TypeDescription_text_type}>
								{userTypesMap.isAdmin}{" "}
							</b>
							<span>
								have the ability to make edits, changes & view other Admins.
								They may also have additional features enabled in various ALA
								applications.
							</span>
						</div>
					</div>
				);
			}
			case vals?.isSuperUser: {
				return (
					<div className={styles.TypeDescription}>
						<div className={styles.TypeDescription_text}>
							<b className={styles.TypeDescription_text_type}>
								{userTypesMap.isSuperUser}{" "}
							</b>
							<span>
								have the ability to access any and all available pages &
								features currently available in an ALA application. They are
								also able to edit other users and/or admins.
							</span>
						</div>
					</div>
				);
			}
			default:
				return;
		}
	};

	return <>{renderDesc(vals)}</>;
};

const UserTypes = ({ vals = {}, handleUserTypes }) => {
	return (
		<div className={styles.UserTypes}>
			<div className={styles.UserTypes_desc}>
				Changing user-type may enable/disable features and access for various
				ALA applications. User-types can be changed at any time.
			</div>
			<CustomCheckbox
				isDisabled={vals?.isMedTechRestricted || vals?.isSuperUser}
				name="isAdmin"
				id="isAdmin"
				label="Admin"
				val={vals?.isAdmin}
				handleCheckbox={handleUserTypes}
				customStyles={customCSS.checkbox}
			/>
			<CustomCheckbox
				isDisabled={vals?.isAdmin || vals?.isSuperUser}
				name="isMedTechRestricted"
				id="isMedTechRestricted"
				label="Med-Tech Restricted"
				val={vals?.isMedTechRestricted}
				handleCheckbox={handleUserTypes}
				customStyles={customCSS.checkbox}
			/>
			<CustomCheckbox
				isDisabled={vals?.isMedTechRestricted || vals?.isAdmin}
				name="isSuperUser"
				id="isSuperUser"
				label="Super User"
				val={vals?.isSuperUser}
				handleCheckbox={handleUserTypes}
				customStyles={customCSS.checkbox}
			/>
			<TypeDescription vals={vals} />
		</div>
	);
};

export default UserTypes;

UserTypes.defaultProps = {};

UserTypes.propTypes = {};
