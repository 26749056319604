import { currentEnv } from "./utils_env";
import { getFileRegistry, downloads, uploads, dms } from "./utils_endpoints";

const MIME_TYPES = {
	png: "image/png",
	jpeg: "image/jpeg",
	gif: "image/gif",
	webp: "image/webp",
	pdf: "application/pdf",
	mp4: "video/mp4",
	text: "text/plain",
	rtf: "application/rtf",
	css: "text/css",
	csv: "text/csv",
	html: "text/html",
	js: "text/javascript",
	json: "application/json",
	svg: "image/svg+xml",
	doc: "application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	stream: "application/octet-stream",
	unknown: "application/octet-stream",
	undefined: "application/octet-stream",
};

/**
 * @description - Fetches a PDF file from ALA Services, and inits a PDF mirror via an embed element
 * @param {String} token - A base64 encoded auth token.
 * @param {Number} id - A "FileRegistryID" that refers to a file record in the ALA DMS.
 * @returns {Blob} - Returns a converted response object as a "Blob" instance. Used for embeddable content, mirror, file downloads etc.
 */
const getFileBlob = async (token, id) => {
	let url = currentEnv.base + downloads.getFile;
	url += "?id=" + id;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const blob = await request.blob();
		return blob;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a list of file records (ie 'FileRegistry') for a given facility.
 * @returns {Array|Null} - Returns an array of files or null, if empty.
 */
const getFileRegistryByFacility = async (token, facilityID) => {
	let url = currentEnv.base + getFileRegistry.byFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a list of file records (ie 'FileRegistry') for a given resident.
 * @returns {Array|Null} - Returns an array of files or null, if empty.
 */
const getFileRegistryByResident = async (token, residentID) => {
	let url = currentEnv.base + getFileRegistry.byResident;
	url += "?" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a list of file records (ie 'FileRegistry') for a given user.
 * @returns {Array|Null} - Returns an array of files or null, if empty.
 */
const getFileRegistryByUser = async (token, userID) => {
	let url = currentEnv.base + getFileRegistry.byUser;
	url += "?" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Extracts a resident's photo from 'RESIDENT_PHOTOS' table into the ALA DMS repository.
 * @param {String} token - Auth token
 * @param {Number} residentId - Resident ID
 * @returns {Boolean} - Returns whether process was successful
 */
const extractResidentPhotoToDMS = async (token, residentId) => {
	let url = currentEnv.base + dms.extract.residentPhoto;
	url += "?" + new URLSearchParams({ residentId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Extracts an entire facility's resident photos from 'RESIDENT_PHOTOS' table into the ALA DMS repository.
 * @param {String} token - Auth token
 * @param {Number} facilityId - Target facility guid.
 * @returns {Boolean} - Returns whether process was successful
 */
const extractFacilitysResidentPhotosToDMS = async (token, facilityId) => {
	let url = currentEnv.base + dms.extract.facilitysResidentPhotos;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * @description - Fetches a file blob, converts it to pdf and creates the blobURL for mirroring.
 * @param {String} token - Auth token
 * @param {Number|String} fileID - A file's unique identifier or filename (w/ complete path)
 */
const getPDFSource = async (token, fileID) => {
	const blob = await getFileBlob(token, fileID);
	const pdfBlob = createBlob(blob, "application/pdf");
	const pdfURL = createURL(pdfBlob);

	return pdfURL;
};
const getIMGSource = async (token, fileID) => {
	const blob = await getFileBlob(token, fileID);
	const url = createURL(blob);
	return url;
};

// creates form-data for a file upload
const getFormData = (fileName, file) => {
	const formData = new FormData();
	formData.append(fileName, file);
	return formData;
};

const uploadFile = async (token, fileIdOrName, file) => {
	let url = currentEnv.base + uploads.upload;
	url += "?" + new URLSearchParams({ id: fileIdOrName });

	const data = getFormData(fileIdOrName, file);

	try {
		const request = await fetch(url, {
			method: "PUT",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "multipart/form-data",
			},
			body: data,
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * File processing & data utils:
 * - Create a file blob
 * - Create an objectURL
 * - Save a file to user's local machine
 * - Convert file size to XXXX unit
 */

/**
 * @description - A helper for converting data into a file blob w/ a custom mimetype.
 * @param {Blob|Response Object} data - Any transformable data type that can be converted to a blob. Typically a response object or blob.
 * @param {String} mimeType - A custom mimetype used to set the new Blob instance to.
 * @returns {Blob} - returns a file blob, w/ a custom mimetype.
 */
const createBlob = (data, mimeType = "application/octet-stream") => {
	return new Blob([data], { type: mimeType });
};

/**
 * @description - Utility that accepts a file blob and creates an object URL.
 * @param {Blob} blob - A file blob to be used for an object URL.
 */
const createURL = (blob) => {
	const fileURL = window.URL.createObjectURL(blob);
	return fileURL;
};

/**
 * @description - A utility for creating an object URI to trigger a file download to a user's machine.
 * @param {Blob} blob - A file blob, typically transformed from the HTTP response object
 * @param {String} filename - A custom filename used for saving the file to a thser's machine.
 * @returns {Blob} - Returns a fileblob that's immediately downloaded to a user's machine.
 */
const saveFile = (blob, filename) => {
	const fileURL = window.URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = fileURL;
	link.download = filename;
	link.click();
	return window.URL.revokeObjectURL(fileURL);
};

/**
 * Converts a file blob to base64.
 * @param {FileBlob} blob - A file blob to be converted to base64
 * @returns {Base64String} - Returns a base64 string for immediate use
 */
const blobToBase64 = (blob) => {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};

/**
 * Converts a base64 file string to a file blob.
 * @param {Base64String} base64 - A base64 file string (eg. 'data:image/png;base.....')
 * @returns {FileBlob} - Returns a file blob
 */
const base64ToBlob = async (base64) => {
	let url = base64;

	try {
		const request = await fetch(url);
		const blob = await request.blob();
		return blob;
	} catch (err) {
		return err;
	}
};

// converts x bytes to any unit between bytes-gb
const convertBytes = (bytes, to = "KB") => {
	switch (to) {
		case "B": {
			return bytes;
		}
		case "KB": {
			const size = (bytes / 1024).toFixed(2);
			return size;
		}
		case "MB": {
			const size = (bytes / 1024 / 1024).toFixed(2);
			return size;
		}
		case "GB": {
			const size = (bytes / 1024 / 1024 / 1024).toFixed(4);
			return size;
		}
		default:
			return bytes;
	}
};

// converts bytes to target unit & formats w/ unit abbreviation
const convertAndFormatBytes = (bytes, to = "KB") => {
	switch (to) {
		case "B": {
			return `${bytes} b`;
		}
		case "KB": {
			const size = (bytes / 1024).toFixed(2);
			return `${size} KB`;
		}
		case "MB": {
			const size = (bytes / 1024 / 1024).toFixed(2);
			return `${size} MB`;
		}
		case "GB": {
			const size = (bytes / 1024 / 1024 / 1024).toFixed(4);
			return `${size} GB`;
		}
		default:
			return `${bytes} b`;
	}
};

// MIME TYPES
export { MIME_TYPES };

export { extractResidentPhotoToDMS, extractFacilitysResidentPhotosToDMS };

// file fetching utils
export {
	getFileBlob,
	getFileRegistryByFacility,
	getFileRegistryByResident,
	getFileRegistryByUser,
};

// file-type fetch & processing utils
export { getIMGSource, getPDFSource };

export { createBlob, createURL, saveFile, blobToBase64, base64ToBlob };

export { convertAndFormatBytes, convertBytes };

export { getFormData, uploadFile };
