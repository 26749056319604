import { currentEnv } from "./utils_env";
import { facility, generic, residentData, residents } from "./utils_endpoints";
import { isEmptyArray, isEmptyObj, isEmptyVal } from "./utils_types";
import { addEllipsis } from "./utils_processing";
import { format } from "date-fns";
import { params } from "./utils_params";
import { getFacilityID } from "./utils_facility";

/**
 * Requests transfer of a resident to a target facility on a specific date.
 * @param {String} token - Auth token
 * @param {Number} residentId - Numeric resident ID
 * @param {String} targetFacilityId - Target facility guid for resident to be transferred to.
 * @param {Date} targetDate - Date that resident should be transferred. If 'null' transfer immediately.
 * @returns {Object} - Returns whether transfer was successful/failed or pending for future date.
 */
const transferResidentToFacility = async (
	token,
	residentId,
	targetFacilityId,
	scheduleFor = null
) => {
	let url = currentEnv.base + residentData.transferResident.toFacility;
	url += "?" + new URLSearchParams({ residentId }); // resident ID to transfer
	url += "&" + new URLSearchParams({ facilityId: targetFacilityId }); // target facility ID to be transferred to
	url += "&" + new URLSearchParams({ scheduleFor }); // target facility ID to be transferred to

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const getResidentsByFacility = async (token, facilityId) => {
	let url = currentEnv.base + facility.getResidents;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches exhaustive (fairly) resident profile
 * @param {String} token - Auth token
 * @param {Number} residentID - Numeric resident id
 * @returns {Object} - Returns object of various resident profile data.
 */
const getResidentProfile = async (token, residentID) => {
	let url = currentEnv.base + residentData.getProfile;
	url += "?residentId=" + residentID;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches exhaustive (fairly) resident profile
 * @param {String} token - Auth token
 * @param {Number} residentID - Numeric resident id
 * @returns {Object} - Returns object of various resident profile data.
 */
const getResidentRecord = async (token, residentID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.residents });
	url += "&" + new URLSearchParams({ ID: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		const data = response.Data?.[0] ?? {};

		return data;
	} catch (err) {
		return err.message;
	}
};

// fetches exhaustive resident assessment data
const getResidentInfo = async (token, residentId) => {
	let url = currentEnv.base + residentData.getAssessment;
	url += "?" + new URLSearchParams({ residentId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const getResidentLOA = async (token, residentID) => {
	let url = currentEnv.base + residentData.getLOA;
	url += "?residentId=" + residentID;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return;
	}
};

// fetches a resident record from the 'RESIDENTS' table
const getResidentContacts = async (token, residentId) => {
	let url = currentEnv.base + residentData.getContacts;
	url += "?" + new URLSearchParams({ residentId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const getResidentDetails = async (token, residentID) => {
	const [resident, loa] = await Promise.all([
		getResidentRecord(token, residentID),
		getResidentLOA(token, residentID),
	]);

	return {
		...resident,
		...loa,
	};
};
const getResidentDetails2 = async (token, residentID) => {
	const [resident, loa, contacts] = await Promise.all([
		getResidentRecord(token, residentID),
		getResidentLOA(token, residentID),
		getResidentContacts(token, residentID),
	]);

	return {
		info: resident, // object
		loa: loa, // array
		contacts: contacts, // array
		// ...resident,
		// ...loa,
		// ...contacts,
	};
};

// RESIDENT SEARCH REQUESTS //
/**
 * Search for a resident by resident values.
 * @param {String} token - Auth token
 * @param {Object} searchParams - Custom query params w/ the search criteria formatted.
 * @returns {Object|Array|Null} - Returns search results for a given search query.
 */
const searchForResident = async (token, searchParams = {}) => {
	let url = currentEnv.base + residents.getResident.search;
	url += "?" + new URLSearchParams({ ...params.residents });
	url += "&" + new URLSearchParams({ ...searchParams });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Handles resident searches w/ custom parameters & search-by-param-type.
 * @param {String} token - Auth token
 * @param {Object[]} allFacilities - Array of facility objects.
 * @param {Object} vals - Object of search values.
 * @returns {Object[]} - Returns an array of resident search results.
 */
const searchForResidentBy = async (token, allFacilities = [], vals = {}) => {
	switch (true) {
		case vals.byAge: {
			const { residentAgeSearch } = vals;
			const resp = await searchForResident(token, {
				Age: residentAgeSearch,
			});
			return resp;
		}
		case vals.byFirstName: {
			const { firstNameSearch } = vals;
			const resp = await searchForResident(token, {
				FirstName: firstNameSearch,
			});
			return resp;
		}
		case vals.byLastName: {
			const { lastNameSearch } = vals;
			const resp = await searchForResident(token, {
				LastName: lastNameSearch,
			});
			return resp;
		}
		case vals.byFullName: {
			const { residentFirstName, residentLastName } = vals;
			const resp = await searchForResident(token, {
				FirstName: residentFirstName,
				LastName: residentLastName,
			});
			return resp;
		}
		case vals?.byFacility: {
			const { facilitySearch } = vals;
			const facilityID = getFacilityID(facilitySearch, allFacilities);
			const resp = await searchForResident(token, {
				guidFacility: facilityID,
			});
			return resp;
		}
		case vals?.byResidentID: {
			const { residentIDSearch } = vals;
			const resp = await searchForResident(token, {
				ID: residentIDSearch,
			});

			return resp;
		}
		case vals?.byUnitType: {
			const { residentUnitSearch } = vals;
			const resp = await searchForResident(token, {
				FloorUnit: residentUnitSearch,
			});

			return resp;
		}
		case vals?.byRoomNum: {
			const { residentRoomSearch } = vals;
			const resp = await searchForResident(token, {
				RoomNum: residentRoomSearch,
			});

			return resp;
		}
		case vals?.byArchive: {
			const archiveParams = getResidentSearchParams(vals);

			const resp = await searchForResident(token, archiveParams);

			return resp;
		}
		case vals?.byYardiNumber: {
			const { yardiNumberSearch } = vals;

			const resp = await searchForResident(token, {
				YardiNumber: yardiNumberSearch,
			});

			return resp;
		}
		case vals?.byMultiple: {
			const { byArchive } = vals;
			const resp = await searchForResident(token, {
				Archive: byArchive,
			});

			return resp;
		}
		default:
			return [];
	}
};

// client-to-table keys map
const residentKeysMap = {
	// booleans
	byArchive: "Archive",
	isArchived: "Archive",
	// byResidentFirstName: 'FirstName',
	// byResidentLastName: 'LastName',
	// byFullName: false,
	// byResidentID: false,
	// byUnitType: false,
	// byFacility: false,
	// byRoomNum: false,
	// archive search fields
	archiveResidentID: "ID",
	archiveResidentFirstName: "FirstName",
	archiveResidentLastName: "LastName",
	archiveResidentRoomSearch: "RoomNum",
	// other search keys
	residentAgeSearch: "Age",
	residentUnitSearch: "FloorUnit",
	residentIDSearch: "ID",
	firstNameSearch: "FirstName",
	lastNameSearch: "LastName",
	residentYardiNumber: "YardiNumber",
};

// extracts non-empty search values & converts them to table-format params
const getResidentSearchParams = (vals = {}) => {
	const keys = Object.keys(vals);

	const nonEmptyParams = keys.reduce((params, key) => {
		const hasVal = !isEmptyVal(vals[key]);
		// if key has a value:
		// - get key, and value & extract table key name from map
		if (hasVal) {
			const val = vals[key];
			const tableKey = residentKeysMap[key];
			params = {
				...params,
				[tableKey]: val,
			};
			return params;
		}

		return params;
	}, {});

	return nonEmptyParams;
};

// sorts unformatted users-list by 'LastName' & formats as string for <CustomDropdown/>
const formatAndSortResidents = (residents = []) => {
	return residents
		.sort((a, b) => {
			return a?.LastName.localeCompare(b?.LastName);
		})
		.map(({ FirstName, LastName, ResidentID }) => {
			return `${FirstName} ${LastName} ~ ALA ID: ${ResidentID}`;
		});
};

// sorts formatted users-list by 'lastName'
const formatAndSortUserResidents = (residents = []) => {
	return residents
		.sort((a, b) => {
			return a?.lastName.localeCompare(b?.lastName);
		})
		.map(({ firstName, lastName, residentID }) => {
			return `${firstName} ${lastName} ~ ALA ID: ${residentID}`;
		});
};

// custom processed resident obj
const createResidentObj = (record) => {
	const id = record?.ID ?? record?.ResidentID;
	const first = record?.FirstName ?? record?.firstName;
	const last = record?.LastName ?? record?.lastName;
	const age = record?.Age ?? record?.age;
	const unitType = record?.FloorUnit ?? record?.floorUnit;
	const roomNum = record?.RoomNum ?? record?.roomNum;
	const facilityID = record?.FacilityId ?? record?.FacilityID;
	const dateOfBirth = record?.DateOfBirth ?? record?.DOB;
	const archiveDate = record?.DateLastArchived;
	const isArchived = record?.Archive ?? record?.isArchived ?? false;
	const admissionDate = record?.DateAdmission ?? record?.admissionDate;
	const yardiNum = record?.YardiNumber ?? "";
	const medicareNum = record?.MedicareNum ?? "";

	// formats date of birth: MM/DD/YYYY
	const dob = !isEmptyVal(dateOfBirth)
		? format(dateOfBirth, "MM/DD/YYYY")
		: dateOfBirth;

	return {
		isArchived,
		admissionDate,
		residentID: id,
		firstName: first,
		lastName: last,
		age: age,
		unitType: unitType,
		roomNum: roomNum,
		facilityID: facilityID,
		dateOfBirth: dob,
		archiveDate: archiveDate,
		yardiNumber: yardiNum,
		medicareNumber: medicareNum,
	};
};

const processResidentsList = (residents = []) => {
	if (isEmptyArray(residents)) return [];
	return residents.reduce((allResidents, resident) => {
		const newRecord = createResidentObj(resident);
		allResidents = [...allResidents, newRecord];
		return allResidents;
	}, []);
};

const processResidentRecord = (record = {}) => {
	const {
		Alergies: allergies,
		CodeStatus: codeStatus,
		DateAdmission: admissionDate,
		DateOfBirth: dateOfBirth,
		DateLastArchived: lastArchivedDate,
		Diabetic: isDiabetic,
		Diagnosis: diagnosisPrimary,
		DiagnosisTwo: diagnosisSecondary,
		FirstName: firstName,
		LastName: lastName,
		FloorUnit: unitType,
		RoomNum: roomNumber,
		SSN: ssn,
		ResidentType: residentStatus,
		ResHeight: height,
		ResWeight: weight,
		MoveInDate: moveInDate,
		FacilityID: facilityID,
	} = record;

	return {
		facilityID,
		allergies,
		codeStatus,
		admissionDate,
		dateOfBirth,
		lastArchivedDate,
		isDiabetic,
		diagnosisPrimary,
		diagnosisSecondary,
		firstName,
		lastName,
		unitType,
		roomNumber,
		ssn,
		residentStatus,
		height,
		weight,
		moveInDate,
	};
};

// RESIDENT FIELD UTILS //

// formats name: 'FirstName LastName'
const getResidentName = (resident) => {
	if (isEmptyObj(resident)) return "";
	const { firstName, lastName } = resident;
	const name = `${lastName}, ${firstName}`;
	return addEllipsis(name, 40);
};

const getResidentsName = (resident) => {
	const first = resident?.FirstName ?? resident?.firstName ?? "";
	const last = resident?.LastName ?? resident?.lastName ?? "";

	return `${last}, ${first}`;
};
const getResidentID = (resident) => {
	const id = resident?.ID ?? resident?.ResidentID ?? resident?.ResidentId;

	return id;
};

const getResidentAge = (resident) => {
	const age = resident?.age ?? "Unknown";

	return age;
};

const getResidentLeaveStatus = (resident) => {
	//
};

const getResidentArchiveInfo = (resident) => {
	const {
		Archive: isArchived,
		DateLastArchived: archiveDate,
		ArchiveLockedUntil: archivedUntil,
	} = resident;

	return {
		isArchived,
		archiveDate,
		archivedUntil,
	};
};

const getResidentUnitType = (resident = {}) => {
	const unitType =
		resident?.FloorUnit ?? resident?.UnitType ?? resident?.unitType ?? "";

	return unitType;
};

const getArchiveDate = (resident) => {
	const { archiveDate } = getResidentArchiveInfo(resident);

	return archiveDate;
};

const getResidentStatus = (resident) => {
	// gets resident's current status:
	// - 'Active'
	// - 'Archived'
	// - 'LOA'
	// - 'Inactive'???
	const { ResidentType: type } = resident;
	const { isArchived, archiveDate, archivedUntil } =
		getResidentArchiveInfo(resident);
	const status = isArchived ? `Archived` : type;

	return {
		status,
		isArchived,
		archiveDate,
		archivedUntil,
	};
};

const getResidentAdmissionDate = (resident) => {
	const date = resident?.DateAdmission ?? resident?.admissionDate;
	if (!date || isEmptyVal(date)) return `Unkown admission date`;
	return format(date, "MM/DD/YYYY h:mm A");
};

// ANY FORMAT UTILS

// supports server/client formats
const getResidentNameAny = (resident) => {
	if (isEmptyObj(resident)) return "";
	const firstName = resident?.firstName ?? resident?.FirstName;
	const lastName = resident?.lastName ?? resident?.LastName;

	const name = `${lastName}, ${firstName}`;
	return addEllipsis(name, 40);
};

// RESIDENT UNIT TYPES //

const residentUnitTypes = [
	`Memory Care`,
	`Personal Care`,
	`Independent`,
	`Assisted Living`,
];

export {
	getResidentsByFacility,
	getResidentProfile,
	getResidentInfo,
	getResidentLOA,
	getResidentContacts,
	// main request utils
	getResidentRecord,
	getResidentDetails,
	getResidentDetails2,
	transferResidentToFacility,
	// search residents
	searchForResident,
	searchForResidentBy,
};

export { formatAndSortResidents, formatAndSortUserResidents };

export { processResidentsList, processResidentRecord };

// custom search utils
export { residentKeysMap, getResidentSearchParams };

export {
	getResidentName,
	getResidentAge,
	getResidentsName,
	getResidentID,
	getResidentStatus,
	getResidentArchiveInfo,
	getArchiveDate,
	getResidentUnitType,
	getResidentLeaveStatus,
	getResidentAdmissionDate,
	// field utils that support server & client format
	getResidentNameAny,
};

// unit types
export { residentUnitTypes };
