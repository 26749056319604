import React from "react";
import styles from "../../css/loctemplate/LOCControllerContainer.module.scss";
import { PropTypes } from "prop-types";

const LOCControllerContainer = ({ children }) => {
	return (
		<div className={styles.LOCControllerContainer}>
			{children}
			{/*  */}
		</div>
	);
};

export default LOCControllerContainer;

LOCControllerContainer.defaultProps = {};

LOCControllerContainer.propTypes = {};
