import React, { useEffect, useState } from "react";
import styles from "../../css/app/ResetOptions.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import {
	checkIfUsernameExists,
	getUserAuthStatus,
} from "../../helpers/utils_auth";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { convertDaysToMins } from "../../helpers/utils_dates";
// components
import ResetPasswordByQuestions from "./ResetPasswordByQuestions";
import ResetPasswordByEmail from "./ResetPasswordByEmail";
import ResetPasswordByAdmin from "./ResetPasswordByAdmin";
import VerifyUser from "./VerifyUser";
import Divider from "../forms/Divider";

// REQUIREMENTS:
// - Implement UI for "Reset Password" upon successfully:
// 		- Answering questions & answers

// USER-FLOWS BY RESET TYPE:
// - By Questions:
// 		1. Validate username exists
// 		2. Answer questions & answers
// 		3. Show "Reset Password" UI
// - By Admin:
// 		1. Validate username exists
// 		2. Fire off email to admin with OTP for user
// 		3. Notify user that admin has been notified via email with UI notice
// - By Email:
// 		1. Validate username exists
// 		2. Fire off email to user's email address
// 		3. Notify user that an email w/ a OTP has been sent and they use it to login once!

// this is used when a request returns too quickly.
// This gives the appearance of a 'loading' state
const FAUX_TIME = 2000;

const initialAccountSecurity = {
	userEmail: null,
	userID: null,
	isValidUser: false,
	hasAppAccess: false,
	possibleMatches: [],
	reasonForFailure: null, // 'AccountLocked', 'InvalidUser'
	failedLogins: null, // # of failed attempts
	resetMethods: [], // ['Admin', 'Email', 'Questions']
};
const canResetByAdmin = (accountSecurity = {}) => {
	const { resetMethods } = accountSecurity;
	if (isEmptyArray(resetMethods)) return null;
	return resetMethods.includes("Admin");
};
const canResetByEmail = (accountSecurity = {}) => {
	const { resetMethods } = accountSecurity;
	if (isEmptyArray(resetMethods)) return null;
	return resetMethods.includes("Email");
};
const canResetByQuestions = (accountSecurity = {}) => {
	const { resetMethods } = accountSecurity;
	if (isEmptyArray(resetMethods)) return null;
	return resetMethods.includes("Questions");
};

const getResetType = (accountSecurity = {}) => {
	if (isEmptyObj(accountSecurity)) return null;
	if (canResetByEmail(accountSecurity)) {
		return "Email";
	} else if (canResetByQuestions(accountSecurity)) {
		return "Questions";
	} else {
		return "Admin";
	}
};

const ConfirmedMsg_OLD = () => {
	return (
		<div className={styles.ConfirmedMsg}>
			<svg className={styles.ConfirmedMsg_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
			<span className={styles.ConfirmedMsg_msg}>Success</span>
		</div>
	);
};
const ConfirmedMsg = () => {
	return (
		<div className={styles.ConfirmedMsg}>
			<svg className={styles.InvalidMsg_icon}>
				<use xlinkHref={`${sprite}#icon-error_outline`}></use>
			</svg>
			{/* <span className={styles.InvalidMsg_msg}>User Does Not Exist!</span> */}
			<span className={styles.InvalidMsg_msg}>
				If we find your account, a reset message will be sent!
			</span>
		</div>
	);
};
const InvalidMsg = () => {
	return (
		<div className={styles.InvalidMsg}>
			<svg className={styles.InvalidMsg_icon}>
				<use xlinkHref={`${sprite}#icon-error_outline`}></use>
			</svg>
			{/* <span className={styles.InvalidMsg_msg}>User Does Not Exist!</span> */}
			<span className={styles.InvalidMsg_msg}>
				If we find your account, a reset message will be sent!
			</span>
		</div>
	);
};

const ResetOptions = ({
	systemUser = {},
	authData = {},
	closeModal,
	dispatchAlert,
}) => {
	const [confirmUsername, setConfirmUsername] = useState("");
	const [isVerifying, setIsVerifying] = useState(false);
	const [userCheck, setUserCheck] = useState({
		isConfirmed: false,
		hasBeenChecked: false,
	});
	const [accountSecurity, setAccountSecurity] = useState({});
	const [resetType, setResetType] = useState(null);

	const { isConfirmed: isUserConfirmed, hasBeenChecked } = userCheck;

	const verifyUser = async () => {
		const { token } = systemUser;
		// check if valid user
		// if valid, get user's access & reset methods
		const [isValidUser, access] = await Promise.all([
			checkIfUsernameExists(token, confirmUsername),
			getUserAuthStatus(token, confirmUsername, convertDaysToMins(3)),
		]);
		setIsVerifying(true);

		if (isValidUser) {
			setUserCheck({
				isConfirmed: true,
				hasBeenChecked: true,
			});
			setAccountSecurity(access);
			return setResetType(() => getResetType(access));
		} else {
			return setUserCheck({
				isConfirmed: false,
				hasBeenChecked: true,
			});
		}
	};

	const handleUsername = (e) => {
		const { value } = e.target;
		if (isEmptyVal(value)) {
			setConfirmUsername(value);
			return setUserCheck({
				isConfirmed: false,
				hasBeenChecked: false,
			});
		} else {
			return setConfirmUsername(value);
		}
	};

	// reset 'isLoading' state after 4000
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let verifyID;
		if (isVerifying) {
			verifyID = setTimeout(() => {
				setIsVerifying(false);
			}, FAUX_TIME);
		}

		return () => {
			isMounted = false;
			clearTimeout(verifyID);
		};
	}, [isVerifying]);

	return (
		<div className={styles.ResetOptions}>
			<div className={styles.ResetOptions_header}>
				<VerifyUser
					systemUser={systemUser}
					authData={authData}
					closeModal={closeModal}
					confirmUsername={confirmUsername}
					handleUsername={handleUsername}
					verifyUsername={verifyUser}
					isVerifying={isVerifying}
				/>
			</div>

			{/* USER CONFIRMATION INDICATOR(S) */}
			{isUserConfirmed && !isVerifying && <ConfirmedMsg />}
			{!isUserConfirmed && hasBeenChecked && <InvalidMsg />}

			<Divider />

			{isUserConfirmed && !isVerifying && (
				<div className={styles.ResetOptions_options}>
					{resetType === "Email" && (
						<ResetPasswordByEmail
							systemUser={systemUser}
							authData={authData}
							accountSecurity={accountSecurity}
							closeModal={closeModal}
							userEmail={confirmUsername}
							dispatchAlert={dispatchAlert}
						/>
					)}
					{resetType === "Questions" && (
						<ResetPasswordByQuestions
							systemUser={systemUser}
							authData={authData}
							closeModal={closeModal}
							usernameIsConfirmed={isUserConfirmed}
							accountSecurity={accountSecurity}
							dispatchAlert={dispatchAlert}
						/>
					)}
					{resetType === "Admin" && (
						<ResetPasswordByAdmin
							systemUser={systemUser}
							authData={authData}
							closeModal={closeModal}
							usernameIsConfirmed={isUserConfirmed}
							accountSecurity={accountSecurity}
							dispatchAlert={dispatchAlert}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ResetOptions;

ResetOptions.defaultProps = {
	authData: {},
};

ResetOptions.propTypes = {
	authData: PropTypes.object,
	closeModal: PropTypes.func,
};
