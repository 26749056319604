import React from "react";
import styles from "../../css/loc/LOCValidatorSummary.module.scss";
import { PropTypes } from "prop-types";
import LOCValidatorResults from "./LOCValidatorResults";

// SHOWS VALIDATION FOR A SINGLE FLOOR UNIT W/ A TITLE HEADING

const LOCValidatorSummary = ({ unitType, validationSummary = {} }) => {
	const { errors } = validationSummary;
	// const {gaps, overlaps} = sortMessages(errors)

	return (
		<div className={styles.LOCValidatorSummary}>
			<div className={styles.LOCValidatorSummary_tableName}>{unitType}</div>
			<div className={styles.LOCValidatorSummary_details}>
				<LOCValidatorResults
					key={`RESULTS-${errors?.length}`}
					results={validationSummary}
				/>
			</div>
		</div>
	);
};

export default LOCValidatorSummary;

LOCValidatorSummary.defaultProps = {};

LOCValidatorSummary.propTypes = {
	unitType: PropTypes.string.isRequired,
	validationSummary: PropTypes.shape({
		hasGap: PropTypes.bool,
		hasOverlap: PropTypes.bool,
		isBaseValid: PropTypes.bool,
		isLastValid: PropTypes.bool,
		errors: PropTypes.arrayOf(PropTypes.string),
	}),
};
