import React from "react";
import styles from "../../css/loctemplate/DeleteTemplate.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	cancel: {
		padding: "1.4rem 2.2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[700],
	},
	confirmDelete: {
		padding: "1.4rem 2.2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: red[600],
		color: "#ffffff",
		marginLeft: "1rem",
	},
};

const msg = `Warning: deleting a template is not reversible. Proceed with caution!`;

const DeleteMsg = ({ templateName }) => {
	return (
		<div className={styles.DeleteMsg}>
			<div className={styles.DeleteMsg_name}>Delete: {templateName}</div>
			<div className={styles.DeleteMsg_msg}>{msg}</div>
		</div>
	);
};

const DeleteTemplate = ({ templateName, confirmDelete, cancelDelete }) => {
	return (
		<div className={styles.DeleteTemplate}>
			<div className={styles.DeleteTemplate_template}>
				<h2 className={styles.DeleteTemplate_template_name}>
					Template: <b>{templateName}</b>
				</h2>
			</div>
			<div className={styles.DeleteTemplate_warning}>
				<DeleteMsg templateName={templateName} />
			</div>
			<div className={styles.DeleteTemplate_actions}>
				<ButtonSM customStyles={customCSS.cancel} handleClick={cancelDelete}>
					Cancel
				</ButtonSM>
				<ButtonSM
					customStyles={customCSS.confirmDelete}
					handleClick={confirmDelete}
				>
					Yes, delete template
				</ButtonSM>
			</div>
		</div>
	);
};

export default DeleteTemplate;

DeleteTemplate.defaultProps = {};

DeleteTemplate.propTypes = {};
