import React, { useState } from "react";
import styles from "../../css/user/DeleteUserButton.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";

const DeleteUserButton = ({ isDisabled = false, handleClick }) => {
	const [showLabel, setShowLabel] = useState(false);

	return (
		<button
			type="button"
			disabled={isDisabled}
			onClick={handleClick}
			className={
				showLabel ? styles.DeleteUserButtonLabel : styles.DeleteUserButton
			}
			onMouseOver={() => setShowLabel(true)}
			onMouseLeave={() => setShowLabel(false)}
		>
			<svg className={styles.DeleteUserButton_icon}>
				<use xlinkHref={`${sprite}#icon-delete`}></use>
			</svg>
			{showLabel && (
				<span className={styles.DeleteUserButton_label}>Delete User</span>
			)}
		</button>
	);
};

export default DeleteUserButton;

DeleteUserButton.defaultProps = {
	isDisabled: false,
};

DeleteUserButton.propTypes = {
	isDisabled: PropTypes.bool,
	handleClick: PropTypes.func.isRequired,
};
