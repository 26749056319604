import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import { useIdleTimer } from "react-idle-timer";
import { withRouter } from "react-router-dom";
import { AuthContext, initialAuthState } from "../state/AuthContext";
import { convertMinsToMs } from "../helpers/utils_dates";
import { GlobalStateContext } from "../state/GlobalStateContext";

// ##TODOS:
// - Consider triggering a dialog UI:
//    - Ask user whether they wanna stay logged in?
//    - If no response, after 30 secs, then force logout
//    - If user confirms, then refresh the auth token

// 30 mins of inactivity
const timeout = convertMinsToMs(30);

const AuthTimeout = ({ history, children }) => {
	const { setAuthData } = useContext(AuthContext);
	const { dispatch: dispatchToState } = useContext(GlobalStateContext);

	// force logout after 30 mins of idle
	const handleOnIdle = (e) => {
		setAuthData({ ...initialAuthState });
		dispatchToState({ type: "FORCE_LOGOUT" });
		// set 'wasLoggedOut' state
		history.replace({
			pathname: "/",
			state: {
				wasLoggedOut: true,
			},
		});
	};

	const handleOnActive = (e) => {
		// console.log(`Time remaining (in ms)? `, getRemainingTime());
	};

	const handleOnAction = (e) => {
		// console.log(`User did something:`, e);
	};

	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: timeout, // 3 minutes
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500,
	});

	return <>{children}</>;
};

export default withRouter(AuthTimeout);

AuthTimeout.defaultProps = {};

AuthTimeout.propTypes = {
	children: PropTypes.any,
};
