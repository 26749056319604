import React, { useRef, useEffect } from "react";
import styles from "../../css/user/AvatarModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";

const AvatarModal = ({ title = "Edit Avatar Photo", closeModal, children }) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const userEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || userEscaped) {
			return closeModal();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeModal, userEscaped]);

	return (
		<div className={styles.AvatarModal} ref={modalRef}>
			<div className={styles.AvatarModal_top}>
				<h2 className={styles.AvatarModal_top_title}>{title}</h2>
				<div className={styles.AvatarModal_top_close} onClick={closeModal}>
					<svg className={styles.AvatarModal_top_close_icon}>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
				</div>
			</div>
			<div className={styles.AvatarModal_main}>{children}</div>
		</div>
	);
};

export default AvatarModal;

AvatarModal.defaultProps = {
	title: "Edit Avatar Photo",
};

AvatarModal.propTypes = {
	title: PropTypes.string,
	closeModal: PropTypes.func.isRequired,
	children: PropTypes.any,
};
