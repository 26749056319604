import React, { useState } from "react";
import styles from "../../css/forms/PasswordValidator.module.scss";
import { PropTypes } from "prop-types";
import {
	calcPasswordScore,
	getPasswordStrength,
} from "../../helpers/utils_validation";
// components
import PasswordInput from "../shared/PasswordInput";
import StrengthIndicator from "./StrengthIndicator";

// ##TODOS:
// - Enable error messages for password requirements

const minLength = 6;

const PasswordValidator = ({
	name = "newPassword",
	id = "newPassword",
	label = "Enter New Password",
	initialVal = "",
	autoComplete = "none",
	placeholder = "Enter a strong password...",
	handleChange,
	customStyles = {},
}) => {
	const [val, setVal] = useState(initialVal);
	const [validation, setValidation] = useState({
		strength: getPasswordStrength(initialVal),
		score: calcPasswordScore(initialVal, minLength),
	});

	const { score } = validation;

	// local onChange handler, forwards value
	const onChangeHandler = (e) => {
		const { value } = e.target;
		setVal(value);
		handleChange(e);
	};

	// runs validation & handles 'onChange'
	const runValidator = (e) => {
		const { value } = e.target;
		onChangeHandler(e);

		const newScore = calcPasswordScore(value, minLength);
		setValidation({
			strength: getPasswordStrength(newScore),
			score: newScore,
		});
	};

	return (
		<div className={styles.PasswordValidator}>
			<div className={styles.PasswordValidator_input}>
				<PasswordInput
					name={name}
					id={id}
					val={val}
					label={label}
					handleChange={runValidator}
					autoComplete={autoComplete}
					placeholder={placeholder}
					customStyles={customStyles}
				/>
			</div>
			<div className={styles.PasswordValidator_indicator}>
				<StrengthIndicator score={score} />
			</div>
			<ul className={styles.PasswordValidator_errors}>
				{/*  */}
				{/*  */}
				{/*  */}
			</ul>
		</div>
	);
};

export default PasswordValidator;

PasswordValidator.defaultProps = {
	name: "newPassword",
	id: "newPassword",
};

PasswordValidator.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	handleChange: PropTypes.func,
};
