import { currentEnv } from "./utils_env";
import { notifications } from "./utils_endpoints";
import { isEmptyObj } from "./utils_types";

/////////////////////////////////////////////////////////////
//////////////// Fetch Notification Requests ////////////////
/////////////////////////////////////////////////////////////

/**
 * Fetches a specified number of incident notification records, pulling from the most recent notifications sent.
 * @param {String} token - Auth token
 * @param {Object} opts - An object query param options
 * @param {String} opts.facilityID - String guid for target facility
 * @param {Number} opts.index - Starting index, as a number.
 * @param {Number} opts.rows - Number of rows to return, as a number.
 * @returns {Array} - Returns an array of 'IncidentNotification' objects
 */
const getIncidentNotifications = async (token, opts = {}) => {
	const { facilityID, index = 0, rows = 5 } = opts;
	let url = currentEnv.base + notifications.incidents;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a specified number of Assessment-Change notification records, pulling from the most recent notifications sent.
 * @param {String} token - Auth token
 * @param {Object} opts - An object query param options
 * @returns {Array} - Returns an array of 'AssessmentChangeNotification' objects
 */
const getAssessmentNotifications = async (token, opts = {}) => {
	const { facilityID, index = 0, rows = 5 } = opts;
	let url = currentEnv.base + notifications.assessments;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a specified number of Admin-Lockout notification records, pulling from the most recent notifications sent.
 * @param {String} token - Auth token
 * @param {Object} opts - An object query param options
 * @returns {Array} - Returns an array of 'AdminLockoutNotification' objects
 */
const getAdminLockoutNotifications = async (token, opts = {}) => {
	const { facilityID, index = 0, rows = 5 } = opts;
	let url = currentEnv.base + notifications.adminLockout;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches all notification lists, returns an object containing the raw lists in their respective properties.
 * @param {String} token - Security token
 * @param {Object} opts - An object of query param settings (eg. 'facilityID', 'index', 'rows')
 * @returns {Object} - Returns an object with each notification list as a property
 */
const getAllNotificationsLists = async (token, opts = {}) => {
	const [incidents, assessments, lockouts] = await Promise.all([
		getIncidentNotifications(token, opts),
		getAssessmentNotifications(token, opts),
		getAdminLockoutNotifications(token, opts),
	]);

	return {
		incidents: incidents,
		assessments: assessments,
		lockouts: lockouts,
	};
};

/////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// Notification UPDATER Requests /////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Updates ONLY the email recipients' list for 'IncidentNotifications'
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Object} updatedRecord - Updated 'IncidentNotification' record w/ updated email list
 * @returns {Boolean} - Returns whether email list was updated successfully.
 */
const updateIncidentEmailList = async (
	token,
	facilityID,
	updatedRecord = {}
) => {
	let url = currentEnv.base + notifications.updateList.incidents;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify([updatedRecord]),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Updates ONLY the email recipients' list for 'AssessmentChangeNotifications'
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Object} updatedRecord - Updated 'AssessmentChangeNotifications' record w/ updated email list
 * @returns {Boolean} - Returns whether email list was updated successfully.
 */
const updateAssessmentEmailList = async (
	token,
	facilityID,
	updatedRecord = {}
) => {
	let url = currentEnv.base + notifications.updateList.assessments;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify([updatedRecord]),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Updates ONLY the email recipients' list for 'AdminLockoutNotifications'
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Object} updatedRecord - Updated 'AdminLockoutNotification' record w/ updated email list
 * @returns {Boolean} - Returns whether email list was updated successfully.
 */
const updateLockoutEmailList = async (
	token,
	facilityID,
	updatedRecord = {}
) => {
	let url = currentEnv.base + notifications.updateList.lockouts;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify([updatedRecord]),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////////////////
//////////////// Remove Recipient Notification Requests ////////////////
////////////////////////////////////////////////////////////////////////

/**
 * Removes an email recipient from the Incidents Notification list & fires off the request to save changes to the database.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Object} updatedRecord - Updated IncidentNotification record w/ updated email recipient's list.
 * @returns {Boolean} - Returns whether incidents list was updated successfully or not.
 */
const removeFromIncidentsList = async (
	token,
	facilityID,
	updatedRecord = {}
) => {
	let url = currentEnv.base + notifications.removeRecipient.fromIncidents;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedRecord),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Removes an email recipient from the Assessment-Change Notification list & fires off the request to save changes to the database.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Object} updatedRecord - Updated 'AssessmentChangeNotification' record w/ updated email recipient's list.
 * @returns {Boolean} - Returns whether Assessment-Change list was updated successfully or not.
 */
const removeFromAssessmentsList = async (
	token,
	facilityID,
	updatedRecord = {}
) => {
	let url = currentEnv.base + notifications.removeRecipient.fromAssessments;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedRecord),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Removes an email recipient from the Admin-Lockouts Notification list & fires off the request to save changes to the database.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Object} updatedRecord - Updated 'AdminLockoutNotification' record w/ updated email recipient's list.
 * @returns {Boolean} - Returns whether Admin-Lockouts list was updated successfully or not.
 */
const removeFromLockoutsList = async (
	token,
	facilityID,
	updatedRecord = {}
) => {
	let url = currentEnv.base + notifications.removeRecipient.fromLockouts;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedRecord),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Determines which notifications list a recipient should be removed from and fires off request.
 * @param {String} token - Auth token
 * @param {String} recipientID - Guid for recipient on notifications' list.
 * @param {String} listType - Notifications' list type (eg. 'INCIDENTS', 'ASSESSMENTS', 'LOCKOUTS')
 * @returns {Boolean} - Returns whether recipient was successfully removed or not.
 */
const removeFromNotificationsList = async (
	token,
	facilityID,
	listType = "INCIDENTS",
	updatedRecord = {}
) => {
	switch (listType) {
		case "INCIDENTS": {
			const wasRemoved = await removeFromIncidentsList(
				token,
				facilityID,
				updatedRecord
			);
			return wasRemoved;
		}
		case "ASSESSMENTS": {
			const wasRemoved = await removeFromAssessmentsList(
				token,
				facilityID,
				updatedRecord
			);
			return wasRemoved;
		}
		case "LOCKOUTS": {
			const wasRemoved = await removeFromLockoutsList(
				token,
				facilityID,
				updatedRecord
			);
			return wasRemoved;
		}
		default:
			return false;
	}
};

/////////////////////////////////////////////////////////////
//////////////////// Notification Utils ////////////////////
/////////////////////////////////////////////////////////////

/**
 * Fetches X number of all notifications & returns the most recent record from each as an object.
 * @param {String} token - Auth token
 * @param {Object} opts - Query param settings (eg. 'facilityID', 'rows', 'index')
 * @returns {Object} - Returns an object of notification keys w/ the most recent record for each notifiation type
 */
const fetchAndProcessNotifications = async (token, opts = {}) => {
	const { incidents, assessments, lockouts } = await getAllNotificationsLists(
		token,
		opts
	);
	// JUST GET MOST RECENT RECORD, IF AVAILABLE
	const incidentRecent = getMostRecentIncidentNotification(incidents);
	const assessmentRecent = getMostRecentAssessmentNotification(assessments);
	const lockoutRecent = getMostRecentLockoutNotification(lockouts);

	return {
		incidents: incidentRecent,
		assessments: assessmentRecent,
		lockouts: lockoutRecent,
	};
};

const processAllNotifications = (allNotifications = {}) => {
	const { incidents, assessments, lockouts } = allNotifications;

	// JUST GET MOST RECENT RECORD, IF AVAILABLE
	const incidentRecent = getMostRecentIncidentNotification(incidents);
	const assessmentRecent = getMostRecentAssessmentNotification(assessments);
	const lockoutRecent = getMostRecentLockoutNotification(lockouts);

	return {
		incidents: incidentRecent,
		assessments: assessmentRecent,
		lockouts: lockoutRecent,
	};
};
// process assessment-change notifications record
const processAssessmentRecord = (assessment = {}) => {
	const {
		ID: id,
		FacilityID: facilityID,
		EmailsTo: sentTo,
		datStart: startDate,
	} = assessment;
	const emailList = getAssessmentRecipients(assessment);

	const newObj = {
		id,
		facilityID,
		sentTo,
		startDate,
		emailList: emailList,
	};

	return newObj;
};
// process incidents notifications record
const processIncidentRecord = (incident = {}) => {
	const {
		ID: id,
		IncidentID: incidentID,
		RunDate: runDate,
		RunID: runID,
		SentTo: sentTo,
		datSent: dateSent,
	} = incident;
	const emailList = getIncidentsRecipients(incident);

	const newObj = {
		id,
		incidentID,
		runDate,
		runID,
		sentTo,
		dateSent,
		emailList: emailList,
	};

	return newObj;
};
// process lockout notifications record
const processLockoutsRecord = (lockout = {}) => {
	const {
		ID: id,
		EmailsTo: sentTo,
		FacilityID: facilityID,
		datStart: startDate,
	} = lockout;
	const emailList = getLockoutRecipients(lockout);

	const newObj = {
		id,
		sentTo,
		facilityID,
		startDate,
		emailList: emailList,
	};

	return newObj;
};

// gets most recent record & normalizes each record.
const processAllNotificationLists = (allNotifications = {}) => {
	const { incidents, assessments, lockouts } = allNotifications;

	// JUST GET MOST RECENT RECORD, IF AVAILABLE
	const incidentRecent = getMostRecentIncidentNotification(incidents);
	const assessmentRecent = getMostRecentAssessmentNotification(assessments);
	const lockoutRecent = getMostRecentLockoutNotification(lockouts);

	// processed/normalized records
	const incident = processIncidentRecord(incidentRecent);
	const assessment = processAssessmentRecord(assessmentRecent);
	const lockout = processLockoutsRecord(lockoutRecent);

	return {
		incidents: incident,
		assessments: assessment,
		lockouts: lockout,
	};
};

// sorts 'Incident Notifications' list
const sortIncidentNotifications = (list = []) => {
	// datSent
	return (
		list?.sort((a, b) => {
			// return new Date(b?.datSent) - new Date(a?.datSent); // OLD VERSION
			return new Date(b?.datStart) - new Date(a?.datStart);
		}) ?? []
	);
};
// sorts 'Assessment Notifications' list
const sortAssessmentNotifications = (list = []) => {
	// datStart
	return (
		list?.sort((a, b) => {
			return new Date(b?.datStart) - new Date(a?.datStart);
		}) ?? []
	);
};
// sorts 'Admin Lockout Notifications' list
const sortLockoutNotifications = (list = []) => {
	// datStart
	return (
		list?.sort((a, b) => {
			return new Date(b?.datStart) - new Date(a?.datStart);
		}) ?? []
	);
};

/////////////////////////////////////////////////////////////////////////////////
//////////////////// Extract Notification Email List  Utils ////////////////////
/////////////////////////////////////////////////////////////////////////////////

// gets most recent incident record
const getMostRecentIncidentNotification = (list = []) => {
	const sorted = sortIncidentNotifications(list);
	const mostRecent = sorted?.[0];
	return mostRecent;
};
// gets most recent assessment record
const getMostRecentAssessmentNotification = (list = []) => {
	const sorted = sortAssessmentNotifications(list);
	const mostRecent = sorted?.[0];
	return mostRecent;
};
// gets most recent admin-lockout record
const getMostRecentLockoutNotification = (list = []) => {
	const sorted = sortLockoutNotifications(list);
	const mostRecent = sorted?.[0];
	return mostRecent;
};
// get list of admin-lockout email recipients
const getLockoutRecipients = (mostRecent = {}) => {
	if (isEmptyObj(mostRecent) || !mostRecent) return [];

	const { EmailsTo } = mostRecent;
	return EmailsTo.split("; ");
};
// get list of assessment-change email recipients
const getAssessmentRecipients = (mostRecent = {}) => {
	if (isEmptyObj(mostRecent) || !mostRecent) return [];

	const { EmailsTo } = mostRecent;
	return EmailsTo.split("; ");
};
// get list of incident email recipients
const getIncidentsRecipients = (mostRecent = {}) => {
	if (isEmptyObj(mostRecent)) return [];
	// OLD VERSION
	// const { SentTo } = mostRecent;
	// return SentTo.split("; ");
	const { EmailsTo } = mostRecent;
	return EmailsTo.split("; ");
};

///////////////////////////////////////////////////////////////////////////
////////////////// REMOVE EMAIL FROM RECORD'S EMAIL LIST //////////////////
///////////////////////////////////////////////////////////////////////////

// remove incident email from list
const removeIncidentRecipient = (emailToRemove, incidentRecord = {}) => {
	const list = getIncidentsRecipients(incidentRecord);
	const updatedList = list.filter((email) => email !== emailToRemove);
	const listAsString = updatedList.join("; ");

	return listAsString;
};
const removeAssessmentRecipient = (emailToRemove, assessmentRecord = {}) => {
	const list = getAssessmentRecipients(assessmentRecord);
	const updatedList = list.filter((email) => email !== emailToRemove);
	const listAsString = updatedList.join("; ");

	return listAsString;
};
const removeLockoutRecipient = (emailToRemove, lockoutRecord = {}) => {
	const list = getLockoutRecipients(lockoutRecord);
	const updatedList = list.filter((email) => email !== emailToRemove);
	const listAsString = updatedList.join("; ");

	return listAsString;
};

/**
 * Removes a provided email address from the list of recipients for a given notification record & returns the updated record.
 * - Supports all notification list types. But requires the 'listType'.
 * @param {String} listType - Notification list type (eg 'INCIDENTS', 'ASSESSMENTS', 'LOCKOUTS').
 * @param {String} emailToRemove - User's email to be removed from notification list.
 * @param {Object} currentRecord - Current notification record to be updated.
 * @returns {Object} - Returns updated Notification record w/ updated email list.
 */
const removeRecipientByListType = (
	listType,
	emailToRemove,
	currentRecord = {}
) => {
	switch (listType) {
		case "INCIDENTS": {
			const updatedList = removeIncidentRecipient(emailToRemove, currentRecord);

			return {
				...currentRecord,
				EmailsTo: updatedList,
			};
		}
		case "ASSESSMENTS": {
			const updatedList = removeAssessmentRecipient(
				emailToRemove,
				currentRecord
			);

			return {
				...currentRecord,
				EmailsTo: updatedList,
			};
		}
		case "LOCKOUTS": {
			const updatedList = removeLockoutRecipient(emailToRemove, currentRecord);

			return {
				...currentRecord,
				EmailsTo: updatedList,
			};
		}
		default:
			return new Error(`❌ Oops! Invalid notification type: `, listType);
	}
};

export {
	// get notification lists
	getIncidentNotifications,
	getAssessmentNotifications,
	getAdminLockoutNotifications,
	// fetch all notifications lists at once
	getAllNotificationsLists,
	// update notification email list utils
	updateIncidentEmailList,
	updateAssessmentEmailList,
	updateLockoutEmailList,
	// remove recipient request utils
	removeFromIncidentsList,
	removeFromAssessmentsList,
	removeFromLockoutsList,
	// wrapper around all client-side remove handlers
	removeRecipientByListType,
	// multi-type handler
	removeFromNotificationsList,
};
// handlers for processing notifications
export { fetchAndProcessNotifications };

export {
	processAllNotifications,
	processAllNotificationLists,
	// sorting utils
	sortIncidentNotifications,
	sortAssessmentNotifications,
	sortLockoutNotifications,
	// get email list
	getLockoutRecipients,
	getAssessmentRecipients,
	getIncidentsRecipients,
	// creates updated list by remove email from record's email list
	removeAssessmentRecipient,
	removeIncidentRecipient,
	removeLockoutRecipient,
};
