import React, { useMemo, useState, useEffect, useCallback } from "react";
import styles from "../../css/views/ManageResidentsView.module.scss";
import { PropTypes } from "prop-types";
import {
	formatAndSortResidents,
	getResidentsByFacility,
	processResidentsList,
} from "../../helpers/utils_residents";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
// components
import ResidentsTable from "../../components/residents/ResidentsTable";
import Spinner from "../../components/shared/Spinner";
import Placeholder from "../../components/shared/Placeholder";
import { getUserFacilityID } from "../../helpers/utils_facility";

// REQUIREMENTS:
// - Transfer resident to different facility
// - Consider showing a resident's table, like 'UserAccessTable'

const ManageResidentsView = ({
	globalState,
	dispatchToState,
	dispatchAlert,
	currentUser,
	currentFacility,
	selectedFacility,
}) => {
	const [facilityResidents, setFacilityResidents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchResidents = useCallback(async () => {
		const { token } = currentUser;
		const facilityID = getUserFacilityID(
			selectedFacility,
			currentUser?.facilities
		);
		const residents = await getResidentsByFacility(token, facilityID);

		if (!isEmptyArray(residents)) {
			setIsLoading(false);
			return setFacilityResidents([...residents]);
		} else {
			setIsLoading(false);
			return setFacilityResidents([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(selectedFacility)) {
			fetchResidents();
		}

		return () => {
			isMounted = false;
		};
	}, [fetchResidents, selectedFacility]);

	if (isEmptyVal(selectedFacility)) {
		return (
			<div className={styles.ManageResidentsView_warning}>
				<Placeholder msg="Select A Facility (above)" size="MD" />
			</div>
		);
	}
	return (
		<div className={styles.ManageResidentsView}>
			<div className={styles.ManageResidentsView_options}>
				<ResidentsTable
					key={`RESIDENTS-TABLE:${facilityResidents?.length}-${selectedFacility}-${isLoading}`}
					isLoading={isLoading}
					currentUser={currentUser}
					currentFacility={currentFacility}
					facilityResidents={[...processResidentsList(facilityResidents)]}
				/>
			</div>
		</div>
	);
};

export default ManageResidentsView;

ManageResidentsView.defaultProps = {};

ManageResidentsView.propTypes = {};
