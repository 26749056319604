import React from "react";
import styles from "../../css/residents/ResidentsTableBody.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import ResidentsTableRow from "./ResidentsTableRow";

const ResidentsTableBody = ({
	currentUser,
	currentFacility,
	allResidents = [],
}) => {
	return (
		<div className={styles.ResidentsTableBody}>
			{!isEmptyArray(allResidents) &&
				allResidents.map((resident, idx) => (
					<ResidentsTableRow
						key={`Resident-${resident.residentID}--${idx}`}
						resident={resident}
						currentFacility={currentFacility}
						currentUser={currentUser}
					/>
				))}
		</div>
	);
};

export default ResidentsTableBody;

ResidentsTableBody.defaultProps = {};

ResidentsTableBody.propTypes = {};
